import { Categorie } from './exportable';

const examencijfers_opleiding: Categorie = {
	label: 'Examencijfer',
	atts: [
		{ att: 'ekc_nm_bron', example: 'cl, duo' },
		{ att: 'ekc_nm_cijfertype', example: 'SE-cijfer, CE-cijfer, Eindcijfer' },
		{ att: 'ekc_nm_niveau', example: 'VMBO b, VMBO k, VMBO (g)t, HAVO, VWO' },
		{ att: 'ekc_nm_schooljaar', example: '2020/2021, 2021/2022, ...' },
		{ att: 'ekc_nr_gem_cijfer', example: '1,0 - 10,0' },
		{ att: 'ekc_nr_q_cijfers', example: '1 - ...', isFilter: false },
	],
};

const examencijfers_school: Categorie = {
	label: 'School',
	atts: [
		{ att: 'ekc_fk_br_vest.br_co_brin', example: '01XX00' },
		{ att: 'ekc_fk_br_vest.br_nm_brin_kort', example: 'Openb SGM CumLaude College' },
	],
};

const examencijfers_vak: Categorie = {
	label: 'Vak',
	atts: [
		{ att: 'ekc_fk_vk.vk_nm_vak', label: 'Vak (officieel)', example: 'Engelse taal en literatuur, wiskunde A, ...' },
		{ att: 'ekc_fk_vk.vk_co_vak', example: '11 - 9999' },
		{ att: 'ekc_fk_vk.vk_nm_vakkengroep', example: 'Engels, Frans, Duits; Wis-, Natuur-, Scheikunde en Biologie, ...' },
		{ att: 'ekc_fk_vk.vk_nm_kernvak', example: 'Nederlands, Engels, wiskunde, overige vakken' },
	],
};

export const examencijfers_all = [examencijfers_opleiding, examencijfers_school, examencijfers_vak];
