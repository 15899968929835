<app-base-dialog [noGap]="true" [noPadding]="true" [topBarTemplate]="topBar" sizeClass="size-22">
	<ng-template #topBar>
		<app-dialog-header class="secondary" title="Favorieten">
			<div class="actions">
				<span class="svg-add-bookmark" (click)="createFavoriet.next()"></span>
				<span class="svg-add-folder" (click)="createMap.next()"></span>
			</div>
		</app-dialog-header>
	</ng-template>
	<app-favorieten-list [createMap]="createMap" [createFavoriet]="createFavoriet"></app-favorieten-list>
</app-base-dialog>
