/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum DoorstroomExt {
  DOORSTROOM = 'Doorstroom',
  OPSTROOM = 'Opstroom',
  DOUBLURE = 'Doublure',
  AFGEWEZEN = 'Afgewezen',
  AFSTROOM = 'Afstroom',
  GESLAAGD = 'Geslaagd',
  DOORSTROOM_EXTERN = 'Doorstroom (extern)',
  OPSTROOM_EXTERN = 'Opstroom (extern)',
  DOUBLURE_EXTERN = 'Doublure (extern)',
  AFGEWEZEN_EXTERN = 'Afgewezen (extern)',
  AFSTROOM_EXTERN = 'Afstroom (extern)',
  INSTROOM = 'Instroom'
}
