<app-button icon="svg-legenda" (clickit)="open = !open" buttonType="secondary">Legenda</app-button>
@if (open) {
	<div class="legenda-anchor">
		<div class="legenda-border" (clickOutside)="open = false" [clickOutsideEvents]="'click,touchend'" [delayClickOutsideInit]="true">
			<div class="legenda-options">
				@for (option of options; track option) {
					<div class="option">
						<div class="legenda" [class]="option.class">
							@if (style === "PILL" && option.value === "Geslaagd") {
								<div class="vink">{{ "\u1E12" }}</div>
							}
						</div>
						<span class="label"> {{ option.value }} </span>
					</div>
				}
			</div>
		</div>
	</div>
}
