import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-dialog-selection-bar',
	templateUrl: './dialog-selection-bar.component.html',
	styleUrls: ['./dialog-selection-bar.component.scss'],
	standalone: true,
})
export class DialogSelectionBarComponent<T> {
	@Input()
	selection!: T;

	@Input()
	selections: T[] = [];

	@Output()
	selectionChange = new EventEmitter<T>();
}
