import { inject } from '@angular/core';
import { CanMatchFn, UrlSerializer } from '@angular/router';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '@cumlaude/shared-services';
import { isUndefined } from 'lodash-es';
import { AuthService } from '@cumlaude/shared-authentication';

export const sharedMatcher: CanMatchFn = (_, segments) => {
	if (segments.length < 2 || segments[0].path !== 'shared') return of(true);

	const authService = inject(AuthService);
	if (!authService.isLoggedIn()) {
		return of(true); // auth-guard onthoudt route en doet de redirect
	}

	const urlSerializer = inject(UrlSerializer);
	const restService = inject(RestService);

	return restService.getGedeeldePaginaUrl(segments[1].path).pipe(
		map((gedeeldePaginaUrl) => {
			if (isUndefined(gedeeldePaginaUrl)) return urlSerializer.parse('shared');

			return urlSerializer.parse(gedeeldePaginaUrl.state);
		})
	);
};
