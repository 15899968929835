<app-dashboard-container [shareVisible]="false" [factTable]="factTable" [exportTypes]="[]">
	<app-filter-panel
		[defaultFilters]="defaultFilters"
		[allFilters]="[]"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/cijferkolommen"
		[permanentFilterExpressions]="permanentFilterExpressions"
	></app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header></app-dashboard-header>
		<app-barchart-table
			[filters]="filterExpressions"
			[permanentFilters]="permanentFilterExpressions"
			[groups]="selectedGroups"
			[config]="this"
		></app-barchart-table>
	</section>
</app-dashboard-container>
