<app-base-dialog sizeClass="size-7-75" [noPadding]="true">
	<div (mouseleave)="dialogRef.close()">
		<div class="option" (click)="dialogRef.close('asc')">
			<div class="svg-sort-asc grey"></div>
			<span>Oplopend</span>
		</div>
		<div class="option" (click)="dialogRef.close('desc')">
			<div class="svg-sort-desc grey"></div>
			<span>Aflopend</span>
		</div>
	</div>
</app-base-dialog>
