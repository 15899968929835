@if (leerling$ | async; as leerling) {
	<div class="dashboard-afwezigheid">
		<app-filter-panel
			[defaultFilters]="filters"
			[allFilters]="getAllFilters()"
			(filterExpressionsChange)="filterExpressions = $event"
			endpoint="/aanwezigheid"
			[permanentFilterExpressions]="getDashboardFilters(leerling.leerling)"
			[overrideDefault]="getSchooljaarFilterOverride(leerling.schooljaarInfo)"
		></app-filter-panel>
		<section class="dashboard">
			<app-dashboard-header [legenda]="['aw_is_abs_geoorloofd']"></app-dashboard-header>
			@if (getSchooljaar()) {
				<app-calendar
					[data]="getCalendarData(filterExpressions!, getDashboardFilters(leerling.leerling))"
					[begin]="getBeginDateForSchooljaar()"
					[generateDay]="generateDay"
					data-exportable
				></app-calendar>
			}
		</section>
	</div>
}
