/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum BovenbouwsuccesUitzondering {
  REGULIER_BOVENBOUWSUCCES = 'Regulier bovenbouwsucces',
  GEEN_BOVENBOUW = 'Geen bovenbouw',
  GEEN_RELEVANTE_PLAATSING = 'Geen relevante plaatsing',
  GEEN_1_OKTOBERPLAATSING = 'Geen 1-oktoberplaatsing',
  TUSSENTIJDSE_INSTROOM = 'Tussentijdse instroom',
  DOORSTROOM_HOGER_ONDERWIJS_ZONDER_UITSLAG = 'Doorstroom hoger onderwijs zonder uitslag',
  OVERGANG_NAAR_PRAKTIJKONDERWIJS = 'Overgang naar praktijkonderwijs',
  UITSTROOM_GEEN_VO = 'Uitstroom/Geen VO',
  NIET_BEKOSTIGBAAR = 'Niet bekostigbaar',
  VAVO_LEERLING = 'VAVO leerling',
  INTERNATIONALE_LEERLING = 'Internationale leerling',
  NIEUWKOMER = 'Nieuwkomer',
  EMIGRATIE = 'Emigratie'
}
