<div class="item" (click)="openGedeeldePaginaPersoonDialog(value, $event)" (mouseenter)="hover.set(true)" (mouseleave)="hover.set(false)">
	<div class="verzender">
		@if (!value.gelezen) {
			<div class="svg-unread"></div>
		}
		<div class="label" [class.ongelezen]="!value.gelezen">{{ value.verzender?.naam }}</div>
	</div>
	<div class="bericht label">
		{{ value.bericht || 'heeft een overzicht met je gedeeld.' }}
	</div>
	<div class="datum" [class.visible]="!meatballVisible()">
		{{ value.verzonden | date: 'dd-MM-yyyy' }}
	</div>
	<app-meatball-menu (opened)="menuOpened.set($event)" size="L" [class.visible]="meatballVisible()">
		<app-meatball-menu-item icon="icon-delete" (click)="delete(value)">Verwijderen</app-meatball-menu-item>
	</app-meatball-menu>
</div>
