import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isUndefined } from 'lodash-es';

export enum PsName {
	prognose = 'prognose',
	subject = 'subject',
}

@Injectable({
	providedIn: 'root',
})
export class PageStateService implements OnDestroy {
	private subscriptions: Subscription[] = [];
	private currentUrl: string = '';

	private state: Partial<{ [name in PsName]: BehaviorSubject<string | undefined> }> = {};

	constructor(router: Router) {
		this.subscriptions.push(
			router.events
				.pipe(
					filter((event) => event instanceof NavigationEnd),
					map((event) => event as NavigationEnd)
				)
				.subscribe((event) => {
					if (this.currentUrl !== event.urlAfterRedirects) {
						this.currentUrl = event.urlAfterRedirects;
					}
				})
		);
	}

	observe(name: PsName): Observable<string | undefined> {
		return this.getBehaviorSubject(name);
	}

	private getBehaviorSubject(name: PsName) {
		let stateElement = this.state[name];
		if (isUndefined(stateElement)) {
			stateElement = new BehaviorSubject<string | undefined>(undefined);
			this.state[name] = stateElement;
		}

		return stateElement;
	}

	dispatch(name: PsName, value: string) {
		this.getBehaviorSubject(name).next(value);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}
}
