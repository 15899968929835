import { Injectable } from '@angular/core';
import { BugsnagService } from '@cumlaude/bugsnag';
import { RCumLaudeAccount, RInstelling, RRol } from '@cumlaude/service-contract';
import Bugsnag from '@bugsnag/js';

@Injectable({
	providedIn: 'root',
})
export class UserBugsnagService extends BugsnagService {
	updateAccount(account: RCumLaudeAccount) {
		if (!this.envConfig.bugsnagApiKey) return;

		Bugsnag.setUser(account.id);
		Bugsnag.addMetadata('user', { rollen: account.rollen });
	}

	updateRol(rol: RRol) {
		if (!this.envConfig.bugsnagApiKey) return;

		Bugsnag.addMetadata('user', { activeRol: rol });
	}

	updateInstelling(instelling: RInstelling) {
		if (!this.envConfig.bugsnagApiKey) return;

		Bugsnag.addMetadata('company', {
			naam: instelling.naam,
			bron: instelling.bron,
			modules: instelling.modules,
		});
	}
}
