<section>
	<h2>Rechten per rol</h2>
	<div class="container">
		<div class="title header">Overzichten delen</div>
		@if (rollen$ | async; as rollen) {
			<div class="content">
				<div class="row header">
					<div class="cell label"></div>
					<div class="cell checkbox">Delen met personen</div>
					<div class="cell checkbox">Delen met groepen</div>
				</div>
				@for (rol of rollen.rollen; track rol) {
					<div class="row">
						<div class="cell label">{{ userService.getRolDescription(rol.rol) }}</div>
						<div class="cell checkbox">
							<app-checkbox
								[value]="rol.delenMetPersonen"
								(valueChange)="changePaginaDelen(rol.rol, rollen.instelling, 'personen', $event)"
							>
							</app-checkbox>
						</div>
						<div class="cell checkbox">
							<app-checkbox
								[value]="rol.delenMetGroepen"
								(valueChange)="changePaginaDelen(rol.rol, rollen.instelling, 'groepen', $event)"
							>
							</app-checkbox>
						</div>
					</div>
				}
			</div>
		}
	</div>
</section>
