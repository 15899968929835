<app-dashboard-container
	class="dashboard-kenmerk"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, getDisplayOptions(), $event)"
>
	<app-filter-panel
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions"
	></app-filter-panel>
	<section class="dashboard" [class]="getKenmerkClass()">
		<app-dashboard-header [variant]="variant" [legenda]="kenmerk"></app-dashboard-header>
		<div class="groepering">
			<div class="weergave kenmerk">
				Kenmerk
				<app-attribute-selector
					[value]="kenmerk"
					(valueChange)="qp.dispatch('kenmerk', $event!)"
					[attrPaths]="kenmerken"
				></app-attribute-selector>
			</div>
			<div class="weergave eenheid">
				Eenheid
				<dt-form-dropdown
					[opties]="eenheidOpties"
					[selectedOptie]="getSelectedEenheidOptie()"
					(onOptieClicked)="qp.dispatch('eenheid', $event)"
				></dt-form-dropdown>
			</div>
		</div>
		@switch (variant) {
			@case ("Actueel") {
				<app-barchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="groups"
					[subgroups]="[kenmerk]"
					[config]="this"
				></app-barchart-table>
			}
			@case ("Historie") {
				<app-vbarchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="getHistorieGroups(groups)"
					[subgroups]="getHistorieSubgroups([groups, [kenmerk]])"
					[batchLevels]="isHistorieBatchVariant() ? 1 : 0"
					[config]="this"
					[partitionMeasure]="getPartitionMeasure(eenheid)"
					[schooljaarPath]="['ds_nm_schooljaar_van']"
				></app-vbarchart-table>
			}
		}
	</section>
</app-dashboard-container>
