import { Component, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { roepAchternaam } from '../../../services/labels';
import { map } from 'rxjs/operators';
import { MedewerkerAccountInfo } from '../../../beheer/gebruikers/gebruikers.component';
import { EditAccountDialogComponent } from '../edit-account-dialog/edit-account-dialog.component';
import { RCumLaudeAccount, RRol } from '@cumlaude/service-contract';
import { AccountsService } from '../../../services/accounts.service';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { CheckboxComponent, InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { BaseDialogComponent, DialogHeaderComponent } from '@cumlaude/shared-components-dialogs';
import { includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';

type MedewerkerX = MedewerkerAccountInfo & { ix: number; heeftAccount: boolean };
export type RCumLaudeAccountX = RCumLaudeAccount & { viaExternDialog: boolean; linkCredentials: boolean };

@Component({
	selector: 'app-interne-accounts-toevoegen-dialog',
	templateUrl: './interne-accounts-toevoegen-dialog.component.html',
	styleUrls: ['./interne-accounts-toevoegen-dialog.component.scss'],
	standalone: true,
	imports: [BaseDialogComponent, DialogHeaderComponent, CheckboxComponent, InstantSearchBoxComponent, ButtonComponent, AsyncPipe],
})
export class InterneAccountsToevoegenDialogComponent {
	filter$ = new BehaviorSubject('');

	filtered$: Observable<MedewerkerX[]>;

	alleMedewerkers: MedewerkerX[];

	selection = new Set<number>();

	showSelection = false;

	constructor(
		@Inject(DIALOG_DATA)
		private dialogData: { myAccount: RCumLaudeAccount; alleMedewerkers: MedewerkerAccountInfo[]; medewerkersIdsMetAccount: string[] },
		private dialog: Dialog,
		protected thisDialogRef: DialogRef<RCumLaudeAccountX[]>,
		private accountsService: AccountsService
	) {
		const metAccount = new Set(dialogData.medewerkersIdsMetAccount);
		this.alleMedewerkers = dialogData.alleMedewerkers.map((mw, ix) => ({ ...mw, ix, heeftAccount: metAccount.has(mw.mw_co_externid) }));
		this.filtered$ = this.filter$.pipe(
			map((zoek) => {
				const metEnZonderAccount = this.alleMedewerkers.filter((medewerker) =>
					includesIgnoreCaseAndDiacritics(this.getNaam(medewerker), zoek)
				);
				const zonderAccount = metEnZonderAccount.filter((medewerker) => !medewerker.heeftAccount);
				return zonderAccount.length > 0 ? zonderAccount : metEnZonderAccount;
			})
		);
	}

	getNaam(medewerker: MedewerkerX) {
		return `${roepAchternaam(medewerker)} (${medewerker.mw_abb_medewerker})`;
	}

	setShowSelection(value: boolean) {
		this.showSelection = value;
		if (value) this.filter$.next('');
	}

	toggle(medewerker: MedewerkerX) {
		if (this.selection.has(medewerker.ix)) this.selection.delete(medewerker.ix);
		else this.selection.add(medewerker.ix);
	}

	openNieuweExterneGebruikerDialog() {
		const emptyAccount: RCumLaudeAccountX = {
			...this.accountsService.createEmptyAccount(this.dialogData.myAccount),
			viaExternDialog: true,
			linkCredentials: false,
		};
		this.thisDialogRef.addPanelClass('hidden');
		const dialogRef = this.dialog.open<RCumLaudeAccountX>(EditAccountDialogComponent, {
			data: {
				caption: 'Gebruiker toevoegen',
				action: 'Toevoegen',
				model: emptyAccount,
			},
		});
		dialogRef.closed.subscribe((newAccount) => {
			if (newAccount) {
				const medewerkerMetZelfdeEmail = this.alleMedewerkers.find(
					(medewerker) => medewerker.mw_nm_emailadres && medewerker.mw_nm_emailadres.toLowerCase() === newAccount.email!.toLowerCase()
				);
				if (medewerkerMetZelfdeEmail) {
					newAccount.naam = roepAchternaam(medewerkerMetZelfdeEmail);
					newAccount.medewerkerExternId = medewerkerMetZelfdeEmail.mw_co_externid;
				}
				this.thisDialogRef.close([newAccount]);
			} else {
				this.thisDialogRef.removePanelClass('hidden');
			}
		});
	}

	getAccountsForSelection(): RCumLaudeAccountX[] {
		return this.alleMedewerkers
			.filter((mw) => this.selection.has(mw.ix))
			.map((medewerker) => ({
				...this.accountsService.createEmptyAccount(this.dialogData.myAccount),
				naam: roepAchternaam(medewerker),
				afkorting: medewerker.mw_abb_medewerker,
				email: medewerker.mw_nm_emailadres,
				medewerkerExternId: medewerker.mw_co_externid,
				viaExternDialog: false,
				linkCredentials: false,
				rollen: this.getRollen(medewerker),
			}));
	}

	getRollen(medewerker: MedewerkerAccountInfo): RRol[] {
		const docent = medewerker.mw_fun_is_docent ? [RRol.DOCENT] : [];
		const mentor = medewerker.mw_fun_is_mentor ? [RRol.MENTOR] : [];
		return [...docent, ...mentor];
	}
}
