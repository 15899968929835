<app-base-dialog sizeClass="size-17" [bottomBarTemplate]="bottomBar" [smallGap]="true">
	<div class="kop">Exporteren als</div>
	<div class="controls">
		<div class="dropdown-container">
			<dt-form-dropdown
				[opties]="exportTypeOptions"
				[selectedOptie]="findOption(options.type)"
				(onOptieClicked)="options.type = $event"
			></dt-form-dropdown>
		</div>
		<i class="icon-more-options" (click)="openMoreOptionsDialog()"></i>
	</div>
	@if (options.type === 'Data') {
		<app-button (clickit)="openColumnDialog()" buttonType="text"> Selecteer kolommen ({{ options.selectedColumns.length }}) </app-button>
	}
	<ng-template #bottomBar>
		<app-button buttonType="secondary" (clickit)="dialogRef.close()">Annuleren</app-button>
		<app-button (clickit)="dialogRef.close(options)" [enabled]="options.type !== 'Data' || options.selectedColumns.length > 0">
			Exporteren
		</app-button>
	</ng-template>
</app-base-dialog>
