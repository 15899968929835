<app-tab-menu class="menu"></app-tab-menu>
<div class="content">
	<section>
		<h2>Vakken uitsluiten</h2>
		<div>
			Het is mogelijk om vakken uit te sluiten in CumLaude. Dit betekent dat deze vakken niet meer zichtbaar zijn in overzichten en niet meer
			meegenomen worden in gemiddeldeberekeningen.
			<br />
			Let op: wijzigingen worden bij de eerstvolgende nachtelijke dataverwerking verwerkt. Dit betekent dat de aanpassingen pas de volgende dag
			zichtbaar zijn.
		</div>
		<div class="controls">
			<app-instant-search-box (fire)="searchInput$.next($event)" placeholder="Vak zoeken"></app-instant-search-box>
			@if (vestigingOpties$ | async; as vestigingOpties) {
				<dt-form-dropdown
					class="vestiging"
					[opties]="vestigingOpties"
					(onOptieClicked)="vestigingInput$.next($event)"
					placeholder="Alle vestigingen"
					[showSearchInput]="true"
					(onSearchInput)="vestigingSearchInput$.next($event)"
					[searchInput]="(vestigingSearchInput$ | async) ?? ''"
				/>
			}
			<app-toggle-button [checked]="(alleenUitgesloten$ | async) ?? false" (toggled)="alleenUitgesloten$.next($event)"
				>Toon enkel uitgesloten vakken</app-toggle-button
			>
		</div>
		<div class="list">
			@if (vakken$ | async; as vakken) {
				<table>
					<thead>
						<tr>
							<th class="naam">Vak</th>
							<th class="vestiging">Vestiging</th>
							<th class="afkorting">Afkorting</th>
							<th class="uitsluiten">Vak uitsluiten</th>
						</tr>
					</thead>
					<tbody>
						@for (vak of vakken; track $index) {
							<tr [class.uitgesloten]="vak.vakUitsluiting?.uitgesloten">
								<td class="naam">{{ vak.naam }}</td>
								<td class="vestiging">{{ vak.vestiging }}</td>
								<td class="afkorting">{{ vak.afkorting }}</td>
								<td class="uitsluiten">
									@if (vak.vakUitsluiting?.uitgesloten) {
										<div class="uitsluiten-button svg-check" (click)="setUitgesloten(vak, false)"></div>
									} @else {
										<div class="uitsluiten-button svg-cross" (click)="setUitgesloten(vak, true)"></div>
									}
								</td>
							</tr>
						}
					</tbody>
				</table>
			}
		</div>
	</section>
</div>
