@for (row of rows; track row) {
	<div [class]="isPlaatsingRow(row) ? 'row-lb' : 'row-ds'">
		@if (isPlaatsingRow(row)) {
			@for (cell of row.plaatsingen; track cell) {
				@if (isPlaatsingCell(cell)) {
					<div class="cell-lb" [class.empty]="!cell" [class]="cell?.class" [appTooltip]="cell?.tooltip || ''" tooltipPosition="right">
						<h4>{{ cell?.lb_nm_schooljaar }}</h4>
						<span>{{ cell?.lb_nm_klas }}</span>
						<span>{{ cell?.lb_nm_opleiding }}</span>
						<span>{{ cell?.lb_nr_leerjaar }} {{ cell?.ilt_nm_niveau }}</span>
						<span>{{ cell?.vs_nm_vestiging }}</span>
					</div>
				} @else {
					<div class="cell-ds-h" [class]="cell?.class" [class.empty]="!cell"></div>
				}
			}
		} @else {
			@for (cell of row.doorstroom; track cell) {
				<div class="cell-ds-v" [class]="cell?.class" [class.empty]="!cell" [class.label]="cell?.label">
					{{ cell?.label }}
				</div>
			}
		}
	</div>
}
