import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { findLast, isUndefined } from 'lodash-es';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class HistoryService implements OnDestroy {
	subscription: Subscription;
	history: string[] = [];

	constructor(
		protected router: Router,
		private location: Location
	) {
		this.subscription = router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map((event) => event as NavigationEnd)
			)
			.subscribe((event) => this.history.push(event.urlAfterRedirects));
	}

	backToPageNot(urlPart: string) {
		const targetUrl = findLast(this.history, (url) => !url.startsWith(urlPart));
		this.navigate(targetUrl);
	}

	back(defaultUrl = '/') {
		this.history.pop();
		if (this.history.length > 0) {
			this.location.back();
		} else {
			this.navigate(defaultUrl);
		}
	}

	private navigate(targetUrl: string | undefined) {
		if (isUndefined(targetUrl)) return;

		this.router.navigateByUrl(targetUrl);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
