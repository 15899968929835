import { Component } from '@angular/core';
import { TableCellComponent } from '../../table/table.model';

@Component({
	selector: 'app-pivot-series-header-cell',
	templateUrl: './pivot-series-header-cell.component.html',
	styleUrls: ['./pivot-series-header-cell.component.scss'],
	standalone: true,
})
export class PivotSeriesHeaderCellComponent implements TableCellComponent<PivotSeriesData> {
	data!: PivotSeriesData;
	className?: string;
}

export type PivotSeriesData = {
	seriesKey: string;
	colKeys: string[];
};
