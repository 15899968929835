import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
	private urls: string[];

	constructor(
		private authService: OAuthService,
		@Inject(ENV_CONFIG) envConfig: EnvConfiguration
	) {
		this.urls = [...(envConfig.dataUrl ? [envConfig.dataUrl] : []), envConfig.restUrl];
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.urls.some((url) => req.url.startsWith(url))) {
			const authHeader = this.authService.authorizationHeader();
			// Clone the request to add the new header.
			const authReq = req.clone({ headers: req.headers.set('Authorization', authHeader) });
			// Pass on the cloned request instead of the original request.
			return next.handle(authReq);
		} else {
			return next.handle(req);
		}
	}
}
