import { Pipe, PipeTransform } from '@angular/core';
import { formatDecimal } from './extended-decimal.pipe';

@Pipe({
	name: 'extendedPercent',
	standalone: true,
})
export class ExtendedPercentPipe implements PipeTransform {
	transform(value: number, format: string): string {
		return formatDecimal(value * 100, format) + '%';
	}
}
