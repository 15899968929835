import { Injectable } from '@angular/core';
import { RestService } from '@cumlaude/shared-services';
import { combineLatest, Observable, shareReplay, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { dateDiffInHours } from '@cumlaude/shared-utils';
import { CumLaudeVersionInfo } from '@cumlaude/service-contract';
import { VERSION } from '@cumlaude/version-generator';
import * as semver from 'semver';
import { AuthService } from '@cumlaude/shared-authentication';

@Injectable({
	providedIn: 'root',
})
export class VersionService {
	restVersionInfo$: Observable<CumLaudeVersionInfo>;
	dataserviceVersionInfo$: Observable<CumLaudeVersionInfo>;

	versionInfos$: Observable<[CumLaudeVersionInfo, CumLaudeVersionInfo]>;

	succesvolETLMoreThan24$: Observable<boolean>;
	latestVersion$: Observable<string>;

	constructor(
		protected restService: RestService,
		protected authService: AuthService
	) {
		this.restVersionInfo$ = authService.loggedIn$.pipe(
			filter((isLoggedIn) => isLoggedIn),
			switchMap(() => restService.getRestVersionInfo()),
			shareReplay(1)
		);
		this.dataserviceVersionInfo$ = authService.loggedIn$.pipe(
			filter((isLoggedIn) => isLoggedIn),
			switchMap(() => restService.getDataserviceVersionInfo()),
			shareReplay(1)
		);
		this.versionInfos$ = combineLatest([this.restVersionInfo$, this.dataserviceVersionInfo$]);
		this.succesvolETLMoreThan24$ = this.restVersionInfo$.pipe(
			map((version) => {
				const laatsteSuccesvolleRun = version.etlLaatsteSuccesvolleRunDateTime;
				if (!laatsteSuccesvolleRun) return false;

				return dateDiffInHours(new Date(laatsteSuccesvolleRun), new Date()) >= 24;
			})
		);
		this.latestVersion$ = this.versionInfos$.pipe(
			map(([restVersionInfo, dataServiceVersionInfo]) => this.getLatestVersion(restVersionInfo, dataServiceVersionInfo, VERSION))
		);
	}

	getLatestVersion(restVersion: CumLaudeVersionInfo, dataserviceVersion: CumLaudeVersionInfo, frontendVersion: string): string {
		return [restVersion.dockerTag, dataserviceVersion.dockerTag, frontendVersion].filter(Boolean).sort(semver.rcompare)[0];
	}
}
