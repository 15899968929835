<div class="selection-box">
	@for (optie of opties; track optie) {
		<div class="optie" [class.is-selected]="isSelected(optie)" (click)="writeValue(optie.value, $event)" [title]="optie.text">
			@if (optie.icon) {
				<i class="{{ optie.icon }} icon-medium icon" [style.color]="optie.iconColor"></i>
			}
			<span class="optie-text text-content {{ optie.style }}">{{ optie.text }}</span>
		</div>
	}
</div>
