/**
 * Atts / AttrPaths die eindigen op deze attributen worden niet getoond als filter/groepering als het een Somtoday school is.
 */
import { Attr } from './data.service';
import { ExtraFilterNames } from './filter-config';
import { Cijferkolomtype } from '@cumlaude/metadata';

export const somtodayBlocklist: (Attr | ExtraFilterNames)[] = [];

export const somtodayUnsupportedCijferkolomtypes = [Cijferkolomtype.ADVIES];
