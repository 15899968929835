import { Component, Type } from '@angular/core';
import { LabelCellComponent, LabelCellData } from '../cells/label-cell/label-cell.component';
import { get, isNil, isUndefined } from 'lodash-es';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { formatDate } from '@angular/common';
import { TooltipType } from '@cumlaude/shared-components-overlays';
import { formatDurationUrenMinuten, MinutesFormat } from '@cumlaude/shared-utils';

export enum AlternatingMode {
	DISABLED = 'Disabled',
	COLOR = 'Color',
	LINE = 'Line',
}

export interface TableModel<M> {
	data: M[];
	columnDefs: ColumnDef<M>[];
	showHeaders: boolean;
	showFooters: boolean;
	/** Extra footer boven de reguliere footer */
	showExtraFooters: boolean;
	rowsClickable: boolean;
	stickyFooters: boolean;
	stickyHeaders: boolean;
	alternating: AlternatingMode;

	columns(): string[];

	getColumnDef(column: string): ColumnDef<M>;

	getRowId(rowModel: M): string;

	getRowgroup?: (rowModel: M) => Rowgroup;

	isEmpty(): boolean;
}

export interface ColumnDef<M> {
	column: string;
	header: HeaderCellDef<M, any>;
	body: CellDef<M, any>;
	footer: FooterCellDef<M, any>;
	extraFooter?: FooterCellDef<M, any>;
	sortable: boolean;
	type?: any;
	visible: boolean | (() => boolean);
	exportable?: boolean | (() => boolean);
	sticky: boolean;
}

export interface Rowgroup {
	groupId: number;
	lastOfGroup: boolean;
	class?: string;
}

type Partial<T> = { [P in keyof T]?: T[P] };

export type CellDataType = 'string' | 'number' | 'percentage' | 'date' | 'datetime' | 'minutes';

export const DEFAULT_FORMATS = {
	number: '1.0-1',
	percentage: '1.0-0',
	string: undefined,
	date: 'dd-MM-yyyy',
	datetime: 'dd-MM-yyyy HH:mm:ss',
	minutes: 'u m',
};

export function getFormattedValue(data: any, dataType?: CellDataType, format?: string): string {
	const [dataFormat, nullLabel] = format?.split('|') ?? [];
	if (isNil(data)) return nullLabel ?? '';
	switch (dataType) {
		case 'number':
			return formatDecimal(data, dataFormat ?? DEFAULT_FORMATS[dataType]);
		case 'percentage':
			return formatDecimal(data * 100, dataFormat ?? DEFAULT_FORMATS[dataType]) + '%';
		case 'date':
			return formatDate(data, dataFormat ?? DEFAULT_FORMATS[dataType], 'nl-NL');
		case 'datetime':
			return formatDate(data, dataFormat ?? DEFAULT_FORMATS[dataType], 'nl-NL');
		case 'minutes':
			return formatDurationUrenMinuten(data, (dataFormat as MinutesFormat) ?? DEFAULT_FORMATS[dataType]);

		default:
			return data?.toString() ?? '';
	}
}

export interface CellDef<M, T> {
	class?: string;
	style?: Partial<CSSStyleDeclaration>;
	component: Type<Component & TableCellComponent<T>>;
	format?: string | ((model: M) => string);
	dataType?: CellDataType | ((model: M) => CellDataType);
	tooltip?: TooltipType | ((model: M) => TooltipType);
	clickHandler?: (rowModel: M) => void;

	getValue(model: M): T;

	getClassName?(model: M): string | undefined;

	getRowspan(model: M): number;

	isVisible(model: M): boolean;
}

export interface HeaderCellDef<M, T> extends CellDef<TableModel<M>, T> {
	headerType: 'default' | 'groepering' | 'addGroup';
	batchGroepering: boolean;
}

export interface FooterCellDef<M, T> extends CellDef<TableModel<M>, T> {}

export interface ExportCellValue {
	value: any;
	type: CellDataType;
	format?: string;
}

export interface TableCellComponent<T> {
	data: T | undefined;
	className?: string;
	format?: string;
	tooltip?: TooltipType;
	dataType?: CellDataType;
	getExportValue?: (data: T, index?: number) => ExportCellValue | any;
}

export function createModel<M>(data: M[], getRowId: (rowModel: M) => string): TableModel<M> {
	return {
		data,
		columnDefs: [],
		showHeaders: true,
		showFooters: false,
		showExtraFooters: false,
		rowsClickable: false,
		stickyHeaders: true,
		stickyFooters: true,
		alternating: AlternatingMode.DISABLED,
		columns(): string[] {
			return this.columnDefs.filter((d) => (typeof d.visible === 'function' ? d.visible() : d.visible)).map((d: ColumnDef<M>) => d.column);
		},
		getColumnDef(column: string): ColumnDef<M> {
			return this.columnDefs.find((d: ColumnDef<M>) => d.column === column)!;
		},
		getRowgroup: undefined,
		getRowId,
		isEmpty(): boolean {
			return this.data.length === 0;
		},
	};
}

export function createColumnDef<M>(column: string, title?: string, sortable: boolean = false, getValue?: (model: M) => string): ColumnDef<M> {
	return {
		column,
		header: createDefaultHeaderCellDef(column, title),
		body: createDefaultCellDef<M>(column, getValue),
		footer: createDefaultFooterCellDef<M>(),
		sortable,
		visible: true,
		sticky: false,
	};
}

export function createDefaultCellDef<M>(column: string, getValue?: (model: M) => string): CellDef<M, LabelCellData> {
	const defaultGetValue = (model: M) => get(model, column)?.toString();
	return {
		component: LabelCellComponent,
		getValue(model: M): string {
			return isUndefined(getValue) ? defaultGetValue(model) : getValue(model);
		},
		getRowspan(_: M): number {
			return 1;
		},
		isVisible: (_: M) => true,
	};
}

export function createDefaultFooterCellDef<M>(): FooterCellDef<M, LabelCellData> {
	return {
		component: LabelCellComponent,
		getValue(): LabelCellData {
			return null;
		},
		getRowspan(): number {
			return 1;
		},
		isVisible: () => true,
	};
}

export function createDefaultHeaderCellDef<M>(column: string, title?: string): HeaderCellDef<M, LabelCellData> {
	return {
		component: LabelCellComponent,
		getValue(): string {
			return title ?? column;
		},
		getRowspan(): number {
			return 1;
		},
		isVisible: () => true,
		headerType: 'default',
		batchGroepering: false,
	};
}
