import { ChangeDetectionStrategy, Component, computed, OnDestroy, OnInit, Signal } from '@angular/core';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { att, att0, percOfParent, percOfTotal } from '../../services/measures';
import {
	Att,
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { Observable } from 'rxjs';
import { maxDeelVanJaar, maxOver, SingleAggregator, sumOver } from '../../services/aggregation';
import { getLeafA, Path } from '../../services/data-tree';
import { memoize } from 'lodash-es';
import { FilterService } from '../../services/filter.service';
import { defaultDoorstroomActueelFilters, defaultDoorstroomHistorieFilters, FilterName } from '../../services/filter-config';
import { BarInfo } from '../../services/stacked-bars';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, BaseDashboardConfig, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { DashboardVariant, Eenheid } from '../../services/weergave-opties';
import { PsName } from '../../services/page-state.service';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { WeergaveOptieComponent } from '../../shared/components/weergave-optie/weergave-optie.component';

interface OvergangI extends Attributes {
	ds_nr_weging: number;
	ds_is_prognose: number;
	xa: { [nr: number]: { ds_nr_weging: number } };
}

interface OvergangA extends Attributes {
	max: number;
	met_overgang: boolean;
	weging: number;
	ds_is_prognose: number;
	maxPctJaar: number;
}

const historieSchooljaarGroup: AttrPath = ['ds_nm_schooljaar_van'];

@Component({
	selector: 'app-overgang',
	templateUrl: './overgang.component.html',
	styleUrls: ['./overgang.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		WeergaveOptieComponent,
	],
})
export class OvergangComponent extends BarchartTableConfig<OvergangI, OvergangA> implements OnInit, OnDestroy {
	defaultGroup: AttrPath = ['ds_fk_ilt_van', 'ilt_nm_niveau'];

	group: AttrPath = this.defaultGroup;

	variant = this.qp.signal('variant');

	eenheid = this.qp.signal('eenheid');

	availableGroups: AttrPath[] = [
		['ds_nm_klas_van'], //
		['ds_nm_uitstroomprofiel_vso_van'],
		['ds_fk_ilt_van', 'ilt_nm_niveau'],
		['ds_fk_ilt_van', 'ilt_abb_profiel'],
		['ds_fk_vs_van', 'vs_nm_vestiging'],
	];

	flexibleMaxGroups = 1;

	flexibleGroupsRemovable = false;

	vanNaarMapping: Partial<{ [van in Att]: AttrPath }> = {
		ds_nm_klas_van: ['ds_nm_klas_naar'],
		'ds_fk_ilt_van.ilt_nm_niveau': ['ds_fk_ilt_naar', 'ilt_nm_niveau'],
		'ds_fk_ilt_van.ilt_abb_profiel': ['ds_fk_ilt_naar', 'ilt_abb_profiel'],
		'ds_fk_vs_van.vs_nm_vestiging': ['ds_fk_vs_naar', 'vs_nm_vestiging'],
		ds_nm_uitstroomprofiel_vso_van: ['ds_fk_lb_naar', 'lb_nm_uitstroomprofiel_vso'],
	};

	actueelFilters: FilterName[] = [
		...defaultDoorstroomActueelFilters, //
		'ds_fk_lb_van.lb_co_brin',
		'ds_co_brin_van',
		'ds_fun_overgangmoment',
		'x_met_overgang',
	];

	historieFilters: FilterName[] = [
		...defaultDoorstroomHistorieFilters, //
		'ds_co_brin_van',
		'ds_fun_overgangmoment',
		'x_met_overgang',
	];

	permanentFilterExpressions: FilterExpression[] = [
		new BasicFilterExpression<number>(['ds_fk_ilt_naar', 'ilt_is_vavo'], 0),
		new BasicFilterExpression<number>(['ds_is_uitstroom_extern'], 0),
		new BasicFilterExpression<number>(['ds_fun_ergens_overgang'], 1),
		new BasicFilterExpression<number>(['ds_is_plaatsing_opeenvolgend'], 1),
		new BasicFilterExpression(['ds_d_plaatsing_tm'], null, '>=', ['ds_fk_lb_van', 'lb_d_1okt']), // Geen pre-1oktoberplaatsingen
	];

	filterExpressions?: FilterExpression[];

	constructor(
		private dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
		this.filterService.configs['x_met_overgang']!.createExpression = this.createOvergangExpression.bind(this);
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	protected getFixedAfterGroups(): number {
		return this.variant() === DashboardVariant.HISTORIE ? 0 : 1;
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe_g().subscribe((groups) => {
				this.setGroup(groups ? groups[0] : this.defaultGroup);
			})
		);
	}

	setGroup(group: AttrPath | null) {
		this.group = <AttrPath>group;
		this.filterService.refresh();
	}

	protected singleAggregators = {
		max: maxOver('ds_nr_weging'),
		weging: sumOver<'ds_nr_weging', OvergangI, number>('ds_nr_weging'),
		ds_is_prognose: maxOver<'ds_is_prognose', OvergangI>('ds_is_prognose'),
		met_overgang,
		maxPctJaar: maxDeelVanJaar('ds_nr_weging'),
	};

	factTable = FactTable.doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		// NB het jaartotaal is hier binnen dezelfde "van" groep; alleen de aantallen over de verschillende "naar" waarden worden opgeteld
		const xa = this.variant() === DashboardVariant.ACTUEEL ? [] : [[1]];
		return this.dataService.getDoorstroomData({ ...options, xa });
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	createLinkData(path: Path<unknown, number[]>, context: DashboardContext<OvergangI, OvergangA, OvergangComponent>): Partial<LinkData> {
		return {
			dashboard: '/details/leerling/doorstroom',
			dataProvider: 'doorstroom',
			...super.createLinkData(path, context),
		};
	}

	createMeasureColumns(): ColumnDef<DataRow<OvergangA>>[] {
		if (this.variant() === DashboardVariant.ACTUEEL)
			return [
				createMeasureColumn<OvergangI, OvergangA>('Overgangen', att('weging'), { format: '1.0-2' }),
				createMeasureColumn('Percentage', percOfParent('weging', 'weging'), { dataType: 'percentage' }),
			];
		else return [];
	}

	enrichTableModel(_context: DashboardContext<OvergangI, OvergangA, OvergangComponent>, tableModel: TableModel<DataRow<OvergangA>>) {
		this.updateHeader(tableModel, tableModel.columnDefs[0], 'Van');
		this.updateHeader(tableModel, tableModel.columnDefs[1], 'Naar');
	}

	private updateHeader(tableModel: TableModel<DataRow<OvergangA>>, columnDef: ColumnDef<DataRow<OvergangA>>, prefix: string) {
		const value = columnDef.header.getValue(tableModel).replace('(naar)', '').replace('volgend schooljaar', '').trim();
		columnDef.header.getValue = () => `${prefix} ${value}`;
	}

	onContextCreated(context: DashboardContext<OvergangI, OvergangA, BaseDashboardConfig<OvergangI, OvergangA>>): void {
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(context.dataRoot?.a.ds_is_prognose)));
	}

	// memoize, otherwise new array keeps triggering change detection
	determineGroups = memoize(this._determineGroups.bind(this), JSON.stringify);

	private _determineGroups(group: AttrPath): AttrPath[] {
		return [group, this.vanNaarMapping[<Att>group.join('.')]!];
	}

	private createOvergangExpression(val: number[] | null): FilterExpression | undefined {
		if (val == null || val.length < 1) return undefined;
		if (val.includes(0) && val.includes(1)) return undefined;
		const op = val[0] == 1 ? '<>' : '=';

		const [attr, other] = this.determineGroups(this.group);
		return new BasicFilterExpression(attr, null, op, other);
	}

	makeBar(attrs: OvergangI, path: Path<OvergangA, number[]>, context: DashboardContext<OvergangI, OvergangA, OvergangComponent>): BarInfo {
		return {
			...super.makeBar(attrs, path, context),
			className: path[1].k === path[2].k ? 'zonder-overgang' : 'met-overgang',
		};
	}

	getBarchartQty(path: Path<OvergangA, number[]>) {
		if ((this.variant() === DashboardVariant.HISTORIE && this.eenheid()) == Eenheid.PERCENTAGE) return 100 * att0('maxPctJaar')(path);
		return getLeafA(path).weging === undefined ? null : 100 * percOfTotal('weging', 'max')(path);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(OvergangComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(OvergangComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups(selectedGroups: AttrPath[]): AttrPath[] {
		return [...selectedGroups.slice(-1), historieSchooljaarGroup];
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.filterService.configs['x_met_overgang']!.createExpression = (_val) => undefined;
	}

	partitionMeasure: Signal<PartitionMeasure<OvergangA>> = computed(() => {
		if (this.eenheid() === Eenheid.AANTAL)
			return {
				type: 'number',
				getValue: att('weging'),
				format: '1.0-2',
			};
		else
			return {
				type: 'percentage',
				getValue: att('maxPctJaar'),
			};
	});

	protected readonly Eenheid = Eenheid;
	protected readonly DashboardVariant = DashboardVariant;
}

const met_overgang: SingleAggregator<OvergangI, boolean> = {
	init: (_, groupKeys) => groupKeys[0] !== groupKeys[1],
	combine: (as) => as.some((x) => x),
};
