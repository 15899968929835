import { capitalize, range } from 'lodash-es';
import { Vaardigheid } from '@cumlaude/metadata';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { formatNumber } from '@angular/common';

export const vaardigheden: Vaardigheid[] = Object.values(Vaardigheid);

export function vaardigheid2route(vaardigheid: Vaardigheid): string {
	return vaardigheid.toLowerCase();
}

export function route2vaardigheid(route: string): Vaardigheid {
	return capitalize(route) as Vaardigheid;
}

export function getHeleNiveaus(vaardigheid: Vaardigheid): number[] {
	const hoogsteNiveau = vaardigheid === 'Rekenen' ? 3 : 4;
	return range(0, hoogsteNiveau + 1);
}

export function getBins(vaardigheid: Vaardigheid): string[] {
	const hoogsteNiveau = vaardigheid === 'Rekenen' ? 3 : 4;
	const numbers = range(0, hoogsteNiveau + 0.5, 0.5);
	return numbers.map((nr) => formatNumber(nr, 'en_US', '1.1-1'));
}

export function getNiveauLabel(nr_referentieniveau: number | null) {
	if (nr_referentieniveau === null) return 'Onbekend';
	return `${formatDecimal(nr_referentieniveau, '1.0-1')}F`;
}
