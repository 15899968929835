import { range } from 'lodash-es';

export type Axis = {
	min: number;
	max: number;
	ticks: Tick[];
	areas?: { className: string; qty?: number }[]; // qty undefined mag alleen op het eind, en vertaalt dan naar max
};

export type Tick = { label: string; qty: number; showLine?: boolean }; // showLine werkt vooralsnog alleen op HBarPartition

/**
 * Maakt een Y-as met (maximaal) nSteps zichtbare waarden. De waarden worden automatisch bepaald obv de opgegeven max en komen uit op 'ronde' getallen (veelvouden van 5, 50, 500 etc. afhankelijk van de 10log van max).
 */
export function createYAxis([min, max]: [number, number], nSteps: number = 5, format = (n: number) => n.toString()): Axis {
	const diff = max - min;
	const b = Math.pow(10, Math.round(Math.log10(diff / nSteps))) / 2; // zoek een basis die geschikt is voor deze max-waarde
	const step = b * Math.ceil(diff / nSteps / b); // rond naar boven af naar het volgende b-tal
	const newMin = Math.floor(min / step) * step; // begin op een lijntje
	const moreThanMax = max + step / 100; // als max zelf een lijntje zou krijgen, willen we die ook graag zien
	return { min: newMin, max, ticks: range(newMin, moreThanMax, step).map((n) => ({ label: format(n), qty: n })) };
}
