import { ColumnType, DataRow } from '../data-tree-table/data-tree-table';
import { DataTreeTableConfig } from '../data-tree-table/data-tree-table-config';
import { Level } from '../../../services/data-tree';
import { ColumnDef } from '../../components/table/table/table.model';
import { Attributes } from '../base-dashboard/base-dashboard-config';
import { DashboardContext } from '../base-dashboard/dashboard-context';
import { FilterService } from '../../../services/filter.service';
import { ToastrService } from 'ngx-toastr';

export abstract class PivotTableConfig<I extends Attributes, A extends Attributes> extends DataTreeTableConfig<I, A> {
	columnOrder: ColumnType[] = [ColumnType.GROUP, ColumnType.MEASURE, ColumnType.PIVOT];

	protected constructor(
		protected filterService: FilterService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	/**
	 * columnRoot is de boom (= verzameling paden die de column keys vormen) met op de leaves in "i" de kolom-posities
	 * en in "a" de geaggregeerde waarden over de rijen heen.
	 */
	createPivotColumns(_columnRoot: Level<A, number[]>, _context: DashboardContext<I, A, PivotTableConfig<I, A>>): ColumnDef<DataRow<A>>[] {
		return [];
	}
}
