<table
	[attr.data-exportable]="exportable ? '' : undefined"
	[dataSource]="dataToRender"
	[ngClass]="{ 'rows-clickable': model.rowsClickable }"
	cdk-table
	class="cdk-table"
	cdkDropList
	cdkDropListOrientation="horizontal"
	(cdkDropListDropped)="drop($event)"
>
	@if (model.columns(); as columns) {
		@for (column of columns; track column; let c = $index; let first = $first) {
			@if (model.getColumnDef(column); as columnDef) {
				<ng-container cdkColumnDef="{{ column }}" [sticky]="columnDef.sticky">
					@switch (columnDef.header.headerType) {
						@case ("default") {
							<th
								cdk-header-cell
								*cdkHeaderCellDef="let row"
								[ngClass]="columnDef.header.class"
								[ngStyle]="columnDef.header.style"
								(click)="clickSort(column)"
								[class]="getSortHeaderClass(column)"
							>
								<span class="sort-icon" [class]="getSortIconClass(column)" data-princexml-ignore data-html2canvas-ignore></span>
								<app-cell-content [model]="model" [cellDef]="columnDef.header"></app-cell-content>
							</th>
						}
						@case ("groepering") {
							<th
								cdk-header-cell
								*cdkHeaderCellDef="let row"
								[ngClass]="columnDef.header.class"
								[ngStyle]="columnDef.header.style"
								class="flex-groepering-header"
							>
								<div class="groepering-group" [class.sort-active]="sortActive === column" [class.first]="first">
									<div
										class="flex-groepering"
										[ngClass]="'groepering-' + column.replace('.', '-')"
										[class.active]="groupingMenuActive() === column"
										[class.first]="first"
										[class.batch]="columnDef.header.batchGroepering"
										[class.sort-active]="sortActive === column"
										(click)="groupingMenuActive.set(column)"
										cdkDrag
										cdkDragLockAxis="x"
										#groepering
									>
										<div *cdkDragPreview>
											<app-cell-content [model]="model" [cellDef]="columnDef.header"></app-cell-content>
										</div>
										<div *cdkDragPlaceholder>
											<app-cell-content [model]="model" [cellDef]="columnDef.header"></app-cell-content>
										</div>
										@if (sortActive === column) {
											<span
												class="sort-icon sort-active"
												[class.svg-sort-asc]="sortDirection === 'asc'"
												[class.svg-sort-desc]="sortDirection === 'desc'"
												(click)="clickSort(column)"
												data-princexml-ignore
												data-html2canvas-ignore
											>
											</span>
										}
										@if (groupingMenuActive() === column) {
											<span class="svg-meatball-menu-grey"></span>
										}
										<app-cell-content [model]="model" [cellDef]="columnDef.header"></app-cell-content>
										<div
											[class.svg-chevron-down]="groupingMenuActive() !== column"
											[class.svg-chevron-up]="groupingMenuActive() === column"
											data-princexml-ignore
											data-html2canvas-ignore
										></div>
									</div>
									@if (columnDef.header.batchGroepering && isGroeperingAvailable()) {
										<div
											class="flex-groepering add groepering-addGroup"
											[class.active]="groupingMenuActive() === 'addGroup'"
											(click)="groupingMenuActive.set('addGroup')"
											#groepering
											data-princexml-ignore
											data-html2canvas-ignore
										>
											<div class="svg-add-groepering"></div>
										</div>
									}
								</div>
							</th>
						}
						@case ("addGroup") {
							<th
								cdk-header-cell
								*cdkHeaderCellDef="let row"
								[ngClass]="columnDef.header.class"
								[ngStyle]="columnDef.header.style"
								class="flex-groepering-header"
							>
								@if (isGroeperingAvailable()) {
									<div class="groepering-group">
										<div
											class="flex-groepering add groepering-addGroup"
											[class.active]="groupingMenuActive() === column"
											(click)="groupingMenuActive.set(column)"
											#groepering
											data-princexml-ignore
											data-html2canvas-ignore
										>
											<div class="svg-add-groepering"></div>
										</div>
									</div>
								}
							</th>
						}
					}
					<td
						cdk-cell
						*cdkCellDef="let row; let r = index"
						[ngClass]="columnDef.body.class"
						[class.hover-help]="c === hoverC || r === hoverR"
						[ngStyle]="columnDef.body.style"
						(mouseenter)="onHover(r, c)"
						(mouseleave)="onHover(undefined, undefined)"
						(click)="onClickedBodyCell(row, columnDef.body.clickHandler)"
						[attr.rowspan]="columnDef.body.getRowspan(row)"
						[style.display]="columnDef.body.getRowspan(row) === 0 ? 'none' : ''"
					>
						<app-cell-content [model]="row" [cellDef]="columnDef.body"></app-cell-content>
					</td>
					<td
						cdk-footer-cell
						*cdkFooterCellDef="let row"
						(click)="onClickedFooterCell(model, columnDef.footer.clickHandler)"
						[ngClass]="columnDef.footer.class"
						[ngStyle]="columnDef.footer.style"
						[class.hover-help]="c === hoverC"
						[class.clickable]="columnDef.footer.clickHandler"
					>
						<app-cell-content [model]="model" [cellDef]="columnDef.footer"></app-cell-content>
					</td>
				</ng-container>
			}
		}

		<ng-container cdkColumnDef="spinner">
			<td cdk-footer-cell *cdkFooterCellDef="let row">
				@if (dataToRender | async; as currentData) {
					@if (currentData.length < model.data.length) {
						<div class="spinner"><span class="one">.</span><span class="two">.</span><span class="three">.</span></div>
					}
				}
			</td>
		</ng-container>

		@if (model.showExtraFooters) {
			@for (column of columns; track column; let c = $index) {
				<ng-container cdkColumnDef="x_{{ column }}">
					@if (model.getColumnDef(column); as columnDef) {
						<td
							cdk-footer-cell
							*cdkFooterCellDef="let row"
							[ngClass]="columnDef.extraFooter?.class ?? ''"
							[ngStyle]="columnDef.extraFooter?.style ?? null"
							[class.hover-help]="c === hoverC"
						>
							@if (columnDef.extraFooter) {
								<app-cell-content [model]="model" [cellDef]="columnDef.extraFooter!"></app-cell-content>
							}
						</td>
					}
				</ng-container>
			}
		}

		@if (model.showHeaders) {
			<tr cdk-header-row *cdkHeaderRowDef="columns" [class.sticky]="model.stickyHeaders"></tr>
		}

		<tr
			cdk-row
			class="connect-table-row"
			*cdkRowDef="let row; columns: columns"
			[id]="model.getRowId(row)"
			[class.alternating-odd]="model.alternating === AlternatingMode.COLOR && model.getRowgroup && model.getRowgroup(row).groupId % 2 === 0"
			[class.alternating-even]="model.alternating === AlternatingMode.COLOR && model.getRowgroup && model.getRowgroup(row).groupId % 2 === 1"
			[class.alternating-line]="model.alternating === AlternatingMode.LINE && model.getRowgroup && model.getRowgroup(row).lastOfGroup"
			[class]="model.getRowgroup?.(row)?.class"
		></tr>

		<tr cdk-footer-row *cdkFooterRowDef="['spinner']" class="tableEnd"></tr>

		@if (model.showExtraFooters) {
			<tr cdk-footer-row *cdkFooterRowDef="x_columns(columns); sticky: model.stickyFooters"></tr>
		}

		@if (model.showFooters) {
			<tr cdk-footer-row *cdkFooterRowDef="columns; sticky: model.stickyFooters"></tr>
		}
	}
</table>
