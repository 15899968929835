<div class="header">
	<h2 class="title u-margin-lg">{{ dashboardName }}</h2>
	<app-button class="u-margin-lg" icon="icon-filter" (clickit)="openFilterPanel()">Filters</app-button>
	<app-button class="u-margin-lg" (clickit)="resetDashboard()" buttonType="text">Overzicht resetten</app-button>
</div>
<div class="filter-label">
	@for (filter of filters; track trackByName($index, filter)) {
		@if (filterService.isVisible(filter[0]) | async) {
			<app-filter-label [filterName]="filter[0]" [value]="filter[1]"></app-filter-label>
		}
	}
</div>
<div class="right-options">
	<ng-content></ng-content>
	@if (variant) {
		<app-toggle-buttons [options]="varianten" [value]="variant" (valueChange)="qp.dispatch('variant', $event)"></app-toggle-buttons>
	}
	@if (legenda) {
		<app-legenda
			[attribute]="legenda"
			[exclude]="legendaExclude"
			[includeNull]="legendaIncludeNull"
			[keyIsClassName]="legendaKeyIsClassName"
			[style]="legendaStyle"
		>
		</app-legenda>
	}
</div>
