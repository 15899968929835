import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'app-immediate-input',
	templateUrl: './immediate-input.component.html',
	styleUrls: ['./immediate-input.component.scss'],
	standalone: true,
})
export class ImmediateInputComponent implements AfterViewInit {
	@Input()
	init!: string;

	@Output()
	done = new EventEmitter<string | undefined>();

	cancelled = false;

	@ViewChild('element')
	ref?: ElementRef;

	ngAfterViewInit(): void {
		const element = this.ref!.nativeElement;
		element.select();
		element.focus();
	}

	submit(event: Event, result: string | undefined) {
		this.done.emit(result);
	}

	cancel() {
		this.done.emit(undefined);
	}
}
