import { getLeafA, Path } from './data-tree';
import { deelVeilig } from '@cumlaude/shared-utils';
import { Attributes } from '../shared/dashboard/base-dashboard/base-dashboard-config';

export function percOfRow<Ai extends keyof A, A extends Attributes & { [ai in Ai]: number }>(attribute: Ai): (row: Path<A, unknown>) => number;
export function percOfRow<Ai extends keyof A, A extends Attributes & { [ai in Ai]: number }>(
	attribute: Ai,
	total: Ai
): (row: Path<A, unknown>) => number;

export function percOfRow<Ai extends keyof A, A extends Attributes & { [ai in Ai]: number }>(
	attribute: Ai,
	total?: Ai
): (row: Path<A, unknown>) => number {
	return (row: Path<A, unknown>) => {
		const numerator = getLeafA(row)[attribute];
		const denominator = getLeafA(row)[total ?? 'count_records'];
		return deelVeilig(numerator, denominator);
	};
}

export function percOfTotal<Ai extends keyof A, A extends Attributes & { [ai in Ai]: number }>(attribute: Ai): (row: Path<A, unknown>) => number;
export function percOfTotal<Ai extends keyof A, A extends Attributes & { [ai in Ai]: number }>(
	attribute: Ai,
	total: Ai
): (row: Path<A, unknown>) => number;

export function percOfTotal<Ai extends keyof A, A extends Attributes & { [ai in Ai]: number }>(
	attribute: Ai,
	total?: Ai
): (row: Path<A, unknown>) => number {
	return (row: Path<A, unknown>) => {
		const numerator = getLeafA(row)[attribute];
		const denominator = row[0].a[total ?? 'count_records'];
		return deelVeilig(numerator, denominator);
	};
}

export function percOfParent<Ai extends keyof A, A extends Attributes & { [ai in Ai]: number }>(attribute: Ai): (row: Path<A, unknown>) => number;
export function percOfParent<Ai extends keyof A, A extends Attributes & { [ai in Ai]: number }>(
	attribute: Ai,
	total: Ai
): (row: Path<A, unknown>) => number;

export function percOfParent<Ai extends keyof A, A extends Attributes & { [ai in Ai]: number }>(
	attribute: Ai,
	total?: Ai
): (row: Path<A, unknown>) => number {
	return (row: Path<A, unknown>) => {
		const numerator = getLeafA(row)[attribute];
		const parentIndex = Math.max(row.length - 2, 0);
		const denominator = row[parentIndex].a[total ?? 'count_records'];
		return deelVeilig(numerator, denominator);
	};
}

export function formatPerc<P>(measure: (path: P) => number): (path: P) => string {
	return (path: P) => `${Math.round(measure(path) * 100)}%`;
}

export function att<Ai extends keyof A, A extends Attributes & { [ai in Ai]: A[ai] }>(attribute: Ai): (row: Path<A, unknown>) => A[typeof attribute] {
	return (row: Path<A, unknown>) => getLeafA(row)[attribute];
}

export function att0<Ai extends keyof A, A extends Attributes & { [ai in Ai]: number }>(
	attribute: Ai
): (row: Path<A, unknown>) => A[typeof attribute] {
	return (row: Path<A, unknown>) => getLeafA(row)[attribute] ?? 0;
}

export function attParent<Ai extends keyof A, A extends Attributes & { [ai in Ai]: A[ai] }>(
	attribute: Ai
): (row: Path<A, unknown>) => A[typeof attribute] {
	return (row: Path<A, unknown>) => row[Math.max(row.length - 2, 0)].a[attribute];
}

export const count_records: (row: Path<Attributes, unknown>) => number = (row: Path<Attributes, unknown>) => getLeafA(row).count_records;
