@if (activeMenu) {
	<div class="root visible">
		@for (menuItem of activeMenu.menus; track menuItem.url) {
			@if (isUrlAllowed(menuItem.url)) {
				@if (menuItem.subMenus && menuItem.subMenuName) {
					<div (mouseenter)="openIfHorizontal(menuItem.subMenuName)" (mouseleave)="closeIfHorizontal(menuItem.subMenuName)">
						<a
							class="root-item"
							[routerLink]="menuItem.url"
							[queryParams]="menuItem.qp"
							queryParamsHandling="merge"
							[class.ingeklapt]="openSubMenu !== menuItem.subMenuName"
							[class.uitgeklapt]="openSubMenu === menuItem.subMenuName"
							routerLinkActive="active"
						>
							<span (click)="toggle(menuItem.subMenuName, $event)">{{ menuItem.naam }}</span>
						</a>
						<div class="sub">
							@for (subMenuItem of menuItem.subMenus; track subMenuItem.url) {
								@if (isUrlAllowed(subMenuItem.url)) {
									<a
										class="sub-item"
										[routerLink]="subMenuItem.url"
										[queryParams]="subMenuItem.qp"
										queryParamsHandling="merge"
										routerLinkActive="active"
									>
										{{ subMenuItem.naam }}
									</a>
								}
							}
						</div>
					</div>
				} @else {
					<a
						class="root-item"
						[routerLink]="menuItem.url"
						[queryParams]="menuItem.qp"
						queryParamsHandling="merge"
						routerLinkActive="active"
					>
						{{ menuItem.naam }}
					</a>
				}
			}
		}
	</div>
}
