<div class="dropdown-container">
	<dt-form-dropdown
		[opties]="filteredDropDownOptions"
		[selectedOptie]="findDropDownOption(selectie)"
		[placeholder]="placeholder"
		[showSearchInput]="true"
		(onSearchInput)="filter($event)"
		(onOptieClicked)="selectieChange.emit($event)"
		[searchInput]="zoek"
		[enabled]="enabled"
	></dt-form-dropdown>
</div>
