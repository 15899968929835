<app-dashboard-container
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
	[factTable]="factTable"
	class="dashboard-cijfers"
>
	<app-filter-panel
		(filterExpressionsChange)="filterExpressions = $event"
		[allFilters]="getAllFilters()"
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[permanentFilterExpressions]="permanentFilterExpressions"
		endpoint="/cijfers"
	></app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header [legenda]="['x_cijfer_ov']" [variant]="variant"></app-dashboard-header>
		@switch (variant) {
			@case ("Actueel") {
				<app-card-list
					[config]="this"
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[groups]="groups"
					[sortHeaders]="actueelSortHeaders"
					[template]="cardTemplate"
					data-exportable
				>
					<ng-template #cardTemplate let-row="row" let-context="context" let-group="group">
						<app-card-cell-header
							[abb_medewerker]="group['cf_fks_mw.mw_abb_medewerker']"
							[title]="getGroupTitle(group)"
							[subtitle]="group['cf_fks_mw.mw_abb_medewerker']"
						></app-card-cell-header>
						<app-hbar-partition [data]="generateHbarPartition(row, context)" [axis]="false"></app-hbar-partition>
						<app-card-cell-footer [measures]="generateMeasures(row)"></app-card-cell-footer>
					</ng-template>
				</app-card-list>
			}
			@case ("Historie") {
				<app-card-list
					[config]="this"
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[groups]="groups"
					[subgroups]="subGroups"
					[sortHeaders]="historieSortHeaders"
					[template]="cardTemplate"
					data-exportable
				>
					<ng-template #cardTemplate let-row="row" let-context="context" let-group="group">
						<app-card-cell-header
							[abb_medewerker]="group['cf_fks_mw.mw_abb_medewerker']"
							[title]="getGroupTitle(group)"
							[subtitle]="group['cf_fks_mw.mw_abb_medewerker']"
						></app-card-cell-header>
						<app-vbar-series [yAxis]="yAxis" [partitions]="generateVbarPartitions(row, context)"></app-vbar-series>
					</ng-template>
				</app-card-list>
			}
		}
	</section>
</app-dashboard-container>
