<section aria-live="polite" class="container">
	<div class="header">
		<h2>Vestigingen</h2>
		<div class="export-container right">
			<div class="spinner">
				@if (showExportSpinner$ | async) {
					<app-css-spinner></app-css-spinner>
				}
			</div>
			<span class="svg-export" [class.hidden]="showExportSpinner$ | async" (click)="export()"></span>
		</div>
	</div>
	<div class="controls">
		@if ({ value: filter$ | async }; as filter) {
			<div class="left">
				<app-instant-search-box [value]="filter.value!" (fire)="search($event)"></app-instant-search-box>
			</div>
		}
	</div>
	<div class="list">
		@if (alleVestigingen$ | async; as vestigingen) {
			@if (accounts$ | async; as accounts) {
				<table>
					<thead>
						<tr>
							<th class="naam">Naam</th>
							@for (vestiging of vestigingen; track vestiging.rVestiging.vestigingId) {
								<th class="vestiging" [class.inactief]="!vestiging.rVestiging.actief" [appTooltip]="getTooltip(vestiging)">
									{{ vestiging.rVestiging.naam }}
									@if (!vestiging.rVestiging.actief) {
										(Inactief)
									}
								</th>
							}
						</tr>
					</thead>
					<tbody>
						@for (account of accounts; track account.id) {
							<tr>
								@if (getAccountNaam(account); as naam) {
									<td class="naam" [appTooltip]="naam">{{ naam }}</td>
								}
								@for (vestiging of vestigingen; track vestiging.rVestiging.vestigingId) {
									<td>
										<app-checkbox
											[value]="account.vestigingen.includes(vestiging.rVestiging.vestigingId)"
											(valueChange)="toggle(account, vestiging.rVestiging.vestigingId, $event)"
										/>
									</td>
								}
							</tr>
						}
					</tbody>
				</table>
			}
		}
	</div>
</section>
