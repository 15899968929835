import { FilterService } from '../../../services/filter.service';
import { CompoundFilterExpression, ExportDataOptions, ExportFilter, FilterExpression } from '../../../services/data.service';
import { ExportOptions, ExportService, ExportType, Orientation } from '../../../services/export.service';
import { take } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { getTimestamp } from '@cumlaude/shared-utils';
import { combineLatestWith, from, lastValueFrom, Observable, of, Subscription, throwError } from 'rxjs';
import { Categorie, Exportable, FactTable, getAtt } from '../../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { categorieAttLabel, categorieMap } from '../../../services/exportable-map';
import { Directive, inject, OnDestroy } from '@angular/core';
import { first, memoize, sortBy } from 'lodash-es';
import { formatDate } from '@angular/common';
import html2canvas from 'html2canvas';
import { LoadingService, LoadingType } from '../../../services/loading.service';
import { FilterName } from '../../../services/filter-config';
import { UrlService } from '../../../services/url.service';
import { DashboardVariant } from '../../../services/weergave-opties';

@Directive()
export abstract class Dashboard implements Exportable, OnDestroy {
	protected exportService = inject(ExportService);

	protected loadingService = inject(LoadingService);

	protected urlService = inject(UrlService);

	protected constructor(
		protected filterService: FilterService,
		protected toastr: ToastrService
	) {}

	abstract factTable: FactTable;

	protected subscriptions: Subscription[] = [];

	protected varianten: DashboardVariant[] = Object.values(DashboardVariant);

	doExport(filters: FilterExpression[], permanentFilters: FilterExpression[], displayOptions: ExportFilter[], exportOptions: ExportOptions) {
		const allFilters = [...filters, ...permanentFilters];
		const filterLabelAndValue = filters.length > 0 ? this.filterService.getFilterLabelAndValue() : of([]);
		lastValueFrom(filterLabelAndValue.pipe(combineLatestWith(this.urlService.routeData$), take(1))).then(([values, routeData]) => {
			const sortedSelectedColumns = sortBy(exportOptions.selectedColumns, (att) => categorieAttLabel(this.factTable, att));
			const exportDataOptions = {
				f: allFilters.length == 1 ? allFilters[0] : new CompoundFilterExpression(allFilters),
				attrs: sortedSelectedColumns,
				attrLabels: sortedSelectedColumns.map((att) => categorieAttLabel(this.factTable, att)),
				...(exportOptions.showTitle ? { title: routeData.path } : {}),
				...(exportOptions.showFilters ? { filters: [...this.getExportFilters(values), ...displayOptions] } : {}),
				...(exportOptions.showLegenda ? { legendaEntries: exportOptions.legendaEntries, legendaStyle: exportOptions.legendaStyle } : {}),
				orientation: exportOptions.orientation,
			};

			this.executeExport(exportOptions, exportDataOptions);
		});
	}

	private executeExport(exportOptions: ExportOptions, exportDataOptions: ExportDataOptions) {
		let blob: Observable<Blob>;
		let extension: string;
		switch (exportOptions.type) {
			case ExportType.DATA:
				blob = this.getExportData(exportDataOptions);
				extension = 'xlsx';
				break;
			case ExportType.PDF:
				blob = this.exportToPdf(exportDataOptions);
				extension = 'pdf';
				break;
			case ExportType.AFBEELDING:
				blob = this.exportToPng(exportDataOptions);
				extension = 'png';
				break;
			case ExportType.TABEL:
				blob = this.exportAsTable(exportDataOptions);
				extension = 'xlsx';
				break;
			default:
				blob = throwError(() => new Error('Export not yet implemented'));
		}

		blob.subscribe({
			next: (blob) => {
				try {
					saveAs(blob, `CumLaude ${exportDataOptions.title ?? 'export'} ${getTimestamp()}.${extension}`);
					this.loadingService.stop(LoadingType.EXPORT);
					this.toastr.success('Export succesvol.');
				} catch (e) {
					this.sendToastError();
				}
			},
			error: (error) => {
				console.error(error);
				this.sendToastError();
			},
		});
	}

	// memoize, otherwise new array keeps triggering change detection
	getAllFilters: () => Categorie[] = memoize(() => {
		const exclude = this.filterExcludes();
		return categorieMap[this.factTable].map((cat) => ({
			...cat,
			atts: cat.atts.filter((att) => !exclude.includes(getAtt(att))),
		}));
	}).bind(this);

	/**
	 * Attributen uit de exportable-lijst die niet in Alle filters terecht moeten komen.
	 */
	filterExcludes(): FilterName[] {
		return [];
	}

	private sendToastError() {
		this.toastr.error('Er ging iets mis bij het exporteren. Probeer opnieuw.');
	}

	getExportData(_options: ExportDataOptions): Observable<Blob> {
		return throwError(() => new Error('Export not implemented for this dashboard.'));
	}

	exportAsTable(_options: ExportDataOptions): Observable<Blob> {
		return throwError(() => new Error('Export not implemented for this dashboard.'));
	}

	exportToPdf(options: ExportDataOptions): Observable<Blob> {
		const html = ['<html class="princexml"><head>'];
		html.push(`<base href="${location.protocol}//${location.host}/"/>`);
		document.querySelectorAll('head link[rel=stylesheet],title,style').forEach((el) => html.push(el.outerHTML));
		html.push(
			`<style type="text/css">@page{size:A4 ${
				options.orientation === Orientation.LANDSCAPE ? 'landscape' : ''
			};@bottom-left{content:'${formatDate(new Date(), 'dd-MM-yyyy', 'nl-NL')}';}}</style>`,
			'</head>',
			`<body class="${this.getDashboardClasses().join(' ')}">`
		);
		html.push(this.createExportHeader(options));
		document.querySelectorAll('[data-exportable]').forEach((el) => html.push(el.outerHTML));
		html.push(this.createExportFilters(options));
		html.push(this.createExportLegenda(options));
		html.push('</body></html>');
		const doc = html.join(' ');
		return this.exportService.exportToPdf(doc);
	}

	/** De "dashboard-xxx"-classes regelen de juiste kleuren in het dashboard en de legenda */
	protected getDashboardClasses(): string[] {
		return Array.from(document.querySelector('[class^=dashboard-]')?.classList ?? []).filter((cl) => cl.startsWith('dashboard-')) ?? [];
	}

	protected createExportHeader(options: ExportDataOptions): string {
		if (options.title) return `<h2 class="header">${options.title}</h2>`;
		return '';
	}

	/** Override deze methode om de "filters" bovenaan de export aan te passen */
	protected getExportFilters(exportFilters: ExportFilter[]): ExportFilter[] {
		return exportFilters;
	}

	protected createExportLegenda(options: ExportDataOptions): string {
		const html: string[] = [];
		if (options.legendaEntries) {
			html.push('<div class="legenda-options">');
			options.legendaEntries.forEach((entry) => {
				html.push(`<div class="option"><div class="legenda ${entry.class}">`);
				if (options.legendaStyle === 'PILL' && entry.value === 'Geslaagd') {
					html.push('<div class="vink">\u1E12</div>');
				}
				html.push(`</div><span class="label">${entry.value}</span></div>`);
			});
			html.push('</div>');
		}
		return html.join('');
	}

	protected createExportFilters(options: ExportDataOptions): string {
		const html: string[] = [];
		if (options.filters) {
			html.push('<dl class="filters">');
			options.filters.forEach((f) => html.push(`<dt>${f.label}</dt><dd>${f.value}</dd>`));
			html.push('</dl>');
		}
		return html.join('');
	}

	exportToPng(options: ExportDataOptions): Observable<Blob> {
		this.loadingService.start(LoadingType.EXPORT, true);

		let headerLayer: HTMLElement | null = null;
		if (options.title || options.filters || options.legendaEntries) {
			headerLayer = document.createElement('div');
			headerLayer.classList.add('html2canvas-header', ...this.getDashboardClasses());
			headerLayer.setAttribute('data-exportable', '');
			headerLayer.innerHTML = this.createExportHeader(options) + this.createExportFilters(options) + this.createExportLegenda(options);
			document.body.insertBefore(headerLayer, document.body.firstChild);
		}

		const elements = document.querySelectorAll('[data-exportable]');

		// Bepaal waar alle elementen moeten komen en hoe groot het canvas moet worden
		const GAP = 16;
		const MARGIN = 16;
		let totalHeight = MARGIN;
		let maxWidth = 0;

		const positionedElements: { element: HTMLElement; left: number; top: number }[] = [];
		elements.forEach((element) => {
			if (element instanceof HTMLElement) {
				// Scroll helemaal naar linksboven om te voorkomen dat de header op de verkeerde plaats komt.
				element.closest('.cdk-virtual-scrollable')?.scrollTo(0, 0);

				if (totalHeight > MARGIN) totalHeight += GAP;

				const { width, height } = element.getBoundingClientRect();
				positionedElements.push({ element, left: MARGIN, top: totalHeight });
				totalHeight += height;
				maxWidth = Math.max(maxWidth, width + 2 * MARGIN);
			}
		});

		const canvas = document.createElement('canvas');
		canvas.width = maxWidth;
		canvas.height = totalHeight + MARGIN;

		const ctx = canvas.getContext('2d');
		if (!ctx) throw new Error('Cannot create canvas context');

		// Begin met een witte achtegrond
		ctx.fillStyle = 'white';
		ctx.fillRect(0, 0, maxWidth, totalHeight + MARGIN);

		// Teken alle exporteerbare elementen op de juiste plaats op het canvas
		const promises = positionedElements.map(
			({ element, left, top }) =>
				new Promise<void>((resolve) =>
					setTimeout(
						() =>
							html2canvas(element, { scale: 1, logging: false }).then((c) => {
								ctx.drawImage(c, left, top);
								resolve();
							}),
						0
					)
				)
		);
		// Als alles klaar is, maak er dan een Blob van met de PNG-data
		const blob = Promise.all(promises).then(() => {
			headerLayer?.remove();
			return new Promise<Blob>((resolve, reject) => canvas.toBlob((b) => (b ? resolve(b) : reject()), 'image/png'));
		});
		return from(blob);
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}
}
