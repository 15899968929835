import { Component, OnInit } from '@angular/core';
import { last, memoize, nth, orderBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { Level, Path } from '../../services/data-tree';
import {
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	defaultDoorstroomAvailableGroups,
	defaultDoorstroomSelectedGroups,
	ExportDataOptions,
	ExportFilter,
	FilterExpression,
} from '../../services/data.service';
import { attrLabel } from '../../services/labels';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { maxOverIf, sumIf, sumOver } from '../../services/aggregation';
import { att, att0, percOfRow } from '../../services/measures';
import { generateCssClassForAttrPath } from '../../services/css-generate-metadata';
import { deelVeilig, generateCssClassForString } from '@cumlaude/shared-utils';
import { BarInfo } from '../../services/stacked-bars';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { defaultDoorstroomActueelFilters, defaultDoorstroomHistorieFilters, FilterName } from '../../services/filter-config';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FilterService } from '../../services/filter.service';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { UrlService } from '../../services/url.service';
import { DashboardVariant, Eenheid } from '../../services/weergave-opties';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { AttributeSelectorComponent } from '../../attribute-selector/attribute-selector.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { Axis, createYAxis } from '../../services/axis';

interface KenmerkI extends Attributes {
	[kenmerkNaam: string]: string | number | null;
	ds_nr_weging: number;
}

interface KenmerkA extends Attributes {
	max: number;
	aantalMetKenmerk: number;
	weging: number;
}

@Component({
	selector: 'app-kenmerk',
	templateUrl: './kenmerk.component.html',
	styleUrls: ['./kenmerk.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		AttributeSelectorComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		FormDropdownComponent,
	],
})
export class KenmerkComponent extends BarchartTableConfig<KenmerkI, KenmerkA> implements OnInit {
	defaultGroups = defaultDoorstroomSelectedGroups;

	groups = defaultDoorstroomSelectedGroups;

	availableGroups = defaultDoorstroomAvailableGroups;

	actueelFilters: FilterName[] = [...defaultDoorstroomActueelFilters, 'x_peildatum'];

	historieFilters: FilterName[] = [...defaultDoorstroomHistorieFilters, 'x_peildatum'];

	kenmerken: AttrPath[] = [
		['ds_is_apcg_van'],
		['ds_fk_ilt_van', 'ilt_is_lwoo'],
		['ds_fk_ilt_van', 'ilt_is_vavo'],
		['ds_nm_instroom_in_schooljaar'],
		['ds_nm_uitstroom_in_schooljaar'],
		['ds_is_examenopstroom_van'],
	];

	kenmerk!: AttrPath;

	eenheidOpties = Object.values(Eenheid).map((val) => new Option(val));

	eenheid!: Eenheid;

	filterExpressions?: FilterExpression[];

	variant!: DashboardVariant;

	permanentFilterExpressions = [
		// Voorkom dubbelingen door doorstroom-record van relevante plaatsing naar zowel niet- als wel-relevante plaatsing
		new BasicFilterExpression(['ds_is_plaatsing_opeenvolgend'], 1),
	];

	constructor(
		private dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected urlService: UrlService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe_g().subscribe((groups) => (this.groups = groups ?? defaultDoorstroomSelectedGroups)),
			this.qp.observe('kenmerk').subscribe((kenmerk) => (this.kenmerk = kenmerk)),
			this.qp.observe('eenheid', Eenheid.PERCENTAGE).subscribe((eenheid) => {
				this.eenheid = eenheid;
				this.filterService.refresh();
			}),
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant))
		);
	}

	factTable = FactTable.doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData(options);
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected singleAggregators = {
		aantalMetKenmerk: sumIf<'ds_nr_weging', KenmerkI>('ds_nr_weging', ({ [this.kenmerk.join('.')]: kenmerkWaarde }: KenmerkI) =>
			kenmerkGevuld(<string>kenmerkWaarde)
		),
		weging: sumOver<'ds_nr_weging', KenmerkI, number>('ds_nr_weging'),
		max: maxOverIf<'ds_nr_weging', KenmerkI>('ds_nr_weging', ({ [this.kenmerk.join('.')]: kenmerkWaarde }: KenmerkI) =>
			kenmerkGevuld(<string>kenmerkWaarde)
		),
	};

	getSelectedEenheidOptie() {
		return this.eenheidOpties.find((optie) => optie.value === this.eenheid)!;
	}

	partitionBarData(rowRoot: Level<KenmerkA, number[]>): Path<KenmerkA, number[]>[][] {
		switch (this.eenheid) {
			case Eenheid.AANTAL:
				return [rowRoot.r.filter((path) => kenmerkGevuld(last(path)!.k))];
			case Eenheid.PERCENTAGE:
				// Eerst de bars met gevulde kenmerkwaarden, daarna zonder
				return [orderBy(rowRoot.r, [(path) => kenmerkGevuld(last(path)!.k)], ['desc'])];
		}
	}

	makeBar(attrs: KenmerkI, path: Path<KenmerkA, number[]>, context: DashboardContext<KenmerkI, KenmerkA, KenmerkComponent>): BarInfo {
		const kenmerkNaam = this.kenmerk;
		const kenmerkWaarde = attrs[kenmerkNaam.join('.')];

		const kenmerkLabel = attrLabel(kenmerkNaam);
		const heeftKenmerk = kenmerkGevuld(<string>kenmerkWaarde) || kenmerkWaarde === 'Geen';

		let kenmerkWaardeClass;
		let kenmerkWaardeLabel;
		if (heeftKenmerk) {
			if (kenmerkWaarde == '1') {
				kenmerkWaardeClass = generateCssClassForAttrPath(kenmerkNaam);
				kenmerkWaardeLabel = kenmerkLabel;
			} else {
				kenmerkWaardeClass = generateCssClassForString(kenmerkWaarde!.toString());
				kenmerkWaardeLabel = this.displayService.display(<string | null>kenmerkWaarde);
			}
		} else {
			kenmerkWaardeLabel = 'Geen ' + kenmerkLabel;
			kenmerkWaardeClass = generateCssClassForString(kenmerkWaardeLabel);
		}

		const rowAantal = nth(path, -2)!.a.weging;
		const percentage = Math.round(deelVeilig(attrs.ds_nr_weging, rowAantal) * 100);

		const tooltipElements: TooltipElement[] = [
			{ label: `Aantal:`, value: `${formatDecimal(attrs.ds_nr_weging, '1.0-2')}` },
			{ label: `Percentage:`, value: `${percentage}%` },
			{ label: `${kenmerkMultiple(kenmerkNaam) ? kenmerkLabel : 'Kenmerk'}:`, value: `${kenmerkWaardeLabel}` },
		];

		const size = context.config.eenheid === Eenheid.PERCENTAGE ? percentage : attrs.ds_nr_weging;

		return {
			...super.makeBar(attrs, path, context),
			size,
			className: kenmerkWaardeClass,
			tooltip: tooltipElements,
		};
	}

	getKenmerkClass(): string {
		const kenmerkLabel = attrLabel(this.kenmerk);
		return 'kenmerk-' + kenmerkLabel.toLowerCase();
	}

	createLinkData(path: Path<unknown, number[]>, context: DashboardContext<KenmerkI, KenmerkA, KenmerkComponent>): Partial<LinkData> {
		return {
			dashboard: '/details/leerling/doorstroom',
			dataProvider: 'doorstroom',
			...super.createLinkData(path, context),
		};
	}

	createMeasureColumns(context: DashboardContext<KenmerkI, KenmerkA, KenmerkComponent>): ColumnDef<DataRow<KenmerkA>>[] {
		if (this.variant === 'Historie') return [];
		return [
			createMeasureColumn('Percentage', percOfRow('aantalMetKenmerk', 'weging'), { dataType: 'percentage' }),
			createMeasureColumn('Aantal', att('aantalMetKenmerk'), {
				clickHandler: (rowModel) => this.handleAantalClick(rowModel, context),
				format: '1.0-2',
			}),
			createMeasureColumn<KenmerkI, KenmerkA>('Leerlingen', att('weging'), { format: '1.0-2' }),
		];
	}

	isHistorieBatchVariant(): boolean {
		return this.variant === DashboardVariant.HISTORIE && this.groups.length > 0;
	}

	enrichTableModel(_context: DashboardContext<KenmerkI, KenmerkA, KenmerkComponent>, tableModel: TableModel<DataRow<KenmerkA>>) {
		tableModel.showFooters = this.variant === 'Actueel';
	}

	private handleAantalClick(rowModel: DataRow<KenmerkA>, context: DashboardContext<KenmerkI, KenmerkA, KenmerkComponent>) {
		const linkData = this.createLinkData(rowModel._path, context);
		const { filter } = linkData;
		const newFilter = this.getAantalFilter(<FilterExpression>filter!);

		this.urlService.navigate({
			...linkData,
			filter: newFilter,
		});
	}

	private getAantalFilter(filter: FilterExpression) {
		let value = undefined;
		if (['ds_fk_ilt_van.ilt_is_lwoo', 'ds_fk_ilt_van.ilt_is_vavo'].includes(this.kenmerk.join('.'))) {
			value = [0];
		} else if (['ds_is_apcg_van', 'ds_is_examenopstroom_van'].includes(this.kenmerk.join('.'))) {
			value = [0, null];
		} else if (kenmerkMultiple(this.kenmerk)) {
			value = [null, 'Geen', ''];
		}

		const bekendFilter = new BasicFilterExpression(this.kenmerk, value, 'not in');
		return new CompoundFilterExpression([filter, bekendFilter]);
	}

	getDisplayOptions(): ExportFilter[] {
		return [{ label: 'Kenmerk', value: attrLabel(this.kenmerk) }];
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(KenmerkComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(KenmerkComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups([selectedGroups, fixedSubgroups]: [AttrPath[], AttrPath[]]): AttrPath[] {
		return [...selectedGroups.slice(-1), ['ds_nm_schooljaar_van'], ...fixedSubgroups];
	}

	createYAxis(context: DashboardContext<KenmerkI, KenmerkA, KenmerkComponent>): Axis {
		if (!context.dataRoot || context.config.eenheid === Eenheid.PERCENTAGE) return super.createYAxis(context);
		return createYAxis([0, context.dataRoot.a.max]);
	}

	createXAxis(context: DashboardContext<KenmerkI, KenmerkA, KenmerkComponent>): Axis {
		if (!context.dataRoot || context.config.eenheid === Eenheid.PERCENTAGE) return super.createXAxis(context);
		return { min: 0, max: context.dataRoot.a.max, ticks: [] };
	}

	getBarchartQty(path: Path<KenmerkA, number[]>, context: DashboardContext<KenmerkI, KenmerkA, KenmerkComponent>) {
		if (context.config.eenheid === Eenheid.AANTAL) return att0('aantalMetKenmerk')(path);
		else return 100;
	}

	getPartitionMeasure = memoize(this._getPartitionMeasure.bind(this), JSON.stringify);

	private _getPartitionMeasure(eenheid: Eenheid): PartitionMeasure<KenmerkA> {
		if (eenheid === Eenheid.PERCENTAGE) {
			return {
				type: 'percentage',
				getValue: percOfRow<keyof KenmerkA, KenmerkA>('aantalMetKenmerk', 'weging'),
			};
		}

		return {
			type: 'number',
			getValue: att('aantalMetKenmerk'),
		};
	}
}

function kenmerkGevuld(waarde: string | null): boolean {
	return !['', '0', 'Geen', null].includes(waarde);
}

function kenmerkMultiple(kenmerk: AttrPath): boolean {
	return ['ds_nm_instroom_in_schooljaar', 'ds_nm_uitstroom_in_schooljaar'].includes(kenmerk.join('.'));
}
