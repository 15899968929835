<div class="label" [class]="getClassNames()" [class.selected]="showDropdown" (click)="toggleDropdown()">
	<p [title]="getTitle()" class="text-content-semi">{{ getValue() }}</p>
	@if (isOptional()) {
		<i class="icon-exit" (click)="removeFilter()"></i>
	}
</div>
@if (showDropdown) {
	<div class="dropdown-anchor">
		<div class="dropdown-border" (clickOutside)="showDropdown = false" [clickOutsideEvents]="'click,touchend'" [delayClickOutsideInit]="true">
			<div class="dropdown-viewport">
				<div class="dropdown-content">
					<ng-template appFilterInput in-dropdown [filterName]="filterName"></ng-template>
				</div>
			</div>
		</div>
	</div>
}
