<div class="card-cell-header">
	@if (data.is_onvoldoende) {
		<div class="indicator-1jaar"></div>
	}
	<span class="card-cell-title">{{ data.pv_nm_indicator }}</span>
</div>
<div [appTooltip]="data.tooltip" class="card-cell-row">
	@if (getPercentielBarData() !== null) {
		<app-single-percentiel-hbar [data]="getPercentielBarData()!"></app-single-percentiel-hbar>
	}
	@if (data.pv_nr_score !== null) {
		<div class="score">{{ getScore() }}</div>
	} @else {
		<div>Score onbekend</div>
	}
</div>
