import { Categorie } from './exportable';

const onderwijsresultaten_onderwijsresultaten: Categorie = {
	label: 'Onderwijsresultaten',
	atts: [
		{ att: 'or_co_brinvest', example: '01XX00', isFilter: false },
		{ att: 'or_des_bron', example: 'Bronsysteem, Inspectie (2023)', isFilter: false },
		{ att: 'or_nm_indicator', example: 'onderwijspositie, onderbouwsnelheid, bovenbouwsucces, examencijfers', isFilter: false },
		{ att: 'or_nm_soort_onderwijs', example: 'OND, VMBO b, VMBO k, VMBO (g)t, HAVO, HAVO / VWO, VWO', isFilter: false },
		{ att: 'pj_nr_peiljaar', example: '2024', isFilter: false },
		{ att: 'or_nm_schooljaar', example: '2022/2023', isFilter: false },
		{ att: 'or_nr_score', example: '456', isFilter: false },
		{ att: 'or_q_apcg', example: '12', isFilter: false },
		{ att: 'or_q_instroom', example: '3', isFilter: false },
		{ att: 'or_q_leerlingen', example: '123', isFilter: false },
		{ att: 'or_q_opstroom', example: '3', isFilter: false },
		{ att: 'or_q_vakken', example: '1234', isFilter: false },
		{ att: 'or_q_lwoo', example: '2', isFilter: false },
	],
};

const onderwijsresultaten_norm: Categorie = {
	label: 'Norm',
	atts: [
		{ att: 'or_fkd_no.no_nr_norm', example: '95,5', isFilter: false },
		{ att: 'or_fkd_no.no_nr_apcg_corr_factor', example: '-0,25', isFilter: false },
		{ att: 'or_fkd_no.no_nr_instroom_corr_factor', example: '-4,5', isFilter: false },
		{ att: 'or_fkd_no.no_nr_lwoo_corr_factor', example: '-2', isFilter: false },
		{ att: 'or_fkd_no.no_nr_opstroom_corr_factor', example: '-0,6', isFilter: false },
	],
};

export const onderwijsresultaten_all = [onderwijsresultaten_onderwijsresultaten, onderwijsresultaten_norm];
