@switch (variant) {
	@case ('Actueel') {
		@if (row.actueelRow) {
			<div class="card-cell-single-content" [class.with-trend]="toonTrend()">
				<span class="card-cell-value">
					{{ row.actueelRow.or_nr_score > 0 && row.indicator === 'op' ? '+' : '' }}{{ row.actueelRow!.or_nr_score | percent: '1.2-2' }}
				</span>
				@if (toonTrend()) {
					<div [class]="row.actueelRow!.graph_class"></div>
				}
			</div>
			<div class="card-cell-single-content">
				<span class="verschil" [class]="row.verschil_class">
					{{ row.or_nr_verschil! > 0 ? '+' : '' }}{{ row.or_nr_verschil! | extendedDecimal: '1.2-2' }}
				</span>
				<span class="norm">
					<span class="norm-groep">{{ row.positie_norm }}</span>
					de norm
				</span>
			</div>
		}
	}
	@case ('Historie') {
		@if (row.linechartProps) {
			<app-linechart [props]="row.linechartProps"></app-linechart>
		}
	}
}
