import { flatMap, fromPairs, isUndefined, last, nth, orderBy, partition, reverse, sumBy, takeWhile, zip } from 'lodash-es';

export type DataTree<D> = D | Map<string, DataTree<D>>;

export interface Level<A, D> {
	/** k: de waarde van het groeperings-attribuut op dit level */
	k: string | null;
	/** v: de gehele subtree onder deze waarde (NB het is een pointer naar een gedeelde subtree, dus de overhead valt mee) */
	v: DataTree<D>;
	/** i: de index van de waarde op dit level -- kan veranderen door sorteeroperatie */
	i: number;
	/** r: de sublijst met alle records onder deze waarde (bevat dus ook circulair het record zelf waar dit level in zit...)
	 *      -- kan van volgorde veranderen door sorteeroperatie
	 *     Op de hogere niveaus betekent dit: alle tabel-rijen die onder deze groeperings-waarde vallen.
	 *     Op het laatste groeperingsniveau: alle data-records die bij deze rij horen.
	 */
	r: Path<A, D>[];
	/**
	 * Als er ge-unnest is met pivot, lopen de "r" paths tot aan het pivot level. Op dat level zelf bevat "xr" alle onderliggende
	 * paths die tot de gehele diepte gaan.
	 */
	xr?: Path<A, D>[];
	/** c: de children van dit level (als levels) -- blijven in originele volgorde bij sorteeroperatie */
	c: Level<A, D>[];
	/** a: geaggregeerde waarden door een custom aggregatiefunctie */
	a: A;
	xa?: { [n: number]: D };
}

/**
 * Een Path is niet zomaar een verzameling Levels, maar een pad vanuit de root van de DataTree
 * richting de diepere niveaus. D.w.z. elk Level is dus een child van de voorgaande.
 *
 * Een rij in de tabel komt overeen met een pad naar het laatste groeperings-niveau.
 * Een balkje in de barchart-table komt overeen met een pad naar het laatste subgroup-niveau.
 */
export type Path<A, D> = Level<A, D>[];

/**
 * wordt alleen gebruikt tijdens het opbouwen van de Paths
 */
export interface PartialLevel<A, D> {
	k: string | null;
	v: DataTree<D>;
	i: number;
	r?: Path<A, D>[];
	xr?: Path<A, D>[];
	c: Level<A, D>[];
	a?: A;
	xa?: { [n: number]: D };
}

export type PartialPath<A, D> = PartialLevel<A, D>[];

export const NULL_STRING = '_NULL';

export const ALL_STRING = '_ALL';

export const ALL_FILTERED_STRING = '_ALL_FILTERED';

export const XAGG_STRING = '_XAGG';

/**
 * Verandert een groepeer-boom (terug) in een platte lijst van records. Elk record is een Path, waarbinnen
 * elk level staat voor een groeperings-attribuut (met aan het begin 1 root level).
 *
 * Als pivot gevuld is, is dit het niveau waarvoor een rij in de tabel aangemaakt wordt. De Paths die door
 * unnest teruggegeven worden gaan dan maar tot dat niveau. Maar het unnesten gaat wel verder: op dit
 * niveau zelf wordt de "xr" lijst gevuld met alle onderliggende Paths naar het diepste niveau.
 */
export function unnest<A, D>(
	tree: DataTree<D>,
	path: PartialPath<A, D> | undefined = undefined, // function modifies last level!
	pivot: number | undefined = undefined,
	aggregateInit: (leaf: D, path: PartialPath<A, D>) => A | undefined = () => undefined,
	aggregateCombine: (c: Level<A, D>[], path: PartialPath<A, D>, all: A | undefined, allFiltered: A | undefined) => A | undefined = () => undefined,
	xaggs: Map<number, DataTree<D>> = new Map()
): Path<A, D>[] {
	if (path === undefined) {
		path = [{ k: 'root', v: tree, i: 0, c: [] }];
	}
	const parent = path[path.length - 1];
	let recs: Path<A, D>[];
	if (!(tree instanceof Map)) {
		recs = [<Path<A, D>>path]; // is eigenlijk nog geen Path maar wordt het wel
		parent.xa = fromPairs([...xaggs] as [number, D][]);
		parent.a = aggregateInit(tree, path);
	} else {
		const [xaggEntries, entries] = partition([...(<Map<string, DataTree<D>>>tree).entries()], ([k]) => k.startsWith(XAGG_STRING));
		const newXaggs = new Map([...xaggs, ...xaggEntries.map(([k, v]) => createXaggMap(k, v))]);
		recs = [];
		let i = 0;
		let all;
		let allFiltered;
		for (const [k, v] of entries) {
			const thisLevel: PartialLevel<A, D> = { k: _NULL2null(k), v, i: i++, c: [] };
			if (k === ALL_STRING) {
				const aggPath = [...path.slice(0, -1), { ...parent, v }];
				all = aggregateInit(<D>v, aggPath);
			} else if (k === ALL_FILTERED_STRING) {
				const aggPath = [...path.slice(0, -1), { ...parent, v }];
				allFiltered = aggregateInit(<D>v, aggPath);
			} else {
				parent.c.push(<Level<A, D>>thisLevel); // is eigenlijk nog geen Level maar wordt het wel
				const vresult = unnest(
					v,
					[...path, thisLevel],
					pivot === undefined ? undefined : pivot - 1,
					aggregateInit,
					aggregateCombine,
					filterXaggs(k, newXaggs)
				);
				recs.push(...vresult);
			}
		}
		parent.a = aggregateCombine(parent.c, path, all, allFiltered);
	}
	if (pivot === 0) {
		parent.xr = recs;
		parent.r = [<Path<A, D>>path];
	} else {
		parent.r = recs;
	}
	return parent.r;
}

function createXaggMap<D>(k: string, v: DataTree<D>): [number, DataTree<D>] {
	return [Number(k.substring(XAGG_STRING.length)), new Map([[k, v]])];
}
function filterXaggs<D>(k: string, xaggs: Map<number, DataTree<D>>): Map<number, DataTree<D>> {
	const newXaggs = [...xaggs].flatMap(([nr, tree]) => lookupXagg(nr, k, tree));
	return new Map(newXaggs);
}

function lookupXagg<D>(nr: number, k: string, tree: DataTree<D>): [number, DataTree<D>][] {
	const branches = tree as Map<string, DataTree<D>>;
	const val = branches.get(k) ?? branches.get(XAGG_STRING + nr);
	return val ? [[nr, val]] : [];
}

// recalculateAggregation?
export function unnestAt<A, D>(
	path: Level<A, D>[],
	depth = 99,
	aggregateInit: (tree: DataTree<D>, path: PartialPath<A, D>) => A | undefined = () => undefined,
	aggregate: (c: Level<A, D>[], path: PartialPath<A, D>) => A | undefined = () => undefined
): void {
	let parents = path.slice(0, -1);
	let child = last(path)!;
	let nOldRecs = 1;
	let newRecs = unnest(child.v, path, depth, aggregateInit, aggregate);
	child.r = newRecs;
	while (parents.length > 0) {
		const parent = last(parents)!;
		const childRecsIx = sumBy(
			parent.c.filter((c) => c.i < child.i),
			(c) => c.r.length
		);
		const nReplaceRecs = nOldRecs;
		nOldRecs = parent.r.length;
		parent.r.splice(childRecsIx, nReplaceRecs, ...newRecs);
		newRecs = parent.r!;
		parents = parents.slice(0, -1);
		child = parent;
	}
}

// de "a" attributes op het diepste tabel-niveau
export function getLeafA<A, D>(path: Path<A, D>): A {
	return last(path)!.a;
}

// is `row` de eerste rij van de tabel waar in kolom `levelNr` de huidige waarde voorkomt?
// (vaak is dit de enige rij waarin deze waarde dan ook echt getoond moet worden)
export function isFirstAtLevel<A, D>(row: Path<A, D>, levelNr: number) {
	return row.slice(levelNr + 1).every((lvl) => lvl.i === 0);
}

export function isLastAtLevel<A, D>(row: Path<A, D>, levelNr: number) {
	return zip(row.slice(levelNr, -1), row.slice(levelNr + 1)).every(([parent, child]) => parent!.c.length - 1 === child!.i);
}

// bij het resultaat van een tabulate zijn de i's op het laaste level aangepast
// dus die kunnen we niet gebruiken
export function isFirstAtLevelTabulated<A, D>(row: Path<A, D>, levelNr: number) {
	const firstAtEnd = row.length <= 1 || last(row)!.k === last(nth(row, -2)!.r[0])!.k;
	return row.slice(levelNr + 1, -1).every((lvl) => lvl.i === 0) && firstAtEnd;
}

/**
 * Sorteert de boom op het aangegeven niveau in de (evt. omgekeerde) volgorde waaruit dat niveau
 * uit de backend komt.
 */
export function sortAtLevel<A, D>(root: Level<A, D>, levelNr: number, direction: 'asc' | 'desc'): Path<A, D>[] {
	if (levelNr === 0) {
		return root.r;
	} else if (levelNr === 1) {
		const orderedChildren = direction === 'asc' ? root.c : reverse([...root.c]);
		orderedChildren.forEach((child, index) => {
			child.i = index;
		});
		const sortedRecs = flatMap(orderedChildren, (child) => child.r);

		if (root.xr) root.xr = sortedRecs;
		else root.r = sortedRecs;

		return root.r;
	} else {
		const orderedChildren = orderBy(root.c, [(child) => child.i]);
		const sortedRecs = flatMap(orderedChildren, (child) => sortAtLevel(child, levelNr - 1, direction));

		if (root.xr) root.xr = sortedRecs;
		else root.r = sortedRecs;

		return root.r;
	}
}

/**
 * Splijt de boom bij de wortel, zodat hij op dat niveau evenveel takken krijgt als records.
 * Niveaus onder de evt. pivot worden met rust gelaten.
 */
export function shrub<A, D>(root: Level<A, D>): Level<A, D> {
	const newLvl = { ...root };
	const c = flatMap(root.c, _shrub).map((lvl, i) => {
		lvl.r[0].splice(0, 0, newLvl);
		return { ...lvl, i };
	});
	return { ...newLvl, c, r: flatMap(c, (lvl) => lvl.r) };
}

function _shrub<A, D>(root: Level<A, D>): Level<A, D>[] {
	if (root.xr || root.c.length == 0) {
		const newLvl: Level<A, D> = { ...root, r: [] };
		newLvl.r.push([newLvl]);
		return [newLvl];
	}
	const { k } = root;
	return flatMap(root.c, (child) => _shrub(child)).map((lvl) => {
		const newLvl: Level<A, D> = { k, c: [lvl], i: 0, r: [...lvl.r], a: lvl.a, v: lvl.v };
		newLvl.r[0].splice(0, 0, newLvl);
		return newLvl;
	});
}

function isAtPivotLevel<A, D>(lvl: Level<A, D>): boolean {
	return !isUndefined(lvl.xr);
}

/**
 * Path dat eindigt bij child.
 */
function childPath<A, D>(parent: Level<A, D>, child: Level<A, D>): Path<A, D> {
	return [...takeWhile(parent.r[0], (lvl) => lvl !== parent), parent, child];
}

/**
 * Sorteert elk niveau van de boom (tot een eventuele pivot) op de waarde die een measure op dat niveau heeft.
 * Niet gebruiken op een (1-rij) boom waar de pivot op level 0 zit, want die is niet te onderscheiden
 * van de tabel die zou horen bij de records die er onder hangen.
 */
export function sortByMeasure<A, D>(
	root: Level<A, D>,
	measure: (path: Level<A, D>[]) => number | string | null,
	direction: 'asc' | 'desc'
): Level<A, D>[][] {
	if (root.c.length === 0) {
		return root.r;
	}

	const orderedChildren: Level<A, D>[] = orderBy(root.c, [(child) => measure(childPath(root, child))], [direction]);
	orderedChildren.forEach((child, index) => {
		child.i = index;
	});
	const sortedRecs = flatMap(orderedChildren, (child) => (isAtPivotLevel(child) ? child.r : sortByMeasure(child, measure, direction)));
	root.r = sortedRecs;
	return sortedRecs;
}

export function _NULL2null(k: string): string | null {
	return k === NULL_STRING ? null : k;
}

export function getPathKey(path: Path<unknown, unknown>) {
	return path.map((lvl) => lvl.k).join(';');
}

export function treeKey(path: Path<unknown, number[]>): [(string | null)[], TreeAsObject<number[]>] {
	const leaf = last(path)!;
	return [path.map((lvl) => lvl.k), dataTreeAsObject(leaf.v)];
}

export function pathTo<A, D>(lvl: Level<A, D>): Path<A, D> {
	const leafPath = lvl.r[0];
	const lvlIx = leafPath.findIndex((val) => val === lvl);
	return leafPath.slice(0, lvlIx + 1);
}

type TreeAsObject<D> = { [k: string]: TreeAsObject<D> } | D;

function dataTreeAsObject<D>(tree: DataTree<D>): TreeAsObject<D> {
	if (tree instanceof Map) return fromPairs([...tree.entries()].map(([k, v]) => [k, dataTreeAsObject(v)]));
	else return tree;
}
