<app-dashboard-container
	class="dashboard-doorstroom"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, getPermanentExpressions(uitsluiten), getDisplayOptions(), $event)"
>
	<app-filter-panel
		[defaultFilters]="actueelFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="getPermanentExpressions(uitsluiten)"
	></app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header [variant]="variant" [legenda]="['ds_nm_idu']" [legendaIncludeNull]="true" [legendaExclude]="legendaExcludes">
		</app-dashboard-header>
		{{ getSchooljarenCaption() }}
		<div class="groepering">
			<app-schooljaar-indicator-over></app-schooljaar-indicator-over>
			<app-onderwijsresultaten-uitsluiten-filter></app-onderwijsresultaten-uitsluiten-filter>
			<app-checkbox label="Toon BRIN-overgang" [value]="toonBrinOvergang" (valueChange)="qp.dispatch('brin-overgang', $event)"></app-checkbox>
		</div>
		<app-barchart-table
			[tableGroups]="table"
			[groups]="variant === 'Historie' ? historieGroups : actueelGroups"
			[filters]="filterExpressions"
			[permanentFilters]="getPermanentExpressions(uitsluiten)"
			[subgroups]="toonBrinOvergang ? doorstroomLevelsMetOvergang : doorstroomLevelsZonderOvergang"
			[config]="this"
		></app-barchart-table>
	</section>
</app-dashboard-container>
