@if (message) {
	<div>{{ message }}</div>
}
<div class="container" data-exportable>
	<svg
		class="header"
		[attr.viewBox]="'0 0 ' + width + ' ' + headerFooterHeight"
		[attr.width]="width + 'px'"
		[attr.height]="headerFooterHeight + 'px'"
	>
		@for (schooljaar of schooljaren; track schooljaar; let ix = $index) {
			<text [attr.x]="(0.5 + ix) * schooljaarWidth" [attr.y]="18" dominant-baseline="middle">{{ schooljaar }}/{{ schooljaar + 1 }}</text>
		}
	</svg>
	<svg
		class="graph"
		[attr.viewBox]="getViewBox()"
		[attr.width]="width + 'px'"
		[attr.height]="height + 'px'"
		[class.scrollable]="scrollable"
		(mousedown)="startDrag($event)"
		(mousemove)="drag($event)"
		(click)="select($event,undefined)"
	>
		@for (schooljaar of schooljaren.slice(1); track schooljaar; let ix = $index) {
			<line [attr.x1]="(ix + 1) * schooljaarWidth" [attr.x2]="(ix + 1) * schooljaarWidth" [attr.y1]="0" [attr.y2]="height"></line>
		}
		@for (entry of blokken | keyvalue; track entry) {
			@if (getBlokInfo(entry.value, selected()); as blokData) {
				<g class="blok"
					[class.hilite]="blokData.filtered"
					[class.lolite]="blokData.filtered === false"
					[class.selected]="entry.value.id===selected()?.id"
					[attr.transform]="getBlokTransform(entry.value)">
					<rect [attr.width]="blokWidth" [attr.y]="(entry.value.height - blokHeight) / 2" [attr.height]="blokHeight" rx="5" (click)="select($event,entry.value)"></rect>
					<text class="leerfase" dominant-baseline="auto" [attr.x]="blokWidth / 2" [attr.y]="entry.value.height / 2 - 4">
						{{ entry.value.niveau }} {{ entry.value.leerjaar }}
					</text>
					<text class="aantal" dominant-baseline="hanging" [attr.x]="blokWidth / 2" [attr.y]="entry.value.height / 2 + 4">
						{{ blokData.measure }}
					</text>
				</g>
			}
		}
		@for (entry of blokken | keyvalue; track entry) {
			<g>
				@for (pill of entry.value.in | keyvalue; track pill) {
					@if (getPillInfo(pill.value, selected()); as pillData) {
						<g class="pill" [class.hilite]="pillData.filtered" [class.lolite]="pillData.filtered === false" [class.clickable]="pillsClickable" [appTooltip]="pill.value.tooltip">
							<rect
								[attr.x]="pill.value.left"
								[attr.y]="pill.value.top"
								[attr.width]="pillWidth"
								[attr.height]="pillHeight"
								[attr.rx]="pill.value.blocky ? 3 : 10"
								[attr.class]="pill.value.className"
								(click)="navigateToDetails(pill.value, false)"
							></rect>
							<text dominant-baseline="middle" [attr.x]="pill.value.left + pillWidth / 2" [attr.y]="pill.value.top + pillHeight / 2">
								{{ pillData.measure }}
							</text>
							@if (pill.value.idu === "Geslaagd") {
								<circle [attr.cx]="pill.value.left + pillWidth" [attr.cy]="pill.value.top" r="6"></circle>
								<g [attr.transform]="'translate(' + (pill.value.left + pillWidth - 3) + ' ' + (pill.value.top - 3) + ')'">
									<path
										class="vink"
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M2.5882 5.82048L6.28849 2.07359C6.43213 1.92725 6.43801 1.69244 6.30025 1.54099L5.56187 0.725083C5.41319 0.56088 5.16034 0.558328 5.0083 0.718277L2.62097 3.22897C2.4706 3.38722 2.22112 3.38636 2.07159 3.22642L1.44662 2.55769C1.30045 2.4003 1.05433 2.39689 0.902286 2.54918L0.112668 3.34468C-0.0376956 3.49612 -0.0376956 3.742 0.113508 3.89174L2.05227 5.82304C2.2018 5.97107 2.44036 5.97022 2.5882 5.82048Z"
									/>
								</g>
							}
						</g>
					}
				}
				@for (pill of entry.value.out | keyvalue; track pill) {
					@if (getPillInfo(pill.value, selected()); as pillData) {
						<g class="pill" [class.hilite]="pillData.filtered" [class.lolite]="pillData.filtered === false" [class.clickable]="pillsClickable" [appTooltip]="pill.value.tooltip">
							<rect
								[attr.x]="pill.value.left"
								[attr.y]="pill.value.top"
								[attr.width]="pillWidth"
								[attr.height]="pillHeight"
								[attr.rx]="pill.value.blocky ? 3 : 10"
								[attr.class]="pill.value.className"
								(click)="navigateToDetails(pill.value, true)"
							></rect>
							<text dominant-baseline="middle" [attr.x]="pill.value.left + pillWidth / 2" [attr.y]="pill.value.top + pillHeight / 2">
								{{ pillData.measure }}
							</text>
							@if (pill.value.idu?.startsWith("Geslaagd")) {
								<circle [attr.cx]="pill.value.left + pillWidth" [attr.cy]="pill.value.top" r="6"></circle>
								<g [attr.transform]="'translate(' + (pill.value.left + pillWidth - 3) + ' ' + (pill.value.top - 3) + ')'">
									<path
										class="vink"
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M2.5882 5.82048L6.28849 2.07359C6.43213 1.92725 6.43801 1.69244 6.30025 1.54099L5.56187 0.725083C5.41319 0.56088 5.16034 0.558328 5.0083 0.718277L2.62097 3.22897C2.4706 3.38722 2.22112 3.38636 2.07159 3.22642L1.44662 2.55769C1.30045 2.4003 1.05433 2.39689 0.902286 2.54918L0.112668 3.34468C-0.0376956 3.49612 -0.0376956 3.742 0.113508 3.89174L2.05227 5.82304C2.2018 5.97107 2.44036 5.97022 2.5882 5.82048Z"
									/>
								</g>
							}
						</g>
					}
				}
			</g>
		}
		@for (lijn of lijnen; track lijn) {
			<path [attr.d]="getPath(lijn)" [class.lolite]="!isLijnFiltered(lijn, selected())"></path>
		}
	</svg>
	<svg
		class="footer"
		[attr.viewBox]="'0 0 ' + width + ' ' + headerFooterHeight"
		[attr.width]="width + 'px'"
		[attr.height]="headerFooterHeight + 'px'"
	>
		@for (schooljaar of schooljaren; track schooljaar; let ix = $index) {
			<text [attr.x]="(0.5 + ix) * schooljaarWidth" [attr.y]="18" dominant-baseline="middle">
				{{ getSchooljaarMeasure(schooljaar) }}
			</text>
		}
	</svg>
</div>
