import { Component, Input } from '@angular/core';
import { IndicatorNaamPrestatieanalyse } from '@cumlaude/metadata';
import { percentiel_obv_normaal } from '../../../services/percentiel';
import { formatNumber, formatPercent } from '@angular/common';
import { TooltipElement, TooltipDirective } from '@cumlaude/shared-components-overlays';
import { SinglePercentielHbarComponent } from '../single-percentiel-hbar/single-percentiel-hbar.component';

export interface PrestatieanalyseCellData {
	pv_nm_indicator: IndicatorNaamPrestatieanalyse;
	pv_nr_score: number | null;
	p25_p75: [number, number] | null;
	tooltip: TooltipElement[];
	is_onvoldoende: boolean;
}

@Component({
	selector: 'app-card-pa-cell',
	templateUrl: './card-pa-cell.component.html',
	styleUrls: ['./card-pa-cell.component.scss'],
	standalone: true,
	imports: [TooltipDirective, SinglePercentielHbarComponent],
})
export class CardPaCellComponent {
	@Input()
	data!: PrestatieanalyseCellData;

	getScore() {
		const { pv_nr_score, pv_nm_indicator } = this.data;
		if (pv_nr_score === null) return '';

		if (pv_nm_indicator === IndicatorNaamPrestatieanalyse.UITSTROOM_I_R_T_HET_IQ)
			return `${pv_nr_score > 0 ? '+' : ''}${formatNumber(pv_nr_score, 'nl_NL', '1.1-1')}`;

		return formatPercent(pv_nr_score / 100, 'nl_NL', '1.0-0');
	}

	getPercentielBarData() {
		const { pv_nr_score, p25_p75 } = this.data;
		if (pv_nr_score == null || p25_p75 == null) return null;
		const percentiel = percentiel_obv_normaal(pv_nr_score, ...p25_p75);
		return { heightPx: 24, widthPx: 64, percentiel };
	}
}
