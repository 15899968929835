<app-base-dialog sizeClass="size-44" [topBarTemplate]="topBar" [bottomBarTemplate]="bottomBar" [multiRow]="true">
	<ng-template #topBar>
		<app-dialog-header title="Rol toekennen"></app-dialog-header>
	</ng-template>
	<div class="top">
		<h3>Selecteer de gebruikers die je de rol {{ data.rolLabel }} wil toekennen</h3>
	</div>
	<div class="controls">
		@if ({ value: filter$ | async }; as filter) {
			<app-instant-search-box
				[value]="filter.value!"
				(fire)="filter$.next($event)"
				placeholder="Zoek gebruiker"
				cdkFocusInitial
			></app-instant-search-box>
		}
	</div>
	@if (filtered$ | async; as filtered) {
		<div class="column-list">
			@for (account of filtered; track account) {
				<div class="account">
					<app-checkbox [label]="getAccountNaam(account)" [value]="selection.has(account.id!)" (valueChange)="toggle(account)">
					</app-checkbox>
				</div>
			} @empty {
				<div>Geen gebruikers gevonden.</div>
			}
		</div>
	}
	<ng-template #bottomBar>
		@if (licentieMelding$ | async; as licentieMelding) {
			<div class="message" [class.warning]="licentieMelding.warning">
				{{ licentieMelding.tekst }}
			</div>
		}
		<app-button buttonType="secondary" (clickit)="dialogRef.close()">Annuleren</app-button>
		<app-button [enabled]="selection.size > 0 && !((licentieMelding$ | async)?.warning ?? false)" (clickit)="dialogRef.close(getSelection())">
			Toekennen
		</app-button>
	</ng-template>
</app-base-dialog>
