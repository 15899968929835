import { Component, OnInit } from '@angular/core';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import {
	AttrPath,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
	InFilterExpression,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { FactTable } from '../../services/exportable';
import { Observable } from 'rxjs';
import { MultiAggregator, weightedAverage, weightedAverageAll, xAgg0 } from '../../services/aggregation';
import { ColumnDef, createDefaultFooterCellDef, createDefaultHeaderCellDef, TableModel } from '../../shared/components/table/table/table.model';
import { ColumnType, createMeasureColumn, DataRow, DataTreeTableComponent } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { ALL_STRING, DataTree, getLeafA, Path, unnest } from '../../services/data-tree';
import { FilterService } from '../../services/filter.service';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { ToastrService } from 'ngx-toastr';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { BarInfo } from '../../services/stacked-bars';
import { Attributes } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { SinglePercentielHbarComponent } from '../prestatieanalyse/single-percentiel-hbar/single-percentiel-hbar.component';
import { Cijferkolomtype } from '@cumlaude/metadata';
import { flatMap, intersection, isEmpty, isNil, last, memoize, nth, uniq } from 'lodash-es';
import { map, tap } from 'rxjs/operators';
import { Sort } from '../../shared/components/table/table/table.component';
import { PercentielSchaalverdelingComponent } from '../prestatieanalyse/percentiel-schaalverdeling/percentiel-schaalverdeling.component';
import { VbarSeriesData, VbarStyle } from '../../shared/dashboard/vbarchart-table/vbar-batch/vbar-batch.component';
import { att, count_records } from '../../services/measures';
import { formatNumber } from '@angular/common';
import { Axis } from '../../services/axis';
import { PartitionMeasure, VbarchartTableComponent, VBarExportColumnDef } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { AfwijkingPercentielWeergave, DashboardVariant } from '../../services/weergave-opties';
import { VPartitionData } from '../../shared/dashboard/vbarchart-table/vbar-series/vbar-series.component';
import { createAggFunctions } from '../../shared/dashboard/base-dashboard/base-dashboard.component';
import { PsName } from '../../services/page-state.service';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { RVestiging } from '@cumlaude/service-contract';

interface CijfersExamenI extends Attributes {
	ekc_nr_gem_cijfer: number;
	ekc_nr_perc_cijfer: number | null;
	xa: { [bitset: number]: { ekc_nr_gem_cijfer: number | null } };
}

interface CijfersExamenA extends Attributes {
	ekc_nr_gem_cijfer: number;
	ekc_nr_perc_cijfer: number | null;
	ekc_nr_gem_cijfer_landelijk: number | null;
	ekc_nr_gem_cijfer_landelijk_historie: number;
}

/**
 * Als weightedAverage, maar pakt alleen op het root-niveau de _ALL (wanneer aanwezig).
 * Wordt gebruikt om het totaal-percentiel aan te passen.
 */
function weightedAverageAllAtRoot(
	attribute: keyof CijfersExamenA,
	initialAttribute: keyof CijfersExamenI
): MultiAggregator<typeof attribute, CijfersExamenI, CijfersExamenA, number | null> {
	const orig = weightedAverage(attribute, initialAttribute);
	const combine = (as: CijfersExamenA[], keys: (string | null)[], all: number | null | undefined) => {
		if (keys.length == 0 && all !== undefined) return all;
		return orig.combine(as, keys, all);
	};
	return { ...orig, combine };
}

const vestigingGroup: AttrPath = ['ekc_fk_br_vest', 'br_co_brin'];

const vakKeyGroups: AttrPath[] = [
	['ekc_fk_vk', 'vk_nm_vak'],
	['ekc_fk_vk', 'vk_co_vak'],
];

@Component({
	selector: 'app-cijfers-examens',
	templateUrl: './cijfers-examens.component.html',
	styleUrls: ['./cijfers-examens.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		FormDropdownComponent,
		BarchartTableComponent,
		DataTreeTableComponent,
		VbarchartTableComponent,
	],
})
export class CijfersExamensComponent extends BarchartTableConfig<CijfersExamenI, CijfersExamenA> implements OnInit {
	defaultGroups: AttrPath[] = [['ekc_fk_vk', 'vk_nm_vak']];

	fixedBeforeGroups = 1;

	selectedGroups: AttrPath[] = this.defaultGroups;

	prognose = false;

	cumlaudeSchooljaren: string | undefined;

	availableGroups: AttrPath[] = [...vakKeyGroups, ['ekc_fk_vk', 'vk_nm_vakkengroep']];

	actueelFilters: FilterName[] = ['ekc_nm_schooljaar', 'ekc_fk_br_vest.br_co_brin', 'ekc_nm_niveau', 'ekc_fk_vk.vk_nm_vak', 'ekc_nm_cijfertype'];

	historieFilters: FilterName[] = ['x_ekc_schooljaar_historie', 'x_ekc_multiselect_schooljaar', ...this.actueelFilters];

	filterExpressions?: FilterExpression[];

	factTable = FactTable.examencijfers;

	variant!: DashboardVariant;

	weergave!: AfwijkingPercentielWeergave;

	cijfertype = Cijferkolomtype.CE_CIJFER;

	ownBrinFilter!: FilterExpression;

	weergaveOpties = Object.values(AfwijkingPercentielWeergave).map((val) => new Option(val));

	protected singleAggregators = {
		ekc_nr_gem_cijfer_landelijk_historie: xAgg0<'ekc_nr_gem_cijfer', CijfersExamenI>('ekc_nr_gem_cijfer'),
	};

	protected multiAggregators = [
		weightedAverage('ekc_nr_gem_cijfer', 'ekc_nr_gem_cijfer'),

		// Het percentiel in de totaalregel wordt uit de root _ALL gehaald. Die is daar niet door de backend in gestopt, maar door de functie patchPercentiel van dit dashboard.
		// Die functie gebruikt ook deze zelfde aggregator om zonodig percentielen van de vestigingen op root-niveau samen te voegen.
		weightedAverageAllAtRoot('ekc_nr_perc_cijfer', 'ekc_nr_perc_cijfer'),

		// landelijk gemiddelde komt uit _ALL op vestigingniveau (eigen vestigingen zitten in een HAVING-filter)
		weightedAverageAll('ekc_nr_gem_cijfer_landelijk', 'ekc_nr_gem_cijfer'),
	];

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
		this.subscriptions.push(
			filterService.observe('ekc_nm_cijfertype').subscribe((cijfertype: Cijferkolomtype) => {
				this.cijfertype = cijfertype;
			}),
			this.userService.rVestigingen$.subscribe((vestigingen: RVestiging[]) => {
				const vestigingBrins = uniq(flatMap(vestigingen, (vestiging) => vestiging.vestigingBrins));
				this.ownBrinFilter = new InFilterExpression(vestigingGroup, vestigingBrins);
			})
		);
	}

	ngOnInit(): void {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe_g().subscribe((groups) => (this.selectedGroups = groups ?? this.defaultGroups)),
			this.qp.observe('afwijkingpercentiel').subscribe((weergave) => (this.weergave = weergave)),
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant))
		);
	}

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		const { f, having } = this.dataService.moveToHaving([vestigingGroup], options);
		const xa = this.variant === DashboardVariant.HISTORIE ? [[options.g!.length - 2]] : [];
		return this.dataService
			.getExamencijfersData({
				...options,
				f,
				r: [0, this.getRollupLevel()],
				having: having ? new CompoundFilterExpression([having, this.ownBrinFilter]) : this.ownBrinFilter,
				xa,
			})
			.pipe(
				map(this.patchPercentiel.bind(this)),
				tap((response) => {
					this.cumlaudeSchooljaren = response.metadata!.get('cumlaudeSchooljaren');
					this.prognose = !isEmpty(this.cumlaudeSchooljaren);
					this.pageStateService.dispatch(PsName.prognose, String(this.prognose));
				})
			);
	}

	/**
	 * Haalt het percentiel voor de totaalregel uit het resultaat van de "order" query en stopt het onder de root _ALL in de
	 * normale data (voordat die in een DataTree wordt omgezet).
	 */
	patchPercentiel(resp: DataResponse<number[]>): DataResponse<number[]> {
		const { data, order } = resp;
		const { aggInit, aggCombine } = createAggFunctions<CijfersExamenI, CijfersExamenA, CijfersExamensComponent>(this, [], resp.measures); // subgroups hebben we hier niet nodig
		const orderRecs = unnest(order!, undefined, undefined, aggInit, aggCombine);
		if (!orderRecs.length) return resp;
		const orderRootPath = orderRecs[0].slice(0, 1);
		const percentielTotaal = att<'ekc_nr_perc_cijfer', CijfersExamenA>('ekc_nr_perc_cijfer')(orderRootPath);
		(<number[]>(<Map<string, DataTree<number[]>>>data).get(ALL_STRING)).splice(
			resp.measures.findIndex((s) => s === 'ekc_nr_perc_cijfer'),
			1,
			percentielTotaal!
		);
		return resp;
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getExamencijfersExportData(options);
	}

	getDefaultSort(tableModel: TableModel<DataRow<CijfersExamenA>>): Sort {
		if (this.weergave === AfwijkingPercentielWeergave.PERCENTIEL) return { active: 'Percentiel', direction: 'desc' };
		return super.getDefaultSort(tableModel);
	}

	getPermanentFilterForFilterValues = memoize(CijfersExamensComponent._getPermanentFilterForFilterValues, JSON.stringify);

	static _getPermanentFilterForFilterValues(ownBrinFilter: FilterExpression) {
		return [ownBrinFilter];
	}

	getCijfertypeKop(landelijk: boolean) {
		const prefix = landelijk ? 'Landelijk ' : '';
		switch (this.cijfertype) {
			case Cijferkolomtype.CE_CIJFER:
				return `${prefix}CE`;
			case Cijferkolomtype.SE_CIJFER:
				return `${prefix}SE`;
			case Cijferkolomtype.EINDCIJFER:
				return `${prefix}Eind`;
			default:
				throw new Error(`Ongeldig cijfertype ${this.cijfertype}`);
		}
	}

	createWeergaveColumns() {
		if (this.weergave === AfwijkingPercentielWeergave.AFWIJKING) {
			return [createMeasureColumn<CijfersExamenI, CijfersExamenA>('Afwijking', (path) => this.getAfwijking(path), { format: '+1.2-2' })];
		}

		const percentielBar = createMeasureColumn<CijfersExamenI, CijfersExamenA>(
			'percentielBar',
			(path) => ({ percentiel: this.getPercentiel(path), heightPx: 36, widthPx: 128 }),
			{
				component: SinglePercentielHbarComponent,
				columnType: ColumnType.BARCHART,
			}
		);
		percentielBar.header = {
			...createDefaultHeaderCellDef('percentielBar', ''),
			component: PercentielSchaalverdelingComponent,
		};
		percentielBar.footer = {
			...createDefaultFooterCellDef(),
			component: PercentielSchaalverdelingComponent,
		};

		return [
			percentielBar,
			createMeasureColumn<CijfersExamenI, CijfersExamenA>('Percentiel', (path) => getLeafA(path).ekc_nr_perc_cijfer, {
				dataType: 'percentage',
			}),
		];
	}

	/**
	 * We weten alleen het correcte aantal leerlingen per vak, en niet op een hoger aggregatieniveau zoals vakkengroep.
	 * Aggregeren over vestigingen heen (=optellen) kan wel.
	 */
	createLeerlingenColumn() {
		return this.isLeerlingenBerekenbaar()
			? [createMeasureColumn<CijfersExamenI, CijfersExamenA>('Leerlingen', (path) => getLeafA(path).count_records)]
			: [];
	}

	isLeerlingenBerekenbaar() {
		const groups = this.getGroupsAndSubgroups(this.selectedGroups, this.variant).map((attrPath) => attrPath.join('.'));
		const vakKeys = vakKeyGroups.map((attrPath) => attrPath.join('.'));
		return intersection(groups, vakKeys).length > 0;
	}

	createMeasureColumns(): ColumnDef<DataRow<CijfersExamenA>>[] {
		if (this.variant === DashboardVariant.ACTUEEL)
			return [
				...this.createWeergaveColumns(),
				createMeasureColumn(this.getCijfertypeKop(false), (path) => getLeafA(path).ekc_nr_gem_cijfer, { format: '1.2-2' }),
				createMeasureColumn(this.getCijfertypeKop(true), (path) => this.getLandelijkCijfer(path), { format: '1.2-2' }),
				...this.createLeerlingenColumn(),
			];
		else return [];
	}

	protected getFixedAfterGroups(): number {
		return this.variant === DashboardVariant.HISTORIE ? 0 : 1;
	}

	getPartitionMeasure = memoize(this._getPartitionMeasure.bind(this), JSON.stringify);

	private _getPartitionMeasure(weergave: AfwijkingPercentielWeergave): PartitionMeasure<CijfersExamenA> {
		if (weergave === AfwijkingPercentielWeergave.AFWIJKING)
			return {
				type: 'number',
				getValue: (path) => this.getAfwijking(path),
				format: '+1.2-2',
				visible: false,
			};
		return {
			type: 'percentage',
			getValue: (path) => {
				const pct = this.getPercentiel(path);
				return pct !== null ? pct / 100 : null;
			},
			format: '1.0-0',
			visible: false,
		};
	}

	getPartitionTooltip(path: Path<CijfersExamenA, number[]>): TooltipElement[] {
		return this.getTooltip(path);
	}

	getTooltip(path: Path<CijfersExamenA, number[]>): TooltipElement[] {
		const tooltips: TooltipElement[] = [];

		if (this.weergave === AfwijkingPercentielWeergave.AFWIJKING) {
			const afwijking = this.getAfwijking(path) ?? 0;
			tooltips.push({ label: 'Afwijking', value: formatNumber(afwijking, 'nl-NL', '1.2-2') });
		} else {
			const percentiel = this.getPercentiel(path);
			tooltips.push({ label: 'Percentiel', value: percentiel === null ? 'Onb' : `${formatNumber(percentiel, 'nl-NL', '1.0-0')}%` });
		}

		const cijfer = att<'ekc_nr_gem_cijfer', CijfersExamenA>('ekc_nr_gem_cijfer')(path);
		tooltips.push({ label: this.cijfertype, value: cijfer == null ? 'Onb' : formatNumber(cijfer, 'nl-NL', '1.2-2') });

		if (this.isLeerlingenBerekenbaar()) {
			tooltips.push({ label: 'Leerlingen', value: `${count_records(path)}` });
		}

		return tooltips;
	}

	makeBar(_attrs: CijfersExamenI, path: Path<CijfersExamenA, number[]>): BarInfo {
		const afwijking = this.getAfwijking(path) ?? 0;
		return {
			size: 100,
			className: afwijking < 0 ? 'afwijkingnegatief' : 'afwijkingpositief',
			tooltip: this.getTooltip(path),
			linkData: {},
		};
	}

	getBarchartQty(path: Path<CijfersExamenA, number[]>): number | null {
		if (this.weergave === AfwijkingPercentielWeergave.AFWIJKING) return this.getAfwijking(path) ?? 0;
		else return this.getPercentiel(path);
	}

	getVbarStyle(): VbarStyle {
		if (this.weergave === AfwijkingPercentielWeergave.AFWIJKING) return VbarStyle.BAR;
		else return VbarStyle.CIRCLE;
	}

	createXAxis(context: DashboardContext<CijfersExamenI, CijfersExamenA, CijfersExamensComponent>): Axis {
		const afwijkingen = flatMap(context.dataRoot!.r, (row) => last(row)!.xr!.map((path) => this.getAfwijking(path) ?? 0));
		const min = Math.min(...afwijkingen, -1);
		const max = Math.max(...afwijkingen, 1);
		return { min, max, ticks: [] };
	}

	createYAxis(context: DashboardContext<CijfersExamenI, CijfersExamenA, CijfersExamensComponent>): Axis {
		if (this.weergave === AfwijkingPercentielWeergave.AFWIJKING) {
			const afwijkingen = flatMap(context.dataRoot!.r, (row) => last(row)!.xr!.map((path) => this.getAfwijking(path) ?? 0));
			const min = Math.min(...afwijkingen, -1);
			const max = Math.max(...afwijkingen, 1);
			const standardTicks = [-1, 0, 1].map((afw) => ({ qty: afw, label: `${afw}` }));
			const extraTicks = min < -1 ? [{ qty: min, label: '' }] : [];
			return { min, max, ticks: [...extraTicks, ...standardTicks] };
		} else {
			return { min: 0, max: 100, ticks: [0, 25, 50, 75, 100].map((qty) => ({ qty, label: `${qty}%` })) };
		}
	}

	getRollupLevel() {
		return this.getGroupsAndSubgroups(this.selectedGroups, this.variant).findIndex((path) => path.join('.') === vestigingGroup.join('.'));
	}

	getLandelijkCijfer(path: Path<CijfersExamenA, number[]>) {
		const landelijkLevel = this.getRollupLevel();
		if (path.length > landelijkLevel) {
			return nth(path, landelijkLevel)!.a.ekc_nr_gem_cijfer_landelijk;
		} else {
			return getLeafA(path).ekc_nr_gem_cijfer_landelijk;
		}
	}

	getAfwijking(path: Path<CijfersExamenA, number[]>): number | null {
		const { ekc_nr_gem_cijfer } = getLeafA(path);
		const ekc_nr_gem_cijfer_landelijk = this.getLandelijkCijfer(path);
		// bij schooljaren zonder data zijn bovenstaande undefined!
		if (isNil(ekc_nr_gem_cijfer) || isNil(ekc_nr_gem_cijfer_landelijk)) return null;
		return ekc_nr_gem_cijfer - ekc_nr_gem_cijfer_landelijk;
	}

	/**
	 * NB als er niet op vestigingen gegroepeerd wordt, komen verschillende vestigingen van de school in subgroups.
	 * De "Percentiel" kolom toont dan het gewogen gemiddelde van de vestigings-percentielen.
	 * (niet het percentiel van het cijfer gemiddeld over de vestigingen)
	 */
	getPercentiel(path: Path<CijfersExamenA, number[]>): number | null {
		const frac = getLeafA(path).ekc_nr_perc_cijfer;
		return isNil(frac) ? null : frac * 100;
	}

	getGroupsAndSubgroups = memoize(CijfersExamensComponent._getGroupsAndSubgroups, (selectedGroups, variant) =>
		JSON.stringify([selectedGroups, variant])
	);

	/**
	 * Dit dashboard vraagt aan de backend altijd een groepering op vestiging (anders kunnen de percentielen niet uitgerekend worden).
	 */
	private static _getGroupsAndSubgroups(selectedGroups: AttrPath[], variant: DashboardVariant): AttrPath[] {
		const maybeSchooljaar: AttrPath[] = variant === DashboardVariant.HISTORIE ? [['ekc_nm_schooljaar']] : [];
		return [['ekc_nm_niveau'], ...selectedGroups, vestigingGroup, ...maybeSchooljaar];
	}

	getGroups = memoize(CijfersExamensComponent._getGroups, (selectedGroups, variant) => JSON.stringify([selectedGroups, variant]));

	private static _getGroups(selectedGroups: AttrPath[], variant: DashboardVariant): AttrPath[] {
		const all = CijfersExamensComponent._getGroupsAndSubgroups(selectedGroups, variant);
		return all.slice(0, CijfersExamensComponent._getNrTableGroups(selectedGroups, variant));
	}

	getSubgroups = memoize(CijfersExamensComponent._getSubgroups, (selectedGroups, variant) => JSON.stringify([selectedGroups, variant]));

	private static _getSubgroups(selectedGroups: AttrPath[], variant: DashboardVariant): AttrPath[] {
		const all = CijfersExamensComponent._getGroupsAndSubgroups(selectedGroups, variant);
		return all.slice(CijfersExamensComponent._getNrTableGroups(selectedGroups, variant));
	}

	/**
	 * Het aantal groeperingen van de tabel. Deze bestaan uit de vaste niveau-groepering, de custom groeperingen en de BRIN.
	 * Bij de Historie-variant (vbarchart) telt de laatste groepering niet mee, die wordt een batch (behalve
	 * als dit de niveau-groepering is).
	 */
	private static _getNrTableGroups(selectedGroups: AttrPath[], variant: DashboardVariant) {
		const standard = 1 + selectedGroups.length + 1;
		return variant === DashboardVariant.ACTUEEL ? standard : Math.max(1, standard - 1);
	}

	getSelectedWeergaveOptie() {
		return this.weergaveOpties.find((optie) => optie.value === this.weergave)!;
	}

	enrichTableModel(
		_context: DashboardContext<CijfersExamenI, CijfersExamenA, CijfersExamensComponent>,
		tableModel: TableModel<DataRow<CijfersExamenA>>
	) {
		tableModel.showFooters = this.variant === DashboardVariant.ACTUEEL && this.weergave === AfwijkingPercentielWeergave.PERCENTIEL;
		tableModel.rowsClickable = false;
	}

	getExportTablePartitionColumns(
		partition: VPartitionData,
		ix: number,
		getValue: (rowModel: DataRow<CijfersExamenA>) => any
	): VBarExportColumnDef<CijfersExamenA>[] {
		return [
			{
				name: 'Percentiel',
				type: 'percentage',
				format: '1.0-0',
				groupName: partition.label,
				getValue,
				unwrapChildren: (seriesData: VbarSeriesData) =>
					getLeafA(<Path<CijfersExamenA, number[]>>seriesData.series[ix].path).ekc_nr_perc_cijfer,
			},
			{
				name: 'Afwijking',
				type: 'number',
				format: '+1.1-1',
				getValue,
				unwrapChildren: (seriesData: VbarSeriesData) => this.getAfwijking(<Path<CijfersExamenA, number[]>>seriesData.series[ix].path),
			},
			{
				name: 'Gemiddelde',
				type: 'number',
				format: '1.2-2',
				getValue,
				unwrapChildren: (seriesData: VbarSeriesData) =>
					getLeafA(<Path<CijfersExamenA, number[]>>seriesData.series[ix].path).ekc_nr_gem_cijfer,
			},
			{
				name: 'Landelijk',
				type: 'number',
				format: '1.2-2',
				getValue,
				unwrapChildren: (seriesData: VbarSeriesData) =>
					getLeafA(<Path<CijfersExamenA, number[]>>seriesData.series[ix].path).ekc_nr_gem_cijfer_landelijk_historie,
			},
			{
				name: 'Leerlingen',
				type: 'number',
				format: '1.0',
				getValue,
				unwrapChildren: (seriesData: VbarSeriesData) => {
					const count = getLeafA(seriesData.series[ix].path).count_records;
					return count ? count : null;
				},
			},
		];
	}

	protected readonly DashboardVariant = DashboardVariant;
	protected readonly AfwijkingPercentielWeergave = AfwijkingPercentielWeergave;
}
