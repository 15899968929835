import { Component, Input } from '@angular/core';
import { ColumnType, DataRow, DataTreeTableComponent } from '../data-tree-table/data-tree-table';
import { Level, Path } from '../../../services/data-tree';
import { Router } from '@angular/router';
import { AlternatingMode, ColumnDef, createColumnDef as createDefaultColumnDef } from '../../components/table/table/table.model';
import { attrLabel } from '../../../services/labels';
import { AttrPath } from '../../../services/data.service';
import { last, nth, sortBy } from 'lodash-es';
import { LinechartProps } from './linechart/linechart.component';
import { LinechartBatchComponent, LinechartBatchData } from './linechart-batch/linechart-batch.component';
import { lookup, lookupPath } from '../../../services/tabulate';
import { getSchooljaarKort } from '@cumlaude/shared-utils';
import { UrlService } from '../../../services/url.service';
import { LinechartTableConfig } from './linechart-table-config';
import { Attributes } from '../base-dashboard/base-dashboard-config';
import { DashboardContext } from '../base-dashboard/dashboard-context';
import { AsyncPipe } from '@angular/common';
import { TableComponent } from '../../components/table/table/table.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

const CHART_COLUMN_NAME = 'chart';

@Component({
	selector: 'app-linechart-table',
	templateUrl: '../data-tree-table/data-tree-table.html',
	styleUrls: ['../data-tree-table/data-tree-table.scss'],
	providers: [{ provide: DataTreeTableComponent, useExisting: LinechartTableComponent }], // zorgt dat dit component als subclass van DataTreeTableComponent wordt herkend voor het ViewChild-attribuut van DataTreeTableConfigConfig
	standalone: true,
	imports: [CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, TableComponent, AsyncPipe],
})
export class LinechartTableComponent<I extends Attributes, A extends Attributes, C extends LinechartTableConfig<I, A>> extends DataTreeTableComponent<
	I,
	A,
	C
> {
	/**
	 * De kolom met linecharts bevat meestal een extra groepering, nl de eerste van de "subgroups" levels. Deze groep noemen we de "batch".
	 * Bij batchLevels==0 is er geen extra groepering.
	 */
	@Input()
	batchLevels!: 0 | 1;

	@Input() linechartHeight: number = 154;

	/**
	 * 1 = alleen schooljaar
	 * 2 = schooljaar + maand, of schooljaar + week
	 */
	@Input() intervalLevels!: 1 | 2;

	constructor(
		protected router: Router,
		protected urlService: UrlService
	) {
		super(router, urlService);
	}

	protected createDataColumns(context: DashboardContext<I, A, LinechartTableConfig<I, A>>): ColumnDef<DataRow<A>>[] {
		return [this.createChartColumn(context)];
	}

	private createChartColumn(context: DashboardContext<I, A, LinechartTableConfig<I, A>>): ColumnDef<DataRow<A>> {
		const name = this.batchLevels ? context.subgroupNames[0] : CHART_COLUMN_NAME;
		const title = this.batchLevels ? attrLabel(<AttrPath>name.split('.')) : '';
		const coldef: ColumnDef<DataRow<A>> = createDefaultColumnDef(name, title, this.batchLevels === 1);

		coldef.body.component = LinechartBatchComponent;
		coldef.body.getValue = (row) => this.createBatchData(context, row._path);
		coldef.type = ColumnType.GROUP;

		if (this.config.isHistorieBatchVariant()) {
			coldef.header.headerType = 'groepering';
			coldef.header.batchGroepering = true;
		}

		return coldef;
	}

	private createBatchData(context: DashboardContext<I, A, LinechartTableConfig<I, A>>, path: Path<A, number[]>): LinechartBatchData<A> {
		const rowLvl = last(path)!;
		const groups = this.batchLevels ? sortBy(rowLvl.c, (lvl) => lvl.i) : [{ ...rowLvl, r: rowLvl.xr ?? rowLvl.r }];
		return {
			batch: groups.map((group) => {
				const linechartProps = this.createLinechartProps(context, path, group);
				return { key: this.batchLevels ? this.displayService.display(group.k) : '', linechartProps };
			}),
			gridHeight: this.linechartHeight,
		};
	}

	private createLinechartProps(
		context: DashboardContext<I, A, LinechartTableConfig<I, A>>,
		path: Path<A, number[]>,
		group: Level<A, number[]>
	): LinechartProps<A> {
		const { config, orderRoot } = context;
		const intervalTopGroups = this.intervalLevels == 1 ? [{ ...orderRoot!, k: '' }] : orderRoot!.c;
		const fullPath = [...path, group];

		const chartData = intervalTopGroups.map((l0) => {
			const firstFoundPath = lookupPath(group, [l0]);
			return {
				label: this.getIntervalLabel(context, 0, l0.k),
				path: firstFoundPath === undefined ? firstFoundPath : [...fullPath, ...firstFoundPath],
				data: l0.c.map((l1) => {
					const intervalPath = this.intervalLevels == 1 ? [l1] : [l0, l1];
					const found = lookup(group, intervalPath);
					const secondFoundPath = lookupPath(group, intervalPath);
					return {
						label: this.getIntervalLabel(context, 1, l1.k),
						path: secondFoundPath === undefined ? secondFoundPath : [...fullPath, ...secondFoundPath],
						data: config.makePoints(found?.r ?? [], context, group),
					};
				}),
			};
		});
		const yAxis = config.getYAxis(fullPath);
		return { data: chartData, yAxis, lineNames: config.getLineNames(fullPath), formatter: config.getQtyFormatter() };
	}

	private getIntervalLabel(context: DashboardContext<I, A, LinechartTableConfig<I, A>>, level: number, key: string | null): string {
		if (key === '') return '';
		const attr = nth(context.subgroupNames[this.batchLevels + Math.min(level, this.intervalLevels - 1)].split('.'), -1);
		switch (attr) {
			case 'per_nm_schooljaar':
				return getSchooljaarKort(<string>key);
			case 'per_nr_maand':
				return ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'][Number(key) - 1];
			default:
				return this.displayService.display(key);
		}
	}

	getAlternatingMode() {
		return AlternatingMode.LINE;
	}
}
