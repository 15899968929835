/**
 * Het komt regelmatig voor dat er een meatball-menu zit in een div waarop zelf ook een
 * klikactie gedefinieerd is.
 *
 * Als je dan op de meatball klikt, moet het meatball-menu openen, en die klikactie niet
 * uitgevoerd worden. Echter, het meatball-menu kan geen stopPropagation doen op de klik,
 * want een ander meatball-menuutje dat eventueel open staat moet deze als click-outside
 * detecteren en sluiten.
 *
 * Daarom moet je in de click handler van de omliggende div controleren of het een meatball
 * click is, en zo ja, de klikactie niet uitvoeren en het event door laten gaan.
 */
export function isMeatballClick(event?: Event) {
	if (!event) return false;
	return event.target instanceof Element && (event.target as Element).attributes.getNamedItem('data')?.value === MEATBALL_ID;
}

export const MEATBALL_ID = 'meat';
