/**
 * Atts / AttrPaths die eindigen op deze attributen worden niet getoond als filter/groepering als de school geen VO vestigingen heeft.
 */
import { Attr } from './data.service';
import { ExtraFilterNames } from './filter-config';

export const geenVOBlocklist: (Attr | ExtraFilterNames)[] = [
	'ds_nm_bbs_uitzondering_van',
	'ds_nm_obs_uitzondering_van',
	'ds_nm_op_uitzondering_van',
	'no_co_indicator',
	'no_d_geldig_tm',
	'no_d_geldig_va',
	'no_nm_indicator',
	'no_nm_soort_onderwijs',
	'no_nm_vergelijkgroep',
	'no_nr_apcg_corr_factor',
	'no_nr_instroom_corr_factor',
	'no_nr_opstroom_corr_factor',
	'no_nr_jobrun',
	'or_co_brin',
	'no_nr_lwoo_corr_factor',
	'no_nr_norm',
	'no_nr_norm2',
	'no_nr_peiljaar',
	'no_nr_versie',
	'no_pk_key',
	'or_co_brinvest',
	'or_co_indicator',
	'or_co_vestiging',
	'or_d_geldig_tm',
	'or_d_geldig_va',
	'or_des_bron',
	'or_fun_vest_brin',
	'or_fun_vest_brin_filter',
	'or_nm_bron',
	'or_nm_indicator',
	'or_nm_schooljaar',
	'or_nm_soort_onderwijs',
	'or_nm_vergelijkgroep',
	'or_nr_jobrun',
	'or_nr_peiljaar',
	'pj_nr_peiljaar',
	'or_nr_score',
	'or_nr_versie',
	'or_pk_key',
	'or_q_apcg',
	'or_q_instroom',
	'or_q_leerlingen',
	'or_q_lwoo',
	'or_q_vakken',
	'ov_co_brinvest',
	'ov_nm_vergelijkgroep',
	'ov_nr_peiljaar',
];
