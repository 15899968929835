<div class="container" [style.height]="getHeight() | px">
	<div class="svg-anchor" [style.width]="axisBorder | px">
		<svg [attr.viewBox]="getViewBox()" [style.width]="getWidth() | px" [style.height]="getHeight() | px" preserveAspectRatio="none">
			<g>
				<rect [attr.x]="axisBorder" [attr.y]="getNiveauTop(0)" [attr.width]="getNiveauWidth()" [attr.height]="niveauHeightPx + 12" class="bg niv0"></rect>
				<rect [attr.x]="axisBorder" [attr.y]="getNiveauTop(1)" [attr.width]="getNiveauWidth()" [attr.height]="niveauHeightPx" class="bg niv1"></rect>
				<rect [attr.x]="axisBorder" [attr.y]="getNiveauTop(2)" [attr.width]="getNiveauWidth()" [attr.height]="niveauHeightPx" class="bg niv2"></rect>
				@if (data.hoogsteNiveau === 3) {
					<rect [attr.x]="axisBorder" [attr.y]="0" [attr.width]="getNiveauWidth()" [attr.height]="12" class="bg niv3"></rect>
				} @else {
					<rect [attr.x]="axisBorder" [attr.y]="getNiveauTop(3)" [attr.width]="getNiveauWidth()" [attr.height]="niveauHeightPx" class="bg niv3"></rect>
					<rect [attr.x]="axisBorder" [attr.y]="0" [attr.width]="getNiveauWidth()" [attr.height]="12" class="bg niv4"></rect>
				}
			</g>
			@for (niv of getNiveaus(); track niv) {
				<line class="niveau-separator" [attr.x1]="axisBorder" [attr.y1]="getNiveauY(niv)" [attr.x2]="getWidth()" [attr.y2]="getNiveauY(niv)"></line>
				<text class="axis-label" text-anchor="end" [attr.x]="axisBorder - 8" [attr.y]="getNiveauY(niv)">{{ getNiveauLabel(niv) }}</text>
			}
			@if (scorePositions().length) {
				<polyline class="score-line" [attr.points]="getPointsString()"></polyline>
			}
		</svg>
	</div>
	<!-- de score-labels zijn in HTML omdat de breedte afhankelijk is van de tekst -->
	@for (score of data.scores; track score) {
		<div #score class="score" [style]="getScorePosition(score, $index)" [appTooltip]="score.tooltip">
			{{score.label}}
		</div>
	}
	@if (scorePositions().length) {
		@for (score of data.scores; track score) {
			<!-- gemiddelden ook in HTML omdat ze boven de labels kunnen komen -->
			@if (score.avg !== null) {
				<div class="gemiddelde-hover" [style]="getGemPosition(score.avg, $index)" [appTooltip]="score.tooltip">
					<div class="gemiddelde-circle"></div>
				</div>
			}
		}
	}
	<div #end></div>
</div>

