import { Att, AttrPath, CijferMeasure, DataOptions, DataResponse, DataService, ExportDataOptions } from '../../../services/data.service';
import { Measure } from './card-cell-footer/card-cell-footer.component';
import { formatNumber } from '@angular/common';
import { deelVeilig } from '@cumlaude/shared-utils';
import { getLeafA, Level, Path } from '../../../services/data-tree';
import { get, last, range, sum } from 'lodash-es';
import { BarInfo } from '../../../services/stacked-bars';
import { makeHistogramBar, partitionHistogram } from '../../../dashboards/cijfers-overzicht/cijfers-overzicht.component';
import { Observable } from 'rxjs';
import { CardListConfig } from './card-list-config';
import { Attributes } from '../base-dashboard/base-dashboard-config';
import { DashboardContext } from '../base-dashboard/dashboard-context';
import { FilterService } from '../../../services/filter.service';
import { FactTable } from '../../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { ExportCardListColumnDef } from './card-list.component';
import { attrLabel } from '../../../services/labels';
import { DataRow, findChildOrEmpty, getInitialAttributes } from '../data-tree-table/data-tree-table';
import { DashboardVariant } from '../../../services/weergave-opties';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { inject } from '@angular/core';
import { aggDecimals, MultiAggregator, noAgg0, SingleAggregator, sumOver, weightedAverage } from '../../../services/aggregation';
import { VMeasure } from '../vbarchart-table/vbar-series/vbar-series.component';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { Axis } from '../../../services/axis';

const cijferMeasures = [CijferMeasure.HISTOGRAM, CijferMeasure.STATS];

export interface CijferMeasureAAttributes extends Attributes {
	cf_nr_label_onvoldoendes: number; // | null
	cijfer: number | null;
	decimalen: number;
	cijferAfgerondOV: number;
	aantalLeerlingen: number;
	aantalCijfers: number;
	onvoldoendes: number;
}

export interface CijferMeasureIAttributes extends Attributes {
	cf_nr_leerlingen: number;
	cf_is_voldoende: number;
	cf_nr_cijfer: number;
	cf_nr_decimalen: number;
	cf_nr_aantal_0: number;
	cf_nr_aantal_1: number;
	cf_nr_aantal_2: number;
	cf_nr_aantal_3: number;
	cf_nr_aantal_4: number;
	cf_nr_aantal_5: number;
	cf_nr_aantal_6: number;
	cf_nr_aantal_7: number;
	cf_nr_aantal_8: number;
	cf_nr_aantal_9: number;
	cf_nr_aantal_10: number;
	cf_nr_label_onvoldoendes: number; // | null
	cf_is_gevuld: number;
}

export abstract class CijfersCardListConfig<G> extends CardListConfig<CijferMeasureIAttributes, G, CijferMeasureAAttributes> {
	protected constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	factTable = FactTable.cijfers;

	variant!: DashboardVariant;

	yAxis: Axis = { min: 0, max: 10, ticks: [] };

	qp = inject(QueryParamStateService);

	protected singleAggregators: Partial<{
		[ai in keyof CijferMeasureAAttributes]: SingleAggregator<CijferMeasureIAttributes, CijferMeasureAAttributes[ai]>;
	}> = <Partial<{ [ai in keyof CijferMeasureAAttributes]: SingleAggregator<CijferMeasureIAttributes, CijferMeasureAAttributes[ai]> }>>{
		aantalLeerlingen: noAgg0('cf_nr_leerlingen'),
		aantalCijfers: sumOver('cf_is_gevuld'),
		onvoldoendes: {
			init: (i: CijferMeasureIAttributes) => i.cf_nr_label_onvoldoendes ?? sum(range(0, 6).map((n) => get(i, 'cf_nr_aantal_' + n, 0))),
			combine: (as: number[]) => sum(as),
		},
		cf_nr_label_onvoldoendes: sumOver<'cf_nr_label_onvoldoendes', CijferMeasureIAttributes, number>('cf_nr_label_onvoldoendes'),
		decimalen: aggDecimals('cf_nr_decimalen'),
	};

	protected multiAggregators: MultiAggregator<keyof CijferMeasureAAttributes, CijferMeasureIAttributes, CijferMeasureAAttributes, number | null>[] =
		[weightedAverage('cijfer', 'cf_nr_cijfer', 'aantalCijfers')];

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getCijfersData({ ...options, m: cijferMeasures });
	}

	subscribeToQueryParams() {
		this.subscriptions.push(this.qp.observe('variant').subscribe((variant) => (this.variant = variant)));
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getCijfersExportData(options);
	}

	generateMeasures(path: Path<CijferMeasureAAttributes, number[]>): Measure[] {
		const row = last(path)!;
		const decimalen = row.a.decimalen;
		return [
			{ label: 'Cijfer', value: `${formatNumber(row.a.cijfer!, 'nl-NL', `1.${decimalen}-${decimalen}`)}` },
			{
				label: '% onvoldoende',
				value: `${formatNumber(deelVeilig(row.a.onvoldoendes, row.a.aantalCijfers) * 100, 'nl-NL', '1.0-0')}%`,
			},
			{ label: 'Leerlingen', value: `${row.a.aantalLeerlingen}` },
		];
	}

	partitionBarData(rowRoot: Level<CijferMeasureAAttributes, number[]>): Path<CijferMeasureAAttributes, number[]>[][] {
		return partitionHistogram(rowRoot);
	}

	getMeasure(path: Path<CijferMeasureAAttributes, number[]>): VMeasure {
		const { cijfer, decimalen } = getLeafA(path);
		return {
			type: 'number',
			value: cijfer,
			format: `1.${decimalen}-${decimalen}`,
		};
	}

	getQty(path: Path<CijferMeasureAAttributes, number[]>): number | null {
		const cijfer = getLeafA(path).cijfer;
		return cijfer ? Math.min(cijfer, 10) : null;
	}

	makeBar(
		attrs: CijferMeasureIAttributes,
		path: Path<CijferMeasureAAttributes, number[]>,
		_context: DashboardContext<CijferMeasureIAttributes, CijferMeasureAAttributes, CijfersCardListConfig<G>>
	): BarInfo {
		const { className, sizeLabel, size } = makeHistogramBar(attrs, path);
		return {
			className,
			size,
			linkData: {},
			tooltip: this.createTooltip(path, sizeLabel, size),
		};
	}

	createTooltip(path: Path<CijferMeasureAAttributes, number[]>, sizeLabel: string, size: number): TooltipElement[] {
		const { cijfer, aantalLeerlingen, aantalCijfers, onvoldoendes } = last(path)!.a;
		if (this.variant === DashboardVariant.ACTUEEL) {
			return [{ label: sizeLabel, value: `${size}` }];
		} else {
			return [
				{ label: 'Gemiddeld cijfer', value: formatDecimal(cijfer!, '1.1-1') },
				{ label: '% onvoldoende', value: `${formatNumber(deelVeilig(onvoldoendes, aantalCijfers) * 100, 'nl-NL', '1.0-0')}%` },
				{ label: 'Leerlingen', value: aantalLeerlingen.toString() },
			];
		}
	}

	getExportColumnDefs(
		context: DashboardContext<
			CijferMeasureIAttributes,
			CijferMeasureAAttributes,
			CardListConfig<CijferMeasureIAttributes, G, CijferMeasureAAttributes>
		>
	): ExportCardListColumnDef<
		CijferMeasureIAttributes,
		G,
		CijferMeasureAAttributes,
		CardListConfig<CijferMeasureIAttributes, G, CijferMeasureAAttributes>
	>[] {
		return [...this.getExportGroupColumnDefs(context), ...this.getExportMeasureColumnDefs(context)];
	}

	protected getExportGroupColumnDefs(
		context: DashboardContext<
			CijferMeasureIAttributes,
			CijferMeasureAAttributes,
			CardListConfig<CijferMeasureIAttributes, G, CijferMeasureAAttributes>
		>
	): ExportCardListColumnDef<
		CijferMeasureIAttributes,
		G,
		CijferMeasureAAttributes,
		CardListConfig<CijferMeasureIAttributes, G, CijferMeasureAAttributes>
	>[] {
		return context.groupNames.map((group, index) => {
			const att: Att = <Att>(<unknown>group);
			const name = attrLabel(<AttrPath>att.split('.'));
			return {
				name,
				type: 'string',
				getValue: (path) => path[index + 1].k,
			};
		});
	}

	protected getExportMeasureColumnDefs(
		context: DashboardContext<
			CijferMeasureIAttributes,
			CijferMeasureAAttributes,
			CardListConfig<CijferMeasureIAttributes, G, CijferMeasureAAttributes>
		>
	): ExportCardListColumnDef<
		CijferMeasureIAttributes,
		G,
		CijferMeasureAAttributes,
		CardListConfig<CijferMeasureIAttributes, G, CijferMeasureAAttributes>
	>[] {
		if (this.variant === DashboardVariant.ACTUEEL) {
			return [
				{
					name: 'Cijfer',
					type: 'number',
					format: (row: DataRow<CijferMeasureAAttributes>) => {
						const { decimalen } = getLeafA(row._path);
						return `1.${decimalen}-${decimalen}`;
					},
					getValue: (path) => last(path)!.a.cijfer,
				},
				{
					name: '% onvoldoende',
					type: 'percentage',
					format: '1.0-0',
					getValue: (path) => deelVeilig(last(path)!.a.onvoldoendes, last(path)!.a.aantalCijfers),
				},
				{ name: 'Leerlingen', type: 'number', getValue: (path) => last(path)!.a.aantalLeerlingen },
			];
		}

		return this.getSchooljaren(context).map((schooljaar) => ({
			name: schooljaar,
			type: 'number',
			format: (row: DataRow<CijferMeasureAAttributes>) => {
				const path = row._path;
				const rec = [...path, findChildOrEmpty(last(path)!, schooljaar)];
				const { cf_nr_decimalen } = getInitialAttributes<CijferMeasureIAttributes>(context.subgroupNames, context.measureNames, rec);
				return `1.${cf_nr_decimalen}-${cf_nr_decimalen}`;
			},
			getValue: (path, context) => {
				const rec = [...path, findChildOrEmpty(last(path)!, schooljaar)];
				const attrs = getInitialAttributes<CijferMeasureIAttributes>(context.subgroupNames, context.measureNames, rec);
				return attrs.cf_nr_cijfer;
			},
		}));
	}
}
