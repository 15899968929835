<app-base-dialog [noGap]="true" [noPadding]="true" [topBarTemplate]="topBar" sizeClass="size-22">
	<ng-template #topBar>
		@if(!detail()) {
			<app-dialog-header class="secondary" title="Wat is er nieuw?">
			</app-dialog-header>
		} @else {
			<app-dialog-header class="secondary clickable" (click)="closeNote()">
				<span class="svg-chevron-left"></span>
			</app-dialog-header>
		}
	</ng-template>
	<div class="content">
		@if(!detail()) {
			@for(note of notes(); track note) {
				<div class="note-container summary" (click)="openNote(note)">
					<div class="note summary" [innerHTML]="note"></div>
				</div>
			}
		} @else {
			<div class="note-container">
				<div class="note" [innerHTML]="detail()"></div>
			</div>
		}
	</div>
</app-base-dialog>


