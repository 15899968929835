<div class="label" (click)="open(!opened)">
	<i [class]="opened ? 'icon-arrow-open' : 'icon-arrow'"></i>
	<label>{{ getLabel() }}</label>
	@if (showSearchIcon()) {
		<i class="icon-zoek" (click)="toggleSearch($event)"></i>
	}
	@if (canErase$ | async) {
		<i class="icon-exit" (click)="erase($event)"></i>
	}
</div>
@if (opened) {
	<ng-template
		appFilterInput
		[filterName]="filterName"
		[searchInput]="searchActivated() ? searchInput : undefined"
		(searchInputChange$)="searchInput = $event"
	>
	</ng-template>
} @else if (valueString) {
	<div class="value" (click)="open(true)">{{ valueString }}</div>
}
