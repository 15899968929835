<div class="container">
	@if (data.showSearchInput) {
		<div class="search-box">
			<div class="svg-search"></div>
			<input #inp type="text" (input)="search(inp.value)" [value]="searchInput()" placeholder="Zoeken..." />
		</div>
	}
	<div class="opties">
		@for (optie of getOptions(); track optie) {
			<div class="optie" [class.is-selected]="isSelected(optie)" (click)="clickOptie(optie)">
				<span class="optie-text text-content {{ optie.style }}" [title]="optie.text">{{ optie.text }}</span>
			</div>
		} @empty {
			<div class="optie no-options">
				<span class="optie-text">{{ data.showEmpty }}</span>
			</div>
		}
	</div>
</div>
