import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpCancelService } from './services/http-cancel-service';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { DataserviceHttpHeaders } from '@cumlaude/service-contract';

/**
 * Cancelt alle pending data-requests op de frontend door niet meer naar de response te luisteren (takeUntil).
 *
 * Voorziet daarnaast alle data-requests (incl. cancel-pending) van een UUID die uniek is voor het tabblad waarbinnen
 * CumLaude draait, en een counter. De backend cancelt bij het afhandelen van cancel-pending alleen requests met dit UUID
 * en counters tot de meegegeven counter.
 */
@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
	private readonly realm: string;

	constructor(
		private httpCancelService: HttpCancelService,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration
	) {
		// Haal realm uit de session storage zodat deze een refresh overleeft en requests van voor de refresh worden gecancelled.
		this.realm = this.getOrCreateRealm();
	}

	intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
		if (req.url.startsWith(this.envConfig.dataUrl!)) {
			return next
				.handle(
					req.clone({
						headers: req.headers.set(DataserviceHttpHeaders.X_REQUEST_COUNTER, this.getAndIncrementCounter()).set(DataserviceHttpHeaders.X_REQUEST_REALM, this.realm),
					})
				)
				.pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
		}

		return next.handle(req);
	}

	private getOrCreateRealm(): string {
		let realm = sessionStorage.getItem('realm');
		if (!realm) {
			realm = crypto.randomUUID();
			sessionStorage.setItem('realm', realm);
		}

		return realm;
	}

	private getAndIncrementCounter(): string {
		const next = (parseInt(sessionStorage.getItem('counter') ?? '0') + 1).toString();
		sessionStorage.setItem('counter', next);
		return next;
	}
}
