import { Component, Input } from '@angular/core';
import { TableCellComponent } from '../../../components/table/table/table.model';
import { LinechartComponent, LinechartProps } from '../linechart/linechart.component';
import { DisplayService } from '../../../../services/display.service';

export type LinechartBatchData<A> = {
	batch: { linechartProps: LinechartProps<A>; key: string }[];
	gridHeight: number;
};

@Component({
	selector: 'app-linechart-batch',
	templateUrl: './linechart-batch.component.html',
	styleUrls: ['./linechart-batch.component.scss'],
	standalone: true,
	imports: [LinechartComponent],
})
export class LinechartBatchComponent<A> implements TableCellComponent<LinechartBatchData<A>> {
	@Input() data!: LinechartBatchData<A>;

	constructor(private displayService: DisplayService) {}

	getLabel(key: string | null) {
		return this.displayService.display(key);
	}
}
