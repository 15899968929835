import { Component } from '@angular/core';
import { TableCellComponent } from '../../../shared/components/table/table/table.model';

@Component({
	selector: 'app-score-schaalverdeling',
	templateUrl: './score-schaalverdeling.component.html',
	styleUrls: ['./score-schaalverdeling.component.scss'],
	standalone: true,
})
export class ScoreSchaalverdelingComponent implements TableCellComponent<string[]> {
	data!: string[];
}
