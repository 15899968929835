<app-base-dialog sizeClass="size-44" [topBarTemplate]="topBar" [bottomBarTemplate]="bottomBar" [multiRow]="true">
	<ng-template #topBar>
		<app-dialog-header title="Gebruiker toevoegen"></app-dialog-header>
	</ng-template>
	<div class="top">
		<h3>Selecteer de medewerkers die je wil toevoegen</h3>
		<app-checkbox
			label="Selectie tonen ({{ selection.size }})"
			label-left
			[value]="showSelection"
			(valueChange)="setShowSelection($event)"
		></app-checkbox>
	</div>
	<div class="controls">
		@if ({ value: filter$ | async }; as filter) {
			<app-instant-search-box
				[value]="filter.value!"
				(fire)="filter$.next($event); showSelection = false"
				placeholder="Zoek medewerker"
				cdkFocusInitial
			></app-instant-search-box>
			<span>Of</span>
			<app-button icon="icon-add" (clickit)="openNieuweExterneGebruikerDialog()">Voeg externe gebruiker toe</app-button>
		}
	</div>
	@if (filtered$ | async; as filtered) {
		<div class="column-list">
			@if (filtered.length === 0 || (showSelection && selection.size === 0)) {
				<div>Geen medewerkers gevonden.</div>
			}
			@for (medewerker of filtered; track medewerker) {
				@if (!showSelection || selection.has(medewerker.ix)) {
					<div class="medewerker" [title]="medewerker.heeftAccount ? 'Deze medewerker heeft al een account.' : ''">
						<app-checkbox
							[label]="getNaam(medewerker)"
							[value]="selection.has(medewerker.ix)"
							(valueChange)="toggle(medewerker)"
							[enabled]="!medewerker.heeftAccount"
						>
						</app-checkbox>
					</div>
				}
			}
		</div>
	}
	<ng-template #bottomBar>
		<app-button buttonType="secondary" (clickit)="thisDialogRef.close()">Annuleren</app-button>
		<app-button [enabled]="selection.size > 0" (clickit)="thisDialogRef.close(getAccountsForSelection())">Toevoegen</app-button>
	</ng-template>
</app-base-dialog>
