import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { RFavorietMap } from '@cumlaude/service-contract';
import { ImmediateInputComponent } from '../shared/components/immediate-input/immediate-input.component';
import { NgClickOutsideDelayOutsideDirective, NgClickOutsideDirective } from 'ng-click-outside2';

@Component({
	selector: 'app-mappen-dropdown',
	templateUrl: './mappen-dropdown.component.html',
	styleUrls: ['./mappen-dropdown.component.scss'],
	standalone: true,
	imports: [NgClickOutsideDirective, ImmediateInputComponent, NgClickOutsideDelayOutsideDirective],
})
export class MappenDropdownComponent {
	@ViewChild('dropdown', { read: ElementRef }) dropdownRef!: ElementRef;

	@Input()
	mappen!: RFavorietMap[];

	@Input()
	value?: RFavorietMap;

	@Input()
	template!: RFavorietMap;

	@Output()
	valueChange = new EventEmitter<RFavorietMap>();

	@Output()
	created = new EventEmitter<RFavorietMap>();

	protected showDropdown = false;

	protected editing = false;

	constructor(private changeDetector: ChangeDetectorRef) {}

	isEmpty() {
		return this.value === undefined;
	}

	isSelected(optie: RFavorietMap) {
		return this.value === optie;
	}

	select(value: RFavorietMap | undefined): void {
		this.value = value;
		this.showDropdown = false;
		this.valueChange.emit(value);
		this.changeDetector.markForCheck();
	}

	remove($event: Event) {
		$event.stopPropagation();
		this.select(undefined);
	}

	create(naam: string | undefined) {
		this.editing = false;
		if (naam === undefined || naam.trim() === '') return;

		const nieuweMap = { ...this.template, naam };
		this.created.emit(nieuweMap);
		this.valueChange.emit(nieuweMap);
		this.showDropdown = false;
	}

	closeDropDown(event: Event) {
		this.showDropdown = false;
		event.preventDefault();
		event.stopPropagation();
	}
}
