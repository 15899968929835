import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, Output, ViewChild } from '@angular/core';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';
import { filter, map, pairwise, throttleTime } from 'rxjs/operators';
import { LijstElement, LijstElementData } from '../Details';
import { PasfotoComponent } from '../../shared/components/pasfoto/pasfoto.component';

@Component({
	selector: 'app-lijst',
	templateUrl: './lijst.component.html',
	styleUrls: ['./lijst.component.scss'],
	standalone: true,
	imports: [CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, PasfotoComponent],
})
export class LijstComponent<E extends LijstElement> implements AfterViewInit, OnDestroy {
	@Input()
	elementen: E[] = [];

	@Input()
	selected: E | undefined;

	@Input()
	getData!: (input: E) => LijstElementData;

	@Output()
	selectedChange = new EventEmitter<E>();

	@Output()
	nextPage = new EventEmitter<number>();

	@ViewChild('scroller') scroller!: CdkVirtualScrollViewport;

	private subscriptions: Subscription[] = [];

	constructor(private ngZone: NgZone) {}

	ngAfterViewInit() {
		this.subscriptions.push(
			this.scroller
				.elementScrolled()
				.pipe(
					map(() => this.scroller.measureScrollOffset('bottom')),
					pairwise(),
					filter(([y1, y2]) => y2 < y1 && y2 < 140),
					throttleTime(200)
				)
				.subscribe(() => {
					this.ngZone.run(() => this.nextPage.emit(this.elementen.length));
				})
		);
	}

	setSelected(element: E) {
		this.selectedChange.emit(element);
	}

	isSelected(element: E): boolean {
		return element === this.selected;
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}
}
