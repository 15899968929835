import { Component } from '@angular/core';
import { AccountScreen, AccountVestigingenRow, getAccountVestigingen } from '../account-screen';
import { RestService } from '@cumlaude/shared-services';
import { DataService } from '../../services/data.service';
import { AccountsService } from '../../services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { createColumnDef, createModel, TableModel } from '../../shared/components/table/table/table.model';
import { combineLatest, Observable, of } from 'rxjs';
import { nonBreakingSpace } from '../../services/labels';
import { TeamToekennenData, TeamToekennenDialogComponent } from '../../dialogs/account/team-toekennen-dialog/team-toekennen-dialog.component';
import { map, switchMap, take } from 'rxjs/operators';
import { Dialog } from '@angular/cdk/dialog';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { TableComponent } from '../../shared/components/table/table/table.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { UserService } from '../../services/user.service';
import { LicentieRol, RCumLaudeAccount, RRol, RVestiging } from '@cumlaude/service-contract';

interface AccountTeamRow extends AccountVestigingenRow {
	autorisaties: string;
}

function makeRow(account: RCumLaudeAccount, alleVestigingen: RVestiging[]): AccountTeamRow {
	const { niveaus, leerjaren, klassen } = account;
	const autorisaties = [...niveaus, ...leerjaren.map(String), ...klassen].join(', ');
	const vestigingen = getAccountVestigingen(account, alleVestigingen);
	return { account, autorisaties, vestigingen };
}

@Component({
	selector: 'app-team',
	templateUrl: '../account-screen.html',
	styleUrls: ['../account-screen.scss'],
	standalone: true,
	imports: [InstantSearchBoxComponent, ButtonComponent, TooltipDirective, TableComponent, AsyncPipe, TitleCasePipe],
})
export class TeamComponent extends AccountScreen<AccountTeamRow> {
	constructor(
		protected restService: RestService,
		protected dataService: DataService,
		protected userService: UserService,
		protected accountsService: AccountsService,
		protected dialog: Dialog,
		protected toastr: ToastrService
	) {
		super(restService, dataService, userService, accountsService, dialog, toastr);
	}

	getRol() {
		return RRol.TEAMLEIDER;
	}

	getLicentieRol(): LicentieRol {
		return LicentieRol.SCHOOLBREEDTEAM;
	}

	getRolLabel() {
		return 'Teamleider';
	}

	getRolDescription() {
		return 'Teamleiders kunnen alle gegevens zien van de afdeling op de vestiging waar zij aan gekoppeld zijn.';
	}

	makeAccountTableModel(accounts: RCumLaudeAccount[]): Observable<TableModel<AccountTeamRow>> {
		return this.alleVestigingen$.pipe(
			map((vestigingen) => {
				const model = createModel(
					accounts.map((account) => makeRow(account, vestigingen)),
					(row) => row.account.id!
				);
				const bewerken = this.createActionColumn(
					'edit',
					'Rol bewerken'.replace(' ', nonBreakingSpace),
					'icon-edit',
					({ account }) => this.openTeamToekennenDialog(account),
					() => true
				);
				model.columnDefs = [
					this.getNaamColumn(),
					createColumnDef('vestigingen', 'Vestigingen'),
					createColumnDef('autorisaties', 'Teamleider'),
					bewerken,
					this.createRolIntrekkenColumn(),
				];
				return model;
			})
		);
	}

	openRolToekennenDialog(_currentFilter: string) {
		this.openTeamToekennenDialog();
	}

	openTeamToekennenDialog(account?: RCumLaudeAccount) {
		const allAccounts = account
			? of([account])
			: this.getVestigingen().pipe(
					switchMap((vestigingen) => this.restService.getAccountsFromFilter({ vestiging: vestigingen, excludeRol: RRol.TEAMLEIDER }))
				);
		combineLatest([this.filter$.pipe(take(1)), allAccounts, this.dataService.getKlassen()]).subscribe(
			([currentFilter, accounts, alleKlassen]) => {
				const data: TeamToekennenData = {
					account,
					alleAccounts: accounts,
					alleKlassen,
				};
				const dialogRef = this.dialog.open<RCumLaudeAccount>(TeamToekennenDialogComponent, { data });
				dialogRef.closed.subscribe((editedAccount) => {
					if (!editedAccount) return;
					this.resetFilter(currentFilter);
					editedAccount.rollen.push(this.getRol());
					this.accountsService.update$.next([
						editedAccount,
						() => {
							if (!account) this.accountsService.createLocal$.next(editedAccount);
						},
						(err) => {
							this.toastr.error(`Er ging iets fout bij het toekennen van rollen: ${err.error}`);
						},
					]);
				});
			}
		);
	}
}
