import { Injectable } from '@angular/core';
import { RestService } from '@cumlaude/shared-services';
import { RLeerlingSelectie } from '@cumlaude/service-contract';
import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { fromPairs } from 'lodash-es';
import { AuthService } from '@cumlaude/shared-authentication';

export interface LeerlingSelectieId {
	id: string;
	naam: string;
}

@Injectable({
	providedIn: 'root',
})
export class LeerlingSelectieService {
	private selecties = new ReplaySubject<{ [id: string]: RLeerlingSelectie }>(1);

	constructor(
		private restService: RestService,
		authService: AuthService
	) {
		authService.loggedIn$.subscribe((loggedIn) => {
			if (loggedIn) this.refresh();
		});
	}

	getLeerlingSelectieIds(): Observable<LeerlingSelectieId[]> {
		this.refresh();
		return this.selecties.pipe(map((obj) => Object.values(obj).map(({ naam, id }) => ({ naam, id: id! }))));
	}

	get(id: string): Observable<RLeerlingSelectie> {
		return this.selecties.pipe(
			map((sel) => {
				if (id in sel) return sel[id];
				throw new Error('not found');
			}),
			take(1),
			catchError(() => this.restService.getLeerlingSelectie(id))
		);
	}

	delete(id: string): Observable<void> {
		return this.restService.deleteLeerlingSelectie(id).pipe(
			switchMap(() => this.selecties),
			take(1),
			map(({ [id]: _, ...rest }) => this.selecties.next(rest))
		);
	}

	put(selectie: RLeerlingSelectie): Observable<RLeerlingSelectie> {
		return this.restService.putLeerlingSelectie(selectie).pipe(
			switchMap(() => this.selecties),
			take(1),
			map((current) => {
				this.selecties.next({ ...current, [selectie.id!]: selectie });
				return selectie;
			})
		);
	}

	refresh() {
		firstValueFrom(this.restService.getLeerlingSelecties()).then((rLeerlingSelecties) => {
			this.selecties.next(fromPairs(rLeerlingSelecties.map((selectie) => [selectie.id, selectie])));
		});
	}
}
