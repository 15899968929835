import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { AccountsService } from '../../services/accounts.service';
import { DataService } from '../../services/data.service';
import { RestService } from '@cumlaude/shared-services';
import { createModel, TableModel } from '../../shared/components/table/table/table.model';
import { AccountRow, AccountScreen } from '../account-screen';
import { Dialog } from '@angular/cdk/dialog';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { TableComponent } from '../../shared/components/table/table/table.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { UserService } from '../../services/user.service';
import { CumLaudeLicentieInfo, LicentieRol, RCumLaudeAccount, RRol } from '@cumlaude/service-contract';
import { map } from 'rxjs/operators';

function makeRow(account: RCumLaudeAccount): AccountRow {
	return { account };
}

@Component({
	selector: 'app-bestuur',
	templateUrl: '../account-screen.html',
	styleUrls: ['../account-screen.scss'],
	standalone: true,
	imports: [InstantSearchBoxComponent, ButtonComponent, TooltipDirective, TableComponent, AsyncPipe, TitleCasePipe],
})
export class BestuurComponent extends AccountScreen<AccountRow> {
	constructor(
		protected restService: RestService,
		protected dataService: DataService,
		protected userService: UserService,
		protected accountsService: AccountsService,
		protected dialog: Dialog,
		protected toastr: ToastrService
	) {
		super(restService, dataService, userService, accountsService, dialog, toastr);

		this.moduleIsEnabled$ = userService.instelling$.pipe(map((instelling) => instelling.bestuurEnabled));
	}

	getRol(): RRol {
		return RRol.BESTUUR;
	}

	getModuleName(): string | undefined {
		return 'Bestuur';
	}

	getRolLabel() {
		return 'Bestuur';
	}

	getRolDescription() {
		return 'Bestuurbreed geautoriseerde gebruikers kunnen gegevens van alle vestigingen bekijken.';
	}

	getLicentieRol(): LicentieRol | undefined {
		return LicentieRol.BESTUUR;
	}

	getLicentieInfo(info: CumLaudeLicentieInfo, _licentieRol: LicentieRol) {
		return {
			tekst: [
				`Licenties in gebruik: ${info[LicentieRol.BESTUUR]?.inGebruik ?? 0}`,
				`Totaal licenties in gebruik door bestuur: ${info[LicentieRol.BESTUURTOTAAL]?.inGebruik ?? 0} ${info[LicentieRol.BESTUURTOTAAL]?.totaal ? `van de ${info[LicentieRol.BESTUURTOTAAL]?.totaal}` : ''}`,
			],
			warning: (info[LicentieRol.BESTUURTOTAAL]?.totaal ?? Number.POSITIVE_INFINITY) < (info[LicentieRol.BESTUURTOTAAL]?.inGebruik ?? 0),
			info: (info[LicentieRol.BESTUURTOTAAL]?.totaal ?? Number.POSITIVE_INFINITY) === (info[LicentieRol.BESTUURTOTAAL]?.inGebruik ?? 0),
		};
	}

	protected getVestigingen(): Observable<string[] | undefined> {
		return of(undefined);
	}

	makeAccountTableModel(accounts: RCumLaudeAccount[]): Observable<TableModel<AccountRow>> {
		const model = createModel(accounts.map(makeRow), (row) => row.account.id!);
		model.columnDefs = [this.getNaamColumn(), this.createRolIntrekkenColumn()];
		return of(model);
	}
}
