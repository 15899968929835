import { Component } from '@angular/core';
import { AccountScreen, AccountVestigingenRow, getAccountVestigingen } from '../account-screen';
import { RestService } from '@cumlaude/shared-services';
import { DataService } from '../../services/data.service';
import { AccountsService } from '../../services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { createColumnDef, createModel, TableModel } from '../../shared/components/table/table/table.model';
import { combineLatest, Observable, of } from 'rxjs';
import { nonBreakingSpace } from '../../services/labels';
import { DimVvVestigingVak } from '@cumlaude/metadata';
import { map, switchMap, take } from 'rxjs/operators';
import { SectieToekennenData, SectieToekennenDialogComponent } from '../../dialogs/account/sectie-toekennen-dialog/sectie-toekennen-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { TableComponent } from '../../shared/components/table/table/table.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { UserService } from '../../services/user.service';
import { LicentieRol, RCumLaudeAccount, RRol, RCumLaudeModule, RVestiging } from '@cumlaude/service-contract';

interface AccountSectieRow extends AccountVestigingenRow {
	autorisaties: string;
}

function makeRow(account: RCumLaudeAccount, alleVakken: DimVvVestigingVak[], alleVestigingen: RVestiging[]): AccountSectieRow {
	const autorisaties = account.vakken
		.map((vakId) => alleVakken.find((vak) => vak.vv_nm_vak === vakId)?.vv_nm_vak)
		.filter((x) => x)
		.join(', ');
	const vestigingen = getAccountVestigingen(account, alleVestigingen);
	return { account, autorisaties, vestigingen };
}

@Component({
	selector: 'app-sectie',
	templateUrl: '../account-screen.html',
	styleUrls: ['../account-screen.scss'],
	standalone: true,
	imports: [InstantSearchBoxComponent, ButtonComponent, TooltipDirective, TableComponent, AsyncPipe, TitleCasePipe],
})
export class SectieComponent extends AccountScreen<AccountSectieRow> {
	constructor(
		protected restService: RestService,
		protected dataService: DataService,
		protected userService: UserService,
		protected accountsService: AccountsService,
		protected dialog: Dialog,
		protected toastr: ToastrService
	) {
		super(restService, dataService, userService, accountsService, dialog, toastr);
	}

	getRol(): RRol {
		return RRol.SECTIELEIDER;
	}

	getModule(): RCumLaudeModule {
		return RCumLaudeModule.SECTIE;
	}

	getRolLabel() {
		return 'Sectieleider';
	}

	getLicentieRol(): LicentieRol {
		return LicentieRol.SECTIE;
	}

	getRolDescription() {
		return 'Sectieleiders kunnen alle gegevens zien van de vakken op de vestiging waar zij aan gekoppeld zijn.';
	}

	makeAccountTableModel(accounts: RCumLaudeAccount[]): Observable<TableModel<AccountSectieRow>> {
		return combineLatest([this.dataService.getVestigingVakken(), this.alleVestigingen$]).pipe(
			map(([alleVakken, vestigingen]) => {
				const model = createModel(
					accounts.map((account) => makeRow(account, alleVakken, vestigingen)),
					(row) => row.account.id!
				);
				const bewerken = this.createActionColumn(
					'edit',
					'Rol bewerken'.replace(' ', nonBreakingSpace),
					'icon-edit',
					({ account }) => this.openSectieToekennenDialog(account),
					() => true
				);
				model.columnDefs = [
					this.getNaamColumn(),
					createColumnDef('vestigingen', 'Vestigingen'),
					createColumnDef('autorisaties', 'Vakken'),
					bewerken,
					this.createRolIntrekkenColumn(),
				];
				return model;
			})
		);
	}

	openRolToekennenDialog(_currentFilter: string) {
		this.openSectieToekennenDialog();
	}

	openSectieToekennenDialog(account?: RCumLaudeAccount) {
		const allAccounts = account
			? of([account])
			: this.getVestigingen().pipe(
					switchMap((vestigingen) => this.restService.getAccountsFromFilter({ vestiging: vestigingen, excludeRol: RRol.SECTIELEIDER }))
				);
		combineLatest([this.filter$.pipe(take(1)), allAccounts, this.dataService.getVestigingVakken()]).subscribe(
			([currentFilter, accounts, alleVakken]) => {
				const data: SectieToekennenData = {
					account,
					alleAccounts: accounts,
					alleVakken,
				};
				const dialogRef = this.dialog.open<RCumLaudeAccount>(SectieToekennenDialogComponent, { data });
				dialogRef.closed.subscribe((editedAccount) => {
					if (!editedAccount) return;
					this.resetFilter(currentFilter);
					editedAccount.rollen.push(this.getRol());
					this.accountsService.update$.next([
						editedAccount,
						() => {
							if (!account) this.accountsService.createLocal$.next(editedAccount);
						},
						() => {},
					]);
				});
			}
		);
	}
}
