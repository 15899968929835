import { FilterName } from './filter-config';

export const equivalentFilters: FilterName[][] = [
	[
		'aw_nm_schooljaar',
		'bv_nm_schooljaar',
		'cf_nm_schooljaar',
		'ds_nm_schooljaar_van',
		'x_prestatieanalyse_ds_schooljaar',
		'lb_nm_schooljaar',
		'lr_d_datum.per_nm_schooljaar',
		'pv_nm_schooljaar',
		'ekc_nm_schooljaar',
		'vkk_nm_schooljaar',
	],
	['x_onderwijsresultaten_cf_schooljaar', 'x_onderwijsresultaten_ds_schooljaar', 'x_onderwijsresultaten_or_schooljaar'],
	[
		'aw_fk_ilt.ilt_nm_niveau',
		'cf_fk_ilt.ilt_nm_niveau',
		'x_cijfer_niveau',
		'ds_fk_ilt_van.ilt_nm_niveau',
		'lr_fk_ilt.ilt_nm_niveau',
		'ds_fun_niveau_bbs_van',
		'ekc_nm_niveau',
		'or_nm_soort_onderwijs',
		'vkk_fk_ilt.ilt_nm_niveau',
	],
	['aw_nr_leerjaar', 'cf_nr_leerjaar', 'x_cijfer_leerjaar', 'ds_nr_leerjaar_van', 'lr_nr_leerjaar', 'vkk_nr_leerjaar'],
	[
		'x_aw_schooljaar_historie',
		'x_cijfer_schooljaar_historie',
		'x_doorstroom_schooljaar_historie',
		'x_pv_schooljaar_historie',
		'x_ekc_schooljaar_historie',
		'x_vkk_schooljaar_historie',
	],
	[
		'x_aw_multiselect_schooljaar',
		'x_cijfer_multiselect_schooljaar',
		'x_doorstroom_multiselect_schooljaar',
		'x_pv_multiselect_schooljaar',
		'x_ekc_multiselect_schooljaar',
		'x_vkk_multiselect_schooljaar',
	],
	['cf_fk_vk.vk_nm_vak', 'ekc_fk_vk.vk_nm_vak', 'vkk_fk_vk.vk_nm_vak'],
	['x_details_periode', 'x_gem_periode', 'x_cijferlijst_gem_periode'],
	['x_cijferlijst_cijfertype', 'x_cijfertype'],
	[
		'aw_fk_br_vest.br_co_brin',
		'bv_fk_lb.lb_co_brin_vestiging',
		'cf_fk_br_vest.br_co_brin',
		'ds_fk_br_vest_van.br_co_brin',
		'ekc_fk_br_vest.br_co_brin',
		'lr_fk_br_vest.br_co_brin',
		'or_co_brinvest',
		'vkk_fk_lb.lb_co_brin_vestiging',
	],
	[
		'aw_fk_br_vest.br_nm_brin_kort',
		'cf_fk_br_vest.br_nm_brin_kort',
		'ds_fk_br_vest_van.br_nm_brin_kort',
		'ekc_fk_br_vest.br_nm_brin_kort',
		'lr_fk_br_vest.br_nm_brin_kort',
	],
	['x_peildatum', 'x_peildatum_optional'],
	[
		'cf_nm_vestiging',
		'cf_fk_vs.vs_nm_vestiging',
		'aw_fk_vs.vs_nm_vestiging',
		'ds_fk_vs_van.vs_nm_vestiging',
		'lr_fk_vs.vs_nm_vestiging',
		'vkk_fk_vs.vs_nm_vestiging',
	],
	['cf_fk_vs_bekostiging.vs_nm_vestiging', 'ds_fk_vs_bekostiging_van.vs_nm_vestiging'],
	['ds_fun_cohortstatus_vanaf', 'ds_fun_cohortstatus_examenopstroom'],
	[
		'aw_fk_lb.lb_co_brin', //
		'bv_fk_lb.lb_co_brin',
		'cf_fk_lb.lb_co_brin',
		'ds_fk_lb_van.lb_co_brin',
		'lr_fk_lb.lb_co_brin',
		'vkk_fk_lb.lb_co_brin',
	],
	[
		'aw_fk_ilt_vorig_sj.ilt_nm_niveau',
		'bv_fk_ilt_vorig_sj.ilt_nm_niveau',
		'cf_fk_ilt_vorig_sj.ilt_nm_niveau',
		'ds_fk_ilt_vorig_sj.ilt_nm_niveau',
		'lr_fk_ilt_vorig_sj.ilt_nm_niveau',
		'vkk_fk_ilt_vorig_sj.ilt_nm_niveau',
	],
	[
		'aw_fk_lb_vorig_sj.lb_abb_leerfase',
		'bv_fk_lb_vorig_sj.lb_abb_leerfase',
		'cf_fk_lb_vorig_sj.lb_abb_leerfase',
		'ds_fk_lb_vorig_sj.lb_abb_leerfase',
		'lr_fk_lb_vorig_sj.lb_abb_leerfase',
		'vkk_fk_lb_vorig_sj.lb_abb_leerfase',
	],
	[
		'aw_fk_lb_vorig_sj.lb_nm_klas',
		'bv_fk_lb_vorig_sj.lb_nm_klas',
		'cf_fk_lb_vorig_sj.lb_nm_klas',
		'ds_fk_lb_vorig_sj.lb_nm_klas',
		'lr_fk_lb_vorig_sj.lb_nm_klas',
		'vkk_fk_lb_vorig_sj.lb_nm_klas',
	],
	[
		'aw_fk_lb_vorig_sj.lb_nm_leerfase',
		'bv_fk_lb_vorig_sj.lb_nm_leerfase',
		'cf_fk_lb_vorig_sj.lb_nm_leerfase',
		'ds_fk_lb_vorig_sj.lb_nm_leerfase',
		'lr_fk_lb_vorig_sj.lb_nm_leerfase',
		'vkk_fk_lb_vorig_sj.lb_nm_leerfase',
	],
	[
		'aw_fk_lb_vorig_sj.lb_nm_opleiding',
		'bv_fk_lb_vorig_sj.lb_nm_opleiding',
		'cf_fk_lb_vorig_sj.lb_nm_opleiding',
		'ds_fk_lb_vorig_sj.lb_nm_opleiding',
		'lr_fk_lb_vorig_sj.lb_nm_opleiding',
		'vkk_fk_lb_vorig_sj.lb_nm_opleiding',
	],
	[
		'aw_fk_lb_vorig_sj.lb_nr_leerjaar',
		'bv_fk_lb_vorig_sj.lb_nr_leerjaar',
		'cf_fk_lb_vorig_sj.lb_nr_leerjaar',
		'ds_fk_lb_vorig_sj.lb_nr_leerjaar',
		'lr_fk_lb_vorig_sj.lb_nr_leerjaar',
		'vkk_fk_lb_vorig_sj.lb_nr_leerjaar',
	],
	[
		'aw_fk_lb_vorig_sj.lb_co_brin_vestiging',
		'bv_fk_lb_vorig_sj.lb_co_brin_vestiging',
		'cf_fk_lb_vorig_sj.lb_co_brin_vestiging',
		'ds_fk_lb_vorig_sj.lb_co_brin_vestiging',
		'lr_fk_lb_vorig_sj.lb_co_brin_vestiging',
		'vkk_fk_lb_vorig_sj.lb_co_brin_vestiging',
	],
	[
		'aw_fk_lb_vorig_sj.lb_co_brin',
		'bv_fk_lb_vorig_sj.lb_co_brin',
		'cf_fk_lb_vorig_sj.lb_co_brin',
		'ds_fk_lb_vorig_sj.lb_co_brin',
		'lr_fk_lb_vorig_sj.lb_co_brin',
		'vkk_fk_lb_vorig_sj.lb_co_brin',
	],
	[
		'aw_fk_lb_vorig_sj.lb_nm_vestiging',
		'bv_fk_lb_vorig_sj.lb_nm_vestiging',
		'cf_fk_lb_vorig_sj.lb_nm_vestiging',
		'ds_fk_lb_vorig_sj.lb_nm_vestiging',
		'lr_fk_lb_vorig_sj.lb_nm_vestiging',
		'vkk_fk_lb_vorig_sj.lb_nm_vestiging',
	],
	[
		'aw_fk_ilt_volgend_sj.ilt_nm_niveau',
		'bv_fk_ilt_volgend_sj.ilt_nm_niveau',
		'cf_fk_ilt_volgend_sj.ilt_nm_niveau',
		'ds_fk_ilt_naar.ilt_nm_niveau',
		'lr_fk_ilt_volgend_sj.ilt_nm_niveau',
		'vkk_fk_ilt_volgend_sj.ilt_nm_niveau',
	],
	[
		'aw_fk_lb_volgend_sj.lb_abb_leerfase',
		'bv_fk_lb_volgend_sj.lb_abb_leerfase',
		'cf_fk_lb_volgend_sj.lb_abb_leerfase',
		'ds_fk_lb_naar.lb_abb_leerfase',
		'lr_fk_lb_volgend_sj.lb_abb_leerfase',
		'vkk_fk_lb_volgend_sj.lb_abb_leerfase',
	],
	[
		'aw_fk_lb_volgend_sj.lb_nm_klas',
		'bv_fk_lb_volgend_sj.lb_nm_klas',
		'cf_fk_lb_volgend_sj.lb_nm_klas',
		'ds_fk_lb_naar.lb_nm_klas',
		'lr_fk_lb_volgend_sj.lb_nm_klas',
		'vkk_fk_lb_volgend_sj.lb_nm_klas',
	],
	[
		'aw_fk_lb_volgend_sj.lb_nm_leerfase',
		'bv_fk_lb_volgend_sj.lb_nm_leerfase',
		'cf_fk_lb_volgend_sj.lb_nm_leerfase',
		'ds_fk_lb_naar.lb_nm_leerfase',
		'lr_fk_lb_volgend_sj.lb_nm_leerfase',
		'vkk_fk_lb_volgend_sj.lb_nm_leerfase',
	],
	[
		'aw_fk_lb_volgend_sj.lb_nm_opleiding',
		'bv_fk_lb_volgend_sj.lb_nm_opleiding',
		'cf_fk_lb_volgend_sj.lb_nm_opleiding',
		'ds_fk_lb_naar.lb_nm_opleiding',
		'lr_fk_lb_volgend_sj.lb_nm_opleiding',
		'vkk_fk_lb_volgend_sj.lb_nm_opleiding',
	],
	[
		'aw_fk_lb_volgend_sj.lb_nr_leerjaar',
		'bv_fk_lb_volgend_sj.lb_nr_leerjaar',
		'cf_fk_lb_volgend_sj.lb_nr_leerjaar',
		'ds_fk_lb_naar.lb_nr_leerjaar',
		'lr_fk_lb_volgend_sj.lb_nr_leerjaar',
		'vkk_fk_lb_volgend_sj.lb_nr_leerjaar',
	],
	[
		'aw_fk_lb_volgend_sj.lb_co_brin_vestiging',
		'bv_fk_lb_volgend_sj.lb_co_brin_vestiging',
		'cf_fk_lb_volgend_sj.lb_co_brin_vestiging',
		'ds_fk_lb_naar.lb_co_brin_vestiging',
		'lr_fk_lb_volgend_sj.lb_co_brin_vestiging',
		'vkk_fk_lb_volgend_sj.lb_co_brin_vestiging',
	],
	[
		'aw_fk_lb_volgend_sj.lb_co_brin',
		'bv_fk_lb_volgend_sj.lb_co_brin',
		'cf_fk_lb_volgend_sj.lb_co_brin',
		'ds_fk_lb_naar.lb_co_brin',
		'lr_fk_lb_volgend_sj.lb_co_brin',
		'vkk_fk_lb_volgend_sj.lb_co_brin',
	],
	[
		'aw_fk_lb_volgend_sj.lb_nm_vestiging',
		'bv_fk_lb_volgend_sj.lb_nm_vestiging',
		'cf_fk_lb_volgend_sj.lb_nm_vestiging',
		'ds_fk_lb_naar.lb_nm_vestiging',
		'lr_fk_lb_volgend_sj.lb_nm_vestiging',
		'vkk_fk_lb_volgend_sj.lb_nm_vestiging',
	],
	[
		'aw_fk_lb.lb_abb_leerfase',
		'bv_fk_lb.lb_abb_leerfase',
		'cf_fk_lb.lb_abb_leerfase',
		'ds_fk_lb_van.lb_abb_leerfase',
		'lr_fk_lb.lb_abb_leerfase',
		'vkk_fk_lb.lb_abb_leerfase',
	],
	[
		'aw_fk_lb.lb_nm_leerfase',
		'bv_nm_leerfase',
		'cf_fk_lb.lb_nm_leerfase',
		'ds_fk_lb_van.lb_nm_leerfase',
		'lr_fk_lb.lb_nm_leerfase',
		'vkk_fk_lb.lb_nm_leerfase',
	],
	['aw_nm_klas', 'bv_fk_lb.lb_nm_klas', 'cf_nm_klas', 'ds_nm_klas_van', 'lr_nm_klas', 'vkk_fk_lb.lb_nm_klas'],
];
