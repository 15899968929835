import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { FilterName } from '../services/filter-config';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { FilterInputDirective } from './filter-input.directive';

@Component({
	selector: 'app-filter-container',
	templateUrl: './filter-container.component.html',
	styleUrls: ['./filter-container.component.scss'],
	standalone: true,
	imports: [FilterInputDirective, AsyncPipe],
})
export class FilterContainerComponent<T> implements OnInit, OnDestroy {
	private readonly SHOW_SEARCH_AFTER = 10;

	@Input()
	filterName!: FilterName;

	/**
	 * Geselecteerde waarde zoals die getoond moet worden bij dichtgeklapte container.
	 */
	valueString!: string | undefined;

	/**
	 * Of het filter te wissen (niet-leeg en optional) is.
	 */
	canErase$ = of(false);

	/**
	 * Of de gebruiker zelf het searchIcon heeft aangeklikt in dit component.
	 */
	searchWanted = false;

	/**
	 * Of er zo veel waarden zijn dat er gezocht moet kunnen worden.
	 */
	enoughValuesForSearch = false;

	/**
	 * Input van de searchbox uit het filtercomponent, moet onthouden worden wanneer de searchbox
	 * verborgen is d.m.v. het searchIcon of door de hele filtercontainer in te klappen. (De opties
	 * moeten in die gevallen niet meer gefilterd worden door de searchInput).
	 */
	searchInput: string = '';

	opened = false;

	protected subscriptions: Subscription[] = [];

	constructor(private filterService: FilterService) {}

	ngOnInit(): void {
		const preactivated = this.filterService.isActive(this.filterName);
		if (preactivated) this.onFilterActivated();
	}

	onFilterActivated() {
		const { valueString } = this.filterService.configs[this.filterName]!;
		const val$ = this.filterService.getFilterStateAsInput(this.filterName);
		this.canErase$ = val$.pipe(map((val) => val !== undefined && this.filterService.isOptional(this.filterName)));
		this.subscriptions.push(
			val$.subscribe((val) => {
				this.valueString = val === undefined ? undefined : valueString(val);
			}),
			this.filterService.options[this.filterName]!.subscribe((filterValues) => {
				this.enoughValuesForSearch = filterValues.activeValues.length + filterValues.inactiveValues.length > this.SHOW_SEARCH_AFTER;
			}),
			this.filterService.filterPanelOpened$.subscribe((open) => {
				if (!open) this.resetSearchFilter();
			})
		);
	}

	getLabel(): string {
		return this.filterService.configs[this.filterName]!.label;
	}

	open(open: boolean) {
		this.opened = open;
		if (open) {
			if (!this.filterService.isActive(this.filterName)) {
				this.filterService.activate([this.filterName], true, { [this.filterName]: undefined });
				this.onFilterActivated();
			}
		} else {
			this.filterService.deactivateIfNeeded([this.filterName]);
		}
	}

	showSearchIcon(): boolean {
		return this.enoughValuesForSearch && this.filterService.isMultiSelect(this.filterName) && this.opened;
	}

	searchActivated() {
		return (this.searchWanted || this.filterService.isSingleSelect(this.filterName)) && this.enoughValuesForSearch;
	}

	toggleSearch($event: Event): void {
		$event.stopPropagation();

		this.searchWanted = !this.searchWanted;
	}

	erase($event: Event): void {
		$event.stopPropagation();
		this.resetSearchFilter();
		this.filterService.setFilterInput(this.filterName, undefined).then(() => this.filterService.deactivateIfNeeded([this.filterName]));
	}

	resetSearchFilter() {
		this.searchInput = '';
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}
}
