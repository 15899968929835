<app-base-dialog [bottomBarTemplate]="bottomBar" [sizeClass]="'size-20'" [topBarTemplate]="topBar">
	<ng-template #topBar>
		@if (selections$ | async; as selections) {
			<app-dialog-selection-bar [selection]="currentSelection()!" (selectionChange)="currentSelection.set($event)" [selections]="selections">
			</app-dialog-selection-bar>
		}
	</ng-template>
	@switch (currentSelection()) {
		@case (PaginaDelenSelection.PERSONEN) {
			<div class="element">
				<span class="title">Delen met</span>
				@if (accounts$ | async; as accounts) {
					<app-multi-select-dropdown
						[allOptions]="accounts"
						[value]="selectedAccountOptions()"
						(valueChange)="selectedAccountOptions.set($event)"
						[showSearchInput]="true"
						[showSearchInputInside]="false"
						placeholder="Gebruiker zoeken"
					></app-multi-select-dropdown>
				}
				<div class="label-container">
					<div class="labels">
						@for (account of selectedAccountOptions(); track account.id) {
							<div class="label">
								<p [title]="account.naam" class="text-label-semi">{{ account.naam }}</p>
								<span class="svg-sluiten" (click)="removeAccount(account)"></span>
							</div>
						}
					</div>
				</div>
				@if (notAllowedAccounts().length > 0) {
					<div class="warning">
						<div class="svg-warning"></div>
						<div class="warning-message">
							{{ notAllowedAccounts().length == 1 ? "Ontvanger " : "Ontvangers " }}
							<span class="warning-accounts">{{ notAllowedAccountNamesString() }}</span>
							{{ notAllowedAccounts().length == 1 ? " heeft" : " hebben" }} niet de juiste rechten om dit overzicht te bekijken.
						</div>
					</div>
				}
			</div>
		}
		@case (PaginaDelenSelection.GROEP) {
			<div class="element">
				<span class="title">Rol</span>
				@if (rollen$ | async; as rollen) {
					<app-multi-select-dropdown
						[allOptions]="rollen"
						[value]="selectedRolOptions()"
						(valueChange)="selectedRolOptions.set($event)"
						placeholder="Rol zoeken"
						summaryAll="Alle"
					></app-multi-select-dropdown>
				}
				<span class="title">Vestiging</span>
				@if (vestigingen$ | async; as vestigingen) {
					<app-multi-select-dropdown
						[allOptions]="vestigingen"
						[value]="selectedVestigingOptions()"
						(valueChange)="selectedVestigingOptions.set($event)"
						placeholder="Vestiging zoeken"
						summaryAll="Alle"
					></app-multi-select-dropdown>
				}
			</div>
		}
		@case (PaginaDelenSelection.LINK) {
			<div class="element">
				@if (link$ | async; as link) {
					<input class="link-input" type="text" disabled [value]="link" />
					<a class="link" [cdkCopyToClipboard]="link" (cdkCopyToClipboardCopied)="showClipboardCopyToastr()">Link kopiëren</a>
				}
			</div>
		}
	}
	@if (currentSelection() !== PaginaDelenSelection.LINK) {
		<div class="element">
			<div class="bericht-header">
				<span class="title">Bericht (Optioneel)</span>
				<span class="aantal" [class.error]="bericht ? bericht.length > 500 : false"> {{ bericht?.length || 0 }} / 500</span>
			</div>
			<textarea class="bericht" [(ngModel)]="bericht" [class.error]="bericht ? bericht.length > 500 : false"></textarea>
		</div>
	}
	<ng-template #bottomBar>
		@switch (currentSelection()) {
			@case (PaginaDelenSelection.LINK) {
				<div class="link-copy-success" [class.show]="showCopySuccess">Link gekopieerd</div>
				<app-button (clickit)="dialogRef.close()">Sluiten</app-button>
			}
			@default {
				<app-button buttonType="secondary" (clickit)="dialogRef.close()">Annuleren</app-button>
				<app-button
					[enabled]="isEnabled(currentSelection(), selectedAccountOptions(), selectedRolOptions(), selectedVestigingOptions(), bericht)"
					(clickit)="save(currentSelection(), selectedAccountOptions(), selectedRolOptions(), selectedVestigingOptions(), bericht)"
				>
					Delen
				</app-button>
			}
		}
	</ng-template>
</app-base-dialog>
