import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isNumeric } from '@cumlaude/shared-utils';

/**
 * Pipe vergelijkbaar met de DecimalPipe, maar ondersteunt een format string die begint
 * met een + om positieve waarden met een + te prefixen (en negatieve met een -).
 *
 * De volledige format string wordt dan:
 * 	{plus}{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
 * plus: optioneel een +-teken om positieve waarden te laten voorafgaan door een + en negatieve door een -. Default leeg.
 * minIntegerDigits: Minimum aantal integer digits vóór de decimale komma. Default is 1.
 * minFractionDigits: Minimum aantal digits na de decimale komma. Default is 0.
 * maxFractionDigits: Maximum aantal digits na de decimale komma. Default is 3.
 */
@Pipe({
	name: 'extendedDecimal',
	standalone: true,
})
export class ExtendedDecimalPipe implements PipeTransform {
	transform(value: number | null | undefined, format: string | undefined): string {
		return formatDecimal(value, format);
	}
}

/**
 * Variant van formatNumber, die een format string ondersteunt die begint met
 * een + om positieve waarden met een + te prefixen (en negatieve met een -).
 */
export function formatDecimal(value: number | string | null | undefined, format: string | undefined) {
	if (!isNumeric(value)) return '';
	const match = format?.match(/(\+?)(\d*?\.\d*-\d*)/) ?? ['', '', format];
	const formatted = formatNumber(Number(value), 'nl-NL', match[2]);
	// Check of afgeronde waarde positief is en niet alleen nullen bevat
	const isPositive = /^[^-]*[1-9].*/.test(formatted);
	return (isPositive ? match[1] : '') + formatted;
}


