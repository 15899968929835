<div
	(click)="onTouched(); toggleDropdown()"
	class="value-box text-content"
	[class.selection-open]="showDropdown"
	[class.invalid]="invalid"
	[class.disabled]="!enabled"
	[class.has-selection]="selectedOptie"
>
	@if (selectedOptie) {
		@if (selectedOptie.icon) {
			<i class="{{ selectedOptie.icon }} icon-medium option-icon text-content {{ selectedOptie.iconColor }}"></i>
		}
		<div [title]="selectedOptie.text" class="selected-value" [class]="selectedOptie.style">{{ selectedOptie.text }}</div>
	} @else {
		<div class="placeholder">{{ placeholder }}</div>
	}
	<i class="icon icon-arrow-open icon-medium chevron-icon"></i>
</div>
@if (showDropdown) {
	<div
		(clickOutside)="closeDropDown($event)"
		[clickOutsideEvents]="'click,touchend'"
		[delayClickOutsideInit]="true"
		class="selection-box"
		[class.contains-search]="showSearchInput"
		[style.height.px]="selectBoxHeight"
		#dropdown
	>
		@if (showDeleteAction && selectedOptie) {
			<div class="optie" (click)="callDeleteAction($event)">
				<span class="icon-medium icon icon-delete text-t5-color"></span>
				<span class="optie-text text-content">Verwijderen</span>
			</div>
		}
		@if (showSearchInput) {
			<div class="search-box">
				<span class="icon-zoek"></span>
				<input #inp type="text" (input)="search(inp.value)" [value]="searchInput" placeholder="Zoeken..." />
			</div>
		}
		@for (optie of getOptions(); track optie) {
			<div class="optie" [class.is-selected]="isSelected(optie)" (click)="writeValue(optie.value, $event)">
				@if (optie.icon) {
					<i class="{{ optie.icon }} icon-medium icon {{ optie.iconColor }}"></i>
				}
				<span [title]="optie.text" class="optie-text text-content {{ optie.style }}">{{ optie.text }}</span>
				@if (menuTemplate) {
					<div class="menu"><ng-container *ngTemplateOutlet="menuTemplate; context: { optie }"></ng-container></div>
				}
			</div>
		} @empty {
			<div class="optie no-options">
				<span class="optie-text">{{ showEmpty }}</span>
			</div>
		}
	</div>
}
