@if (inDropdown) {
	<app-single-select-list [opties]="options" [selectedOptie]="getSelected(options)" (onOptieClicked)="select($event)"></app-single-select-list>
} @else {
	<app-single-select-dropdown
		placeholder="Alle"
		[opties]="options"
		[selectedOptie]="getSelected(options)"
		(onOptieClicked)="select($event)"
		[showSearchInput]="searchInput !== undefined"
		[searchInput]="searchInput ?? ''"
		(onSearchInput)="search($event)"
		(onShowDropdown)="showDropdown($event)"
		[showEmpty]="showEmpty"
	>
	</app-single-select-dropdown>
}
