import { Component, computed, OnInit, Signal } from '@angular/core';
import { Observable } from 'rxjs';
import { Path } from '../../services/data-tree';
import { AttrPath, DataOptions, DataResponse, DataService, ExportDataOptions, FilterExpression } from '../../services/data.service';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { maxDeelVanJaar, maxOver, noAgg0 } from '../../services/aggregation';
import { att, att0, percOfParent } from '../../services/measures';
import { FilterName } from '../../services/filter-config';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { FilterService } from '../../services/filter.service';
import { memoize } from 'lodash-es';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { DashboardVariant, Eenheid } from '../../services/weergave-opties';
import { Axis, createYAxis } from '../../services/axis';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { WeergaveOptieComponent } from '../../shared/components/weergave-optie/weergave-optie.component';
import { formatPercent } from '@angular/common';

interface VakkenI extends Attributes {
	vkk_nr_leerlingen: number;
	xa: { [nr: number]: { vkk_nr_leerlingen: number } };
}

interface VakkenA extends Attributes {
	max: number;
	aantalLeerlingen: number;
	maxPctJaar: number;
}

@Component({
	selector: 'app-vakken',
	templateUrl: './vakken.component.html',
	styleUrls: ['./vakken.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		WeergaveOptieComponent,
	],
})
export class VakkenComponent extends BarchartTableConfig<VakkenI, VakkenA> implements OnInit {
	defaultGroups: AttrPath[] = [['vkk_fk_ilt', 'ilt_nm_niveau'], ['vkk_nr_leerjaar']];

	selectedGroups: AttrPath[] = this.defaultGroups;

	availableGroups: AttrPath[] = [
		['vkk_fk_ilt', 'ilt_nm_niveau'],
		['vkk_fk_ilt', 'ilt_nm_opleiding'],
		['vkk_fk_ilt', 'ilt_abb_profiel'],
		['vkk_fk_lb', 'lb_nm_opleiding'],
		['vkk_nr_leerjaar'],
		['vkk_fk_vs', 'vs_nm_vestiging'],
		['vkk_fk_lb', 'lb_nm_klas'],
		['vkk_nm_lesgroep'],
		['vkk_fk_ll', 'll_nm_leerling'],
		['vkk_fk_vk', 'vk_nm_vak'],
		['vkk_fk_vk', 'vk_nm_vak_uni'],
		['vkk_nr_leerjaar_vak'],
		['vkk_nm_niveau_vak'],
		['vkk_fk_vk', 'vk_nm_vakkengroep'],
		['vkk_nm_soort_vakpositie'],
	];

	actueelFilters: FilterName[] = [
		'vkk_nm_schooljaar',
		'x_vakkeuze_peildatum',
		'vkk_fk_lb.lb_co_brin',
		'vkk_fk_vs.vs_nm_vestiging',
		'vkk_fk_ilt.ilt_nm_niveau',
		'vkk_nr_leerjaar',
		'vkk_nm_vak',
	];

	historieFilters: FilterName[] = [
		'x_vkk_schooljaar_historie', //
		'x_vkk_multiselect_schooljaar',
		...this.actueelFilters.filter((f) => !['vkk_nm_lesgroep'].includes(f)),
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = [];

	variant = this.qp.signal('variant');

	eenheid = this.qp.signal('eenheid');

	constructor(
		private dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(this.qp.observe_g().subscribe((groups) => (this.selectedGroups = groups ?? this.defaultGroups)));
	}

	factTable = FactTable.vakkeuze;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		// xagg: distinct #lln waarbij vak uit de groeperingen is gelaten (maar schooljaar er wel in zit)
		const xa = this.variant() === DashboardVariant.ACTUEEL ? [] : [[this.selectedGroups.length]];
		return this.dataService.getVakkeuzeData({
			...options,
			r: [0, this.selectedGroups.length],
			xa,
		});
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getVakkeuzeExportData(options);
	}

	protected singleAggregators = {
		max: maxOver('vkk_nr_leerlingen'),
		aantalLeerlingen: noAgg0('vkk_nr_leerlingen'),
		maxPctJaar: maxDeelVanJaar('vkk_nr_leerlingen'),
	};

	createLinkData(path: Path<unknown, number[]>, context: DashboardContext<VakkenI, VakkenA, VakkenComponent>): Partial<LinkData> {
		return {
			dashboard: '/details/leerling/vakken',
			dataProvider: 'vakkeuze',
			...super.createLinkData(path, context),
		};
	}

	createMeasureColumns(): ColumnDef<DataRow<VakkenA>>[] {
		if (this.variant() === DashboardVariant.HISTORIE) return [];

		return [
			createMeasureColumn('Percentage', percOfParent('aantalLeerlingen', 'aantalLeerlingen'), { dataType: 'percentage' }),
			createMeasureColumn<VakkenI, VakkenA>('Leerlingen', att('aantalLeerlingen')),
		];
	}

	protected getFixedAfterGroups(): number {
		return this.variant() === DashboardVariant.HISTORIE ? 0 : 1;
	}

	enrichTableModel(_context: DashboardContext<VakkenI, VakkenA, VakkenComponent>, tableModel: TableModel<DataRow<VakkenA>>) {
		tableModel.showFooters = this.variant() === DashboardVariant.ACTUEEL;
	}

	historieSubGroups: AttrPath[] = [['vkk_nm_vak'], ['vkk_nm_schooljaar']];

	// memoize, otherwise new array keeps triggering change detection
	getActueelGroups = memoize(VakkenComponent._getActueelGroups, JSON.stringify);

	private static _getActueelGroups(selectedGroups: AttrPath[]): AttrPath[] {
		return [...selectedGroups, ['vkk_nm_vak']];
	}

	createYAxis(context: DashboardContext<VakkenI, VakkenA, VakkenComponent>): Axis {
		if (!context.dataRoot) return super.createYAxis(context);
		if (this.eenheid() === Eenheid.AANTAL) {
			return createYAxis([0, context.dataRoot.a.max]);
		} else {
			return createYAxis([0, context.dataRoot.a.maxPctJaar], 5, (val) => formatPercent(val, 'nl_NL'));
		}
	}

	getBarchartQty(path: Path<VakkenA, number[]>) {
		if (this.variant() === DashboardVariant.ACTUEEL) return 100 * percOfParent('aantalLeerlingen', 'aantalLeerlingen')(path);
		else if (this.eenheid() === Eenheid.AANTAL) return att0('aantalLeerlingen')(path);
		else return att0('maxPctJaar')(path);
	}

	partitionMeasure: Signal<PartitionMeasure<VakkenA>> = computed(() => {
		if (this.eenheid() === Eenheid.AANTAL)
			return {
				type: 'number',
				getValue: att('aantalLeerlingen'),
				format: '1.0-0',
			};
		else
			return {
				type: 'percentage',
				getValue: att('maxPctJaar'),
			};
	});

	protected readonly DashboardVariant = DashboardVariant;
	protected readonly Eenheid = Eenheid;
}
