@for (child of data.batch; track child) {
	<div>
		<div>{{ getLabel(child.key, child.attr) }}</div>
		<app-vbar-series
			[partitions]="child.series"
			[barHeight]="data.barHeight"
			[yAxis]="data.yAxis"
			[style]="data.style"
			[options]="data.options"
		></app-vbar-series>
	</div>
}
