<div
	#button
	class="svg-meatball-menu"
	[class.grey]="size === 'M'"
	[class.large]="size === 'L'"
	(click)="toggleOpen()"
	[attr.data]="MEATBALL_ID"
></div>
<ng-template #dropdown class="dropdown">
	<div class="dropdown-anchor">
		<div class="dropdown" [delayClickOutsideInit]="true" (clickOutside)="hide()" [clickOutsideEvents]="'click,touchend'" (click)="hide()">
			<ng-content></ng-content>
		</div>
	</div>
</ng-template>
