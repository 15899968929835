import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-toggle-buttons',
	templateUrl: './toggle-buttons.component.html',
	styleUrls: ['./toggle-buttons.component.scss'],
	standalone: true,
})
export class ToggleButtonsComponent<T> implements OnInit {
	@Input()
	options: T[] = [];

	@Input()
	value?: T;

	@Input()
	labelFunction: (value: T) => string = (value) => `${value}`;

	@Output()
	valueChange = new EventEmitter<T>();

	select(option: T) {
		this.value = option;
		this.valueChange.emit(option);
	}

	ngOnInit(): void {
		if (!this.value) this.value = this.options[0];
	}
}
