<app-base-dialog sizeClass="size-25" [topBarTemplate]="topBar" [bottomBarTemplate]="bottomBar">
	<ng-template #topBar>
		<app-dialog-header [title]="dialogData.caption"></app-dialog-header>
	</ng-template>
	<div class="panel-card__normal">
		<div class="input-box" [class.is-error]="isShowFeedback(naam)">
			Naam
			<input [(ngModel)]="model.naam" required #naam="ngModel" cdkFocusInitial />
			<ul class="feedbackPanel">
				@if (naam.errors?.['required']) {
					<li class="feedbackPanelERROR">Dit veld is verplicht.</li>
				}
			</ul>
		</div>
		<div class="input-box" [class.is-error]="isShowFeedback(email)">
			E-mailadres
			<input [(ngModel)]="model.email" required email #email="ngModel" />
			<ul class="feedbackPanel">
				@if (email.errors?.['required']) {
					<li class="feedbackPanelERROR">Dit veld is verplicht.</li>
				}
				@if (email.errors?.['email']) {
					<li class="feedbackPanelERROR">Voer een geldig e-mailadres in.</li>
				}
			</ul>
		</div>
	</div>
	<ng-template #bottomBar>
		<app-button buttonType="secondary" (clickit)="dialogRef.close()">Annuleren</app-button>
		<app-button (clickit)="onDialogClick()" [enabled]="!naam.invalid && !email.invalid">{{ dialogData.action }}</app-button>
	</ng-template>
</app-base-dialog>
