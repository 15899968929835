import { Component, Input } from '@angular/core';
import { BarchartTableComponent } from '../barchart-table/barchart-table.component';
import { AlternatingMode, ColumnDef, createColumnDef as createDefaultColumnDef, TableModel } from '../../components/table/table/table.model';
import { VbarBatchComponent, VbarBatchData, VBarOptions, VbarSeriesData, VbarStyle } from './vbar-batch/vbar-batch.component';
import { flatMap, isFunction, last, sortBy } from 'lodash-es';
import { getLeafA, Level, Path } from '../../../services/data-tree';
import { getSchooljaarKort } from '@cumlaude/shared-utils';
import { AttrPath, BasicFilterExpression, CompoundFilterExpression, ExportDataOptions, InFilterExpression } from '../../../services/data.service';
import { NO_MEASURE, VPartitionData } from './vbar-series/vbar-series.component';
import { Router } from '@angular/router';
import { attrLabel } from '../../../services/labels';
import {
	ColumnType,
	DataRow,
	DataTreeTableComponent,
	ExportTable,
	ExportTableColumnDef,
	findChildOrEmpty,
	getInitialAttributes,
} from '../data-tree-table/data-tree-table';
import { UrlService } from '../../../services/url.service';
import { Attributes } from '../base-dashboard/base-dashboard-config';
import { DashboardContext } from '../base-dashboard/dashboard-context';
import { BarchartTableConfig } from '../barchart-table/barchart-table-config';
import { Axis } from '../../../services/axis';
import { att } from '../../../services/measures';
import { AsyncPipe } from '@angular/common';
import { TableComponent } from '../../components/table/table/table.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

const VBAR_COLUMN_NAME = 'vbarchart';

export interface VBarExportColumnDef<A extends Attributes> extends ExportTableColumnDef<A> {
	unwrapChildren: (seriesData: VbarSeriesData) => any;
}

export type PartitionMeasure<A extends Attributes> = {
	type: 'string' | 'number' | 'percentage' | ((path: Path<A, number[]>) => 'string' | 'number' | 'percentage');
	getValue: (path: Path<A, number[]>) => any;
	format?: string | ((path: Path<A, number[]>) => string);
	visible?: boolean; // default true
};

@Component({
	selector: 'app-vbarchart-table',
	templateUrl: '../data-tree-table/data-tree-table.html',
	styleUrls: ['../data-tree-table/data-tree-table.scss'],
	providers: [{ provide: DataTreeTableComponent, useExisting: VbarchartTableComponent }], // zorgt dat dit component als subclass van DataTreeTableComponent wordt herkend voor het ViewChild-attribuut van DataTreeTableConfigConfig
	standalone: true,
	imports: [CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, TableComponent, AsyncPipe],
})
export class VbarchartTableComponent<I extends Attributes, A extends Attributes, C extends BarchartTableConfig<I, A>> extends BarchartTableComponent<
	I,
	A,
	C
> {
	/**
	 * measure die boven elke (verticale) partition komt te staan (default aantal records)
	 */
	@Input()
	partitionMeasure?: PartitionMeasure<A> = { type: 'number', getValue: att('count_records') };

	/**
	 * De kolom met vbar-series bevat meestal een extra groepering, nl de eerste van de "subgroups" levels. Deze groep noemen we de "batch".
	 * Bij batchLevels==0 is er geen extra groepering.
	 */
	@Input()
	batchLevels!: 0 | 1;

	@Input()
	barchartSize: number = 100;

	@Input()
	schooljaarPath!: AttrPath;

	@Input()
	style = VbarStyle.BAR;

	@Input()
	options?: VBarOptions;

	private yAxis!: Axis;

	constructor(
		protected router: Router,
		protected urlService: UrlService
	) {
		super(router, urlService);
	}

	protected createDataColumns(context: DashboardContext<I, A, BarchartTableConfig<I, A>>): ColumnDef<DataRow<A>>[] {
		this.yAxis = context.config.createYAxis(context);
		return [this.createVbarColumn(context)];
	}

	private createVbarColumn(context: DashboardContext<I, A, BarchartTableConfig<I, A>>): ColumnDef<DataRow<A>> {
		const name = this.batchLevels ? context.subgroupNames[0] : VBAR_COLUMN_NAME;
		const title = this.batchLevels ? attrLabel(<AttrPath>name.split('.')) : '';
		const coldef: ColumnDef<DataRow<A>> = createDefaultColumnDef(name, title, this.batchLevels === 1);

		coldef.body.component = VbarBatchComponent;
		coldef.body.getValue = (row) => this.createBatchData(context, row._path);
		coldef.type = ColumnType.GROUP;

		if (this.config.isHistorieBatchVariant()) {
			coldef.header.headerType = 'groepering';
			coldef.header.batchGroepering = true;
		}

		return coldef;
	}

	private createBatchData(context: DashboardContext<I, A, BarchartTableConfig<I, A>>, path: Path<A, number[]>): VbarBatchData {
		const { filter } = context;
		const schooljaren = sortBy(
			(<InFilterExpression<string[]>>(
				(<CompoundFilterExpression>filter).filters.find(
					(exp) => exp instanceof BasicFilterExpression && exp.type === 'in' && exp.attr.join('.') == this.schooljaarPath.join('.')
				)!
			)).val
		);
		const groups = this.batchLevels ? sortBy(last(path)!.c, (lvl) => lvl.i) : [last(path)!];
		return {
			batch: groups.map((group) => {
				return {
					series: schooljaren.map((sj) => this.createVPartitionData(group, path, sj, context)),
					key: this.batchLevels ? group.k : 'Totaal',
					attr: this.batchLevels ? <AttrPath>context.subgroupNames[0].split('.') : undefined,
				};
			}),
			barHeight: this.barchartSize,
			yAxis: this.yAxis,
			style: this.style,
			options: this.options,
		};
	}

	private createVPartitionData(
		group: Level<A, number[]>,
		path: Level<A, number[]>[],
		sj: string,
		context: DashboardContext<I, A, BarchartTableConfig<I, A>>
	): VPartitionData {
		const { subgroupNames, measureNames, config } = context;

		const child = findChildOrEmpty(group, sj);
		const schooljaarPath = this.batchLevels === 0 ? [...path, child] : [...path, group, child];

		const recss = config.partitionBarData(last(schooljaarPath)!, context).map((recs) =>
			recs.map((rec) => {
				const attrs = getInitialAttributes<I>(subgroupNames, measureNames, rec);
				return config.makeBar(attrs, rec, context);
			})
		);

		const qty = config.getBarchartQty(schooljaarPath, context);
		const label = getSchooljaarKort(sj);
		const tooltip = config.getPartitionTooltip(schooljaarPath);

		const areas =
			this.style === VbarStyle.CIRCLE && qty !== null
				? [{ qty: 25, className: 'percentielnegatief' }, { qty: 75, className: 'percentielgemiddeld' }, { className: 'percentielpositief' }]
				: undefined;

		const measure = this.partitionMeasure
			? {
					type: isFunction(this.partitionMeasure.type) ? this.partitionMeasure.type(schooljaarPath) : this.partitionMeasure.type,
					format: isFunction(this.partitionMeasure.format) ? this.partitionMeasure.format(schooljaarPath) : this.partitionMeasure.format,
					value: getLeafA(schooljaarPath).count_records ? this.partitionMeasure.getValue(schooljaarPath) : null,
					visible: this.partitionMeasure.visible,
				}
			: NO_MEASURE;

		return {
			stacks: recss,
			measure,
			label,
			qty,
			areas,
			linkData: config.createLinkData(schooljaarPath, context),
			tooltip,
			path: schooljaarPath,
		};
	}

	protected getExportTable(models: TableModel<DataRow<A>>[][][], options: ExportDataOptions): ExportTable<A> {
		if (!models.length || !models[0].length) return { data: [], options };

		const firstModel = models[0][0][0];
		const titleColumns = this.getExportTitleColumns();
		// De laatste grouping column bevat meerdere grafieken: deze vormen een batch met een series per grafiek, die weer bestaat uit een partition per schooljaar.
		// Deze constructie moet ge-unwrapped worden: we genereren een rij per series uit de batch (=laatste groepering), en een kolom per schooljaar (partition uit de series).
		const standardColumns: ExportTableColumnDef<A>[] = []; // Gewone groeperingskolommen, deze zijn hetzelfde voor alle grafieken in de DataRow
		const unwrappingColumns: VBarExportColumnDef<A>[] = []; // De laatste groeperingskolom en alle schooljaren
		firstModel.columnDefs
			.filter((def) => def.type === ColumnType.GROUP)
			.forEach((def) => {
				const name = def.header.getValue(firstModel);
				// Haal de data van de eerste DataRow om het datatype en de schooljaren te bepalen
				const value = def.body.getValue(firstModel.data[0]);
				if (typeof value !== 'object') {
					// Als getValue een primitive type teruggeeft is het een gewone groeperingskolom
					standardColumns.push({ name, type: 'string', getValue: def.body.getValue });
				} else {
					// Anders bevat het alle batch-data
					const batchData = <VbarBatchData>value;
					if (name) {
						// Voeg kolom toe voor laatste groepering
						unwrappingColumns.push({
							name,
							type: 'string',
							getValue: def.body.getValue,
							unwrapChildren: (seriesData) => seriesData.key, // de key van de series is de laatste groepering
						});
					}
					batchData.batch[0].series.forEach((partition, ix) => {
						unwrappingColumns.push(...this.config.getExportTablePartitionColumns(partition, ix, def.body.getValue));
					});
				}
			});
		const columns = [...titleColumns, ...standardColumns, ...unwrappingColumns];

		const data = flatMap(models, (m) =>
			flatMap(m, (cm) =>
				cm.map((model) => ({
					columns,
					rows: flatMap(model.data, (row) => {
						const titles = titleColumns.map((col) => col.getValue(row));
						const standardColumnValues: any[] = standardColumns.map((col) => col.getValue(row));
						if (unwrappingColumns.length) {
							const batchData: VbarBatchData = unwrappingColumns[0].getValue(row);
							return batchData.batch.map((series) => [
								...titles,
								...standardColumnValues, // Herhaal de gewone groeperingskolommen over alle gegenereerde rijen
								...unwrappingColumns.map((col) => col.unwrapChildren(series)),
							]);
						} else return [standardColumnValues];
					}),
				}))
			)
		);
		return { data, options };
	}

	getAlternatingMode() {
		return AlternatingMode.LINE;
	}
}
