<svg [attr.viewBox]="getViewBox()" [attr.width]="getWidth() + 'px'" [attr.height]="getHeight() + 'px'" preserveAspectRatio="none">
	@if (yAxis.ticks.length > 0 && style === "BAR") {
		<line class="axis" [attr.x1]="paddingLeft" [attr.y1]="paddingTop" [attr.x2]="paddingLeft" [attr.y2]="barHeight + paddingTop"></line>
	}
	@for (partition of partitions; let i = $index; track i) {
		<g [attr.transform]="getBarTransform(i)">
			@for (area of getSVGAreas(i); track $index) {
				<g class="areas">
					<rect [attr.x]="0" [attr.y]="area.y" [attr.width]="barWidth" [attr.height]="area.height" [attr.class]="area.className"></rect>
				</g>
			}
			<rect
				class="tooltip"
				x="0"
				[attr.y]="-barHeight"
				[attr.width]="barWidth"
				[attr.height]="barHeight"
				[appTooltip]="partition.tooltip"
			></rect>
		</g>
	}
	<g [attr.transform]="getYAxisTransform()">
		@for (tick of getSVGTicks(); let i = $index; track i) {
			<line [class.axis]="i == 0 || tick.y == 0" x1="-3" [attr.y1]="tick.y" [attr.x2]="getWidth() - paddingLeft" [attr.y2]="tick.y"></line>
		}
		@for (tick of getSVGTicks(); track $index) {
			<g>
				<text [attr.x]="-5" [attr.y]="tick.y" text-anchor="end" dominant-baseline="middle">{{ tick.label }}</text>
			</g>
		}
	</g>
	<g>
		@for (measure of measures; let i = $index; track i) {
			<g [attr.transform]="getMeasureTransform(i)">
				<text x="0" y="0" text-anchor="middle" dominant-baseline="middle" (click)="onTextClick(i)">{{ measure }}</text>
			</g>
		}
	</g>
	@for (partition of partitions; let i = $index; track i) {
		<g [attr.transform]="getBarTransform(i)">
			@if (style === "CIRCLE" && partition.qty !== null) {
				<circle r="4" [attr.cx]="0.5 * barWidth" [attr.cy]="-qtyToPx(partition.qty)"></circle>
			}
		</g>
	}
	@if (style === "BAR") {
		<ng-container>
			@for (bars of pctStacks; track bars; let i = $index) {
				<g [attr.transform]="getPartitionTransform(i)">
					@for (bar of bars; track bar; let j = $index) {
						<g class="bar">
							<clipPath [id]="getClipPathId(i, j)">
								<rect [attr.x]="0.2" [attr.y]="bar.pctStart" [attr.width]="0.6" [attr.height]="bar.pct"></rect>
							</clipPath>
							<rect
								[appTooltip]="bar.tooltip"
								[attr.x]="0.2"
								[attr.y]="bar.pctStart"
								[attr.width]="0.6"
								[attr.height]="bar.pct"
								[attr.class]="bar.className"
								(click)="onBarClick(bar)"
							></rect>
							@if (bar.pct > 10) {
								<g [attr.clip-path]="'url(#' + getClipPathId(i, j) + ')'">
									<text
										x="0"
										y="0"
										text-anchor="middle"
										dominant-baseline="middle"
										[attr.transform]="getTextTransform(i, 0.5, bar.pctStart + 0.5 * bar.pct)"
									>
										{{ bar.text }}
									</text>
								</g>
							}
						</g>
					}
				</g>
			}
			@if (options?.showPartitionRectangle) {
				<g>
					@for (r of getPartitionRectangles(); track $index) {
						<rect [attr.x]="r.x" [attr.y]="r.y" [attr.width]="r.width" [attr.height]="r.height" class="partition-rectangle"></rect>
					}
				</g>
			}
		</ng-container>
	}
	<g [attr.transform]="getXAxisTransform()">
		@if (yAxis.ticks.length === 0) {
			<line class="axis" x1="0" y1="0" [attr.x2]="getWidth()" y2="0"></line>
		}
		@for (xlabel of xlabels; let i = $index; track i) {
			<g [attr.transform]="'translate(' + (i + 0.5) * barWidth + ' 0)'">
				@if (style === "BAR") {
					<line x1="0" y1="0" x2="0" y2="3"></line>
				}
				<g transform="translate(0 5) rotate(270)">
					<text x="0" y="0" text-anchor="end" dominant-baseline="middle">{{ xlabel }}</text>
				</g>
			</g>
		}
		@for (i of xrangeIncl; track i) {
			<g [attr.transform]="'translate(' + i * barWidth + ' 0)'">
				@if (style === "CIRCLE") {
					<line x1="0" y1="3" x2="0" [class.axis]="i == 0" [attr.y2]="-barHeight"></line>
				}
			</g>
		}
	</g>
</svg>
