import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParameterCodec, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { fromPairs } from 'lodash-es';

/**
 * De HttpUrlEncodingCodec die de HttpClient default gebruikt past op bepaalde karakters
 * geen percent-encoding toe waar hij dat volgens
 * https://www.rfc-editor.org/rfc/rfc3986#section-2.2
 * wel zou moeten.
 * (Een van die karakters is ";", en dat levert problemen op doordat Traefik hem vervangt
 * door "&".)
 * Deze interceptor vervangt deze codec door eentje die parameters encode d.m.v. de
 * standaard JavaScript "encodeURIComponent" die zich wel aan de spec houdt.
 */
@Injectable({ providedIn: 'root' })
export class ParamEncodingInterceptor implements HttpInterceptor {
	intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
		return next.handle(
			req.clone({
				params: new HttpParams({
					encoder: new StandardCodec(),
					fromObject: toObject(req.params),
				}),
			})
		);
	}
}

function toObject(params: HttpParams): { [k: string]: string[] } {
	return fromPairs(params.keys().map((k) => [k, params.getAll(k)!]));
}

class StandardCodec implements HttpParameterCodec {
	decodeKey(key: string): string {
		return decodeURIComponent(key);
	}

	decodeValue(value: string): string {
		return decodeURIComponent(value);
	}

	encodeKey(key: string): string {
		return encodeURIComponent(key);
	}

	encodeValue(value: string): string {
		return encodeURIComponent(value);
	}
}
