@if (leerling$ | async; as leerling) {
	<div class="dashboard-toets">
		<app-filter-panel
			[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
			[allFilters]="getAllFilters()"
			(filterExpressionsChange)="filterExpressions = $event"
			endpoint="/cijfers"
			[permanentFilterExpressions]="getVariantPermanentFilters(cijferkolomKeuze, dossier, leerling.leerling, variant)"
			[overrideDefault]="getSchooljaarLeerfaseFilterOverride(leerling.schooljaarInfo)"
		></app-filter-panel>
		<section class="dashboard">
			<app-dashboard-header [legenda]="['x_cijfer_ov_advies']" [variant]="variant"></app-dashboard-header>
			<div class="groepering">
				@if (variant === "Actueel") {
					<div class="weergave cijfertype">
						Cijfertype:
						<dt-form-dropdown
							[opties]="cijferkolomKeuzeOpties()"
							[selectedOptie]="getSelectedCijfertypeOptie()"
							(onOptieClicked)="qp.dispatch('cijfertype', $event)"
						></dt-form-dropdown>
					</div>
				}
				@if (variant === "Actueel" && cijferkolomKeuze !== "Gemiddelde" && userService.bron() === InstellingBron.Somtoday) {
					<div class="weergave dossier">
						Toetsdossier:
						<dt-form-dropdown
							[opties]="dossierOpties"
							[selectedOptie]="getSelectedDossierOptie()"
							(onOptieClicked)="qp.dispatch('dossier', $event)"
						></dt-form-dropdown>
					</div>
				}
			</div>
			<app-pivot-table
				[groups]="[['cf_nm_vak']]"
				[subgroups]="variant === 'Actueel' ? actueelSubgroups : historieSubgroups"
				[columnSort]="variant === 'Actueel' ? sortToetsKolommen : sortHistorieKolommen"
				[filters]="filterExpressions"
				[permanentFilters]="getVariantPermanentFilters(cijferkolomKeuze, dossier, leerling.leerling, variant)"
				[config]="this"
			></app-pivot-table>
		</section>
	</div>
}
