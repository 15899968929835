import { getInitialAttributes } from '../data-tree-table/data-tree-table';
import { DataTreeTableConfig } from '../data-tree-table/data-tree-table-config';
import { Level, Path } from '../../../services/data-tree';
import { PointInfo } from './linechart/linechart.component';
import { last, max } from 'lodash-es';
import { Attributes } from '../base-dashboard/base-dashboard-config';
import { DashboardContext } from '../base-dashboard/dashboard-context';
import { FilterService } from '../../../services/filter.service';
import { ToastrService } from 'ngx-toastr';
import { Axis } from '../../../services/axis';

export abstract class LinechartTableConfig<I extends Attributes, A extends Attributes> extends DataTreeTableConfig<I, A> {
	protected constructor(
		protected filterService: FilterService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	makePoints(r: Path<A, number[]>[], context: DashboardContext<I, A, LinechartTableConfig<I, A>>, _group: Level<A, number[]>): PointInfo[] {
		const rec = r[0];
		if (!rec) return [{ qty: 0, lineClass: 'default' }];

		const attrs = getInitialAttributes<I>(context.subgroupNames, context.measureNames, rec);
		return [{ qty: attrs.count_records, lineClass: 'default' }];
	}

	getYAxis(path: Path<A, number[]>): Axis {
		const pathMax = max(last(path)!.r.map((rec) => last(rec)!.a.count_records)) ?? 100;
		return { min: 0, max: pathMax, ticks: [] };
	}

	getLineNames(_path: Path<A, number[]>): { [lineClass: string]: string } {
		return { default: 'Waarde' };
	}

	getQtyFormatter(): undefined | ((n: number) => string) {
		return undefined;
	}
}
