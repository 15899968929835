<app-dashboard-container
	[class]="variant() === 'Actueel' ? 'dashboard-afwezigheid' : 'dashboard-afwezigheid-historie'"
	[factTable]="factTable"
	[exportTypes]="variant() === 'Actueel' ? actueelExportTypes : historieExportTypes"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
>
	<app-filter-panel
		[defaultFilters]="variant() === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/aanwezigheid"
		[permanentFilterExpressions]="permanentFilterExpressions"
	></app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header
			[variant]="variant()"
			[legenda]="variant() === 'Actueel' ? ['aw_is_abs_geoorloofd'] : ['x_aw_is_abs_geoorloofd_historie']"
		>
		</app-dashboard-header>
		<div class="groepering">
			<app-weergave-optie label="Eenheid" param="tijdseenheid" [value]="tijdseenheid()" [enum]="Tijdseenheid"></app-weergave-optie>
			@if (variant() === "Historie") {
				<app-weergave-optie label="Interval" param="interval" [value]="interval()" [enum]="Interval"></app-weergave-optie>
			}
		</div>
		@switch (variant()) {
			@case ("Actueel") {
				<app-barchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="selectedGroups()"
					[subgroups]="fixedSubgroups"
					[config]="this"
				></app-barchart-table>
			}
			@case ("Historie") {
				<app-linechart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="getHistorieGroups(selectedGroups())"
					[subgroups]="getHistorieSubgroups([selectedGroups(), intervalSubgroups, fixedSubgroups])"
					[batchLevels]="isHistorieBatchVariant() ? 1 : 0"
					[intervalLevels]="nrIntervalSubgroups"
					[config]="this.linechartConfig"
				></app-linechart-table>
			}
		}
	</section>
</app-dashboard-container>
