/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum PlaatsingUitzondering {
  PLAATSING_TELT_MEE = 'Plaatsing telt mee',
  GEEN_RELEVANTE_PLAATSING = 'Geen relevante plaatsing',
  GEEN_1_OKTOBERPLAATSING = 'Geen 1-oktoberplaatsing',
  TUSSENTIJDSE_INSTROOM = 'Tussentijdse instroom'
}
