import { InjectionToken } from '@angular/core';

export const ENV_CONFIG = new InjectionToken<EnvConfiguration>('ENV_CONFIG');

export const ENV_CONFIG_URL = 'assets/env.config.json';

export interface EnvConfiguration {
	production: boolean;
	environmentName: string;
	clientId: string;
	restUrl: string;
	authUrl: string;
	dataUrl?: string;
	somtodayUrl?: string;
	supportMail?: string;
	supportPhone?: string;
	zendeskLink?: string;
	gtmContainerId?: string;
	gtmAuth?: string;
	gtmPreview?: string;
	appcuesAccountId?: string;
	bugsnagApiKey?: string;
}
