<app-dashboard-container
	class="dashboard-cijferlijst"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, [], getDisplayOptions(), $event)"
>
	<app-filter-panel
		[defaultFilters]="cijferlijstFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/cijfers"
		[permanentFilterExpressions]="[]"
	></app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header [legenda]="['x_cijfer_ov']"></app-dashboard-header>
		<div class="groepering">
			<div class="weergave">
				Tekortpunten:
				<dt-form-dropdown
					[opties]="tekortpuntenKeuzeOpties"
					[selectedOptie]="getSelectedTekortpuntenOptie()"
					(onOptieClicked)="qp.dispatch('tekortpunten', $event)"
				></dt-form-dropdown>
			</div>
			<div class="weergave">
				Vak Sortering:
				<dt-form-dropdown
					[opties]="sorteringKeuzeOpties"
					[selectedOptie]="getSelectedSorteringOptie()"
					(onOptieClicked)="qp.dispatch('vakOrder', $event)"
				></dt-form-dropdown>
			</div>
		</div>
		<app-pivot-table
			[filters]="filterExpressions"
			[permanentFilters]="[]"
			[defaultGroups]="defaultGroups"
			[tableGroups]="getFirstGroup(groups)"
			[groups]="getOtherGroups(groups)"
			[subgroups]="subgroups"
			[columnSort]="sortColumn"
			[config]="this"
		></app-pivot-table>
	</section>
</app-dashboard-container>
