import { Component } from '@angular/core';
import { TableCellComponent } from '../../table/table.model';

@Component({
	selector: 'app-multiline-cell',
	templateUrl: './multiline-cell.component.html',
	styleUrls: ['./multiline-cell.component.scss'],
	standalone: true,
})
export class MultilineCellComponent implements TableCellComponent<string[]> {
	data!: string[];
	className?: string;

	getExportValue(data: string[]) {
		return data.join('\n');
	}
}
