<ul class="option-list">
	@for (option of options; track option) {
		<li
			class="option {{ option.style }}"
			[class.selected]="isSelected(option)"
			[class.blocked]="isBlocked(option)"
			(click)="onSelected(option)"
			[title]="isBlocked(option) ? blockedHover ?? '' : ''"
		>
			<span class="label">{{ option.text }}</span>
		</li>
	}
</ul>
