import { last } from 'lodash-es';
import { _NULL2null, ALL_STRING, DataTree, unnest } from './data-tree';

const PIVOT_STRING = '_PIVOT';

export type EdgePath<A> = [Edge<A>[], (string | null)[], number];

export interface DataNode {
	id: string;
	k: string | null;
}

export interface Edge<A> {
	id: string;
	p: DataNode; // parent node
	c: DataNode; // child node
	r: EdgePath<A>[]; // all paths running over this edge
	a: A; // data aggregated from leaves
}

export interface DataGraph<A> {
	nodes: Map<string, DataNode>;
	edges: Map<string, Edge<A>>;
}

/**
 * Maakt in de basis van een boom een gerichte graaf:
 *
 * root _ A __ B __ C                  root _ A _
 *    |          \_ D                     |      \
 *    |                       ==>         |      |    _ C _
 *    \_______ B __ D                     \_____ B __/_ D _\_ toor
 *               \_ E                                \_ E _/
 *
 * door nodes die verschillend zijn in de boom te mappen naar eenzelfde node in de graaf. Dit gebeurt op basis van "node id". In het bovenstaande
 * voorbeeld zijn er meerdere nodes met id's B en D; zoals te zien hoeven ze niet op hetzelfde niveau in de boom te zitten. (Verder komt het node id
 * hier overeen met de key in de boom; i.h.a. hoeft dat niet maar kan het door een functie bepaald worden.)
 * Ook wordt er een node "toor" toegevoegd, het tegenovergestelde van root, waarin alle paden weer samenkomen.
 *
 * Een edge bevat in zijn datastructuur (in het "r" veld) een lijstje met alle paden die over deze edge lopen: edge B-D bevat paden
 *
 * root-A-B-D-toor
 * root-B-D-toor
 *
 * Deze paden bestaan zelf weer uit edges, resp.
 *
 * root-A, A-B, B-D, D-toor
 * root-B, B-D, D-toor.
 *
 * In feite is de input-boom uitgebreider; onder elk van de leaves C,D,E hangt nog een niveau _PIVOT met daaronder weer een boom. De paden uit deze
 * boom worden geconcateneerd aan het pad tot de _PIVOT en op die manier meegenomen in het tweede element van EdgePath.
 */
export function makeGraph(tree: DataTree<number[]>) {
	const nodes = new Map<string, DataNode>();
	const edges = new Map<string, Edge<number>>();
	const root = getOrCreate(nodes, 'root', () => ({ id: 'root', k: 'root' }));
	conflue(tree, [[], [], 0], root, false, nodes, edges);
	return { nodes, edges };
}

/**
 * Een node direct onder de root stelt het eerste schooljaar van een doorstroompad voor. Het heeft bijv. "2018/2019" als key in de tree,
 * en krijgt dit ook als id in de graph.
 * Alle andere nodes stellen een leerfase voor in de verschillende opeenvolgende schooljaren. Key "HAVO 2" in de tree direct onder "2018/2019"
 * krijgt "2018:HAVO 2" als id in de graph.
 * De node in de boom onder 2017/2018 -> HAVO 1 -> HAVO 2 krijgt ook als id "2018:HAVO 2" en wordt daarmee dus dezelfde node in de graph.
 */
function getOrCreatePlaatsingNode(nodes: Map<string, DataNode>, path: EdgePath<number>, k: string | null): DataNode {
	const edges = path[0];
	const eersteJaar = edges[0]?.c.id?.substring(0, 4);
	const depth = eersteJaar == undefined ? 0 : edges.length - 1;
	const id = eersteJaar == undefined ? `${k}` : `${Number(eersteJaar) + depth}:${k}`;
	return getOrCreate(nodes, id, () => ({ id, k }));
}

function getOrCreateEdge(edges: Map<string, Edge<number>>, p: DataNode, c: DataNode): Edge<number> {
	const id = edgeId(p, c);
	return getOrCreate(edges, id, () => ({ id, p, c, r: [], a: 0 }));
}

function conflue(
	tree: DataTree<number[]>,
	path: EdgePath<number>,
	source: DataNode,
	pivot: boolean,
	nodes: Map<string, DataNode>,
	edges: Map<string, Edge<number>>
): [EdgePath<number>[], number] {
	let m = 0;
	const paths: EdgePath<number>[] = [];

	if (pivot) {
		const stackrecs = unnest(tree);
		const toor = getOrCreate(nodes, 'toor', () => ({ id: 'toor', k: 'toor' }));
		const toToor = getOrCreateEdge(edges, source, toor);

		for (const rec of stackrecs) {
			const count = (<number[]>last(rec)!.v)[0];
			m += count;
			const resultPath: EdgePath<number> = [[...path[0], toToor], [...path[1], ...rec.slice(1).map((l) => l.k)], count];
			paths.push(resultPath);
		}

		toToor.r.push(...paths);
		toToor.a += m;
	} else {
		for (const [k, v] of (<Map<string, DataTree<number[]>>>tree).entries()) {
			let newSource;
			let newPath: EdgePath<number>;
			if (k === PIVOT_STRING) {
				newPath = path;
				newSource = source;
			} else if (k === ALL_STRING) {
				continue;
			} else {
				newSource = getOrCreatePlaatsingNode(nodes, path, _NULL2null(k));
				const thisLink = getOrCreateEdge(edges, source, newSource);
				newPath = [[...path[0], thisLink], path[1], 0];
			}

			const [vresult, vm] = conflue(v, newPath, newSource, k === PIVOT_STRING, nodes, edges);
			paths.push(...vresult);
			m += vm;
		}

		if (path[0].length > 0) {
			last(path[0])!.r.push(...paths);
			last(path[0])!.a += m;
		}
	}

	return [paths, m];
}

export function edgeId(p: DataNode, c: DataNode) {
	return `${p.id}:${c.id}`;
}

export function getOrCreate<K, V>(map: Map<K, V>, key: K, create: () => V): V {
	if (map.has(key)) return <V>map.get(key);
	const val = create();
	map.set(key, val);
	return val;
}
