import { Component, Input } from '@angular/core';
import { TableCellComponent } from '../../../shared/components/table/table/table.model';
import { PxPipe } from '@cumlaude/shared-pipes';

export interface SinglePercentielHbarData {
	percentiel: number | null; // 0 - 100
	heightPx: number;
	widthPx: number;
}

@Component({
	selector: 'app-single-percentiel-hbar',
	templateUrl: './single-percentiel-hbar.component.html',
	styleUrls: ['./single-percentiel-hbar.component.scss'],
	standalone: true,
	imports: [PxPipe],
})
export class SinglePercentielHbarComponent implements TableCellComponent<SinglePercentielHbarData> {
	@Input()
	data!: SinglePercentielHbarData;

	getViewBox() {
		return `-5 0 ${this.data.widthPx + 10} ${this.data.heightPx}`;
	}

	getCx() {
		if (this.data.percentiel === null) return null;

		return (this.data.widthPx * this.data.percentiel) / 100;
	}
}
