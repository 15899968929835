/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum UitstroomIqStatus {
  INFORMATIE_VOLLEDIG = 'Informatie volledig',
  ONBEKENDE_IQ_SCORE = 'Onbekende IQ-score',
  UITSTROOMNIVEAU_NIET_AF_TE_LEIDEN = 'Uitstroomniveau niet af te leiden',
  ONBEKENDE_IQ_SCORE_EN_UITSTROOMNIVEAU_NIET_AF_TE_LEIDEN = 'Onbekende IQ-score en uitstroomniveau niet af te leiden'
}
