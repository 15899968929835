@if (data) {
	<a (click)="data.callback()">
		@if (data.icon) {
			<i [class]="data.icon"></i>
		}
		@if (data.title) {
			<span>{{ data.title }}</span>
		}
	</a>
}
