import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { Directive, inject, Input, OnChanges, OnDestroy } from '@angular/core';
import { Attributes, BaseDashboardConfig } from './base-dashboard-config';
import { Router } from '@angular/router';
import { UrlService } from '../../../services/url.service';
import { AttrPath, CompoundFilterExpression, DataResponse, FilterExpression } from '../../../services/data.service';
import { isUndefined } from 'lodash-es';
import { DashboardContext, isHappyContext, parseErrorResponse } from './dashboard-context';
import { ErrorMessage } from '@cumlaude/metadata';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Level, PartialPath, unnest } from '../../../services/data-tree';
import { getInitialAttributes } from '../data-tree-table/data-tree-table';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { BugsnagService } from '@cumlaude/bugsnag';
import { QueryParamStateService } from '../../../services/query-param-state.service';

@Directive()
export abstract class BaseDashboardComponent<I extends Attributes, A extends Attributes, C extends BaseDashboardConfig<I, A>>
	implements OnChanges, OnDestroy
{
	@Input()
	tableGroups: AttrPath[] | undefined;

	/**
	 * Ingestelde groeperingen. Deze kunnen door de gebruiker zijn ingesteld of fixed door dit dashboard,
	 * of een combinatie van die 2.
	 */
	@Input()
	groups: AttrPath[] = [];

	/**
	 * Default groeperingen van het dashboard
	 */
	@Input()
	defaultGroups!: AttrPath[];

	/**
	 * Subgroups zijn groeperingen die op 1 rij in de tabel worden weergegeven. Measures worden wel voor iedere
	 * waarde van zo'n subgroup bepaald/uitgerekend.
	 */
	@Input()
	subgroups: AttrPath[] = [];

	/**
	 * Ingestelde filters. Deze kunnen door de gebruiker zijn ingesteld of fixed door dit dashboard,
	 * of een combinatie van die 2.
	 */
	@Input()
	filters?: FilterExpression[];

	/**
	 * Permanent ingestelde filters. Deze worden bepaald door het dashboard en kunnen niet gezien of aangepast worden door de gebruiker.
	 */
	@Input()
	permanentFilters: FilterExpression[] = [];

	/**
	 * Configuratie voor dashboard type.
	 */
	@Input()
	config!: C;

	/**
	 * Het kan voorkomen dat bijv. de groups en de subgroups tegelijk veranderen. Om te voorkomen dat er het component
	 * zichzelf dan 2x update (2 requests naar de backend) worden alle (veranderlijke) inputs nu in 1 observable gebundeld.
	 */
	protected inputs$ = new ReplaySubject<[AttrPath[], CompoundFilterExpression, AttrPath[]]>(1);

	protected dashboardContext$ = new Subject<DashboardContext<I, A, C> | ErrorMessage>();

	protected subscriptions: Subscription[] = [];

	protected userGroups?: AttrPath[];

	protected readonly envConfig: EnvConfiguration = inject(ENV_CONFIG);

	private bugsnag: BugsnagService = inject(BugsnagService);

	protected qp: QueryParamStateService = inject(QueryParamStateService);

	protected constructor(
		protected router: Router,
		protected urlService: UrlService
	) {
		this.subscriptions.push(
			this.qp.observe_g().subscribe((userGroups) => (this.userGroups = userGroups)),
			this.inputs$
				.pipe(
					switchMap(([g, f, s]) => {
						let groups = [...g, ...s];
						if (!isUndefined(this.tableGroups)) groups = [...this.tableGroups, ...groups];

						return this.config
							.getData({
								g: groups,
								f,
							})
							.pipe(
								map((resp) => this.createDashboardContext(resp, f)),
								catchError((response) => parseErrorResponse(response, this.bugsnag))
							);
					})
				)
				.subscribe((context) => {
					this.dashboardContext$.next(context);
				}),
			this.dashboardContext$.subscribe((context) => {
				if (isHappyContext(context)) this.config.onContextCreated(context);
			})
		);
	}

	ngOnChanges(): void {
		if (this.filters === undefined) return;

		const filters = new CompoundFilterExpression([...this.permanentFilters, ...this.filters]);
		this.inputs$.next([this.groups, filters, this.subgroups]);
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	protected createDashboardContext(resp: DataResponse<number[]>, f: FilterExpression): DashboardContext<I, A, C> {
		const { groupNames, subgroupNames, measureNames } = this.getNames(resp);
		const ngroups = groupNames.length;
		const { aggInit, aggCombine } = createAggFunctions<I, A, C>(this.config, subgroupNames, measureNames);

		const records = unnest<A, number[]>(resp.data, undefined, ngroups, aggInit, aggCombine);
		const dataRoot = records[0]?.[0];
		const orderRoot = resp.order ? unnest<unknown, []>(resp.order)[0]?.[0] : undefined;
		return {
			config: this.copyConfig(this.config),
			filter: f,
			tableRootIndex: <0 | 1 | 3>this.tableGroups?.length ?? 0,
			groupNames,
			subgroupNames,
			measureNames,
			dataRoot,
			aggCombine,
			orderRoot,
		};
	}

	protected getNames(resp: DataResponse<number[]>) {
		// haal de groups op uit de metadata van de response, want de groups die de gebruiker heeft aangeklikt
		// kunnen ondertussen weer gewijzigd zijn
		const groupNames = resp.g.slice(0, resp.g.length - this.subgroups.length);
		const subgroupNames = resp.g.slice(resp.g.length - this.subgroups.length);
		const measureNames = resp.measures;
		return { groupNames, subgroupNames, measureNames };
	}

	/**
	 * Maakt een kopie van de configuratie zodat de functies werken met de variabel waardes die op dat moment relevant zijn.
	 */
	private copyConfig(config: C) {
		return Object.assign(Object.create(Object.getPrototypeOf(config)), config);
	}
}

export function createAggFunctions<I extends Attributes, A extends Attributes, C extends BaseDashboardConfig<I, A>>(
	config: C,
	subgroupNames: string[],
	measureNames: string[]
) {
	const singleAggregators = config.getSingleAggregators();
	const multiAggregators = config.getMultiAggregators();

	// construeer het "a" object op het diepste data-niveau (1 gekleurd balkje)
	function aggInit(data: number[], path: PartialPath<A, number[]>): A {
		const initialAttributes = getInitialAttributes<I>(subgroupNames, measureNames, path);
		const ret: A = <A>{};
		for (const attName in singleAggregators) {
			ret[attName] = singleAggregators[attName]!.init(
				initialAttributes,
				path.slice(1).map((level) => level.k)
			);
		}
		for (const aggregator of multiAggregators) {
			ret[aggregator.attribute] = aggregator.init(
				initialAttributes,
				path.slice(1).map((level) => level.k)
			);
		}
		return ret;
	}

	// aggregeer de "a" measures, werkt zowel binnen de barchart als binnen de tabel
	function aggCombine(c: Level<A, number[]>[], path: PartialPath<A, number[]>, all: A | undefined, allFiltered: A | undefined): A {
		const ret: A = <A>{};

		const childrenAttributes = c.map((child) => child.a);
		const keys = path.slice(1).map((level) => level.k);

		for (const attName in singleAggregators) {
			ret[attName] = singleAggregators[attName]!.combine(
				childrenAttributes.map((ca) => ca[attName]),
				keys,
				all?.[attName],
				allFiltered?.[attName]
			);
		}
		for (const aggregator of multiAggregators) {
			ret[aggregator.attribute] = aggregator.combine(
				childrenAttributes,
				keys,
				all?.[aggregator.attribute],
				allFiltered?.[aggregator.attribute]
			);
		}

		return ret;
	}

	return { aggInit, aggCombine };
}
