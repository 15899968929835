// uit connect

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[appContentHost]',
	standalone: true,
})
export class ContentHostDirective {
	constructor(public viewContainerRef: ViewContainerRef) {}
}
