<div class="column">
	<div class="panel user-info">
		<div class="panel__header">
			<span class="label">Welkom, {{ getName() }}!</span>
		</div>
		<div class="user-info__content">
			@if (options | async; as opts) {
				@if (opts.length > 0) {
					<app-toggle-buttons [options]="opts" [labelFunction]="getRolName" [value]="actieveRol" (valueChange)="changeRol($event)">
					</app-toggle-buttons>
				}
			}
			<div class="details">
				<app-pasfoto size="size-3-5" [pasfotoKey]="account.afkorting"></app-pasfoto>
				<div class="details__info">
					<span class="title">{{ getName() }}</span>
					<span class="content">{{ getRolDescription(actieveRol) }}</span>
				</div>
			</div>
		</div>
	</div>
	@if (gppService.list$ | async; as gedeeldePaginaPersonen) {
		<div class="panel gedeelde-paginas">
			<div class="panel__header">
				<div class="label">Inbox</div>
				<div class="aantal">{{ gedeeldePaginaPersonen.length }}</div>
			</div>
			@if (gedeeldePaginaPersonen.length > 0) {
				<div class="inbox">
					@for (gpp of gedeeldePaginaPersonen; track gpp) {
						<app-gedeelde-pagina-persoon [value]="gpp"> </app-gedeelde-pagina-persoon>
					}
				</div>
			} @else {
				<div class="no-messages">Geen berichten</div>
			}
		</div>
	}
</div>
<div class="column">
	<div class="panel favorieten">
		<div class="panel__header">
			<span class="label">Favorieten</span>
			<span class="svg-add-folder" (click)="createFavorietMap.next()"></span>
		</div>
		<div class="favorieten__content">
			<app-favorieten-list [createMap]="createFavorietMap"></app-favorieten-list>
		</div>
	</div>
</div>
