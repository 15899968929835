<div class="menu">
	<app-tab-menu></app-tab-menu>
</div>
<div class="content">
	<div class="uitleg">
		Hier kun je de schooleigen norm van het dashboard Basisvaardigheden instellen. Hierdoor kun je de resultaten van jouw school vergelijken met
		de gestelde doelen.
		<br />
		De ingestelde normen worden direct toegepast en zichtbaar op het dashboard Basisvaardigheden.
	</div>
	<div class="toolbar">
		@if (vestigingOpties$ | async; as opties) {
			<div class="selector vestiging">
				<span>Vestiging</span>
				<app-multi-select-dropdown
					[allOptions]="opties"
					(valueChange)="selecteerVestigingen($event)"
					[enabled]="!dirty()"
				></app-multi-select-dropdown>
			</div>
		}
		<div class="selector schooljaar">
			<span>Schooljaar</span>
			<app-multi-select-dropdown
				[allOptions]="schooljaarOpties"
				[value]="schooljaren()"
				(valueChange)="schooljaren.set($event)"
				[enabled]="!dirty()"
			></app-multi-select-dropdown>
		</div>
		<div class="buttons">
			<app-button buttonType="secondary" [enabled]="dirty()" (clickit)="revert()">Annuleren</app-button>
			<app-button [enabled]="dirty()" (clickit)="trySave()">Opslaan</app-button>
		</div>
	</div>
	@if (overwriteWarning()) {
		<div class="warning-message">
			<div class="svg-warning"></div>
			<div>{{ overwriteWarning() }}</div>
		</div>
	}
	@if (showErrors() && !correct()) {
		<div class="error-message">
			<div class="svg-error"></div>
			<div>De percentages tellen niet op tot 100%. Controleer de ingevoerde waarden en probeer het opnieuw.</div>
		</div>
	}
	<div class="tabel">
		@if (table()) {
			<div class="header row">
				<div class="leerfase">Leerfase</div>
				@for (niveau of niveaus(); track niveau) {
					<div [class]="'niv' + $index">{{ niveau }}</div>
				}
				<div class="accent">Verdeeld</div>
				<div class="accent">Over</div>
			</div>
			@for (row of table()!.rows; track row.leerfase; let rowIx = $index) {
				<div class="row" [class.error]="showErrors() && incorrectRows().includes(rowIx)">
					<div class="leerfase">{{ row.leerfase }}</div>
					@for (doel of row.doelen; track colIx; let colIx = $index) {
						<input
							type="text"
							[attr.id]="getInputId(rowIx, colIx)"
							maxlength="3"
							[value]="fractionToText(doel)"
							(input)="updateTable(rowIx, colIx, $event)"
							(keydown)="onKeydown(rowIx, colIx, $event)"
							[class.invalid]="!isValid(doel)"
							[appTooltip]="!isValid(doel) ? 'Dit veld mag alleen cijfers bevatten tussen 0 en 100.' : undefined"
							tooltipClass="error"
							tooltipPosition="right"
						/>
					}
					<div class="accent">{{ fractionToText(totals()[rowIx]) }} %</div>
					<div class="accent">{{ fractionToText(1 - totals()[rowIx]) }} %</div>
				</div>
			}
		}
	</div>
</div>

<ng-template #confirmLeaveDialog let-data>
	<app-confirm-dialog caption="Weet je het zeker?" action="Wijzigingen opslaan" secondaryAction="Wijzigingen annuleren">
		De laatste wijzigingen zijn nog niet opgeslagen en zullen verloren gaan.
	</app-confirm-dialog>
</ng-template>
