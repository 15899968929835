import { Component, computed, input, Signal, signal, ViewChild, WritableSignal } from '@angular/core';
import { DetailsDashboard } from '../../base-details-panel/details.dashboard';
import { LeerlingDetail } from '../../Details';
import { combineLatest, lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import {
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataService,
	ExportDataOptions,
	ExportFilter,
} from '../../../services/data.service';
import { ExportOptions } from '../../../services/export.service';
import { Dashboard } from '../../../shared/dashboard/base-dashboard/dashboard';
import { FilterService } from '../../../services/filter.service';
import { FactTable } from '../../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { UrlService } from '../../../services/url.service';
import { LegendaComponent } from '../../../legenda/legenda.component';
import { ScoreData, ScoreLinechartComponent, ScoreLinechartData } from '../score-linechart/score-linechart.component';
import { RouterLink } from '@angular/router';
import { toObservable } from '@angular/core/rxjs-interop';
import { FacBvBasisvaardigheden, Vaardigheid } from '@cumlaude/metadata';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { DisplayService } from '../../../services/display.service';
import { vaardigheden, vaardigheid2route } from '../../../services/vaardigheden';
import { ReferentieNiveau } from '../../../services/label-enums';
import { Level, unnest } from '../../../services/data-tree';
import { lookupByKeys } from '../../../services/tabulate';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { ExportColumnDef, ExportTable } from '../../../shared/dashboard/data-tree-table/data-tree-table';
import { DashboardHeaderComponent } from '../../../dashboard-header/dashboard-header.component';

type AfnameMetStats = FacBvBasisvaardigheden & { avg: number | null; lln: number };

@Component({
	selector: 'app-leerling-basisvaardigheden-details-panel',
	templateUrl: './leerling-basisvaardigheden-details-panel.component.html',
	styleUrls: ['./leerling-basisvaardigheden-details-panel.component.scss'],
	standalone: true,
	imports: [LegendaComponent, RouterLink, ScoreLinechartComponent],
})
export class LeerlingBasisvaardighedenDetailsPanelComponent extends Dashboard implements DetailsDashboard<LeerlingDetail> {
	nr_leerling$ = new ReplaySubject<number>(1);

	bv_nm_vaardigheid = input.required<Vaardigheid>();

	legendaExclude = computed(() => {
		const vaardigheid = this.bv_nm_vaardigheid();
		return vaardigheid === 'Rekenen'
			? [ReferentieNiveau.R35F, ReferentieNiveau.R40F, ReferentieNiveau.NIVEAU_GEMIDDELDE]
			: [ReferentieNiveau.NIVEAU_GEMIDDELDE];
	});

	vaardigheden = vaardigheden;

	afnames: WritableSignal<AfnameMetStats[] | undefined> = signal(undefined);

	chartData: Signal<ScoreLinechartData | undefined> = computed(() => {
		const afnames = this.afnames();
		if (afnames === undefined) return undefined;

		return {
			hoogsteNiveau: this.bv_nm_vaardigheid() === 'Rekenen' ? 3 : 4,
			scores: afnames.map(this.getScoreData.bind(this)),
		};
	});

	dashboardName!: string;

	@ViewChild(LegendaComponent)
	legendaComponent?: LegendaComponent;

	constructor(
		private dataService: DataService,
		private displayService: DisplayService,
		protected filterService: FilterService,
		protected toastr: ToastrService,
		urlService: UrlService
	) {
		super(filterService, toastr);
		const data$ = combineLatest([this.nr_leerling$, toObservable(this.bv_nm_vaardigheid)]).pipe(
			switchMap(([nr_leerling, bv_nm_vaardigheid]) =>
				dataService.getLeerlingBasisvaardigheden(nr_leerling, bv_nm_vaardigheid).pipe(
					switchMap((llAfnames) => this.fetchGroupStats(llAfnames).pipe(map((gemTree) => ({ llAfnames, gemTree })))),
					startWith({ llAfnames: undefined, gemTree: undefined })
				)
			)
		);

		this.subscriptions.push(
			data$.subscribe(({ llAfnames, gemTree }) => {
				const afnamesMetStats = llAfnames?.map((afname) => {
					const { avg, lln } = lookupByKeys(gemTree!, [afname.bv_nm_schooljaar!, afname.bv_nm_leerfase!, afname.bv_nm_toetsmoment!])!.a;
					return { ...afname, avg, lln };
				});
				this.afnames.set(afnamesMetStats?.filter((afname) => afname.bv_nr_referentieniveau !== null));
			}),
			urlService.routeData$.subscribe((routeData) => (this.dashboardName = routeData.title))
		);
	}

	factTable = FactTable.basisvaardigheden;

	/**
	 * Haalt middels een apart data request de groepsstatistieken op die horen bij de gegeven toetsafnames
	 * (d.w.z. met hetzelfde schooljaar, leerfase, toetsmoment en vaardigheid, maar dan over alle leerlingen).
	 * Resultaat is een data-tree met in de "a" (op de leaves) een object met de bv_nr_referentieniveau (gemiddelde) en count_records measures uit de backend.
	 */
	fetchGroupStats(llAfnames: FacBvBasisvaardigheden[]): Observable<Level<{ lln: number; avg: number | null }, unknown> | undefined> {
		const g: AttrPath[] = [['bv_nm_schooljaar'], ['bv_nm_leerfase'], ['bv_nm_toetsmoment']];
		const groepFilters = llAfnames.map(
			({ bv_nm_schooljaar, bv_nm_leerfase, bv_nm_toetsmoment }) =>
				new CompoundFilterExpression([
					new BasicFilterExpression(['bv_nm_schooljaar'], bv_nm_schooljaar),
					new BasicFilterExpression(['bv_nm_leerfase'], bv_nm_leerfase),
					new BasicFilterExpression(['bv_nm_toetsmoment'], bv_nm_toetsmoment),
					new BasicFilterExpression(['bv_nm_vaardigheid'], this.bv_nm_vaardigheid()),
				])
		);
		const f = new CompoundFilterExpression(groepFilters, 'or');
		return this.dataService.getBasisvaardighedenData({ g, f }).pipe(
			map((resp) => {
				const avgIndex = resp.measures.indexOf('bv_nr_referentieniveau');
				const countIndex = resp.measures.indexOf('count_records');
				const aggInit = (v: number[]) => ({
					avg: v[avgIndex],
					lln: v[countIndex],
				});
				return unnest(resp.data, undefined, undefined, aggInit)[0]?.[0];
			})
		);
	}

	getScoreData(afname: AfnameMetStats): ScoreData {
		const { bv_nm_leerfase, bv_nr_referentieniveau, bv_d_afname, bv_nm_referentieniveau, bv_nm_toetsmoment, avg, lln } = afname;
		const tooltip: TooltipElement[] = [
			{ label: 'Datum', value: this.displayService.display(bv_d_afname, ['bv_d_afname']) },
			{ label: 'Toetsmoment', value: this.displayService.display(bv_nm_toetsmoment) },
			{ label: 'Referentieniveau leerling', value: this.displayService.display(bv_nm_referentieniveau) },
			{ label: `Gemiddelde alle ${bv_nm_leerfase}-leerlingen`, value: this.getNiveauGemiddeldeLabel(avg) },
			{ label: '# lln', value: this.displayService.display(lln) },
		];
		return { label: bv_nm_leerfase!, qty: bv_nr_referentieniveau!, avg, tooltip };
	}

	exportAsTable(options: ExportDataOptions): Observable<Blob> {
		const columns: (ExportColumnDef & { getValue: (afname: AfnameMetStats) => any })[] = [
			{ name: 'Leerfase', type: 'string', getValue: (afname: AfnameMetStats) => afname.bv_nm_leerfase },
			{ name: 'Datum', type: 'date', getValue: (afname: AfnameMetStats) => afname.bv_d_afname },
			{ name: 'Toetsmoment', type: 'string', getValue: (afname: AfnameMetStats) => afname.bv_nm_toetsmoment },
			{ name: 'Referentieniveau leerling', type: 'string', getValue: (afname: AfnameMetStats) => afname.bv_nm_referentieniveau },
			{ name: 'Leerfase-gemiddelde', type: 'string', getValue: (afname: AfnameMetStats) => this.getNiveauGemiddeldeLabel(afname.avg) },
			{ name: '# lln', type: 'string', getValue: (afname: AfnameMetStats) => afname.lln },
		];
		const table: ExportTable = {
			data: [{ columns, rows: this.afnames()!.map((afname) => columns.map((col) => col.getValue(afname))) }],
			options,
		};
		return this.dataService.getTableExport(table);
	}

	getNiveauGemiddeldeLabel(gem: number | null) {
		if (gem === null) return '-';

		return `${formatDecimal(gem, '1.1-1')}F`;
	}

	doDetailsExport(exportOptions: ExportOptions) {
		lastValueFrom(this.nr_leerling$.pipe(take(1))).then((nr) => {
			const filters = [
				new BasicFilterExpression(['bv_nr_leerling'], nr),
				new BasicFilterExpression(['bv_nm_vaardigheid'], this.bv_nm_vaardigheid()),
			];
			this.doExport([], filters, [], exportOptions);
		});
	}

	getExportData(options: ExportDataOptions): Observable<Blob> {
		return this.dataService.getBasisvaardighedenExportData(options);
	}

	setSelected(selected: LeerlingDetail): void {
		this.nr_leerling$.next(selected.lb_nr_leerling);
	}

	getRouterLink(vaardigheid: Vaardigheid) {
		return `../${vaardigheid2route(vaardigheid)}`;
	}

	protected getExportFilters(_exportFilters: ExportFilter[]): ExportFilter[] {
		// De gebruiker kan geen filters instellen op het Leerling Details Basisvaardigheden dashboard, dus zijn ook niet relevant om te exporteren
		return [];
	}
}
