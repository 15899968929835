<app-base-dialog [sizeClass]="'size-44'" [topBarTemplate]="topBar" [bottomBarTemplate]="bottomBar">
	<ng-template #topBar>
		<app-dialog-header title="Inbox"></app-dialog-header>
	</ng-template>
	@if (!isEnabled(data.gedeeldePaginaPersoon)) {
		<div class="warning">
			<div class="svg-alert"></div>
			<div class="warning-text">
				Je kunt de gedeelde pagina niet openen of als favoriet opslaan omdat je geen toegang hebt tot het desbetreffende overzicht. Neem
				contact op met je applicatiebeheerder om de juiste rechten aan te vragen.
			</div>
		</div>
	}
	<div class="item">
		<div class="column">
			<div class="small-values">
				<div class="text label">Datum</div>
				<div class="text label">Afzender</div>
				<div class="text label">Overzicht</div>
			</div>
			@if (data.gedeeldePaginaPersoon.bericht) {
				<div class="text label">Bericht</div>
			}
		</div>
		<div class="column values">
			<div class="small-values">
				<div class="text favoriet">
					<div>
						{{ data.gedeeldePaginaPersoon.verzonden | date: 'dd-MM-yyyy' }}
					</div>
					<div
						class="favoriet-toevoegen"
						[class.enabled]="isEnabled(data.gedeeldePaginaPersoon)"
						(click)="opslaanAlsFavoriet(data.gedeeldePaginaPersoon)"
					>
						<div [class]="isEnabled(data.gedeeldePaginaPersoon) ? 'svg-add-bookmark' : 'svg-add-bookmark-grey'"></div>
						<div class="text">Opslaan als favoriet</div>
					</div>
				</div>
				<div class="text">{{ data.gedeeldePaginaPersoon.verzender?.naam }}</div>
				<div class="text">{{ data.gedeeldePaginaPersoon.dashboard }}</div>
			</div>
			@if (data.gedeeldePaginaPersoon.bericht) {
				<div class="text bericht">{{ data.gedeeldePaginaPersoon.bericht }}</div>
			}
		</div>
	</div>
	<ng-template #bottomBar>
		<app-button buttonType="secondary" (clickit)="dialogRef.close()">Sluiten</app-button>
		<app-button [enabled]="isEnabled(data.gedeeldePaginaPersoon)" (clickit)="openOverzicht(data.gedeeldePaginaPersoon)">
			Open overzicht
		</app-button>
	</ng-template>
</app-base-dialog>
