/**
 * Atts / AttrPaths die eindigen op deze attributen worden niet getoond als filter/groepering als de school geen VSO vestigingen heeft.
 */
import { Attr } from './data.service';
import { ExtraFilterNames } from './filter-config';

export const geenVSOBlocklist: (Attr | ExtraFilterNames)[] = [
	'ds_nm_einduitstroom_vso',
	'ds_nm_einduitstroom_vso_uitzondering',
	'ds_nm_prestatieanalyse_vso_uitzondering',
	'ds_nm_tussentijdse_uitstroom_vso',
	'ds_nm_uitstroomprofiel_vso_van',
	'll_nr_uitstroompunt',
	'lb_nm_uitstroomprofiel_vso',
	'pv_d_geldig_tm',
	'pv_d_geldig_va',
	'pv_co_brinvest',
	'pv_fun_vest_brin',
	'pv_nm_indicator',
	'pv_nm_schooljaar',
	'pv_nm_vergelijkgroep',
	'pv_nr_score',
];
