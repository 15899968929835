import { Attribute, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	standalone: true,
})
export class ButtonComponent {
	@Input()
	icon: string | undefined;

	@Input()
	enabled = true;

	@Input()
	ctrlClickEnabled = false;

	@Output()
	clickit: EventEmitter<void> = new EventEmitter<void>();

	@Output()
	clickitCtrl: EventEmitter<void> = new EventEmitter<void>();

	iconRight: boolean;

	@Input()
	buttonType: 'primary' | 'secondary' | 'text' = 'primary';

	constructor(@Attribute('icon-right') iconRight: string) {
		this.iconRight = iconRight !== null;
	}

	click(event: MouseEvent) {
		if (this.ctrlClickEnabled && (event.ctrlKey || event.metaKey)) this.clickitCtrl.emit();
		else this.clickit.emit();
	}
}
