import { Component, EventEmitter, Inject, signal } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Option } from '@cumlaude/shared-components-inputs';
import { includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';

export interface SingleSelectDropdownData<T> {
	opties: Option<T>[];
	selectedOptie?: Option<T>;
	showEmpty: string;
	showSearchInput: boolean;
}

@Component({
	selector: 'app-single-select-dropdown-dialog',
	templateUrl: './single-select-dropdown-dialog.component.html',
	styleUrls: ['./single-select-dropdown-dialog.component.scss'],
	standalone: true,
	imports: [],
})
export class SingleSelectDropdownDialogComponent<T> {
	searchInput = signal('');

	onSearchInput = new EventEmitter<string>();

	constructor(
		public dialogRef: DialogRef<Option<T>>,
		@Inject(DIALOG_DATA) protected data: SingleSelectDropdownData<T>
	) {}

	search($event: string) {
		this.onSearchInput.next($event);
	}

	getOptions(): Option<T>[] {
		return this.data.opties.filter((optie) => this.matchesSearch(optie));
	}

	private matchesSearch(value: Option<T>): boolean {
		const searchInput = this.searchInput();
		if (searchInput == undefined) return true;
		return includesIgnoreCaseAndDiacritics(value.text, searchInput);
	}

	isSelected(optie: Option<T>) {
		return optie.value === this.data.selectedOptie?.value;
	}

	clickOptie(clickedOptie: Option<T>) {
		this.dialogRef.close(clickedOptie);
	}
}
