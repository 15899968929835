import { computed, Injectable, Signal } from '@angular/core';
import { UserService } from './user.service';
import { switchMap } from 'rxjs';
import { DataService } from './data.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';

interface MedewerkerPasfoto {
	mw_abb_medewerker: string;
	mw_pic_pasfoto: string | null;
}

@Injectable({
	providedIn: 'root',
})
export class PasfotoService {
	private readonly pasfotos: Signal<MedewerkerPasfoto[] | undefined>;

	constructor(
		protected userService: UserService,
		protected dataService: DataService
	) {
		this.pasfotos = toSignal(
			this.userService.rolChanged$.pipe(
				switchMap(() => this.dataService.getMedewerkers<MedewerkerPasfoto>({ col: [['mw_abb_medewerker'], ['mw_pic_pasfoto']] })),
				map((response) => response.rows)
			)
		);
	}

	getPasfoto(pasfotoKey: string): Signal<string | null | undefined> {
		return computed(() => {
			const pasfotos = this.pasfotos();
			if (!pasfotos) return null;

			return pasfotos.find((pasfoto) => pasfoto.mw_abb_medewerker === pasfotoKey)?.mw_pic_pasfoto;
		});
	}
}
