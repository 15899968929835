import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
	selector: 'app-toggle-button',
	templateUrl: './toggle-button.component.html',
	styleUrls: ['./toggle-button.component.scss'],
	standalone: true,
	imports: [FormsModule],
})
export class ToggleButtonComponent {
	@Input()
	after: boolean = false;

	@Input()
	checked!: boolean;

	@Output()
	toggled = new EventEmitter<boolean>();
}
