import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { FilterExpression } from '../services/data.service';
import { FilterService } from '../services/filter.service';
import { FilterName } from '../services/filter-config';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { flatMap, sortBy, toPairs } from 'lodash-es';
import { Categorie } from '../services/exportable';
import { map } from 'rxjs/operators';
import { InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { AsyncPipe } from '@angular/common';
import { FilterContainerComponent } from './filter-container.component';
import { includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';

@Component({
	selector: 'app-filter-panel',
	templateUrl: './filter-panel.component.html',
	styleUrls: ['./filter-panel.component.scss'],
	standalone: true,
	imports: [FilterContainerComponent, InstantSearchBoxComponent, AsyncPipe],
})
export class FilterPanelComponent implements OnChanges, OnDestroy {
	@Input()
	defaultFilters!: FilterName[];

	@Input()
	allFilters!: Categorie[];

	@Input()
	permanentFilterExpressions!: FilterExpression[];

	@Input()
	overrideDefault?: Partial<{ [name in FilterName]: any }>;

	@Input()
	endpoint!: string;

	@Output()
	filterExpressionsChange = new EventEmitter<FilterExpression[]>();

	@ViewChild('searchBox')
	private readonly searchBox?: InstantSearchBoxComponent;

	defaultTabSelected = true;

	visibleDefaultFilters$: Observable<FilterName[]> = of();

	nonDefaultFilters$: Observable<[string, FilterName[]][]>;

	filterFilter$ = new BehaviorSubject('');

	private readonly subscriptions: Subscription[] = [];

	constructor(private readonly filterService: FilterService) {
		this.nonDefaultFilters$ = combineLatest([this.filterService.getRegularActiveFilters(), this.filterFilter$]).pipe(
			map(([active, filterFilter]) => {
				const activeNonDefault: FilterName[] = [];
				const pairs = toPairs(this.filterService.getNonDefaultFilters());
				const newPairs: [string, FilterName[]][] = pairs.map(([categorieLabel, atts]) => [
					categorieLabel,
					sortBy(
						flatMap(atts, (att) => {
							if (!this.matches(filterFilter, att, categorieLabel)) return [];
							if (!active.includes(att)) return [att];
							activeNonDefault.push(att);
							return [];
						}),
						(att) => this.filterService.configs[att]!.label
					),
				]);
				return [['Actieve filters', activeNonDefault], ...newPairs];
			})
		);

		this.subscriptions.push(
			this.filterService.filterPanelOpened$.subscribe((opened) => {
				if (opened) {
					this.defaultTabSelected = true;
					this.filterFilter$.next('');
				} else {
					this.filterService.clearTentative();
				}
			}),
			this.filterService.getFilterExpressions().subscribe((fex) => this.filterExpressionsChange.emit(fex))
		);
	}

	trackByCategory(index: number, cat: [string, FilterName[]]): string {
		return cat[0];
	}

	matches(filterFilter: string, att: FilterName, categorieLabel: string) {
		if (filterFilter === '') return true;

		const filterConfig = this.filterService.configs[att]!;
		return (
			includesIgnoreCaseAndDiacritics(filterConfig.label, filterFilter) ||
			filterConfig.searchKeys.some((searchKey) => includesIgnoreCaseAndDiacritics(searchKey, filterFilter)) ||
			includesIgnoreCaseAndDiacritics(categorieLabel, filterFilter)
		);
	}

	ngOnChanges(): void {
		this.filterService.endpoint = this.endpoint;
		this.filterService.permanentFilterExpressions = this.permanentFilterExpressions;
		this.filterService.setAvailableFilters(this.defaultFilters, this.allFilters, this.overrideDefault);
		this.visibleDefaultFilters$ = combineLatest(
			this.filterService.getDefaultFilters().map((f) => this.filterService.isVisible(f).pipe(map((v) => (v ? [f] : []))))
		).pipe(map((namesLists) => flatMap(namesLists)));
	}

	close() {
		this.filterService.filterPanelOpened$.next(false);
	}

	switchTab() {
		if (this.defaultTabSelected) {
			setTimeout(() => {
				this.searchBox!.focus();
			}, 50);
		} else {
			this.filterFilter$.next('');
			this.filterService.clearTentative();
		}
		this.defaultTabSelected = !this.defaultTabSelected;
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}
}
