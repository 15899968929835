import { Component, signal, ViewChild } from '@angular/core';
import { DetailsDashboard } from '../../base-details-panel/details.dashboard';
import { DashboardLeerling, LeerlingDetail } from '../../Details';
import {
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataService,
	FilterExpression,
	InFilterExpression,
} from '../../../services/data.service';
import { FilterService } from '../../../services/filter.service';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { intersection, last, memoize, sortBy, union } from 'lodash-es';
import { ToetsA, ToetsComponent, ToetsI } from '../../../dashboards/toets/toets.component';
import { FilterName } from '../../../services/filter-config';
import { map, take } from 'rxjs/operators';
import { DataRow, getInitialAttributes } from '../../../shared/dashboard/data-tree-table/data-tree-table';
import { ColumnDef, createColumnDef, createDefaultFooterCellDef, TableModel } from '../../../shared/components/table/table/table.model';
import { isFirstAtLevelTabulated, Level, Path } from '../../../services/data-tree';
import { MultilineCellComponent } from '../../../shared/components/table/cells/multiline-cell/multiline-cell.component';
import { MultiAggregator, SingleAggregator, sumOver, weightedAverage } from '../../../services/aggregation';
import { lastValueFrom, ReplaySubject } from 'rxjs';
import { DashboardContext } from '../../../shared/dashboard/base-dashboard/dashboard-context';
import { ExportOptions } from '../../../services/export.service';
import { ToastrService } from 'ngx-toastr';
import { CijferkolomKeuze, DashboardVariant, Dossier } from '../../../services/weergave-opties';
import { Cijferkolomtype } from '@cumlaude/metadata';
import { CijferlijstCellComponent } from '../../../shared/components/table/cells/cijferlijst-cell/cijferlijst-cell.component';
import { getSchooljaarKort } from '@cumlaude/shared-utils';
import { getCellPath, PivotTableComponent } from '../../../shared/dashboard/pivot-table/pivot-table.component';
import { PivotSeriesFooterCellComponent } from '../../../shared/components/table/cells/pivot-series-footer-cell/pivot-series-footer-cell.component';
import { LabelCellComponent } from '../../../shared/components/table/cells/label-cell/label-cell.component';
import { DataCellComponent } from '../../../shared/components/table/cells/data-cell/data-cell.component';
import { att } from '../../../services/measures';
import { LinkData } from '../../../shared/dashboard/base-dashboard/base-dashboard-config';
import { ToetsData } from '../../../shared/components/table/cells/toets-periode-cell/toets-periode-cell.component';
import { AsyncPipe } from '@angular/common';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { DashboardHeaderComponent } from '../../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../../filter-panel/filter-panel.component';
import { CijferlijstData } from '../../../services/cijfers';

@Component({
	selector: 'app-leerling-cijferlijst-details-panel',
	templateUrl: './leerling-cijferlijst-details-panel.component.html',
	styleUrls: ['./leerling-cijferlijst-details-panel.component.scss'],
	standalone: true,
	imports: [FilterPanelComponent, DashboardHeaderComponent, FormDropdownComponent, PivotTableComponent, AsyncPipe],
})
export class LeerlingCijferlijstDetailsPanelComponent extends ToetsComponent implements DetailsDashboard<LeerlingDetail> {
	leerling$ = new ReplaySubject<DashboardLeerling>(1);

	fixedBeforeGroups = 1;

	flexibleMaxGroups = 0;

	filterExpressions?: FilterExpression[];

	actueelFilters: FilterName[] = [
		'cf_fun_schooljaar_leerfase', //
		'x_cf_is_alternatievenormering',
		'x_details_periode',
		'cf_co_kolom',
		'cf_nm_vak',
	];

	historieFilters: FilterName[] = [
		'cf_fun_schooljaar_leerfase', // is onzichtbaar en filtert niet, maar is alleen om het schooljaar van de actueel-variant vast te houden
		'x_cf_is_alternatievenormering',
	];

	variant!: DashboardVariant;

	get actueelSubgroups() {
		return this.subgroups;
	}

	historieSubgroups: AttrPath[] = [['cf_nm_schooljaar'], ['cf_nm_klas'], ['cf_nm_kolomtype']];

	cijferkolomKeuzeOpties = signal<Option<CijferkolomKeuze>[]>(
		[CijferkolomKeuze.ALLE, CijferkolomKeuze.TOETS, CijferkolomKeuze.ADVIES, CijferkolomKeuze.GEMIDDELDE].map((val) => new Option(val))
	);

	@ViewChild(DashboardHeaderComponent)
	dashboardHeaderComponent?: DashboardHeaderComponent;

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(dataService, filterService, qp, toastr);
	}

	ngOnInit() {
		super.ngOnInit();
		this.subscriptions.push(
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.filterService.observe('cf_fun_schooljaar_leerfase').subscribe(([val]) => this.qp.dispatch('schooljaar', val.split(' ')[0]))
		);
	}

	createMeasureColumns(_context: DashboardContext<ToetsI, ToetsA, LeerlingCijferlijstDetailsPanelComponent>): ColumnDef<DataRow<ToetsA>>[] {
		return [];
	}

	createPivotColumns(
		columnRoot: Level<ToetsA, number[]>,
		context: DashboardContext<ToetsI, ToetsA, LeerlingCijferlijstDetailsPanelComponent>
	): ColumnDef<DataRow<ToetsA>>[] {
		if (this.variant === DashboardVariant.ACTUEEL) {
			return super.createPivotColumns(columnRoot, context);
		} else {
			return columnRoot.r.map((path) => this.createHistorieColumn(path, context));
		}
	}

	createLinkData(_path: Path<ToetsA, number[]>, _context: DashboardContext<ToetsI, ToetsA, ToetsComponent>): Partial<LinkData> {
		return {};
	}

	protected createPeriodeColumn(
		lvl: Level<ToetsA, number[]>,
		context: DashboardContext<ToetsI, ToetsA, LeerlingCijferlijstDetailsPanelComponent>
	): ColumnDef<DataRow<ToetsA>> {
		const columnDef = super.createPeriodeColumn(lvl, context);

		columnDef.extraFooter = createDefaultFooterCellDef();
		columnDef.extraFooter.component = PivotSeriesFooterCellComponent;
		columnDef.extraFooter.class = 'toets-cell';
		columnDef.extraFooter.format = '1.0-0';
		columnDef.extraFooter.getValue = () => mapAttForRapportcijferEnGemiddelde(lvl, 'sum_tekortpunten');

		columnDef.footer.component = PivotSeriesFooterCellComponent;
		columnDef.footer.class = 'toets-cell';
		columnDef.footer.format = '1.1-1';
		columnDef.footer.getValue = () => mapAttForRapportcijferEnGemiddelde(lvl, 'gem_cijfer');

		return columnDef;
	}

	/**
	 * Voor de historie-variant hebben we wel kolommen met 1 cijfer erin.
	 * De kop is het schooljaar (wat niet herhaald wordt als er meerdere kolommen voor zijn). De subkop is de klas (bij laatste gemiddelde) of
	 * SE/CE/Eind.
	 */
	private createHistorieColumn(
		path: Path<ToetsA, number[]>,
		context: DashboardContext<ToetsI, ToetsA, LeerlingCijferlijstDetailsPanelComponent>
	): ColumnDef<DataRow<ToetsA>> {
		const keys = path.map((lvl) => lvl.k);
		const [_root, cf_nm_schooljaar, cf_nm_klas, cf_nm_kolomtype] = keys;

		const colIndex = last(path)!.i;
		const colKey = `kolom-${colIndex}`;
		const coldef = createColumnDef<DataRow<ToetsA>>(colKey);

		const eersteKolomVanSchooljaar = isFirstAtLevelTabulated(path, 1);
		const schooljaar = eersteKolomVanSchooljaar ? getSchooljaarKort(cf_nm_schooljaar!) : '';
		const klasofKolomtype = this.getKlasofKolomtype(<Cijferkolomtype>cf_nm_kolomtype, cf_nm_klas);

		coldef.header.component = MultilineCellComponent;
		coldef.header.getValue = () => [schooljaar, klasofKolomtype];
		coldef.header.class = 'toets-cell';

		coldef.body.component = CijferlijstCellComponent;
		coldef.body.getValue = this.makeCijferlijstData(colIndex, context);
		coldef.body.class = `toets-cell ${eersteKolomVanSchooljaar ? '' : ' opvolger'}`;

		const { sum_tekortpunten, gem_cijfer } = last(path)!.a;

		coldef.extraFooter = createDefaultFooterCellDef();
		coldef.extraFooter.component = DataCellComponent;
		coldef.extraFooter.format = '1.0-0';
		coldef.extraFooter.getValue = () => sum_tekortpunten;
		coldef.extraFooter.class = `value toets-cell ${eersteKolomVanSchooljaar ? '' : ' opvolger'}`;

		coldef.footer.component = DataCellComponent;
		coldef.footer.format = '1.1-1';
		coldef.footer.getValue = () => gem_cijfer;
		coldef.footer.class = `value toets-cell ${eersteKolomVanSchooljaar ? '' : ' opvolger'}`;

		return coldef;
	}

	private getKlasofKolomtype(cf_nm_kolomtype: Cijferkolomtype, cf_nm_klas: string | null): string {
		switch (cf_nm_kolomtype) {
			case Cijferkolomtype.GEMIDDELDE:
				return this.displayService.display(cf_nm_klas);
			case Cijferkolomtype.SE_CIJFER:
				return 'SE';
			case Cijferkolomtype.CE_CIJFER:
				return 'CE';
			case Cijferkolomtype.EINDCIJFER:
				return 'Eind';
			default:
				return cf_nm_kolomtype;
		}
	}

	private makeCijferlijstData(
		colIndex: number,
		context: DashboardContext<ToetsI, ToetsA, LeerlingCijferlijstDetailsPanelComponent>
	): (row: DataRow<ToetsA>) => CijferlijstData | null {
		const { subgroupNames, measureNames } = context;
		return (row: DataRow<ToetsA>) => {
			const kolomPath = getCellPath(row._path, colIndex);
			if (!kolomPath) return null;

			const attr = getInitialAttributes<ToetsI>(subgroupNames, measureNames, kolomPath);
			const linkData = context.config.createLinkData(kolomPath, context);
			return { ...attr, linkData };
		};
	}

	protected makeToetsData(attrs: ToetsI, path: Path<ToetsA, number[]>, context: DashboardContext<ToetsI, ToetsA, ToetsComponent>): ToetsData {
		const linkData = context.config.createLinkData(path, context);
		const {
			cf_nr_cijfer,
			cf_nr_decimalen,
			clc_abb_label_avg,
			clc_is_voldoende_avg,
			cf_abb_kolomkop_agg,
			cf_co_kolom_agg,
			cf_des_kolom_agg,
			cf_nr_weging_agg,
			cf_nm_bijzonderheid,
			cf_nm_kolomtype,
		} = attrs;
		return {
			cf_nr_cijfer,
			cf_nr_decimalen,
			clc_abb_label_avg,
			clc_is_voldoende_avg,
			cf_nm_bijzonderheid,
			cf_nm_kolomtype,
			abb_kolomkop: cf_abb_kolomkop_agg,
			co_kolom: cf_co_kolom_agg,
			des_kolom: cf_des_kolom_agg,
			nr_leerlingen: undefined,
			nr_weging: cf_nr_weging_agg,
			perc_onvoldoende: undefined,
			linkData,
		};
	}

	doDetailsExport(exportOptions: ExportOptions) {
		lastValueFrom(this.leerling$.pipe(take(1))).then(({ leerling }) =>
			this.doExport(
				this.filterExpressions!,
				this.getVariantPermanentFilters(this.cijferkolomKeuze, this.dossier, leerling, this.variant),
				[],
				exportOptions
			)
		);
	}

	public sortHistorieKolommen(colPath: (string | null)[], keys0: (string | null)[], keys1: (string | null)[]): (string | null)[] {
		const allKeys = union(keys0, keys1);

		//cf_nm_kolomtype
		if (colPath.length == 3) {
			return intersection(
				[Cijferkolomtype.GEMIDDELDE, Cijferkolomtype.SE_CIJFER, Cijferkolomtype.CE_CIJFER, Cijferkolomtype.EINDCIJFER],
				allKeys
			);
		}

		return [...sortBy(allKeys)];
	}

	protected singleAggregators: Partial<{ [ai in keyof ToetsA]: SingleAggregator<ToetsI, ToetsA[ai]> }> = {
		sum_tekortpunten: sumOver('cf_nr_tekortpunten'),
	};

	protected multiAggregators: MultiAggregator<keyof ToetsA, ToetsI, ToetsA, number | null>[] = [weightedAverage('gem_cijfer', 'cf_nr_cijfer')];

	getVariantPermanentFilters = memoize(this._getVariantPermanentFilters, (c, d, l, v) => JSON.stringify([c, d, l.lb_nr_leerling, v]));

	private _getVariantPermanentFilters(
		cijferkolomKeuze: CijferkolomKeuze,
		dossier: Dossier,
		leerling: LeerlingDetail,
		variant: DashboardVariant
	): FilterExpression[] {
		const huidigeLeerling = new BasicFilterExpression(['cf_nr_leerling'], leerling.lb_nr_leerling);
		const geenToets = new CompoundFilterExpression(
			[
				new InFilterExpression(['cf_nm_kolomtype'], [Cijferkolomtype.SE_CIJFER, Cijferkolomtype.CE_CIJFER, Cijferkolomtype.EINDCIJFER]),
				new CompoundFilterExpression([
					new BasicFilterExpression(['cf_nm_kolomtype'], Cijferkolomtype.GEMIDDELDE),
					new BasicFilterExpression(['cf_is_laatste_gemiddelde'], 1),
				]),
			],
			'or'
		);
		return variant === DashboardVariant.ACTUEEL
			? [...super._getPermanentFilters(cijferkolomKeuze, dossier, [['cf_nm_leerling']]), huidigeLeerling]
			: [huidigeLeerling, geenToets];
	}

	getSchooljaarLeerfaseFilterOverride = memoize(LeerlingCijferlijstDetailsPanelComponent._getSchooljaarLeerfaseFilterOverride);

	private static _getSchooljaarLeerfaseFilterOverride(schooljaarInfo: string) {
		return { cf_fun_schooljaar_leerfase: schooljaarInfo };
	}

	enrichTableModel(_context: DashboardContext<ToetsI, ToetsA, LeerlingCijferlijstDetailsPanelComponent>, tableModel: TableModel<DataRow<ToetsA>>) {
		const cijferkolomKeuze = this.cijferkolomKeuze;
		if ([CijferkolomKeuze.ALLE, CijferkolomKeuze.GEMIDDELDE].includes(cijferkolomKeuze)) {
			tableModel.showExtraFooters = true;
			const colDef = tableModel.columnDefs[0];
			colDef.extraFooter = createDefaultFooterCellDef();
			colDef.extraFooter.getValue = () => 'Tekortpunten';
			colDef.extraFooter.class = 'label';
			colDef.footer.component = LabelCellComponent;
			colDef.footer.getValue = () => 'Gemiddeld cijfer';
			colDef.footer.class = 'label';
		} else {
			tableModel.showFooters = false;
		}
	}

	setSelected(selected: LeerlingDetail, schooljaar?: string): void {
		const permanentFilters: FilterExpression[] = [new BasicFilterExpression(['cf_nr_leerling'], selected.lb_nr_leerling)];
		if (schooljaar) permanentFilters.push(new BasicFilterExpression(['cf_nm_schooljaar'], schooljaar));

		this.subscriptions.push(
			this.dataService
				.getAllFilterValues('/cijfers', new BasicFilterExpression(['cf_fun_schooljaar_leerfase']), permanentFilters)
				.pipe(map((result) => result.values[result.values.length - 1]))
				.subscribe((value: string | undefined) =>
					this.leerling$.next({
						leerling: selected,
						schooljaarInfo: value ?? selected.lb_nm_schooljaar + ' ' + selected.lb_nm_opleiding + ' ' + selected.lb_nr_leerjaar,
					})
				)
		);
	}
}

function mapAttForRapportcijferEnGemiddelde<Ai extends keyof ToetsA>(lvl: Level<ToetsA, number[]>, attr: Ai): (ToetsA[Ai] | null)[] {
	return lvl.r.map((path) => {
		if (![Cijferkolomtype.GEMIDDELDE, Cijferkolomtype.RAPPORTCIJFER].includes(<Cijferkolomtype>path[2].k)) return null;
		return att<Ai, ToetsA>(attr)(path);
	});
}
