<app-dashboard-container
	class="dashboard-doorstroom"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
>
	<app-filter-panel
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions"
	>
	</app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header [variant]="variant" [legenda]="['ds_nm_idu']" [legendaIncludeNull]="true" [legendaExclude]="legendaExcludes">
		</app-dashboard-header>
		<div class="groepering">
			<div class="weergave doorstroomweergave">
				Weergave
				<dt-form-dropdown
					[opties]="weergaveOpties"
					[selectedOptie]="getSelectedOption()"
					(onOptieClicked)="qp.dispatch('doorstroomweergave', $event)"
				></dt-form-dropdown>
			</div>
			<app-checkbox label="Toon BRIN-overgang" [value]="toonBrinOvergang" (valueChange)="qp.dispatch('brin-overgang', $event)"></app-checkbox>
		</div>
		@switch (variant) {
			@case ("Actueel") {
				<app-barchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="selectedGroups"
					[subgroups]="fixedSubgroups"
					[config]="this"
				>
				</app-barchart-table>
			}
			@case ("Historie") {
				@if (getPartitionMeasure(weergaveOptie); as partitionMeasure) {
					<app-vbarchart-table
						[filters]="filterExpressions"
						[permanentFilters]="permanentFilterExpressions"
						[defaultGroups]="defaultGroups"
						[groups]="getHistorieGroups(selectedGroups)"
						[subgroups]="getHistorieSubgroups([selectedGroups, fixedSubgroups])"
						[batchLevels]="isHistorieBatchVariant() ? 1 : 0"
						[config]="this"
						[partitionMeasure]="partitionMeasure"
						[schooljaarPath]="['ds_nm_schooljaar_van']"
					>
					</app-vbarchart-table>
				}
			}
		}
	</section>
</app-dashboard-container>
