<app-dashboard-container
	class="dashboard-cijfers"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, getPermanentExpressions(uitsluiten), getDisplayOptions(), $event)"
>
	<app-filter-panel
		[defaultFilters]="actueelFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/cijfers"
		[permanentFilterExpressions]="getPermanentExpressions(uitsluiten)"
	></app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header [variant]="variant" [legenda]="['x_cijfer_ov']"></app-dashboard-header>
		{{ getSchooljarenCaption() }}
		<div class="groepering">
			<app-schooljaar-indicator-over schooljaarFilterName="x_onderwijsresultaten_cf_schooljaar"></app-schooljaar-indicator-over>
			<app-onderwijsresultaten-uitsluiten-filter [isLeerlingen]="false"></app-onderwijsresultaten-uitsluiten-filter>
		</div>
		<app-barchart-table
			[tableGroups]="table"
			[filters]="filterExpressions"
			[permanentFilters]="getPermanentExpressions(uitsluiten)"
			[defaultGroups]="[defaultGroup]"
			[groups]="getGroups(variant, selectedGroup)"
			[config]="this"
		></app-barchart-table>
	</section>
</app-dashboard-container>
