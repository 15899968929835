<div [class.no-gap]="noGap" [class.small-gap]="smallGap" [class]="sizeClass" [class.use-parent-height]="useParentHeight" class="dialog-container">
	@if (topBarTemplate) {
		<div class="top-bar-container">
			<ng-container [ngTemplateOutlet]="topBarTemplate"></ng-container>
		</div>
	}
	<div
		[class.multi-row]="multiRow"
		[class.no-padding]="noPadding"
		[class.scrollable]="scrollable"
		[class.use-parent-height]="useParentHeight"
		class="content-container"
	>
		<ng-content></ng-content>
	</div>
	@if (bottomBarTemplate) {
		<div class="bottom-bar-container">
			<ng-container [ngTemplateOutlet]="bottomBarTemplate"></ng-container>
		</div>
	}
</div>
