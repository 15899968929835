@if (searchInput !== undefined) {
	<input type="text" (input)="search($event)" [value]="searchInput" class="search-box" placeholder="Zoeken..." />
}
@if (isShowSelectAll()) {
	<a (click)="selectAllOrNone()">
		@if (allSelected()) {
			Deselecteer alle
		} @else {
			Selecteer alle
		}
	</a>
}

@if (text && !inDropdown) {
	<app-multi-select-text
		[options]="options"
		[value]="getSelected(options)"
		(valueChange)="select($event)"
		[minimum]="minimum"
		[blockedHover]="blockedHover"
	>
	</app-multi-select-text>
} @else {
	<app-multi-select-checkbox
		[class]="getClassName()"
		[legenda]="legenda"
		[minimum]="minimum"
		[blockedHover]="blockedHover"
		[options]="applyFilter(options, searchInput)"
		[value]="getSelected(options)"
		(valueChange)="select($event)"
		[inDropdown]="inDropdown"
		[showEmpty]="showEmpty"
	>
	</app-multi-select-checkbox>
}
