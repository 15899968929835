<app-tab-menu [shareVisible]="shareVisible" [exportVisible]="exportTypes.length > 0" (exportClicked)="startExport$.next()"></app-tab-menu>
<div class="hider" [class.show-sidebar]="sidebarOpened$ | async">
	<div class="overlay" [class.is-open]="sidebarOpened$ | async" (click)="closeSidebar()"></div>
	<div class="spinner-overlay">
		@if (showSpinner$ | async) {
			<app-svg-spinner></app-svg-spinner>
		}
	</div>
	<ng-content></ng-content>
</div>
