@if (getAllOptions(); as options) {
	@if (inDropdown) {
		<app-single-select-list [opties]="options" [selectedOptie]="getSelected(options)" (onOptieClicked)="select($event)"></app-single-select-list>
	} @else {
		<dt-form-dropdown
			placeholder="Geen"
			[opties]="options"
			[selectedOptie]="getSelected(options)"
			(onOptieClicked)="select($event)"
			[showSearchInput]="searchInput !== undefined"
			[searchInput]="searchInput ?? ''"
			(onSearchInput)="search($event)"
			[menuTemplate]="menu"
		>
		</dt-form-dropdown>
	}
}

<ng-template #menu let-optie="optie">
	<app-meatball-menu>
		<app-meatball-menu-item icon="icon-edit" (click)="editSelectie(optie.value, $event)">Bewerken</app-meatball-menu-item>
		<app-meatball-menu-item icon="icon-delete" (click)="deleteSelectie(optie.value, $event)">Verwijderen</app-meatball-menu-item>
	</app-meatball-menu>
</ng-template>

<ng-template #confirmDeleteDialog let-selectie>
	<app-confirm-dialog caption="Leerlingselectie verwijderen" action="Verwijderen">
		Je staat op het punt om de leerlingselectie <b>{{ selectie.naam }}</b> te verwijderen. Weet je het zeker?
	</app-confirm-dialog>
</ng-template>
