<app-dashboard-container
	class="dashboard-uitstroom"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
>
	<app-filter-panel
		[defaultFilters]="variant() === DashboardVariant.ACTUEEL ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions"
	></app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header [variant]="variant()"></app-dashboard-header>
		@if (variant() === DashboardVariant.HISTORIE) {
			<div class="groepering">
				<app-weergave-optie label="Eenheid" param="eenheid" [value]="eenheid()" [enum]="Eenheid"></app-weergave-optie>
			</div>
		}
		@switch (variant()) {
			@case (DashboardVariant.ACTUEEL) {
				<app-barchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="groups"
					[subgroups]="[['ds_is_prognose']]"
					[config]="this"
				></app-barchart-table>
			}
			@case (DashboardVariant.HISTORIE) {
				<app-vbarchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="getHistorieGroups(groups)"
					[subgroups]="getHistorieSubgroups([groups, []])"
					[batchLevels]="isHistorieBatchVariant() ? 1 : 0"
					[config]="this"
					[schooljaarPath]="['ds_nm_schooljaar_van']"
					[partitionMeasure]="partitionMeasure()"
				></app-vbarchart-table>
			}
		}
	</section>
</app-dashboard-container>
