import { Component } from '@angular/core';
import { TableCellComponent } from '../../table/table.model';

@Component({
	selector: 'app-action-cell',
	templateUrl: './action-cell.component.html',
	styleUrls: ['./action-cell.component.scss'],
	standalone: true,
})
export class ActionCellComponent implements TableCellComponent<ActionCellProperties | undefined> {
	data!: ActionCellProperties | undefined;
}

export interface ActionCellProperties {
	title?: string;
	icon?: string;
	callback: () => void;
}
