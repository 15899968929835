<app-base-dialog sizeClass="size-25" [topBarTemplate]="topBar" [bottomBarTemplate]="bottomBar">
	<ng-template #topBar>
		<app-dialog-header [title]="data.selectie.id ? 'Leerlingselectie bewerken' : 'Leerlingselectie opslaan'"></app-dialog-header>
	</ng-template>
	<div class="content">
		<div class="row">
			<div class="row-title">
				<span>Naam selectie </span>
				<span class="required-icon">*</span>
			</div>
			<input
				#inp
				class="name-input"
				required="true"
				type="text"
				(input)="naam = inp.value"
				[value]="naam"
				(keyup.enter)="close()"
				cdkFocusInitial
			/>
		</div>
		<div class="row">
			<div class="row-title">
				<span>Leerlingen</span>
			</div>
			<app-multi-select-dropdown
				[allOptions]="leerlingOptions"
				[value]="selectedLeerlingen"
				(valueChange)="selectedLeerlingen = $event"
				placeholder="Leerlingen selecteren"
				[showSearchInput]="true"
				[summaryAll]="'Volledige selectie (' + leerlingOptions.length + ' leerlingen)'"
			></app-multi-select-dropdown>
		</div>
	</div>
	<ng-template #bottomBar>
		<app-button buttonType="secondary" (clickit)="dialogRef.close()">Annuleren</app-button>
		<app-button [enabled]="isNotEmpty()" (clickit)="dialogRef.close(getResult())">Opslaan</app-button>
	</ng-template>
</app-base-dialog>
