import { Component, ElementRef, Inject, signal, viewChild } from '@angular/core';
import { BaseDialogComponent } from '@cumlaude/shared-components-dialogs';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AttrPath } from '../../../services/data.service';
import { attrLabel } from '../../../services/labels';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { GroeperingSorterenDialogComponent } from '../groepering-sorteren-dialog/groepering-sorteren-dialog.component';
import { Overlay } from '@angular/cdk/overlay';
import { firstValueFrom } from 'rxjs';
import { SortDirection } from '../../../shared/components/table/table/table.component';

export interface GroeperingOptions {
	activeGroepering?: AttrPath;
	availableGroeperingen: AttrPath[];
	userGroups: AttrPath[];
	deletable: boolean;
}

interface GroeperingKeuze {
	groepering: AttrPath;
	naam: string;
	current: boolean;
}

@Component({
	selector: 'app-groepering-dialog',
	templateUrl: './groepering-dialog.component.html',
	styleUrls: ['./groepering-dialog.component.scss'],
	standalone: true,
	imports: [BaseDialogComponent],
})
export class GroeperingDialogComponent {
	protected groeperingen: GroeperingKeuze[];

	sorteerElement = viewChild<ElementRef>('sorteren');

	menuSorterenLaatste = signal(false);

	protected sorteerDialogRef?: DialogRef<SortDirection>;

	constructor(
		@Inject(DIALOG_DATA) protected options: GroeperingOptions,
		protected dialogRef: DialogRef<GroeperingOptions>,
		protected qp: QueryParamStateService,
		private dialog: Dialog,
		private readonly overlay: Overlay
	) {
		this.groeperingen = options.availableGroeperingen
			.filter((group) => {
				const groupString = group.join('.');
				if (options.activeGroepering?.join('.') === groupString) return true;

				return !options.userGroups.some((userGroep) => userGroep.join('.') === groupString);
			})
			.map((group) => {
				return {
					groepering: group,
					naam: attrLabel(group),
					current: options.activeGroepering?.join('.') === group.join('.'),
				};
			})
			.sort((groupA, groupB) => groupA.naam.localeCompare(groupB.naam));
	}

	select(groepering: GroeperingKeuze) {
		if (!groepering.current) this.updateGroeperingen(groepering.groepering);

		this.dialogRef.close();
	}

	async sort() {
		const sorteerElement = this.sorteerElement();
		if (!sorteerElement) return;

		const activeGroepering = this.options.activeGroepering;
		if (!activeGroepering) return;

		const menuSorterenLaatste = this.menuSorterenLaatste();
		if (menuSorterenLaatste) return;

		this.menuSorterenLaatste.set(true);

		this.sorteerDialogRef = this.dialog.open<SortDirection>(GroeperingSorterenDialogComponent, {
			hasBackdrop: false,
			positionStrategy: this.overlay
				.position()
				.flexibleConnectedTo(sorteerElement)
				.withFlexibleDimensions(false)
				.withPositions([
					{
						originX: 'end',
						originY: 'top',
						overlayX: 'start',
						overlayY: 'top',
						offsetX: 1,
						offsetY: 0,
					},
				]),
		});

		const sortOrder = await firstValueFrom(this.sorteerDialogRef.closed);
		if (!sortOrder) return;

		const newSort = { direction: sortOrder, active: activeGroepering.join('.') };
		this.qp.dispatch('sortOrder', newSort);
	}

	delete() {
		this.updateGroeperingen();
		this.dialogRef.close();
	}

	private updateGroeperingen(groepering?: AttrPath) {
		let changedGroeperingen = [...this.options.userGroups];
		const activeGroepering = this.options.activeGroepering;
		if (activeGroepering) {
			const ix = this.options.userGroups.findIndex((group) => group.join('.') === activeGroepering.join('.'));
			if (groepering) {
				changedGroeperingen[ix] = groepering;
			} else {
				changedGroeperingen.splice(ix, 1);
			}
		} else if (groepering) {
			changedGroeperingen = [...changedGroeperingen, groepering];
		}

		this.qp.dispatch_g(changedGroeperingen);
	}

	closeSorteren() {
		this.menuSorterenLaatste.set(false);
		this.sorteerDialogRef?.close();
	}
}
