import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilterName } from '../services/filter-config';
import { FilterComponent } from '../services/data.service';
import { FilterService } from '../services/filter.service';
import { Subscription } from 'rxjs';
import { Option, CheckboxComponent, FormDropdownComponent, SingleSelectListComponent } from '@cumlaude/shared-components-inputs';
import { find } from 'lodash-es';
import { SchooljaarHistorieOption } from './schooljaar-historie-option';

@Component({
	selector: 'app-schooljaar-historie-filter',
	templateUrl: './schooljaar-historie-filter.component.html',
	styleUrls: ['./schooljaar-historie-filter.component.scss'],
	standalone: true,
	imports: [FormDropdownComponent, SingleSelectListComponent, CheckboxComponent],
})
export class SchooljaarHistorieFilterComponent
	implements OnInit, OnDestroy, FilterComponent<{ option: SchooljaarHistorieOption; inclHuidig: boolean }>
{
	allOptions = Object.values(SchooljaarHistorieOption).map(this.mapToDropDownOption.bind(this));

	filterName!: FilterName;
	inDropdown!: boolean;
	searchInput: string | undefined;

	currentOption!: SchooljaarHistorieOption;
	inclHuidig = true;

	protected subscriptions: Subscription[] = [];

	constructor(protected filterService: FilterService) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.filterService.getFilterStateAsInput(this.filterName).subscribe(({ option, inclHuidig }) => {
				this.currentOption = option;
				this.inclHuidig = inclHuidig;
			})
		);
	}

	getSelected(): Option<SchooljaarHistorieOption> {
		return find(this.allOptions, (option) => option.value === this.currentOption)!;
	}

	selectOption(option: SchooljaarHistorieOption) {
		this.currentOption = option;
		this.filterService.setFilterInput(this.filterName, { option, inclHuidig: this.inclHuidig });
	}

	selectHuidig(inclHuidig: boolean) {
		this.inclHuidig = inclHuidig;
		this.filterService.setFilterInput(this.filterName, { option: this.currentOption, inclHuidig });
	}

	protected mapToDropDownOption(option: SchooljaarHistorieOption): Option<SchooljaarHistorieOption> {
		return new Option<SchooljaarHistorieOption>(option);
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}
}
