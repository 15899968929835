<div class="dropdown-container">
	<app-multi-select-dropdown
		[allOptions]="allDropDownOptions"
		[value]="selectie"
		[placeholder]="placeholder"
		[showSearchInput]="zoeken"
		(valueChange)="changeSelectie($event)"
		[enabled]="enabled"
		[matchesSearch]="matchesSearch"
	></app-multi-select-dropdown>
</div>
