import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	effect,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	signal,
	SimpleChanges,
	ViewChild,
	viewChildren,
} from '@angular/core';
import { AlternatingMode, TableModel } from './table.model';
import { flatMap, isUndefined, last, sum } from 'lodash-es';
import {
	CdkCell,
	CdkCellDef,
	CdkColumnDef,
	CdkFooterCell,
	CdkFooterCellDef,
	CdkFooterRow,
	CdkFooterRowDef,
	CdkHeaderCell,
	CdkHeaderCellDef,
	CdkHeaderRow,
	CdkHeaderRowDef,
	CdkRow,
	CdkRowDef,
	CdkTable,
} from '@angular/cdk/table';
import { firstValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CellContentComponent } from '../cell-content/cell-content.component';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ConnectedPosition, Overlay } from '@angular/cdk/overlay';
import { GroeperingDialogComponent } from '../../../../dialogs/groepering/groepering-dialog/groepering-dialog.component';
import { AttrPath } from '../../../../services/data.service';
import { QueryParamStateService } from '../../../../services/query-param-state.service';
import { UserService } from '../../../../services/user.service';
import { CdkDrag, CdkDragDrop, CdkDragPlaceholder, CdkDragPreview, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';

export declare interface Sort {
	/** The id of the column being sorted. */
	active: string;
	/** The sort direction. */
	direction: SortDirection;
}

export declare type SortDirection = 'asc' | 'desc' | '';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./table.component.scss'],
	standalone: true,
	imports: [
		CdkTable,
		NgClass,
		CdkColumnDef,
		CdkHeaderCellDef,
		CdkHeaderCell,
		NgStyle,
		CellContentComponent,
		CdkCellDef,
		CdkCell,
		CdkFooterCellDef,
		CdkFooterCell,
		CdkHeaderRowDef,
		CdkHeaderRow,
		CdkRowDef,
		CdkRow,
		CdkFooterRowDef,
		CdkFooterRow,
		AsyncPipe,
		CdkDropList,
		CdkDrag,
		CdkDragPreview,
		CdkDragPlaceholder,
	],
})
export class TableComponent<M> implements OnChanges, AfterViewInit {
	@Input()
	model!: TableModel<M>;

	@Input()
	hoverHelp = false;

	@Output()
	rowClick = new EventEmitter<M>();

	@Input()
	exportable = false;

	@Input()
	sortDirection!: SortDirection;

	@Input()
	sortActive!: string;

	@Input()
	incremental = false;

	@Input()
	availableGroeperingen: AttrPath[] = [];

	@Input()
	userGroups: AttrPath[] = [];

	@Input()
	userGroupsDeletable!: boolean;

	hoverC?: number;

	hoverR?: number;

	groupingMenuActive = signal<string | undefined>(undefined);

	groeperingen = viewChildren<ElementRef>('groepering');

	dataToRender: Subject<M[]> = new ReplaySubject(1);

	renderBatchSize?: number;

	renderedRows$!: Observable<[number, number]>;

	@ViewChild(CdkTable, { static: true })
	private table_!: CdkTable<M>;

	private dialogRef: DialogRef<unknown, GroeperingDialogComponent> | undefined;

	constructor(
		private ref: ChangeDetectorRef,
		private destroyRef: DestroyRef,
		private dialog: Dialog,
		private readonly overlay: Overlay,
		protected qp: QueryParamStateService,
		protected userService: UserService
	) {
		effect(async () => {
			if (this.dialogRef) this.dialogRef.close();

			const menuActive = this.groupingMenuActive();
			if (!menuActive) return;

			const groeperingElement = this.groeperingen().find((element) =>
				element.nativeElement.classList.contains(`groepering-${menuActive.replace('.', '-')}`)
			);
			if (!groeperingElement) return;

			const activeGroepering = menuActive === 'addGroup' ? undefined : <AttrPath>menuActive.split('.');
			const offsetX = menuActive === 'addGroup' ? -40 : -24;

			const boundingClientRect = groeperingElement.nativeElement.getBoundingClientRect();
			let height = window.innerHeight - boundingClientRect.bottom - 32;

			let position: ConnectedPosition = {
				originX: 'end',
				originY: 'bottom',
				overlayX: 'start',
				overlayY: 'top',
				offsetX: offsetX,
				offsetY: 1,
			};

			if (height < 250) {
				position = {
					originX: 'end',
					originY: 'top',
					overlayX: 'start',
					overlayY: 'bottom',
					offsetX: offsetX,
					offsetY: 1,
				};
				height = boundingClientRect.top - 32;
			}

			this.dialogRef = this.dialog.open(GroeperingDialogComponent, {
				data: {
					activeGroepering,
					availableGroeperingen: this.getAvailableGroeperingen(),
					userGroups: this.userGroups,
					deletable: this.userGroupsDeletable,
				},
				maxHeight: `${height}px`,
				backdropClass: 'cdk-overlay-transparent-backdrop',
				positionStrategy: this.overlay
					.position()
					.flexibleConnectedTo(groeperingElement)
					.withFlexibleDimensions(false)
					.withPositions([position]),
			});

			await firstValueFrom(this.dialogRef.closed);
			this.groupingMenuActive.set(undefined);
		});
	}

	ngAfterViewInit() {
		/**
		 * Bij incrementeel renderen komt er elke keer een aantal rows bij dat minstens dit aantal pixels
		 * inneemt qua hoogte.
		 */
		const pixelsPerBatch = window.innerHeight;

		const tableRowContainer = this.table_._rowOutlet.elementRef.nativeElement.parentElement;
		const tableFooter = this.table_._footerRowOutlet.elementRef.nativeElement.parentElement;

		// Emit als er rows in de DOM bij zijn gekomen.
		this.renderedRows$ = observeMutations(tableRowContainer, { childList: true }).pipe(
			map<MutationRecord[], [number, number]>((entries) => {
				const added: Element[] = flatMap(entries, (entry) => <Element[]>[...entry.addedNodes.values()]).filter(
					(node: Node) => node.nodeType === Node.ELEMENT_NODE
				);
				const nrRows = added.length;
				const nrPixels = sum(added.map((elt) => elt.getBoundingClientRect().height));
				return [nrRows, nrPixels];
			})
		);

		// Zodra er daadwerkelijk één gerenderd is weten we hoe hoog de rows zijn. Render alle volgende in batches van ongeveer een scherm groot.
		this.renderedRows$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(([nrRows, nrPixels]) => {
			if (nrRows > 0) this.renderBatchSize = Math.ceil((pixelsPerBatch * nrRows) / nrPixels);
		});

		// Klaar met renderen? Render meer rows als de footer in zicht is (of in zicht komt door scrollen).
		this.renderedRows$
			.pipe(
				switchMap(() => observeIntersection(tableFooter, {}).pipe(map((entries) => last(entries)!.isIntersecting))),
				startWith(true),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe((footerInZicht) => {
				if (footerInZicht) this.renderMore();
			});
	}

	renderMore() {
		firstValueFrom(this.dataToRender).then((data) => {
			const len = data.length;
			if (len < this.model.data.length) this.dataToRender.next(this.model.data.slice(0, data.length + (this.renderBatchSize ?? 1)));
		});
	}

	async forceTotalRender(): Promise<void> {
		const rendered = await firstValueFrom(this.dataToRender);
		if (rendered.length == this.model.data.length) return;

		// Zoals ik het nu observeer triggert dataToRender.next() altijd synchroon de DOM update
		// en is het dus eigenlijk niet nodig om daar met een promise op te wachten.
		// Maar als we dat toch willen doen (voor de zekerheid) moeten we eerst de promise
		// aanmaken, anders is de DOM update al geweest en kan je lang wachten.
		const promise = firstValueFrom(this.renderedRows$);
		this.dataToRender.next(this.model.data);
		await promise;
	}

	onClickedBodyCell(rowModel: M, clickHandler?: (rowModel: M) => void) {
		if (isUndefined(clickHandler)) this.rowClick.emit(rowModel);
		else clickHandler(rowModel);
	}

	onClickedFooterCell(rowModel: TableModel<M>, clickHandler: ((rowModel: TableModel<M>) => void) | undefined) {
		if (isUndefined(clickHandler)) return;

		clickHandler(rowModel);
	}

	onHover(r: number | undefined, c: number | undefined) {
		if (this.hoverHelp) {
			this.hoverR = r;
			this.hoverC = c;
		}
	}

	getSortHeaderClass(column: string) {
		return this.model.getColumnDef(column).sortable ? 'sort-enabled' : '';
	}

	getSortIconClass(column: string) {
		if (!this.model.getColumnDef(column).sortable) return '';

		if (this.sortActive !== column) return 'svg-sort-asc';

		return `sort-active svg-sort-${this.sortDirection}`;
	}

	clickSort(column: string) {
		if (!this.model.getColumnDef(column).sortable) return;

		const direction = this.sortActive === column ? flipped(this.sortDirection) : 'asc';
		const newSort = { direction, active: column };
		this.qp.dispatch('sortOrder', newSort);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('model' in changes) {
			this.dataToRender.next(this.incremental ? [] : this.model.data);
			this.renderBatchSize = undefined;
		}

		// Forceer een update van de header en footer row
		this.table_.removeHeaderRowDef(null!);
		this.table_.removeFooterRowDef(null!);
	}

	refresh(): void {
		this.ref.detectChanges();
		this.table_.renderRows();
	}

	x_columns(columns: string[]): string[] {
		// De extra footer wordt getoond door een extra rij te tonen die geprefixte kolommen bevat
		return columns.map((c) => 'x_' + c);
	}

	protected readonly AlternatingMode = AlternatingMode;

	isGroeperingAvailable() {
		return (this.userGroups ?? []).length < this.getAvailableGroeperingen().length;
	}

	getAvailableGroeperingen() {
		return this.availableGroeperingen.filter((groepering) => this.userService.isAttrPathAllowed(groepering));
	}

	drop(event: CdkDragDrop<any, any>) {
		const userGroups = [...this.userGroups];
		moveItemInArray(userGroups, event.previousIndex, event.currentIndex);
		this.qp.dispatch_g(userGroups);
	}
}

function flipped(sort: SortDirection): SortDirection {
	switch (sort) {
		case 'desc':
			return 'asc';
		case 'asc':
			return 'desc';
		case '':
			return '';
	}
}

function observeMutations(node: Node, options: MutationObserverInit): Observable<MutationRecord[]> {
	return new Observable((subscriber) => {
		const observer = new MutationObserver((entries) => subscriber.next(entries));
		observer.observe(node, options);
		return () => observer.disconnect();
	});
}

function observeIntersection(element: Element, options: IntersectionObserverInit): Observable<IntersectionObserverEntry[]> {
	return new Observable((subscriber) => {
		const observer = new IntersectionObserver((entries) => subscriber.next(entries), options);
		observer.observe(element);
		return () => observer.disconnect();
	});
}
