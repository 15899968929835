@if (vm$ | async; as vm) {
	<ng-container class="table-container">
		@if (!vm.error && vm.models.length == 0) {
			<div>De huidige selectie levert geen resultaten op.</div>
		} @else if (vm.models.length) {
			<cdk-virtual-scroll-viewport [appendOnly]="true" itemSize="200">
				@if (tableGroups?.length == 3) {
					<div class="inside">
						<div class="table-groups" *cdkVirtualFor="let level1 of vm.models; trackBy: trackByIx">
							@if (showTitles) {
								<h3 [class.empty]="isTopLevelKeyEmpty(level1)" [attr.data-exportable]="exportable ? '' : undefined">
									{{ getTopLevelTitle(level1) }}
								</h3>
							}
							@for (level2 of level1; track trackByIx($index, level2)) {
								<div class="table-container horizontal">
									@for (model of level2; track trackByIx($index, model)) {
										<div>
											@if (showTitles) {
												<div
													class="label"
													[class.empty]="isKeyEmpty(model)"
													[attr.data-exportable]="exportable ? '' : undefined"
												>
													{{ getTitle(model) }}
												</div>
											}
											<app-table
												[model]="model"
												[sortActive]="vm.sortState.active"
												[sortDirection]="vm.sortState.direction"
												(rowClick)="rowClick($event)"
												[hoverHelp]="hoverHelp"
												[exportable]="exportable"
												[availableGroeperingen]="config.getAvailableGroups()"
												[userGroups]="userGroups ?? defaultGroups"
												[userGroupsDeletable]="config.flexibleGroupsRemovable"
											>
											</app-table>
										</div>
									}
								</div>
							}
						</div>
					</div>
				} @else {
					<div class="inside">
						@for (level1 of vm.models; track trackByIx($index, level1)) {
							<div>
								@if (showTitles) {
									<h3 [class.empty]="isTopLevelKeyEmpty(level1)" [attr.data-exportable]="exportable ? '' : undefined">
										{{ getTopLevelTitle(level1) }}
									</h3>
								}
								@for (level2 of level1; track trackByIx($index, level2)) {
									<div class="table-container">
										<div *cdkVirtualFor="let model of level2; trackBy: trackByIx">
											@if (showTitles) {
												<div
													class="label"
													[class.empty]="isKeyEmpty(model)"
													[attr.data-exportable]="exportable ? '' : undefined"
												>
													{{ getTitle(model) }}
												</div>
											}
											<app-table
												[model]="model"
												[sortActive]="vm.sortState.active"
												[sortDirection]="vm.sortState.direction"
												(rowClick)="rowClick($event)"
												[hoverHelp]="hoverHelp"
												[exportable]="exportable"
												[incremental]="isIncrementalRender()"
												[availableGroeperingen]="config.getAvailableGroups()"
												[userGroups]="userGroups ?? defaultGroups"
												[userGroupsDeletable]="config.flexibleGroupsRemovable"
											>
											</app-table>
										</div>
									</div>
								}
							</div>
						}
					</div>
				}
			</cdk-virtual-scroll-viewport>
		}
		@switch (vm.error?.error) {
			@case (ErrorEnum.BENCHMARK_NOT_ALLOWED) {
				<div>
					Helaas hebben wij op dit moment geen toestemming van jouw school om de noodzakelijke data voor de CumLaude benchmark te verwerken
					en te tonen. Neem voor meer informatie contact op met je beheerder.
				</div>
			}
			@case (ErrorEnum.LIMIT_EXCEEDED) {
				<div>
					De huidige selectie levert te veel ({{ vm.error!.parameters!.leaves }}) resultaten op. Voeg filters toe om deze te beperken tot
					minder dan {{ vm.error!.parameters!.limit }}.
				</div>
			}
			@case (ErrorEnum.UNKNOWN) {
				<div>Er is een onverwachte fout opgetreden.</div>
			}
			@case (ErrorEnum.MISSING_MODULE) {
				<div>
					Deze school heeft module {{ vm.error!.parameters!.module }} niet afgenomen. Neem contact op met
					<a href="mailto:{{ envConfig.supportMail }}">CumLaude Support</a> om de mogelijkheden te bespreken.
				</div>
			}
			@case (ErrorEnum.MISSING_ROLE) {
				<div>
					Je kunt de gegevens niet opvragen als {{ vm.error!.parameters!.rol }} omdat je deze rol niet (meer) hebt. Neem contact op met de
					beheerder van CumLaude op je school.
				</div>
			}
			@case (ErrorEnum.NO_ACCESS) {
				<div>Je hebt geen toegang tot de gevraagde data. Neem contact op met de beheerder van CumLaude op je school.</div>
			}
			@case (ErrorEnum.INDICATOR_IQ_NO_LONGER_USED) {
				<div>Vanaf 2023 wordt deze indicator niet meer gehanteerd door de inspectie.</div>
			}
		}
	</ng-container>
}
