import { Component } from '@angular/core';
import { AttrPath, BasicFilterExpression, FilterExpression } from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { UitzonderingComponent } from './uitzondering.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { SchooljaarIndicatorOverComponent } from '../../schooljaar-indicator-over/schooljaar-indicator-over.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';

@Component({
	selector: 'app-uitzondering-doorstroom',
	templateUrl: './uitzondering.component.html',
	styleUrls: ['./uitzondering.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		ButtonComponent,
		DashboardHeaderComponent,
		TooltipDirective,
		SchooljaarIndicatorOverComponent,
		BarchartTableComponent,
	],
})
export class UitzonderingDoorstroomComponent extends UitzonderingComponent {
	groups: AttrPath[] = [['ds_nm_plaatsing_uitzondering'], ['ds_nm_status'], ['ds_fk_ilt_van', 'ilt_nm_niveau'], ['ds_nr_leerjaar_van']];

	initialFilters: FilterName[] = [
		'ds_nm_schooljaar_van',
		'ds_fk_lb_van.lb_co_brin',
		'ds_fk_vs_van.vs_nm_vestiging',
		'ds_fk_ilt_van.ilt_nm_niveau',
		'ds_nr_leerjaar_van',
		'ds_nm_plaatsing_uitzondering',
		'ds_nm_status',
	];

	permanentFilterExpressions: FilterExpression[] = [new BasicFilterExpression(['ds_is_relevante_doorstroom'], 1)];
}
