import { deburr, last } from 'lodash-es';

export function prettyPeopleList(elements: string[], max: number, singular?: string, plural?: string) {
	return prettyListInternal(elements, max, singular, plural, true);
}

export function prettyThingsList(elements: string[], max: number, singular?: string, plural?: string) {
	return prettyListInternal(elements, max, singular, plural, false);
}

function prettyListInternal(elements: string[], max: number, singular: string | undefined, plural: string | undefined, people: boolean) {
	const joined = joinElements(elements, max, people);

	if (singular && plural) {
		return elements.length == 1 ? `${joined} ${singular}` : `${joined} ${plural}`;
	} else {
		return joined;
	}
}

export function includesIgnoreCaseAndDiacritics(candidate: string | undefined, target: string): boolean {
	if (!candidate) return false;
	return deburr(candidate).toLowerCase().includes(deburr(target).toLowerCase());
}

function joinElements(elements: string[], max: number, people: boolean): string {
	if (elements.length == 1) {
		return elements[0];
	} else if (elements.length <= max) {
		return `${elements.slice(0, -1).join(', ')} en ${last(elements)}`;
	} else {
		return `${elements[0]} en ${elements.length - 1} ${people ? 'anderen' : 'andere'}`;
	}
}
