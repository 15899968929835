@if (showSearchInput && !showSearchInputInside) {
	<div
		(click)="showDropdown = enabled"
		class="search-box search-box-outside"
		[class.disabled]="!enabled"
		[class.selection-open]="showDropdown"
		[class.has-selection]="!isEmpty()"
	>
		<input #inp type="text" (input)="searchInput = inp.value" [value]="searchInput" [placeholder]="placeholder" />
		<span class="svg-search"></span>
	</div>
}
@if (!showSearchInput || showSearchInputInside) {
	<div
		(click)="showDropdown = !showDropdown && enabled"
		class="value-box text-content"
		[class.disabled]="!enabled"
		[class.selection-open]="showDropdown"
		[class.has-selection]="!isEmpty()"
	>
		@if (isEmpty()) {
			<div class="placeholder">{{ placeholder }}</div>
		} @else {
			<div class="selected-value" [title]="getSummary()">{{ getSummary() }}</div>
		}
		<i class="icon icon-arrow-open icon-medium chevron-icon"></i>
	</div>
}
@if (showDropdown) {
	<div
		(clickOutside)="closeDropDown($event)"
		[clickOutsideEvents]="'click,touchend'"
		[delayClickOutsideInit]="true"
		[clickOutsideExclude]="'.search-box'"
		class="selection-box"
		[class.contains-search]="showSearchInput && showSearchInputInside"
		#dropdown
	>
		@if (showSearchInput && showSearchInputInside) {
			<div class="search-box">
				<input #inp type="text" (input)="searchInput = inp.value" [value]="searchInput" placeholder="Zoeken..." />
				<span class="svg-search"></span>
			</div>
		}
		<app-multi-select-checkbox
			[class.no-overflow]="getAllOptions(false).length > 30"
			[options]="getAllOptions()"
			[value]="getSelectedOptions()"
			(valueChange)="setSelectedOptions($event)"
			[inDropdown]="true"
		></app-multi-select-checkbox>
	</div>
}
