import { Component, OnInit } from '@angular/core';
import {
	AttrPath,
	BasicFilterExpression,
	CijferMeasure,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	ExportFilter,
	FilterExpression,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { last, memoize, nth, orderBy, range, sortBy, union } from 'lodash-es';
import { FilterService } from '../../services/filter.service';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow, getInitialAttributes } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { Observable } from 'rxjs';
import { DropDownOption, FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { getLeafA, Level, Path } from '../../services/data-tree';
import { ColumnDef, createColumnDef, createDefaultFooterCellDef, TableModel } from '../../shared/components/table/table/table.model';
import { aggDecimals, MultiAggregator, SingleAggregator, sumOver, weightedAverage } from '../../services/aggregation';
import { att } from '../../services/measures';
import { CijferlijstCellComponent } from '../../shared/components/table/cells/cijferlijst-cell/cijferlijst-cell.component';
import { LabelCellComponent } from '../../shared/components/table/cells/label-cell/label-cell.component';
import { PivotTableConfig } from '../../shared/dashboard/pivot-table/pivot-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { getCellPath, PivotTableComponent } from '../../shared/dashboard/pivot-table/pivot-table.component';
import { DataCellComponent } from '../../shared/components/table/cells/data-cell/data-cell.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { CijferlijstData } from '../../services/cijfers';
import { UserService } from '../../services/user.service';
import { InstellingBron } from '@cumlaude/service-contract';

interface CijferlijstI extends Attributes {
	cf_nr_tekortpunten: number;
	cf_nr_cijfer: number;
	cf_nr_decimalen: number;
	clc_abb_label_avg: any;
	clc_is_voldoende_avg: number;
	cf_nm_bijzonderheid: any;
	cf_nm_kolomtype: any;
}

interface CijferlijstA extends Attributes {
	sum_tekortpunten: number;
	gem_cijfer: number | null;
	decimalen: number;
}

@Component({
	selector: 'app-cijferlijst',
	templateUrl: './cijferlijst.component.html',
	styleUrls: ['./cijferlijst.component.scss'],
	standalone: true,
	imports: [DashboardContainerComponent, FilterPanelComponent, DashboardHeaderComponent, FormDropdownComponent, PivotTableComponent],
})
export class CijferlijstComponent extends PivotTableConfig<CijferlijstI, CijferlijstA> implements OnInit {
	defaultGroups: AttrPath[] = [['cf_nm_klas']];

	fixedAfterGroups = 1;

	groups: AttrPath[] = this.defaultGroups;

	subgroups: AttrPath[] = [['cf_fun_nr_volgnr_vak'], ['cf_abb_vak']];

	cijferlijstFilters: FilterName[] = [
		'cf_nm_schooljaar',
		'cf_fk_lb.lb_co_brin',
		'cf_nm_vestiging',
		'cf_fk_ilt.ilt_nm_niveau',
		'cf_nr_leerjaar',
		'cf_nm_vak',
		'cf_nm_lesgroep',
		'x_cf_is_alternatievenormering',
		'x_cijferlijst_cijfertype',
		'x_cijferlijst_gem_periode',
	];

	availableGroups: AttrPath[] = [
		['cf_map_idu'],
		['cf_nm_klas'],
		['cf_nr_leerjaar'],
		['cf_nm_opleiding'],
		['cf_fk_ilt', 'ilt_nm_niveau'],
		['cf_fk_ilt', 'ilt_nm_opleiding'],
		['cf_fk_ilt', 'ilt_abb_profiel'],
		['cf_fk_lb', 'lb_nm_uitstroomprofiel_vso'],
		['cf_nr_leerjaar_vak'],
		['cf_abb_onderwijssoort_vak'],
		['cf_nm_vestiging'],
		['cf_fk_lb_vorig_sj', 'lb_nm_vestiging'],
		['cf_fk_lb', 'lb_nm_leerfase'],
		['cf_fk_lb_vorig_sj', 'lb_nm_leerfase'],
	];

	filterExpressions?: FilterExpression[];

	tekortpuntenKeuze!: number;

	tekortpuntenKeuzeOpties = Object.values(range(0, 6)).map((val) => new Option(val, val == 0 ? '<Alle>' : val + ' of meer'));

	sorteringKeuze!: number;

	sorteringKeuzeOpties = ['Tekortpunten desc', 'Tekortpunten asc', 'Vakvolgorde desc'].map((val, index) => this.makeOption(val, index));

	private makeOption(label: string, value: number): DropDownOption<number> {
		const text = label.replace(' asc', '').replace(' desc', '');
		const icon = label.includes(' asc') ? 'icon-arrow-up' : 'icon-arrow-down';
		return {
			value,
			text,
			icon,
			iconColor: 'text-t5-color',
		};
	}

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService,
		userService: UserService
	) {
		super(filterService, toastr);
		if (userService.bron() === InstellingBron.Magister) {
			this.cijferlijstFilters.push('cf_abb_kolomkop', 'cf_nr_kolom');
		}
	}

	ngOnInit(): void {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe_g().subscribe((groups) => (this.groups = groups ?? this.defaultGroups)),
			this.qp.observe('tekortpunten').subscribe((tekortpunten) => {
				this.tekortpuntenKeuze = tekortpunten;
				this.filterService.refresh();
			}),
			this.qp.observe('vakOrder').subscribe((sortOrder) => {
				this.sorteringKeuze = sortOrder;
				this.filterService.refresh();
			})
		);
	}

	factTable = FactTable.cijfers;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getCijfersData({
			...options,
			m: [CijferMeasure.STATS, CijferMeasure.LABEL],
			threshold: this.tekortpuntenKeuze,
		});
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getCijfersExportData(options);
	}

	createPivotColumns(
		columnRoot: Level<CijferlijstA, number[]>,
		context: DashboardContext<CijferlijstI, CijferlijstA, CijferlijstComponent>
	): ColumnDef<DataRow<CijferlijstA>>[] {
		let r = columnRoot.r;
		if ([0, 1].includes(this.sorteringKeuze))
			r = orderBy(r, [(path) => last(path)!.a.sum_tekortpunten, (path) => last(path)!.a.gem_cijfer], ['asc', 'desc']);

		const map = r.map((path) => this.createColumn(path, context));
		if (this.sorteringKeuze == 0) return map.reverse();
		return map;
	}

	private createColumn(
		path: Path<CijferlijstA, number[]>,
		context: DashboardContext<CijferlijstI, CijferlijstA, CijferlijstComponent>
	): ColumnDef<DataRow<CijferlijstA>> {
		const vak = last(path)?.k!;
		const volgnummer = nth(path, -2)?.k!;

		const colKey = `vak-${volgnummer}-${vak}`;
		const coldef = createColumnDef<DataRow<CijferlijstA>>(colKey);

		coldef.header.component = LabelCellComponent;
		coldef.header.getValue = () => vak;
		coldef.header.class = 'vak-cijfer-header';

		const colIndex = last(path)?.i!;
		coldef.body.component = CijferlijstCellComponent;
		coldef.body.getValue = this.makeCijferlijstData(colIndex, context);
		coldef.body.class = 'vak-cijfer-column';

		const { sum_tekortpunten, gem_cijfer, decimalen } = getLeafA(path);

		coldef.extraFooter = createDefaultFooterCellDef();
		coldef.extraFooter.component = DataCellComponent;
		coldef.extraFooter.format = '1.0-0';
		coldef.extraFooter.getValue = () => sum_tekortpunten;
		coldef.extraFooter.class = 'value';

		coldef.footer.component = DataCellComponent;
		coldef.footer.format = `1.${decimalen}-${decimalen}`;
		coldef.footer.getValue = () => gem_cijfer;
		coldef.footer.class = 'value';

		return coldef;
	}

	private makeCijferlijstData(
		colIndex: number,
		context: DashboardContext<CijferlijstI, CijferlijstA, CijferlijstComponent>
	): (row: DataRow<CijferlijstA>) => CijferlijstData | null {
		const { subgroupNames, measureNames } = context;
		return (row: DataRow<CijferlijstA>) => {
			const vakPath = getCellPath(row._path, colIndex);
			if (!vakPath) return null;

			const attr = getInitialAttributes<CijferlijstI>(subgroupNames, measureNames, vakPath);
			const linkData = context.config.createLinkData(vakPath, context);
			return { ...attr, linkData };
		};
	}

	sortColumn(colPath: (string | null)[], keys0: (string | null)[], keys1: (string | null)[]): (string | null)[] {
		if (colPath.length == 1) return sortBy(union(keys0, keys1), [Number]);
		else return sortBy(union(keys0, keys1));
	}

	private readonly cijferFormat = (path: Path<CijferlijstA, number[]>) => {
		const { decimalen } = getLeafA(path);
		return `1.${decimalen}-${decimalen}`;
	};

	createMeasureColumns(context: DashboardContext<CijferlijstI, CijferlijstA, CijferlijstComponent>): ColumnDef<DataRow<CijferlijstA>>[] {
		const tekortPunten = createMeasureColumn('TP.', att('sum_tekortpunten'), { context });
		tekortPunten.header.class = 'measure-header';
		tekortPunten.footer.getValue = () => null;

		const gemiddelde = createMeasureColumn('Gem.', att('gem_cijfer'), { context, format: this.cijferFormat });
		gemiddelde.header.class = 'measure-header';
		gemiddelde.extraFooter = createDefaultFooterCellDef();
		gemiddelde.extraFooter.getValue = () => 'TP.';
		gemiddelde.extraFooter.class = 'label';
		gemiddelde.footer.component = LabelCellComponent;
		gemiddelde.footer.getValue = () => 'Gem.';
		gemiddelde.footer.class = 'label';

		return [tekortPunten, gemiddelde];
	}

	enrichTableModel(context: DashboardContext<CijferlijstI, CijferlijstA, CijferlijstComponent>, tableModel: TableModel<DataRow<CijferlijstA>>) {
		tableModel.showExtraFooters = true;

		if (context.groupNames.length == 1) {
			// 'Totaal'-label staat normaal in de footer van de eerste kolom, maar als alle dynamische groeperingen weg zijn gehaald is dit
			// cf_nm_leerling, en daar willen we iets anders in zetten. In de "+" kolom is nog wel plaats...
			tableModel.getColumnDef('addGroup').footer.getValue = () => 'Totaal';
		}

		const leerlingFooter = tableModel.getColumnDef('cf_nm_leerling').footer;
		leerlingFooter.getValue = (tableModel) => {
			const count = last(this.getPathToTableRoot(context, tableModel))!.r.length;
			return `${count} leerling${count == 1 ? '' : 'en'}`;
		};

		leerlingFooter.clickHandler = (tableModel) => {
			const linkData = this.createLinkData(this.getPathToTableRoot(context, tableModel), context);
			const tekortpuntenFilter =
				this.tekortpuntenKeuze == 0 ? [] : [new BasicFilterExpression(['cf_fun_sum_tekortpunten'], this.tekortpuntenKeuze, '>=')];
			const filter = new CompoundFilterExpression([...(linkData.filter as CompoundFilterExpression).filters, ...tekortpuntenFilter]);
			this.urlService.navigate({ ...linkData, dataProvider: 'cijfers_per_leerling', filter });
		};
	}

	getPathToTableRoot(context: DashboardContext<CijferlijstI, CijferlijstA, CijferlijstComponent>, tableModel: TableModel<DataRow<CijferlijstA>>) {
		const tableLevel = context.groupNames.length == 1 ? 0 : 1;
		return tableModel.data[0]._path.slice(0, tableLevel + 1);
	}

	getSelectedTekortpuntenOptie() {
		return this.tekortpuntenKeuzeOpties.find((optie) => optie.value === this.tekortpuntenKeuze)!;
	}

	getSelectedSorteringOptie() {
		return this.sorteringKeuzeOpties.find((optie) => optie.value === this.sorteringKeuze)!;
	}

	// memoize, otherwise new array keeps triggering change detection
	getFirstGroup = memoize(CijferlijstComponent._getFirstGroup, JSON.stringify);

	private static _getFirstGroup(groups: AttrPath[]): AttrPath[] {
		if (groups.length == 0) return [];
		return [groups[0]];
	}

	// memoize, otherwise new array keeps triggering change detection
	getOtherGroups = memoize(CijferlijstComponent._getOtherGroups, JSON.stringify);

	private static _getOtherGroups(groups: AttrPath[]): AttrPath[] {
		return [...groups.slice(1), ['cf_nm_leerling']];
	}

	protected singleAggregators: Partial<{ [ai in keyof CijferlijstA]: SingleAggregator<CijferlijstI, CijferlijstA[ai]> }> = {
		sum_tekortpunten: sumOver('cf_nr_tekortpunten'),
		decimalen: aggDecimals('cf_nr_decimalen'),
	};

	protected multiAggregators: MultiAggregator<keyof CijferlijstA, CijferlijstI, CijferlijstA, number | null>[] = [
		weightedAverage('gem_cijfer', 'cf_nr_cijfer'),
	];

	createLinkData(
		path: Path<CijferlijstA, number[]>,
		context: DashboardContext<CijferlijstI, CijferlijstA, CijferlijstComponent>
	): Partial<LinkData> {
		const linkData = super.createLinkData(path, context);
		return {
			...linkData,
			dashboard: '/details/leerling/cijferlijst',
			dataProvider: 'cijfers',
		};
	}

	getDisplayOptions(): ExportFilter[] {
		return [{ label: 'Tekortpunten', value: this.tekortpuntenKeuze ? `${this.tekortpuntenKeuze} of meer` : 'Alle' }];
	}
}
