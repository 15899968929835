/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ExamencijfersUitzondering {
  REGULIER_EXAMENCIJFER = 'Regulier examencijfer',
  ASSISTENTENOPLEIDING_ENTREEOPLEIDING = 'Assistentenopleiding/Entreeopleiding',
  VAVO_OPLEIDING = 'VAVO-opleiding',
  VAKMANSCHAP_TECHNOLOGIE_ROUTE = 'Vakmanschap/technologie-route',
  NIET_BEKOSTIGBAAR = 'Niet bekostigbaar',
  NIET_GESLAAGD_OF_AFGEWEZEN = 'Niet geslaagd of afgewezen',
  IN_EERDER_JAAR_GEEXAMINEERD_VAN_GESPREID_EXAMEN = 'In eerder jaar ge\xEBxamineerd van gespreid examen'
}
