<app-base-dialog sizeClass="size-34" [multiRow]="true" [topBarTemplate]="topBar" [bottomBarTemplate]="bottomBar">
	<ng-template #topBar>
		<app-dialog-header title="Rol toekennen"></app-dialog-header>
	</ng-template>
	<div class="top">
		<h3>Selecteer de gebruiker die je de rol Sectieleider wil toekennen</h3>
	</div>
	<div class="control">
		<div class="label">Gebruiker</div>
		<app-beheer-dropdown
			[(selectie)]="data.account"
			[opties]="data.alleAccounts"
			[id]="getAccountId"
			[zoekString]="getAccountZoekString"
			[label]="getAccountLabel"
			[enabled]="selectAccountEnabled"
		></app-beheer-dropdown>
	</div>
	<div>Selecteer de vakken waar deze gebruiker sectieleider van is.</div>
	<div class="control">
		<div class="label">Vakken</div>
		<app-beheer-multi-dropdown
			class="wide"
			[(selectie)]="selectedVakken"
			[opties]="getAlleVakken(data.account)"
			[id]="getVakId"
			[zoekString]="getVakZoekString"
			[label]="getVakLabel"
			placeholder="Selecteren..."
		></app-beheer-multi-dropdown>
	</div>
	<ng-template #bottomBar>
		<app-button buttonType="secondary" (clickit)="dialogRef.close()">Annuleren</app-button>
		<app-button [enabled]="data.account !== undefined && selectedVakken.length > 0" (clickit)="dialogRef.close(returnAccount())">
			Toekennen
		</app-button>
	</ng-template>
</app-base-dialog>
