import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { range } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { addMonths, getISOWeekAndYear } from '@cumlaude/shared-utils';
import { TooltipDirective, TooltipType } from '@cumlaude/shared-components-overlays';
import { AsyncPipe, DatePipe, TitleCasePipe } from '@angular/common';

export interface DayItem {
	weight: number;
	className: string;
}
export interface Day {
	otherMonth?: boolean;
	date: Date;
	items?: DayItem[];
	classNames?: string[] | Object;
	tooltip?: TooltipType;
}

export interface Week {
	days: Day[];
	number: number;
}

export interface Month {
	start: Date;
	weeks: Week[];
}

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
	standalone: true,
	imports: [TooltipDirective, AsyncPipe, TitleCasePipe, DatePipe],
})
export class CalendarComponent<D> implements OnChanges {
	@Input()
	data!: Observable<D>;

	months!: Observable<Month[]>;

	@Input()
	begin!: Observable<Date>;

	@Input()
	generateDay!: (date: Date, data: D) => Day;

	@Input()
	nrOfMonths = 12;

	@Input()
	showWeekdays = false;

	@Input()
	showWeekNumbers = true;

	@Input()
	showWeekends = false;

	@Input()
	showOtherMonthDates = false;

	@Input()
	showNext = false;

	@Input()
	showPrevious = false;

	@Output()
	onClick = new EventEmitter<Date>();

	protected rawHover = new EventEmitter<Date | null>();

	@Output()
	// Een klein beetje debounce en vergelijking op timestamp om knipperen te voorkomen
	onHover = this.rawHover.pipe(
		debounceTime(10),
		distinctUntilChanged((a: Date | null, b: Date | null) => a?.getDate() === b?.getDate())
	);

	@Output()
	previousMonth = new EventEmitter<void>();

	@Output()
	nextMonth = new EventEmitter<void>();

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.data) {
			this.months = combineLatest([this.data, this.begin]).pipe(
				map(([data, begin]) => this.generateMonths(begin, (d) => this.generateDay(d, data)))
			);
		}
	}

	generateMonths(begin: Date, generateDay: (date: Date) => Day): Month[] {
		return range(this.nrOfMonths)
			.map((i) => addMonths(begin, i))
			.map((start) => {
				const month: Month = { start: new Date(start), weeks: [] };
				const d = new Date(start);
				const dezeMaand = d.getMonth();
				const volgendeMaand = (dezeMaand + 1) % 12; // getMonth() is 0-based; december/11 => januari/0
				d.setHours(0, 0, 0, 0); // middernacht
				d.setDate(1); // naar eerste van de maand
				if (!this.showWeekends && [6, 0].includes(d.getDay())) {
					// valt 1e v/d maand in het weekend?
					// dan naar volgende maandag
					d.setDate(8 - ((d.getDay() + 6) % 7));
				} else {
					// anders naar vorige maandag
					d.setDate(1 - ((d.getDay() + 6) % 7));
				}

				while (d.getMonth() !== volgendeMaand) {
					const week: Week = { number: getISOWeekAndYear(d).week, days: [] };
					for (let j = 0; j < (this.showWeekends ? 7 : 5); j++) {
						if (dezeMaand === d.getMonth() || this.showOtherMonthDates)
							week.days.push({ ...generateDay(d), otherMonth: dezeMaand !== d.getMonth() });
						else week.days.push({ date: new Date(d), otherMonth: true });
						d.setDate(d.getDate() + 1);
					}
					if (!this.showWeekends) d.setDate(d.getDate() + 2);
					month.weeks.push(week);
				}

				return month;
			});
	}
}
