/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum Instroom {
  INTERN = 'Intern',
  INTERN_TUSSENTIJDS = 'Intern (tussentijds)',
  EXTERN = 'Extern',
  EXTERN_TUSSENTIJDS = 'Extern (tussentijds)'
}
