<div class="sort-headers">
	@for (sortHeader of sortHeaders; track sortHeader) {
		<div class="sort-header" (click)="changeSortOrder(sortHeader)">
			<span class="header">{{ sortHeader.label }}</span>
			@if (sortState.active == sortHeader.sortTarget.join('.')) {
				<span [class.svg-arrow-down]="sortState.direction === 'desc'" [class.svg-arrow-up]="sortState.direction === 'asc'"></span>
			}
		</div>
	}
</div>
