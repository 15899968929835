import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, NavigationExtras, Params, Router } from '@angular/router';
import { isArray, isNil, last } from 'lodash-es';
import { LinkData } from '../shared/dashboard/base-dashboard/base-dashboard-config';
import { UserService } from './user.service';
import { combineLatestWith, filter, ReplaySubject, Subscription } from 'rxjs';
import { QueryParamStateService } from './query-param-state.service';
import { map } from 'rxjs/operators';
import { PageStateService, PsName } from './page-state.service';
import { FilterExpression, flattenFilters } from './data.service';

export interface RouteData {
	title: string;
	from: string;
	path: string;
}

@Injectable({
	providedIn: 'root',
})
export class UrlService implements OnDestroy {
	private subscriptions: Subscription[] = [];
	routeTree$ = new ReplaySubject<string[]>(1);
	routeData$ = new ReplaySubject<RouteData>(1);

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		qp: QueryParamStateService,
		ps: PageStateService
	) {
		this.subscriptions.push(
			router.events
				.pipe(
					filter((event) => event instanceof ActivationEnd),
					map((event) => event as ActivationEnd)
				)
				.subscribe((event) => this.routeTree$.next(this.getRouteTree(event.snapshot.root))),
			this.routeTree$
				.pipe(combineLatestWith(qp.observe('variant'), ps.observe(PsName.prognose), ps.observe(PsName.subject)))
				.subscribe(([routeTree, variant, prognose, subject]) => this.updateRouteData(routeTree, variant, prognose === 'true', subject))
		);
	}

	private updateRouteData(routeTree: string[], variant?: string, prognose?: boolean, subject?: string) {
		let tree = [...routeTree];
		if (subject) tree = tree.map((value) => value.replace('%subject%', subject));

		let path = tree.join(' - ');
		if (prognose) path = path.replace('%prognose%', 'Prognose');
		else path = path.replace('%prognose%', '').trim();

		if (variant) path += ` - ${variant}`;

		const from = tree[0] === 'Details' ? tree[1] : tree[0];

		const title = tree[tree.length - 1]?.replace('%prognose%', prognose ? 'Prognose' : '').trim();

		const routeData = <RouteData>{
			title,
			from,
			path,
		};
		this.routeData$.next(routeData);
	}

	getFrom(): string {
		const root = this.activatedRoute.snapshot.root;
		const tree = this.getRouteTree(root);
		return tree[0] === 'Details' ? tree[1] : tree[0];
	}

	private getRouteTree(route: ActivatedRouteSnapshot): string[] {
		const firstChild = route.firstChild;
		const childTree = isNil(firstChild) ? [] : this.getRouteTree(firstChild);
		const routeTree = [route.data?.name];
		if (route.data.bv_nm_vaardigheid) routeTree.push(route.data.bv_nm_vaardigheid);
		return [...routeTree, ...childTree].filter((value) => value);
	}

	getRouterLink(linkData?: Partial<LinkData>): string | undefined {
		if (!linkData) return undefined;

		const urlRequested = linkData.dashboard;
		if (!urlRequested) return undefined;

		const urlAllowed = this.userService.checkUrlForRol(urlRequested);
		if (urlAllowed === true) return urlRequested;
		if (urlAllowed === false) return undefined;
		return urlAllowed.toString();
	}

	navigate(linkData: Partial<LinkData>) {
		const urlRequested = linkData.dashboard;
		if (!urlRequested) return;

		const urlAllowed = this.userService.checkUrlForRol(urlRequested);
		if (urlAllowed === false) return;

		const urlTree = urlAllowed === true ? this.router.parseUrl(urlRequested) : urlAllowed;
		this.router.navigateByUrl(Object.assign(urlTree, { queryParams: this.getQueryParams(linkData)!, queryParamHandling: 'merge' }));
	}

	getQueryParams(linkData?: Partial<LinkData>): { sp?: string | string[]; sf?: string | string[]; from: string } | undefined {
		if (!linkData) return undefined;

		const { dashboard, dataProvider, filter } = linkData;
		if (!dashboard) return undefined;

		const schooljaar = this.getSchooljaarFromFilter(linkData.filter);

		const selectionFilter = isArray(filter) ? filter.map((f) => JSON.stringify(f)) : JSON.stringify(filter);
		return {
			...(schooljaar ? { schooljaar } : {}),
			sp: dataProvider!,
			sf: selectionFilter,
			from: this.getFrom(),
		};
	}

	private getSchooljaarFromFilter(filter?: FilterExpression | FilterExpression[]): string | undefined {
		if (!filter) return undefined;

		const filters = flattenFilters(filter);
		let schooljaar = last(filters.filter((filter) => filter.attr.join('.').includes('nm_schooljaar_van')));
		if (!schooljaar) schooljaar = last(filters.filter((filter) => filter.attr.join('.').includes('nm_schooljaar')));

		if (!schooljaar) return undefined;

		const value = schooljaar.val;
		return isArray(value) ? last(value) : value;
	}

	redirect(url: string[], queryParams: Params, queryParamsHandling: 'merge' | 'preserve' | '' | null = 'merge', extras: NavigationExtras = {}) {
		return this.router.navigate(url, { ...extras, queryParams, queryParamsHandling });
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}
}
