export enum RolRestriction {
	DOCENT = 'DOCENT',
	SECTIELEIDER = 'SECTIELEIDER',
	SCHOOLBREED = 'SCHOOLBREED',
	BEHEERDER = 'BEHEERDER',
	TEAMLEIDER = 'TEAMLEIDER',
	TEAMLEIDER_VIA_LEERLING = 'TEAMLEIDER_VIA_LEERLING',
	MENTOR = 'MENTOR',
	BESTUUR = 'BESTUUR',
}
