@if (groups$ | async; as groups) {
	<div cdkDropListGroup cdkScrollable class="content">
		@for (group of groups; track group) {
			<div cdkDropList [cdkDropListData]="group" cdkDropListLockAxis="y" (cdkDropListDropped)="drop($event)">
				@if (group.map) {
					<div class="map">
						<i
							[class]="group.map.id && opened.has(group.map.id) ? 'icon-arrow-open' : 'icon-arrow'"
							(click)="toggleMapOpen(group.map!.id)"
						>
						</i>
						<span class="svg-folder" (click)="toggleMapOpen(group.map!.id)"></span>
						@if (editingMap !== group.map!.id) {
							<span class="map-naam" (click)="toggleMapOpen(group.map!.id)" [title]="group.map!.naam">
								{{ group.map!.naam }}
							</span>
							<app-meatball-menu>
								<app-meatball-menu-item icon="icon-edit" (click)="editingMap = group.map!.id">Bewerken</app-meatball-menu-item>
								<app-meatball-menu-item icon="icon-delete" (click)="confirmDeleteMap(group)">Verwijderen</app-meatball-menu-item>
							</app-meatball-menu>
						} @else {
							<app-immediate-input
								class="map-naam"
								[init]="group.map!.naam"
								(done)="createOrUpdateFavorietMap(group.map!, $event)"
							></app-immediate-input>
						}
					</div>
				}
				@if (!group.map || opened.has(group.map!.id!)) {
					<div class="favorieten" [class.in-map]="group.map">
						@for (favoriet of group.items; track favoriet) {
							<div cdkDrag (cdkDragStarted)="dragActive = true" [cdkDragData]="favoriet" class="favoriet">
								<div *cdkDragPlaceholder></div>
								@if (editingFavoriet !== favoriet.id!) {
									<span class="favoriet-naam" (click)="openFavoriet(favoriet.state)" [title]="favoriet.naam">
										{{ favoriet.naam }}
									</span>
									<app-meatball-menu>
										<app-meatball-menu-item icon="icon-edit" (click)="editingFavoriet = favoriet.id!"
											>Bewerken</app-meatball-menu-item
										>
										<app-meatball-menu-item icon="icon-delete" (click)="confirmDeleteFavoriet(favoriet)"
											>Verwijderen</app-meatball-menu-item
										>
									</app-meatball-menu>
								} @else {
									<app-immediate-input
										class="favoriet-naam"
										[init]="favoriet.naam"
										(done)="updateFavoriet(favoriet, $event)"
									></app-immediate-input>
								}
							</div>
						}
						@if (group.items.length == 0) {
							<div class="geen-favorieten" [class.in-map]="group.map">
								@if (group.map) {
									<span>Deze map is leeg.</span>
								} @else if (groups.length === 1) {
									<span>Je hebt nog geen favorieten.</span>
								}
							</div>
						}
					</div>
				}
			</div>
		}
	</div>
}

<ng-template #deleteMapDialog let-group>
	<app-confirm-dialog action="Verwijderen" caption="Map verwijderen">
		<span>
			Je staat op het punt om de map <b>{{ group.map!.naam }}</b> te verwijderen.
		</span>
		@if (group.items.length > 0) {
			<span>Deze map bevat favorieten die verloren zullen gaan als je de map verwijdert.</span>
		}
		<span>Weet je het zeker?</span>
	</app-confirm-dialog>
</ng-template>

<ng-template #deleteFavorietDialog let-favoriet>
	<app-confirm-dialog action="Verwijderen" caption="Favoriet verwijderen">
		Je staat op het punt om de favoriet <b>{{ favoriet.naam }}</b> te verwijderen. Weet je het zeker?
	</app-confirm-dialog>
</ng-template>
