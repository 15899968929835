<table [class]="className">
	<tr>
		<td>{{ data.seriesKey }}</td>
	</tr>
	<tr>
		@for (key of data.colKeys; track key) {
			<td>{{ key }}</td>
		}
	</tr>
</table>
