<app-dashboard-container
	class="dashboard-cohortrendement"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
>
	<app-filter-panel
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions"
	>
	</app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header [variant]="variant" [legenda]="['x_cohortrendement']"></app-dashboard-header>
		<div class="groepering">
			<div class="weergave cohortrendementtype">
				Type
				<dt-form-dropdown
					[opties]="typeOpties"
					[selectedOptie]="typeOptie"
					(onOptieClicked)="qp.dispatch('cohortrendementtype', $event)"
				></dt-form-dropdown>
			</div>
			<div class="weergave cohortrendementweergave">
				Weergave
				<dt-form-dropdown
					[opties]="weergaveOpties"
					[selectedOptie]="weergaveOptie"
					(onOptieClicked)="qp.dispatch('cohortrendementweergave', $event)"
				></dt-form-dropdown>
			</div>
		</div>
		@switch (variant) {
			@case ("Actueel") {
				<app-barchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="selectedGroups"
					[subgroups]="subgroups"
					[config]="this"
				>
				</app-barchart-table>
			}
			@case ("Historie") {
				<app-vbarchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="getHistorieGroups(selectedGroups)"
					[subgroups]="getHistorieSubgroups(selectedGroups, subgroups)"
					[batchLevels]="isHistorieBatchVariant() ? 1 : 0"
					[config]="this"
					[partitionMeasure]="getPartitionMeasure(weergaveOptie)"
					[schooljaarPath]="['ds_nm_schooljaar_van']"
				>
				</app-vbarchart-table>
			}
		}
	</section>
</app-dashboard-container>
