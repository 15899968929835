<app-dashboard-container
	class="dashboard-overgang"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
>
	<app-filter-panel
		[defaultFilters]="variant() === DashboardVariant.ACTUEEL ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions"
	>
	</app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header [variant]="variant()" [legenda]="['x_met_overgang']"></app-dashboard-header>
		@if (variant() === DashboardVariant.HISTORIE) {
			<div class="groepering">
				<app-weergave-optie label="Eenheid" param="eenheid" [value]="eenheid()" [enum]="Eenheid"></app-weergave-optie>
			</div>
		}
		@switch (variant()) {
			@case (DashboardVariant.ACTUEEL) {
				<app-barchart-table
					[defaultGroups]="[defaultGroup]"
					[groups]="determineGroups(group)"
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[config]="this"
				></app-barchart-table>
			}
			@case (DashboardVariant.HISTORIE) {
				<app-vbarchart-table
					[defaultGroups]="[defaultGroup]"
					[groups]="getHistorieGroups(determineGroups(group))"
					[subgroups]="getHistorieSubgroups(determineGroups(group))"
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[batchLevels]="1"
					[config]="this"
					[schooljaarPath]="['ds_nm_schooljaar_van']"
					[partitionMeasure]="partitionMeasure()"
				></app-vbarchart-table>
			}
		}
	</section>
</app-dashboard-container>
