import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from '../utils/option';

@Component({
	selector: 'app-multi-select-text',
	templateUrl: './multi-select-text.component.html',
	styleUrls: ['./multi-select-text.component.scss'],
	standalone: true,
})
export class MultiSelectTextComponent<T> {
	@Input()
	options!: Option<T>[];

	@Input()
	value!: Option<T>[];

	@Input()
	minimum = 0;

	@Input()
	blockedHover?: string;

	@Output()
	valueChange = new EventEmitter<Option<T>[]>();

	onSelected(option: Option<T>) {
		if (this.isBlocked(option)) return;

		if (this.value.includes(option)) this.value = this.value.filter((opt) => opt != option);
		else this.value = [...this.value, option];

		this.valueChange.emit(this.value);
	}

	isSelected(option: Option<T>) {
		return this.value.includes(option);
	}

	isBlocked(option: Option<T>): boolean {
		return this.value.length <= this.minimum && this.isSelected(option);
	}
}
