import { Component } from '@angular/core';
import { RestService } from '@cumlaude/shared-services';
import { DataService, QueryFilterExpression } from '../../services/data.service';
import { AccountsService } from '../../services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { pull } from 'lodash-es';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Dialog } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { TableComponent } from '../../shared/components/table/table/table.component';
import { CheckboxComponent, InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { UserService } from '../../services/user.service';
import { RCumLaudeAccount, RVestiging } from '@cumlaude/service-contract';
import { TooltipDirective, TooltipElement } from '@cumlaude/shared-components-overlays';
import { DimVsVestiging } from '@cumlaude/metadata';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { LoadingService, LoadingType } from '../../services/loading.service';
import { Orientation } from '../../services/export.service';
import { saveAs } from 'file-saver';
import { accountLabelMetAfkorting, getTimestamp } from '@cumlaude/shared-utils';
import { ExportColumnDef, ExportTable } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { CssSpinnerComponent } from '../../layout/css-spinner/css-spinner.component';

interface InstellingVestiging {
	rVestiging: RVestiging;
	vestiging?: DimVsVestiging;
}

@Component({
	selector: 'app-vestigingen',
	templateUrl: './vestigingen.component.html',
	styleUrls: ['./vestigingen.component.scss'],
	standalone: true,
	imports: [InstantSearchBoxComponent, TableComponent, AsyncPipe, CheckboxComponent, TooltipDirective, ButtonComponent, CssSpinnerComponent],
})
export class VestigingenComponent {
	alleVestigingen$: Observable<InstellingVestiging[]>;
	filter$: Observable<string>;
	accounts$: Observable<RCumLaudeAccount[]>;
	showExportSpinner$: Observable<boolean>;

	constructor(
		protected restService: RestService,
		protected dataService: DataService,
		protected userService: UserService,
		protected accountsService: AccountsService,
		protected dialog: Dialog,
		protected toastr: ToastrService,
		protected loadingService: LoadingService
	) {
		this.alleVestigingen$ = combineLatest([userService.beheerRVestigingen$, dataService.getVestigingen()]).pipe(
			map(([rVestigingen, vestigingen]) => {
				return rVestigingen.map((rVestiging) => {
					const vestiging = vestigingen.find((vestiging) => vestiging.vs_id_vestiging === rVestiging.vestigingId);
					return { rVestiging, vestiging };
				});
			}),
			shareReplay(1)
		);
		this.showExportSpinner$ = loadingService.shouldShowLoadingIndicator(LoadingType.EXPORT);
		this.filter$ = this.accountsService.filter$.pipe(map(({ quicksearch }) => quicksearch ?? ''));
		this.accounts$ = this.accountsService.list$;
		this.accountsService.filter$.next({ quicksearch: '' });
	}

	search(quicksearch: string) {
		this.accountsService.filter$.next({ quicksearch });
	}

	toggle(account: RCumLaudeAccount, vestigingId: string, value: boolean) {
		if (account.vestigingen.includes(vestigingId) === value) return;
		if (value) account.vestigingen.push(vestigingId);
		else pull(account.vestigingen, vestigingId);
		this.accountsService.update$.next([account, () => {}, () => {}]);
	}

	getTooltip(vestiging: InstellingVestiging): TooltipElement[] | undefined {
		if (!vestiging.vestiging) return;

		const brins = vestiging.vestiging.vs_is_actief ? vestiging.vestiging.vs_cos_brinvest_actief! : vestiging.vestiging.vs_cos_brinvest!;

		return [
			{
				label: 'Naam:',
				value: vestiging.rVestiging.naam,
			},
			{
				label: 'Brin(s):',
				value: brins.join(', '),
			},
		];
	}

	async export() {
		const data = await this.generateExportTable();

		try {
			const blob = await firstValueFrom(this.dataService.getTableExport(data));
			saveAs(blob, `CumLaude vestigingen ${getTimestamp()}.xlsx`);
			this.loadingService.stop(LoadingType.EXPORT);
			this.toastr.success('Export succesvol.');
		} catch (error) {
			console.error(error);
			this.toastr.error('Er ging iets mis bij het exporteren. Probeer opnieuw.');
		}
	}

	private async generateExportTable(): Promise<ExportTable> {
		const vestigingen = await firstValueFrom(
			this.alleVestigingen$.pipe(map((vestigingen) => vestigingen.map((vestiging) => vestiging.rVestiging)))
		);
		const accounts = await firstValueFrom(this.accounts$);

		const columns: ExportColumnDef[] = [
			{
				name: 'Naam',
				type: 'string',
			},
			{
				name: 'Afkorting',
				type: 'string',
			},
		];
		vestigingen.forEach((vestiging) =>
			columns.push({
				name: vestiging.naam,
				type: 'string',
			})
		);

		const rows: any[][] = accounts.map((account) => {
			const accountVestigingen = vestigingen.map((vestiging) => (account.vestigingen.includes(vestiging.vestigingId) ? 'Ja' : 'Nee'));
			return [account.naam, account.afkorting, ...accountVestigingen];
		});

		return {
			data: [
				{
					columns: columns,
					rows: rows,
				},
			],
			options: {
				title: 'Beheer > Vestigingen',
				f: new QueryFilterExpression([]),
				attrs: [],
				attrLabels: [],
				orientation: Orientation.PORTRAIT,
			},
		};
	}

	getAccountNaam(account: RCumLaudeAccount) {
		return accountLabelMetAfkorting(account);
	}
}
