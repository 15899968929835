<app-base-dialog sizeClass="size-47" [topBarTemplate]="topBar" [bottomBarTemplate]="bottomBar" [multiRow]="true">
	<ng-template #topBar>
		<app-dialog-header title="Rol toekennen"></app-dialog-header>
	</ng-template>
	<div class="top">
		<h3>Selecteer de gebruiker die je de rol Teamleider wil toekennen</h3>
	</div>
	<div class="control">
		<div class="label">Gebruiker</div>
		<app-beheer-dropdown
			[(selectie)]="data.account"
			[opties]="data.alleAccounts"
			[id]="getAccountId"
			[zoekString]="getAccountZoekString"
			[label]="getAccountLabel"
			[enabled]="selectAccountEnabled"
		></app-beheer-dropdown>
	</div>
	<div>Selecteer de niveaus en/of leerjaren, of de klassen waar deze gebruiker teamleider van is.</div>
	<div class="control-row">
		<div class="control">
			<div class="label">Niveau</div>
			<app-beheer-multi-dropdown
				[(selectie)]="selectedNiveaus"
				[opties]="alleNiveaus"
				[zoeken]="false"
				[enabled]="!selectedKlassen.length"
			></app-beheer-multi-dropdown>
		</div>
		<div class="control">
			<div class="label">Leerjaar</div>
			<app-beheer-multi-dropdown
				[(selectie)]="selectedLeerjaren"
				[opties]="alleLeerjaren"
				[zoeken]="false"
				[enabled]="!selectedKlassen.length"
			></app-beheer-multi-dropdown>
		</div>
		<div class="separator">of</div>
		<div class="control">
			<div class="label">Klas</div>
			<app-beheer-multi-dropdown
				[(selectie)]="selectedKlassen"
				[opties]="getAlleKlassen(data.account)"
				[id]="getKlasId"
				[zoekString]="getKlasZoekString"
				[label]="getKlasLabel"
				[enabled]="!selectedNiveaus.length && !selectedLeerjaren.length"
			></app-beheer-multi-dropdown>
		</div>
	</div>
	<ng-template #bottomBar>
		<app-button buttonType="secondary" (clickit)="dialogRef.close()">Annuleren</app-button>
		<app-button
			[enabled]="data.account !== undefined && selectedNiveaus.length + selectedLeerjaren.length + selectedKlassen.length > 0"
			(clickit)="dialogRef.close(returnAccount())"
		>
			Toekennen
		</app-button>
	</ng-template>
</app-base-dialog>
