import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-meatball-menu-item',
	templateUrl: './meatball-menu-item.component.html',
	styleUrls: ['./meatball-menu-item.component.scss'],
	standalone: true,
})
export class MeatballMenuItemComponent {
	@Input()
	icon?: string;
}
