@if (heeftAuthenticatieError) {
	<div class="melding">
		<div class="header">
			<i class="icon-large icon-synchroniseren"></i>
			<span class="text-head">Er is iets misgegaan</span>
		</div>
		<span class="text-small-content">Er is een fout opgetreden bij het inloggen. Probeer het nog een keer.</span>
		<div (click)="probeerOpnieuw()" class="button text-small-content-semi">Opnieuw proberen</div>
	</div>
}
