import { Component } from '@angular/core';
import { UitstroomtypeVso } from '@cumlaude/metadata';
import { AttrPath, BasicFilterExpression, FilterExpression } from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { UitzonderingComponent } from './uitzondering.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { SchooljaarIndicatorOverComponent } from '../../schooljaar-indicator-over/schooljaar-indicator-over.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';

@Component({
	selector: 'app-uitzondering-tussentijdse-uitstroom',
	templateUrl: './uitzondering.component.html',
	styleUrls: ['./uitzondering.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		ButtonComponent,
		DashboardHeaderComponent,
		TooltipDirective,
		SchooljaarIndicatorOverComponent,
		BarchartTableComponent,
	],
})
export class UitzonderingTussentijdseUitstroomComponent extends UitzonderingComponent {
	fixedBeforeGroups = 4;

	groups: AttrPath[] = [
		['ds_nm_tussentijdse_uitstroom_vso_uitzondering'],
		['ds_nm_uitstroomprofiel_vso_van'],
		['ds_fk_ll', 'll_nm_schoolsoort_svb'],
		['ds_fk_ll', 'll_des_uitschrijving'],
	];

	initialFilters: FilterName[] = [
		'x_prestatieanalyse_ds_schooljaar',
		'ds_fk_br_vest_van.br_co_brin',
		'ds_fk_ilt_van.ilt_nm_niveau',
		'ds_nr_leerjaar_van',
		'ds_nm_prestatieanalyse_vso_uitzondering',
		'ds_nm_tussentijdse_uitstroom_vso_uitzondering',
	];

	permanentFilterExpressions: FilterExpression[] = [
		new BasicFilterExpression(['ds_is_plaatsing_opeenvolgend'], 1), // Voorkom dubbelingen maar sta wel tussentijdse in/uitstroom toe
		new BasicFilterExpression(['ds_nm_uitstroomtype_vso'], UitstroomtypeVso.TUSSENTIJDSE_UITSTROOM),
		new BasicFilterExpression(['ds_fk_br_vest_van', 'br_is_vso'], 1),
	];
}
