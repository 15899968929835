<app-base-dialog sizeClass="size-25" [topBarTemplate]="topBar" [bottomBarTemplate]="bottomBar" [multiRow]="true">
	<ng-template #topBar>
		<app-dialog-header title="Wat is je ervaring met CumLaude?"></app-dialog-header>
		@if (!done) {
			<div class="radio-button-group">
				@for (option of options; track option) {
					<div class="radio-option" (click)="indruk = option">
						<label>
							<i [class]="icons[option]"></i>
							<input type="radio" [value]="option" />
							<i class="checkmark" [class]="indruk === option ? 'icon-radio-checked' : 'icon-radio-unchecked'"></i>
						</label>
					</div>
				}
			</div>
		}
	</ng-template>
	@if (done) {
		<h1>Bedankt voor je feedback</h1>
		<div class="metfoto">
			<i class="foto"></i>
			<div>
				<div class="tekst">We nemen jouw input mee in onze evaluaties en doorontwikkeling.</div>
				<div class="ondertekening">Karoline Heidrich<br />Product owner CumLaude</div>
			</div>
		</div>
	} @else {
		<div class="control">
			<div class="label">Opmerking</div>
			<textarea [(ngModel)]="opmerking" placeholder="Vertel hier waarom dit zo is."></textarea>
		</div>
	}
	<ng-template #bottomBar>
		@if (done) {
			<app-button (clickit)="dialogRef.close()">Klaar!</app-button>
		} @else {
			<app-button buttonType="secondary" (clickit)="dialogRef.close()">Annuleren</app-button>
			<app-button (clickit)="send()" [enabled]="indruk !== undefined || opmerking.length > 0">Verzenden</app-button>
		}
	</ng-template>
</app-base-dialog>
