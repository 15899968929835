<section aria-live="polite" class="container">
	<div class="header">
		<div class="header-rol-info">
			<h2>{{ getRolLabel() }}</h2>
			@if (moduleIsEnabled$ | async) {
				<div class="description">{{ getRolDescription() }}</div>
			}
		</div>
		@if (moduleIsEnabled$ | async) {
			@if (licentieMelding$ | async; as licentieMelding) {
				<div class="licentie-melding right" [class.warning]="licentieMelding.warning">
					<div class="licentie-melding-tekst">
						@for (tekst of licentieMelding.tekst; track $index; let last = $last) {
							<div class="licentie-melding-content">
								<span>{{ tekst }}</span>
								@if (licentieMelding.warning || licentieMelding.info) {
									@if (last) {
										<div
											[class.svg-alert]="licentieMelding.warning"
											[class.svg-info]="licentieMelding.info"
											appTooltip="Er zijn geen licenties meer beschikbaar. Je kunt geen nieuwe rollen toekennen. Trek rollen in of neem contact op met CumLaude Support om de mogelijkheden te bespreken."
										></div>
									} @else {
										<div class="empty"></div>
									}
								}
							</div>
						}
					</div>
				</div>
			}
		}
	</div>
	@switch (moduleIsEnabled$ | async) {
		@case (true) {
			<div class="controls">
				@if ({ value: filter$ | async }; as filter) {
					<div class="left">
						<app-instant-search-box [value]="filter.value!" (fire)="search($event)"></app-instant-search-box>
						@if (isToekennen()) {
							<app-button
								[enabled]="!((licentieMelding$ | async)?.info || ((licentieMelding$ | async)?.warning ?? false))"
								icon="icon-add"
								(clickit)="openRolToekennenDialog(filter.value!)"
							>
								Rol toekennen
							</app-button>
						}
					</div>
				}
			</div>
			@if (accountTableModel$ | async; as model) {
				<div class="list">
					@if (model.data.length > 0) {
						<app-table [model]="model"></app-table>
					} @else {
						<div>Geen gebruikers gevonden.</div>
					}
				</div>
			}
		}
		@default {
			<div class="description">
				Interesse in de module <span>{{ getModuleName() | titlecase }}</span
				>? Neem contact op met <a href="mailto:{{ envConfig.supportMail }}">CumLaude Support</a> om de mogelijkheden te bespreken!
			</div>
		}
	}
</section>
