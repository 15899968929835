/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ErrorMessageEnum {
  BENCHMARK_NOT_ALLOWED = 'BENCHMARK_NOT_ALLOWED',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
  UNKNOWN = 'UNKNOWN',
  CREDENTIALS_ALREADY_EXIST = 'CREDENTIALS_ALREADY_EXIST',
  CREDENTIALS_ALREADY_EXIST_HERE = 'CREDENTIALS_ALREADY_EXIST_HERE',
  EMAIL_INVALID = 'EMAIL_INVALID',
  IMMUTABLE_ROLE = 'IMMUTABLE_ROLE',
  MISSING_MODULE = 'MISSING_MODULE',
  MISSING_ROLE = 'MISSING_ROLE',
  NO_ACCESS = 'NO_ACCESS',
  QUERY_TIMEOUT = 'QUERY_TIMEOUT',
  UNSUFFICIENT_LICENSES = 'UNSUFFICIENT_LICENSES',
  INDICATOR_IQ_NO_LONGER_USED = 'INDICATOR_IQ_NO_LONGER_USED'
}
