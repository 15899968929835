<app-dashboard-container
	class="dashboard-plaatsing-advies"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, getPermanentExpressions(uitsluiten), getDisplayOptions(), $event)"
>
	<app-filter-panel
		[defaultFilters]="initialFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="getPermanentExpressions(uitsluiten)"
	></app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header [legenda]="['x_onderwijspositie_punten']" [variant]="variant"></app-dashboard-header>
		<div class="caption">
			<span>{{ getSchooljarenCaption() }}</span>
			<span class="svg-info" [appTooltip]="getSchooljarenTooltip()"></span>
		</div>
		<div class="groepering">
			<app-schooljaar-indicator-over [hideExclusief]="DRIE_SCHOOLJAREN_EXCL_2020"></app-schooljaar-indicator-over>
			<app-onderwijsresultaten-uitsluiten-filter></app-onderwijsresultaten-uitsluiten-filter>
		</div>
		<app-barchart-table
			[filters]="filterExpressions"
			[permanentFilters]="getPermanentExpressions(uitsluiten)"
			[defaultGroups]="defaultGroups"
			[groups]="determineGroups(selectedGroups, variant)"
			[subgroups]="determineSubgroups(selectedGroups)"
			[config]="this"
		></app-barchart-table>
	</section>
</app-dashboard-container>
