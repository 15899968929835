import { Component, Input } from '@angular/core';
import { IndicatorNaamPrestatieanalyse } from '@cumlaude/metadata';
import { LinechartComponent, LinechartProps } from '../../../shared/dashboard/linechart-table/linechart/linechart.component';
import { PrestatieanalyseA } from '../prestatieanalyse.component';

export interface PrestatieanalyseHistoryCellData {
	pv_nm_indicator: IndicatorNaamPrestatieanalyse;
	is_onvoldoende: boolean;
	linechartProps: LinechartProps<PrestatieanalyseA>;
}

@Component({
	selector: 'app-card-pa-history-cell',
	templateUrl: './card-pa-history-cell.component.html',
	styleUrls: ['./card-pa-history-cell.component.scss'],
	standalone: true,
	imports: [LinechartComponent],
})
export class CardPaHistoryCellComponent {
	@Input()
	data!: PrestatieanalyseHistoryCellData;
}
