import { firstValueFrom, Subject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@cumlaude/shared-authentication';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';

@Injectable({
	providedIn: 'root',
})
export class HttpCancelService {
	private cancelPendingRequests$ = new Subject<void>();

	private remoteCancelEnabled: boolean = false;

	constructor(
		private http: HttpClient,
		authService: AuthService,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration
	) {
		authService.loggedIn$.subscribe((loggedIn) => {
			this.remoteCancelEnabled = loggedIn;
		});
	}

	/** Cancels all pending Http requests. */
	public cancelPendingRequests(currentDashboard: string | null, targetDashboard: string) {
		if (this.remoteCancelEnabled) {
			if (this.isDetailsDashboard(currentDashboard, targetDashboard)) return;

			this.cancelPendingRequests$.next();
			firstValueFrom(this.http.post<void>(`${this.envConfig.dataUrl}/cancel-pending`, {}));
		}
	}

	/**
	 * Indien huidige en doel dashboard beiden tot dezelfde details subgroep (leerling of docent) behoren, dan niet cancelen.
	 */
	private isDetailsDashboard(currentDashboard: string | null, targetDashboard: string) {
		if (!currentDashboard) return false;

		if (!currentDashboard.startsWith('/details/leerling') && !currentDashboard.startsWith('/details/docent')) return false;

		if (currentDashboard.startsWith('/details/leerling') && targetDashboard.startsWith('/details/leerling')) return true;
		return currentDashboard.startsWith('/details/docent') && targetDashboard.startsWith('/details/docent');
	}

	public onCancelPendingRequests() {
		return this.cancelPendingRequests$.asObservable();
	}
}
