<div class="dashboard-doorstroom">
	<div class="top">
		<h3>{{ dashboardName }}</h3>
		<app-legenda class="right" [attribute]="['ds_nm_idu']" [includeNull]="true"></app-legenda>
	</div>
	<div class="scrollport">
		<div class="dummy"></div>
		<div #anchor class="anchor"></div>
		<div class="paths">
			@for (path of paths; track path) {
				<app-doorstroom-path [path]="path" data-exportable></app-doorstroom-path>
			}
		</div>
	</div>
</div>
