<button
	[class.icon-right]="icon && iconRight"
	[class.icon-left]="icon && !iconRight"
	[disabled]="!enabled"
	[class.disabled]="!enabled"
	[class]="buttonType"
	(click)="click($event)"
>
	@if (icon && !iconRight) {
		<i [class]="icon"></i>
	}
	<ng-content></ng-content>
	@if (icon && iconRight) {
		<i [class]="icon"></i>
	}
</button>
