import { DataTreeTableConfig } from '../data-tree-table/data-tree-table-config';
import { Path } from '../../../services/data-tree';
import { Attributes } from '../base-dashboard/base-dashboard-config';
import { DashboardContext } from '../base-dashboard/dashboard-context';
import { FilterService } from '../../../services/filter.service';
import { ToastrService } from 'ngx-toastr';
import { Axis } from '../../../services/axis';
import { TooltipElement } from '@cumlaude/shared-components-overlays';

export abstract class BarchartTableConfig<I extends Attributes, A extends Attributes> extends DataTreeTableConfig<I, A> {
	protected constructor(
		protected filterService: FilterService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	getBarchartQty(_path: Path<A, number[]>, _context: DashboardContext<I, A, DataTreeTableConfig<I, A>>): number | null {
		return 100;
	}

	getPartitionTooltip(_path: Path<A, number[]>): TooltipElement[] | undefined {
		return undefined;
	}

	createXAxis(_context: DashboardContext<I, A, DataTreeTableConfig<I, A>>): Axis {
		return { min: 0, max: 100, ticks: [] };
	}

	createYAxis(_context: DashboardContext<I, A, DataTreeTableConfig<I, A>>): Axis {
		return { min: 0, max: 100, ticks: [] };
	}
}
