import { Component, Input, OnInit } from '@angular/core';
import { TableCellComponent } from '../table/table/table.model';
import { BarInfo, BarInfoPct, buildPctStack } from '../../../services/stacked-bars';
import { UrlService } from '../../../services/url.service';
import { zipWith } from 'lodash-es';
import { Axis } from '../../../services/axis';
import { PxPipe } from '@cumlaude/shared-pipes';
import { RouterLink } from '@angular/router';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';

export interface HPartitionData {
	/** Een 2d array van bars. Deze worden geplaatst met gutters ertussen. */
	stacks: BarInfo[][];
	qty: number | null;
	barWidth: number;
	text?: string;
	axis: Axis;
}

type CSSTick = {
	label: string;
	width: number;
	showLine?: boolean;
};

type CSSArea = { width: number; className: string };

@Component({
	selector: 'app-hbar-partition',
	templateUrl: './hbar-partition.component.html',
	styleUrls: ['./hbar-partition.component.scss'],
	standalone: true,
	imports: [TooltipDirective, RouterLink, PxPipe],
})
export class HbarPartitionComponent implements TableCellComponent<HPartitionData>, OnInit {
	@Input() data!: HPartitionData;

	@Input()
	axis = true;

	gutterPx = 5;

	className = '';

	/**
	 * De partition uit de input wordt omgezet in één stack (lijst van bars): de verschillende stacks worden samengevoegd, met nieuwe bars voor gutters ertussenin.
	 */
	bars!: BarInfoPct[];

	constructor(protected urlService: UrlService) {}

	ngOnInit() {
		const gutterBar: BarInfo = { size: 0, text: '', className: 'gutter', tooltip: '', linkData: {} };
		this.bars = buildPctStack(this.data.stacks, this.qtyToPx(this.data.qty), this.gutterPx, gutterBar);
	}

	qtyToPx(qty: number | null): number {
		if (qty === null) return 0;

		const { min, max } = this.data.axis;
		return (this.data.barWidth * qty) / (max - min);
	}

	getFillerWidth() {
		return this.qtyToPx(Math.min(this.data.qty ?? 0, 0) - this.data.axis.min);
	}

	getWidthInPixels(qty: number, pct: number): number {
		return (this.qtyToPx(qty) / 100) * pct;
	}

	getCSSAreas(): CSSArea[] {
		const areas = this.data.axis.areas;
		if (!areas) return [];

		const { min, max } = this.data.axis;
		const qties = areas.map(({ qty }) => Math.min(Math.max(min, qty ?? max), max));
		const classes = areas.map(({ className }) => className);

		return zipWith([min, ...qties.slice(0, -1)], [...qties], classes, (lo, hi, className) => ({
			width: this.qtyToPx(hi - lo),
			className,
		})).filter((area) => area.width > 0);
	}

	getCSSTicks(): CSSTick[] {
		const ticks = this.data.axis.ticks;
		if (ticks.length === 0) return [];

		const { min, max } = this.data.axis;
		const qties = ticks.map(({ qty }) => Math.min(Math.max(min, qty), max));

		return zipWith([min, ...qties.slice(0, -1)], ticks, (lo, { qty: hi, label, showLine }) => ({
			width: this.qtyToPx(hi - lo),
			label,
			showLine,
		})).filter((area) => area.width > 0);
	}

	getExportValue(data: HPartitionData) {
		return data.qty;
	}
}
