<div class="container">
	<svg
		#svg
		[attr.viewBox]="getViewBox()"
		[attr.width]="getWidth() + 'px'"
		[attr.height]="getHeight() + 'px'"
		preserveAspectRatio="none"
		(click)="onClick($event)"
	>
		@if (props.yAxis.ticks.length > 0) {
			<g [attr.transform]="getYAxisTransform()">
				<line x1="0" y1="0" x2="0" [attr.y2]="gridHeight" class="grid1"></line>
				@for (tick of svgTicks; track tick) {
					<line
						x1="-3"
						[attr.y1]="tick.y"
						[attr.x2]="getWidth() - paddingLeft - paddingRight"
						[attr.y2]="tick.y"
						[attr.class]="tick.y === 0 ? 'grid1' : 'grid2'"
					></line>
				}
				@for (tick of svgTicks; track tick) {
					<g [attr.transform]="'translate(-5 ' + tick.y + ') scale(1 -1)'">
						<text x="0" y="0" text-anchor="end" dominant-baseline="middle">{{ tick.label }}</text>
					</g>
				}
			</g>
		}
		<g [attr.transform]="getXAxisTransform()">
			@if (props.yAxis.ticks.length === 0) {
				<line x1="0" y1="0" [attr.x2]="getWidth()" y2="0"></line>
			}
			@for (xlabel of xlabels; track xlabel; let i = $index) {
				<g [attr.transform]="'translate(' + i * intervalWidth + ' 0)'">
					<line x1="0" [attr.y1]="-gridHeight" x2="0" y2="3" class="grid3"></line>
					<g transform="translate(0 5) rotate(270)">
						<text x="0" y="0" text-anchor="end" dominant-baseline="middle">{{ xlabel }}</text>
					</g>
				</g>
			}
		</g>
		<g [attr.transform]="getGroupLabelTransform()">
			@for (groupLabel of groupLabels; track groupLabel) {
				<g [attr.transform]="'translate(' + groupLabel.ix * intervalWidth + ' 0)'">
					<line x1="0" [attr.y1]="0" x2="0" [attr.y2]="gridHeight + 3" class="grid1"></line>
				</g>
			}
		</g>
		@for (line of lines; track line) {
			<g [attr.transform]="getDataTransform()">
				@if (line.points.length === 1) {
					<circle [attr.cx]="getPointX(line)" [attr.cy]="getPointY(line)" [attr.class]="line.lineClass" r="2"></circle>
				} @else if (line.points.length > 1) {
					<polyline [attr.points]="getPointsString(line)" [attr.class]="line.lineClass" fill="none"></polyline>
				}
			</g>
		}
		<g [attr.transform]="getGroupLabelTransform()">
			@for (groupLabel of groupLabels; track groupLabel) {
				<g [attr.transform]="'translate(' + groupLabel.ix * intervalWidth + ' 0)'">
					<text x="4" y="0" text-anchor="start" dominant-baseline="hanging" class="groupLabel">{{ groupLabel.label }}</text>
				</g>
			}
		</g>
		@for (tooltip of tooltipData; track tooltip; let ix = $index) {
			<g [attr.transform]="getDataTransform()" [class]="'tooltip-area tooltip-area-' + ix">
				@for (p of tooltip.points; track p) {
					<circle [attr.cx]="p.x" [attr.cy]="p.y" [attr.class]="p.lineClass" r="2"></circle>
				}
			</g>
		}
	</svg>
</div>
