<app-dashboard-container (doExport)="this.doExport(filterExpressions!, [], [], $event)" [factTable]="factTable" class="dashboard-cijfers">
	<app-filter-panel
		(filterExpressionsChange)="filterExpressions = $event"
		[allFilters]="getAllFilters()"
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[permanentFilterExpressions]="getPermanentFilterForFilterValues(ownBrinFilter)"
		endpoint="/examencijfers"
	></app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header
			[legendaKeyIsClassName]="true"
			[legenda]="[weergave === 'Afwijking' ? 'x_afwijking_neg_pos' : 'x_percentiel_neg_gem_pos']"
			[variant]="variant"
		>
		</app-dashboard-header>
		@if (prognose) {
			<div class="sub-title">
				@switch (variant) {
					@case (DashboardVariant.ACTUEEL) {
						DUO heeft voor dit schooljaar (nog) geen landelijke examencijfers gepubliceerd. Voor het berekenen van het landelijk
						gemiddelde worden hier uitsluitend data van CumLaude gebruikt.
					}
					@case (DashboardVariant.HISTORIE) {
						DUO heeft voor {{ cumlaudeSchooljaren }} (nog) geen landelijke examencijfers gepubliceerd. Daarom wordt voor
						{{ cumlaudeSchooljaren?.includes(",") ? "deze jaren" : "dit jaar" }} het landelijk gemiddelde berekend met uitsluitend data
						van CumLaude.
					}
				}
			</div>
		}
		<div class="groepering">
			<div class="weergave">
				Weergave:
				<dt-form-dropdown
					(onOptieClicked)="qp.dispatch('afwijkingpercentiel', $event)"
					[opties]="weergaveOpties"
					[selectedOptie]="getSelectedWeergaveOptie()"
				></dt-form-dropdown>
			</div>
		</div>
		@switch (variant) {
			@case (DashboardVariant.ACTUEEL) {
				@switch (weergave) {
					@case (AfwijkingPercentielWeergave.AFWIJKING) {
						<app-barchart-table
							[filters]="filterExpressions"
							[defaultGroups]="defaultGroups"
							[groups]="getGroups(selectedGroups, variant)"
							[subgroups]="getSubgroups(selectedGroups, variant)"
							[config]="this"
						>
						</app-barchart-table>
					}
					@case (AfwijkingPercentielWeergave.PERCENTIEL) {
						<app-data-tree-table
							[filters]="filterExpressions"
							[defaultGroups]="defaultGroups"
							[groups]="getGroups(selectedGroups, variant)"
							[subgroups]="getSubgroups(selectedGroups, variant)"
							[config]="this"
						>
						</app-data-tree-table>
					}
				}
			}
			@case (DashboardVariant.HISTORIE) {
				<app-vbarchart-table
					[filters]="filterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="getGroups(selectedGroups, variant)"
					[subgroups]="getSubgroups(selectedGroups, variant)"
					[batchLevels]="1"
					[config]="this"
					[schooljaarPath]="['ekc_nm_schooljaar']"
					[style]="getVbarStyle()"
					[barchartSize]="112"
					[partitionMeasure]="getPartitionMeasure(weergave)"
				>
				</app-vbarchart-table>
			}
		}
	</section>
</app-dashboard-container>
