import { Component, OnInit, ViewChild } from '@angular/core';
import { DetailsDashboard } from '../../base-details-panel/details.dashboard';
import { DashboardMedewerker, MedewerkerDetail } from '../../Details';
import { lastValueFrom, ReplaySubject } from 'rxjs';
import { FilterService } from '../../../services/filter.service';
import { getSchooljaarForDate, getSchooljaarTovHuidig } from '@cumlaude/shared-utils';
import { isNil, memoize } from 'lodash-es';
import { AttrPath, BasicFilterExpression, DataService, FilterExpression } from '../../../services/data.service';
import { FilterName } from '../../../services/filter-config';
import { SortHeader } from '../../../shared/dashboard/card-list/card-sort-headers/card-sort-headers.component';
import { take } from 'rxjs/operators';
import { CijfersCardListConfig } from '../../../shared/dashboard/card-list/cijfers-card-list-config';
import { ExportOptions } from '../../../services/export.service';
import { ToastrService } from 'ngx-toastr';
import { CardListComponent } from '../../../shared/dashboard/card-list/card-list.component';
import { VbarSeriesComponent } from '../../../shared/dashboard/vbarchart-table/vbar-series/vbar-series.component';
import { CardCellFooterComponent } from '../../../shared/dashboard/card-list/card-cell-footer/card-cell-footer.component';
import { HbarPartitionComponent } from '../../../shared/components/hbar/hbar-partition.component';
import { CardCellHeaderComponent } from '../../../shared/dashboard/card-list/card-cell-header/card-cell-header.component';
import { DashboardHeaderComponent } from '../../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../../filter-panel/filter-panel.component';
import { AsyncPipe } from '@angular/common';

interface CijfersDocentG {
	'cf_fk_lb.lb_nm_leerfase': string | undefined;
	cf_nm_lesgroep: string | undefined;
}

@Component({
	selector: 'app-docent-cijfers-details-panel',
	templateUrl: './docent-cijfers-details-panel.component.html',
	styleUrls: ['./docent-cijfers-details-panel.component.scss'],
	standalone: true,
	imports: [
		FilterPanelComponent,
		DashboardHeaderComponent,
		CardListComponent,
		CardCellHeaderComponent,
		HbarPartitionComponent,
		CardCellFooterComponent,
		VbarSeriesComponent,
		AsyncPipe,
	],
})
export class DocentCijfersDetailsPanelComponent extends CijfersCardListConfig<CijfersDocentG> implements DetailsDashboard<MedewerkerDetail>, OnInit {
	medewerker$ = new ReplaySubject<DashboardMedewerker>(1);

	permanentFilterExpressions = [];

	filterExpressions?: FilterExpression[];

	actueelFilters: FilterName[] = [
		'cf_nm_schooljaar',
		'x_cijfertype',
		'cf_fun_periode',
		'x_cf_is_alternatievenormering',
		'cf_fk_ilt.ilt_nm_niveau',
		'cf_nr_leerjaar',
	];

	historieFilters: FilterName[] = ['x_cijfer_schooljaar_historie', 'x_cijfer_multiselect_schooljaar', ...this.actueelFilters.slice(1)];

	actueelGroups: AttrPath[] = [['cf_nm_lesgroep']];

	historieGroups: AttrPath[] = [['cf_fk_lb', 'lb_nm_leerfase']];

	subGroups: AttrPath[] = [['cf_nm_schooljaar']];

	actueelSortHeaders: SortHeader[] = [
		{ sortTarget: ['cf_nr_cijfer'], defaultDirection: 'desc', label: 'Cijfer' },
		{ sortTarget: ['cf_nm_lesgroep'], defaultDirection: 'asc', label: 'Lesgroep' },
	];

	historieSortHeaders: SortHeader[] = [{ sortTarget: ['cf_fk_lb', 'lb_nm_leerfase'], defaultDirection: 'asc', label: 'Leerfase' }];

	@ViewChild(DashboardHeaderComponent)
	dashboardHeaderComponent?: DashboardHeaderComponent;

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		protected toastr: ToastrService
	) {
		super(dataService, filterService, toastr);
	}

	doDetailsExport(exportOptions: ExportOptions) {
		lastValueFrom(this.medewerker$.pipe(take(1))).then((medewerker) =>
			this.doExport(
				this.filterExpressions!,
				this.getDashboardFilters(this.permanentFilterExpressions, medewerker.medewerker),
				[],
				exportOptions
			)
		);
	}

	ngOnInit(): void {
		this.subscribeToQueryParams();
		this.subscriptions.push(this.filterService.observe('cf_nm_schooljaar').subscribe((val) => this.qp.dispatch('schooljaar', val)));
	}

	subscribeToQueryParams() {
		this.subscriptions.push(this.qp.observe('variant').subscribe((variant) => (this.variant = variant)));
	}

	setSelected(selected: MedewerkerDetail, schooljaar?: string): void {
		let targetSchooljaar = schooljaar;
		if (!targetSchooljaar) {
			if (isNil(selected.mw_fun_d_uitdienst)) targetSchooljaar = getSchooljaarForDate(new Date(selected.mw_fun_d_uitdienst));
			else targetSchooljaar = getSchooljaarTovHuidig();
		}

		this.medewerker$.next({
			medewerker: selected,
			schooljaarInfo: targetSchooljaar,
		});
	}

	getSchooljaarFilterOverride = memoize(DocentCijfersDetailsPanelComponent._getSchooljaarFilterOverride);

	private static _getSchooljaarFilterOverride(schooljaarInfo: string) {
		return { cf_nm_schooljaar: schooljaarInfo };
	}

	getDashboardFilters = memoize(DocentCijfersDetailsPanelComponent._getDashboardFilters, (f, m) => JSON.stringify([f, m]));

	private static _getDashboardFilters(filters: FilterExpression[], medewerker: MedewerkerDetail): FilterExpression[] {
		return [...filters, new BasicFilterExpression(['cf_fks_mw'], medewerker.mw_pk_key, 'any')];
	}
}
