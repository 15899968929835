<app-base-dialog sizeClass="size-10-5">
	@if (options.type === 'PDF') {
		<div class="kop">Pagina-indeling</div>
		<dt-form-dropdown
			[opties]="orientationOptions"
			[selectedOptie]="findOption(options.orientation)"
			(onOptieClicked)="options.orientation = $event"
		></dt-form-dropdown>
	}
	<div class="kop">Tonen in export:</div>
	<div class="checks">
		<app-checkbox label="Paginatitel" [(value)]="options.showTitle"></app-checkbox>
		<app-checkbox label="Filters" [(value)]="options.showFilters"></app-checkbox>
		@if (options.type === 'PDF' || options.type === 'Afbeelding') {
			<app-checkbox label="Legenda" [(value)]="options.showLegenda"></app-checkbox>
		}
	</div>
	<app-button buttonType="text" (clickit)="dialogRef.close()">Gereed</app-button>
</app-base-dialog>
