import { ExportTable, findChildOrEmpty, getInitialAttributes, getRowLevel } from '../data-tree-table/data-tree-table';
import { BasicFilterExpression, CompoundFilterExpression, ExportDataOptions, InFilterExpression } from '../../../services/data.service';
import { Path, treeKey } from '../../../services/data-tree';
import { BarInfo } from '../../../services/stacked-bars';
import { HPartitionData } from '../../components/hbar/hbar-partition.component';
import { NO_MEASURE, VMeasure, VPartitionData } from '../vbarchart-table/vbar-series/vbar-series.component';
import { last, memoize, sortBy } from 'lodash-es';
import { getSchooljaarKort } from '@cumlaude/shared-utils';
import { Attributes, BaseDashboardConfig } from '../base-dashboard/base-dashboard-config';
import { DashboardContext } from '../base-dashboard/dashboard-context';
import { FilterService } from '../../../services/filter.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Directive, ViewChild } from '@angular/core';
import { CardListComponent, ExportCardListColumnDef } from './card-list.component';

@Directive()
export abstract class CardListConfig<I extends Attributes, G, A extends Attributes> extends BaseDashboardConfig<I, A> {
	clickable: boolean = false;

	protected constructor(
		protected filterService: FilterService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	generateHbarPartition(row: Path<A, number[]>, context: DashboardContext<I, A, CardListConfig<I, G, A>>): HPartitionData {
		const pathss = this.partitionBarData(getRowLevel(row), context);
		const stacks: BarInfo[][] = pathss.map((paths) =>
			paths
				.map((path) => {
					const attrs = getInitialAttributes<I>(context.subgroupNames, context.measureNames, path);
					return this.makeBar(attrs, path, context);
				})
				.filter((bar) => bar.size)
		);
		return {
			stacks: stacks,
			qty: 100,
			barWidth: 245,
			axis: { min: 0, max: 100, ticks: [] },
		};
	}

	// NB memoize key neemt niet de hele context mee
	generateVbarPartitions = memoize(this._generateVbarPartitions, (r, c) => JSON.stringify([treeKey(r), c.filter]));

	_generateVbarPartitions(row: Path<A, number[]>, context: DashboardContext<I, A, CardListConfig<I, G, A>>): VPartitionData[] {
		return this.getSchooljaren(context).map((sj) => {
			const schooljaarPath = [...row, findChildOrEmpty(last(row)!, sj)];
			const recss = this.partitionBarData(last(schooljaarPath)!, context).map((recs) =>
				recs
					.map((rec) => {
						const attrs = getInitialAttributes<I>(context.subgroupNames, context.measureNames, rec);
						return this.makeBar(attrs, rec, context);
					})
					.filter((bar) => bar.size)
			);

			return {
				stacks: recss,
				measure: this.getMeasure(schooljaarPath),
				label: getSchooljaarKort(sj),
				qty: this.getQty(schooljaarPath),
				linkData: this.createLinkData(schooljaarPath, context),
				path: schooljaarPath,
			};
		});
	}

	getMeasure(_path: Path<A, number[]>): VMeasure {
		return NO_MEASURE;
	}

	getQty(_path: Path<A, number[]>): number | null {
		return 100;
	}

	protected getSchooljaren(context: DashboardContext<I, A, CardListConfig<I, G, A>>): string[] {
		return sortBy(
			(<InFilterExpression<string[]>>(
				(<CompoundFilterExpression>context.filter).filters.find(
					(exp) => (<BasicFilterExpression<string>>exp).attr.join('.') == ['cf_nm_schooljaar'].join('.')
				)!
			)).val
		);
	}

	// Inject het gebruikte CardListComponent zodat we dit kunnen gebruiken voor de tabel-export.
	// NB subclasses van CardListComponent moeten met "providers" in hun decorator aangeven dat ze een CardListComponent zijn,
	// want de dependency injection van Angular heeft geen weet van inheritance.
	@ViewChild(CardListComponent)
	protected cardListComponent?: CardListComponent<I, G, A, CardListConfig<I, G, A>>;

	exportAsTable(options: ExportDataOptions): Observable<Blob> {
		if (this.cardListComponent) return this.cardListComponent.exportAsTable(options);
		return super.exportAsTable(options);
	}

	getExportTable(_context: DashboardContext<I, A, CardListConfig<I, G, A>>, _options: ExportDataOptions): ExportTable<A> | null {
		return null;
	}

	getExportColumnDefs(_context: DashboardContext<I, A, CardListConfig<I, G, A>>): ExportCardListColumnDef<I, G, A, CardListConfig<I, G, A>>[] {
		return [];
	}
}
