/**
 * Enums die alleen in de frontend gebruikt worden (bijv. om legenda's te produceren voor booleans of berekende attributen).
 */

export enum DefaultBooleanEnum {
	JA = 'Ja',
	NEE = 'Nee',
}

export enum APCG {
	APCG = 'APCG',
	GEEN_APCG = 'Geen APCG',
}

export enum Diplomavak {
	DIPLOMAVAK = 'Diplomavak',
	GEEN_DIPLOMAVAK = 'Geen diplomavak',
}

export enum ExamenOpstroom {
	EXAMENOPSTROOM = 'Examenopstroom',
	GEEN_EXAMENOPSTROOM = 'Geen examenopstroom',
}

export enum ExamenKandidaat {
	EXAMENKANDIDAAT = 'Examenkandidaat',
	GEEN_EXAMENKANDIDAAT = 'Geen examenkandidaat',
}

export enum ExamenOpleiding {
	EXAMENJAAR = 'Examenopleiding',
	GEEN_EXAMENJAAR = 'Geen examenopleiding',
}

export enum InExamendossier {
	IN_EXAMENDOSSIER = 'In examendossierdossier',
	NIET_IN_EXAMENDOSSIER = 'Niet in examendossier',
}

export enum InVoortgangsdossier {
	IN_VOORTGANGSDOSSIER = 'In voortgangsdossier',
	NIET_IN_VOORTGANGSDOSSIER = 'Niet in voortgangsdossier',
}

export enum Is1OktPlaatsing {
	WEL_1OKT_PLAATSING = '1-oktoberplaatsing',
	GEEN_1OKT_PLAATSING = 'Geen 1-oktoberplaatsing',
}

export enum Is1FebrPlaatsing {
	WEL_1FEBR_PLAATSING = '1-februariplaatsing',
	GEEN_1FEBR_PLAATSING = 'Geen 1-februariplaatsing',
}

export enum Is1JanPlaatsing {
	WEL_1JAN_PLAATSING = '1-januariplaatsing',
	GEEN_1JAN_PLAATSING = 'Geen 1-januariplaatsing',
}

export enum Is1AprilPlaatsing {
	WEL_1APRIL_PLAATSING = '1-aprilplaatsing',
	GEEN_1APRIL_PLAATSING = 'Geen 1-aprilplaatsing',
}

export enum Is1JuliPlaatsing {
	WEL_1JULI_PLAATSING = '1-juliplaatsing',
	GEEN_1JULI_PLAATSING = 'Geen 1-juliplaatsing',
}

export enum Bekostigd {
	BEKOSTIGD = 'Bekostigd',
	NIET_BEKOSTIGD = 'Niet bekostigd',
}

export enum Bevorderd {
	BEVORDERD = 'Bevorderd',
	NIET_BEVORDERD = 'Niet bevorderd',
}

export enum LaatstePlaatsingSchooljaar {
	LAATSTE_PLAATSING_SJ = 'Laatste plaatsing',
	NIET_LAATSTE_PLAATSING_SJ = 'Niet de laatste plaatsing',
}

export enum Leerwerktraject {
	LEERWERKTRAJECT = 'Leerwerktraject',
	GEEN_LEERWERKTRAJECT = 'Geen leerwerktraject',
}

export enum LWOO {
	LWOO = 'LWOO',
	GEEN_LWOO = 'Geen LWOO',
}

export enum Nieuwkomer {
	NIEUWKOMER = 'Nieuwkomer',
	GEEN_NIEUWKOMER = 'Geen nieuwkomer',
}

export enum OnderwijspositiePunten {
	NIVEAU_LAGER_DAN_ADVIES = '-1 (niveau lager dan advies)',
	NIVEAU_GELIJK_AAN_ADVIES = '0 (niveau gelijk aan advies)',
	HAVO_VWO_PLAATSING_BIJ_HAVO_ADVIES = '+0,5 (havo/vwo plaatsing bij havo-advies)',
	NIVEAU_HOGER_DAN_ADVIES = '+1 (niveau hoger dan advies)',
}

export enum PlaatsingActueel {
	PLAATSING_ACTUEEL = 'Actuele plaatsing',
	PLAATSING_NIET_ACTUEEL = 'Niet-actuele plaatsing',
}

export enum PlaatsingStatus { // *_is_pl_voorlopig
	VOORLOPIG = 'Voorlopig',
	DEFINITIEF = 'Definitief',
}

export enum Prognose {
	PROGNOSE = 'Prognose',
	GEEN_PROGNOSE = 'Geen prognose',
}

export enum ReferentieNiveau {
	R00F = '0F',
	R05F = '0,5F, op weg naar 1F',
	R10F = '1F',
	R15F = '1,5F, op weg naar 2F',
	R20F = '2F',
	R25F = '2,5F',
	R30F = '3F',
	R35F = '3,5F',
	R40F = '4F',
	NIVEAU_GEMIDDELDE = 'Gemiddelde',
	GEMIDDELDE_LEERFASE = 'Gemiddelde leerfase',
	TARGET_GEMIDDELDE = 'Schooleigen norm',
}

export enum TeltMeeVoorSlagen {
	TELT_MEE = 'Telt mee voor slagen',
	TELT_NIET_MEE = 'Telt niet mee voor slagen',
}

export enum Overgang {
	MET_OVERGANG = 'Met overgang',
	ZONDER_OVERGANG = 'Zonder overgang',
}

export enum VakantieMidden {
	VAKANTIE_MIDDEN = 'Vakantie midden',
	GEEN_VAKANTIE_MIDDEN = 'Geen vakantie midden',
}

export enum VakantieNoord {
	VAKANTIE_NOORD = 'Vakantie noord',
	GEEN_VAKANTIE_NOORD = 'Geen vakantie noord',
}

export enum VakantieZuid {
	VAKANTIE_ZUID = 'Vakantie zuid',
	GEEN_VAKANTIE_ZUID = 'Geen vakantie zuid',
}

export enum Voldoende {
	VOLDOENDE = 'Voldoende',
	ONVOLDOENDE = 'Onvoldoende',
}

export enum Weekend {
	WEEKEND = 'Weekend',
	WEEKDAG = 'Weekdag',
}

export enum MetZonderCE {
	MET_CE = 'Alleen vakken met CE',
	ZONDER_CE = 'Vakken met SE en/of CE',
}

export enum NiveauVerschil {
	_25_NIVEAUS_LAGER_DAN_ADVIES = '≥ 2,5 niveaus lager dan advies',
	_20_NIVEAUS_LAGER_DAN_ADVIES = '2,0 niveaus lager dan advies',
	_15_NIVEAUS_LAGER_DAN_ADVIES = '1,5 niveaus lager dan advies',
	_10_NIVEAUS_LAGER_DAN_ADVIES = '1,0 niveaus lager dan advies',
	_05_NIVEAUS_LAGER_DAN_ADVIES = '0,5 niveaus lager dan advies',
	NIVEAU_GELIJK_AAN_ADVIES = 'niveau gelijk aan advies',
	_05_HAVO_VWO_PLAATSING_BIJ_HAVO_ADVIES = '0,5 havo/vwo plaatsing bij havo-advies',
	_05_NIVEAUS_HOGER_DAN_ADVIES = '0,5 niveaus hoger dan advies',
	_10_NIVEAUS_HOGER_DAN_ADVIES = '1,0 niveaus hoger dan advies',
	_15_NIVEAUS_HOGER_DAN_ADVIES = '1,5 niveaus hoger dan advies',
	_20_NIVEAUS_HOGER_DAN_ADVIES = '2,0 niveaus hoger dan advies',
	_25_NIVEAUS_HOGER_DAN_ADVIES = '≥ 2,5 niveaus hoger dan advies',
}

export enum CijferVerschil {
	MINUS_3_75 = '< -3,75',
	MINUS_3_5 = '-3,5',
	MINUS_3 = '-3',
	MINUS_2_5 = '-2,5',
	MINUS_2 = '-2',
	MINUS_1_5 = '-1,5',
	MINUS_1 = '-1',
	MINUS_0_5 = '-0,5',
	_0 = '0',
	_0_5 = '+0,5',
	_1 = '+1',
	_1_5 = '+1,5',
	_2 = '+2',
	_2_5 = '+2,5',
	_3 = '+3',
	_3_5 = '+3,5',
	_3_75 = '≥ +3,75',
}

export enum VAVO {
	VAVO = 'VAVO',
	GEEN_VAVO = 'Geen VAVO',
}

export enum Cijfer {
	NulTmDrie = '0 t/m 3',
	Vier = '4',
	Vijf = '5',
	Zes = '6',
	Zeven = '7',
	Acht = '8',
	Negen = '9',
	Tien = '10',
}

export enum CijferOV {
	NulTmDrie = '0 t/m 3',
	Vier = '4',
	Vijf = '5',
	Zes = '6',
	Zeven = '7',
	Acht = '8',
	Negen = '9',
	Tien = '10',
	Voldoende = 'voldoende',
	Onvoldoende = 'onvoldoende',
}

export enum CijferOVAdvies {
	NulTmDrie = '0 t/m 3',
	Vier = '4',
	Vijf = '5',
	Zes = '6',
	Zeven = '7',
	Acht = '8',
	Negen = '9',
	Tien = '10',
	Voldoende = 'voldoende',
	Onvoldoende = 'onvoldoende',
	Advies = 'advies',
}

export enum Afwezigheid {
	AFWEZIGHEID = 'Afwezigheid',
	AANWEZIGHEID = 'Aanwezigheid',
}

export enum AbsentieKlasse {
	Geoorloofd = 'Geoorloofde afwezigheid',
	Ongeoorloofd = 'Ongeoorloofde afwezigheid',
}

export enum AbsentieKlasseHistorie {
	Totaal = 'Afwezigheid totaal',
	Geoorloofd = 'Geoorloofde afwezigheid',
	Ongeoorloofd = 'Ongeoorloofde afwezigheid',
}

export enum CijferDossier {
	Voortgangsdossier = 'Voortgangsdossier',
	Examendossier = 'Examendossier',
}

export enum Lesregistratie {
	Huiswerk = 'Huiswerk niet in orde',
	Materiaal = 'Materiaal niet in orde',
	Mobiel = 'Mobiel',
	TeLaat = 'Te laat',
	Verwijderd = 'Verwijderd',
	Overig = 'Overig',
}

export enum Cohortrendementwaarde {
	ZONDER_VERTRAGING = 'Zonder vertraging',
	OP_NIVEAU = 'Op niveau',
	OPGESTROOMD = 'Opgestroomd',
	BOVEN_ADVIES = 'Boven advies',
	OPGESTROOMD_GEDOUBLEERD = 'Opgestroomd & gedoubleerd',
	BOVENADVIES_GEDOUBLEERD = 'Boven advies & gedoubleerd',
	GEDOUBLEERD = 'Gedoubleerd',
	AFGESTROOMD = 'Afgestroomd',
	ONDER_ADVIES = 'Onder advies',
	AFGESTROOMD_GEDOUBLEERD = 'Afgestroomd & gedoubleerd',
	ONDER_ADVIES_GEDOUBLEERD = 'Onder advies & gedoubleerd',
	AFGEWEZEN = 'Afgewezen',
}

export enum IQPunt {
	'IQPUNT 1' = '1 (< 35)',
	'IQPUNT 2' = '2 (35 t/m 49)',
	'IQPUNT 3' = '3 (50 t/m 69)',
	'IQPUNT 4' = '4 (70 t/m 79)',
	'IQPUNT 5' = '5 (80 t/m 89)',
	'IQPUNT 6' = '6 (90 t/m 110)',
	'IQPUNT 7' = '7 (111 t/m 120)',
	'IQPUNT 8' = '8 (> 120)',
}

export enum Uitstroompunt {
	'UITSTROOMPUNT 5,0' = '5,0',
	'UITSTROOMPUNT 5,5' = '5,5',
	'UITSTROOMPUNT 6,0' = '6,0',
	'UITSTROOMPUNT 7,0' = '7,0',
	'UITSTROOMPUNT 8,0' = '8,0',
	'UITSTROOMPUNT 9,0' = '9,0',
	'UITSTROOMPUNT 10,0' = '10,0',
	'UITSTROOMPUNT 10,5' = '10,5',
	'UITSTROOMPUNT 11,0' = '11,0',
	'UITSTROOMPUNT 11,5' = '11,5',
	'UITSTROOMPUNT 12,0' = '12,0',
}

export enum OnderwijsresultatenActueel {
	BovenDeNormWaarde = 'Boven de norm',
	OnderDeNormWaarde = 'Onder de norm',
	StijgingVorigJaar = 'Stijging t.o.v. vorig jaar',
	DalingVorigJaar = 'Daling t.o.v. vorig jaar',
	DalingVorige2Jaren = 'Daling t.o.v. vorige ≥2 jaar',
	OnderDeNormIndicator = 'Onder de norm',
	OnderDeNorm2JarenIndicator = 'Onder de norm ≥2 jaar',
}

export enum OnderwijsresultatenHistorie {
	Score = 'Score',
	GecorrigeerdeNorm = 'Gecorrigeerde norm',
	LandelijkeNorm = 'Landelijke norm',
	Onvoldoende = 'Onvoldoende',
	Onvoldoende2Jaren = 'Onvoldoende ≥2 jaar',
}

export enum PercentielNegatiefGemiddeldPositief {
	PercentielNegatief = 'Negatief (0% - 25%)',
	PercentielGemiddeld = 'Gemiddeld (25% - 75%)',
	PercentielPositief = 'Positief (75% - 100%)',
}

export enum AfwijkingNegatiefPositief {
	AfwijkingNegatief = 'Negatief',
	AfwijkingPositief = 'Positief',
}

export enum PrestatieanalyseHistorie {
	ScoreHistorie = 'Score',
	NegatiefHistorie = 'Negatief (0% - 25%)',
	GemiddeldHistorie = 'Gemiddeld (25% - 75%)',
	PositiefHistorie = 'Positief (75% - 100%)',
}

export enum Ondersteunend {
	WEL_ONDERSTEUNEND = 'Wel ondersteunend',
	NIET_ONDERSTEUNEND = 'Niet ondersteunend',
}

export enum Onderwijzend {
	WEL_ONDERWIJZEND = 'Wel onderwijzend',
	NIET_ONDERWIJZEND = 'Niet onderwijzend',
}

export enum CohortstatusTot {
	VOLLEDIG_COHORT_GEVOLGD = 'Volledig cohort gevolgd',
	TUSSENTIJDS_INGESTROOMD = 'Tussentijds ingestroomd',
}

export enum Geoorloofd {
	GEOORLOOFD = 'Geoorloofd',
	ONGEOORLOOFD = 'Ongeoorloofd',
}

export enum Succesvol {
	SUCCESVOL = 'Succesvol',
	NIET_SUCCESVOL = 'Niet succesvol',
}
