/**
 * Benadering van de cumulatieve normaalverdeling P(X<=x) met X ~ N(mean,std)
 * https://stackoverflow.com/questions/5259421/cumulative-distribution-function-in-javascript
 */
function ncdf(x: number, mean: number, std: number) {
	const nx = (x - mean) / std;
	const t = 1 / (1 + 0.2315419 * Math.abs(nx));
	const d = 0.3989423 * Math.exp((-nx * nx) / 2);
	let prob = d * t * (0.3193815 + t * (-0.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))));
	if (nx > 0) prob = 1 - prob;
	return prob;
}

/**
 * Berekent, gegeven het 25e en 75e percentiel van een verdeling, het percentiel (0-100) van "score", onder de aanname
 * dat de verdeling normaal is.
 */
export function percentiel_obv_normaal(score: number, p25: number, p75: number) {
	const mean = (p25 + p75) / 2;
	const std = (p75 - p25) / 1.34898;
	return ncdf(score, mean, std) * 100;
}
