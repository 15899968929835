<div class="card-cell-header">
	@if (row.jaren_onvoldoende_class) {
		<div [class]="row.jaren_onvoldoende_class"></div>
	}
	<span class="card-cell-title"> {{ row.key }} </span>
</div>
@switch (variant) {
	@case ('Actueel') {
		@if (row.actueelRow) {
			<div class="card-cell-score">
				@switch (row.indicator) {
					@case ('exc') {
						<span class="card-cell-value">
							{{ row.actueelRow.or_nr_score | extendedDecimal: '1.2-2' }}
						</span>
					}
					@case ('bbs') {
						<span class="card-cell-value">
							{{ row.actueelRow.or_nr_score | percent: '1.2-2' }}
						</span>
					}
				}
				@if (toonTrend()) {
					<div [class]="row.actueelRow.graph_class ?? 'empty-trend'"></div>
				}
			</div>
			<span [class]="row.verschil_class"> {{ row.or_nr_verschil! > 0 ? '+' : '' }}{{ row.or_nr_verschil! | extendedDecimal: '1.2-2' }} </span>
		}
	}
	@case ('Historie') {
		@if (row.linechartProps) {
			<app-linechart [props]="row.linechartProps"></app-linechart>
		}
	}
}
