import { Component } from '@angular/core';
import { TableCellComponent } from '../../../shared/components/table/table/table.model';

@Component({
	selector: 'app-percentiel-schaalverdeling',
	templateUrl: './percentiel-schaalverdeling.component.html',
	styleUrls: ['./percentiel-schaalverdeling.component.scss'],
	standalone: true,
})
export class PercentielSchaalverdelingComponent implements TableCellComponent<void> {
	data!: void;
}
