<app-dashboard-container
	class="dashboard-cijfers"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
>
	<app-filter-panel
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/cijfers"
		[permanentFilterExpressions]="permanentFilterExpressions"
	></app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header [variant]="variant" [legenda]="['x_cijfer_ov']"></app-dashboard-header>
		<div class="groepering">
			@if (variant === "Actueel") {
				<div class="weergave">
					Kolommen
					<app-multi-select-dropdown
						[allOptions]="columnMultiSelectOptions"
						[value]="visibleColumns"
						(valueChange)="qp.dispatch('col', $event)"
						placeholder="Kolommen"
					>
					</app-multi-select-dropdown>
				</div>
			}
		</div>
		@switch (variant) {
			@case ("Actueel") {
				<app-barchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="groups"
					[config]="this"
				>
				</app-barchart-table>
			}
			@case ("Historie") {
				<app-vbarchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="getHistorieGroups(groups)"
					[subgroups]="getHistorieSubgroups(groups)"
					[batchLevels]="isHistorieBatchVariant() ? 1 : 0"
					[config]="this"
					[schooljaarPath]="['cf_nm_schooljaar']"
					[partitionMeasure]="partitionMeasure"
				>
				</app-vbarchart-table>
			}
		}
	</section>
</app-dashboard-container>
