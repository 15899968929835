<app-base-dialog sizeClass="size-11-25" [noPadding]="true" [useParentHeight]="true">
	<div class="container">
		<div class="groeperingen" (mouseenter)="closeSorteren()">
			@for (groepering of groeperingen; track groepering.groepering) {
				<div class="element groepering" [class.actief]="groepering.current" (click)="select(groepering)">
					{{ groepering.naam }}
				</div>
			}
		</div>
		@if (options.activeGroepering) {
			<div class="element sorteren" [class.active]="menuSorterenLaatste()" (mouseenter)="sort()" #sorteren>
				<div class="svg-sort-asc grey"></div>
				<span>Sorteren</span>
				<div class="svg-chevron-right small grey"></div>
			</div>
			@if (options.deletable) {
				<div class="element delete" (mouseenter)="closeSorteren()" (click)="delete()">
					<div class="svg-delete"></div>
					<span>Verwijderen</span>
				</div>
			}
		}
	</div>
</app-base-dialog>
