import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@cumlaude/shared-authentication';
import { MultiSelectDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { InFilterExpression } from '../../../services/data.service';
import { RLeerlingSelectie } from '@cumlaude/service-contract';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { DialogHeaderComponent, BaseDialogComponent } from '@cumlaude/shared-components-dialogs';

export type LeerlingRecord = { nummer: number; naam: string };

@Component({
	selector: 'app-leerlingselectie-bewerken-dialog',
	templateUrl: './leerlingselectie-bewerken-dialog.component.html',
	styleUrl: './leerlingselectie-bewerken-dialog.component.scss',
	standalone: true,
	imports: [BaseDialogComponent, DialogHeaderComponent, MultiSelectDropdownComponent, ButtonComponent],
})
export class LeerlingselectieBewerkenDialogComponent {
	naam: string;

	selectedLeerlingen!: LeerlingRecord[];

	leerlingOptions!: Option<LeerlingRecord>[];

	constructor(
		protected dialogRef: DialogRef<RLeerlingSelectie>,
		@Inject(DIALOG_DATA) protected data: { leerlingen: LeerlingRecord[]; selectie: RLeerlingSelectie },
		protected authService: AuthService
	) {
		this.selectedLeerlingen = data.leerlingen;
		this.leerlingOptions = data.leerlingen.map((leerling) => new Option<LeerlingRecord>(leerling, leerling.naam));
		this.naam = data.selectie.naam;
	}

	getResult(): RLeerlingSelectie {
		const nrs = this.selectedLeerlingen.map(({ nummer }) => nummer);
		const selectionFilter = JSON.stringify(new InFilterExpression(['x_values'], nrs));
		return { ...this.data.selectie, naam: this.naam, selectionProvider: 'values', selectionFilter };
	}

	isNotEmpty() {
		return this.naam.trim().length > 0 && this.selectedLeerlingen.length > 0;
	}

	close() {
		if (this.isNotEmpty()) this.dialogRef.close(this.getResult());
	}
}
