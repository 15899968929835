import { Categorie, FactTable, getAtt, getAttLabel } from './exportable';
import { doorstroom_all } from './exportable-doorstroom';
import { loopbaan_all } from './exportable-loopbaan';
import { cijfers_all } from './exportable-cijfers';
import { Att } from './data.service';
import { FilterName } from './filter-config';
import { lesregistraties_all } from './exportable-lesregistraties';
import { aanwezigheid_all } from './exportable-aanwezigheid';
import { examencijfers_all } from './exportable-examencijfers';
import { onderwijsresultaten_all } from './exportable-onderwijsresultaten';
import { vakkeuze_all } from './exportable-vakkeuze';
import { basisvaardigheden_all } from './exportable-basisvaardigheden';

type CategorieMap = {
	[f in FactTable]: Categorie[];
};

export const categorieMap: CategorieMap = {
	aanwezigheid: aanwezigheid_all,
	basisvaardigheden: basisvaardigheden_all,
	cijfers: cijfers_all,
	cijferkolommen: [],
	doorstroom: doorstroom_all,
	examencijfers: examencijfers_all,
	lesregistratie: lesregistraties_all,
	loopbaan: loopbaan_all,
	onderwijsresultaten: onderwijsresultaten_all,
	prestatieanalyseVso: [],
	vakkeuze: vakkeuze_all,
};

export function categorieAttLabel(factTable: FactTable, att: Att): string {
	for (const categorie of categorieMap[factTable]) {
		for (const catAtt of categorie.atts) {
			if (getAtt(catAtt) == att) return getAttLabel(catAtt);
		}
	}
	return att;
}

export const onderwijsresultatenDsFilterExcludes: FilterName[] = ['ds_nm_schooljaar_van'];
export const prestatieanalyseDsFilterExcludes: FilterName[] = ['ds_nm_schooljaar_van'];
