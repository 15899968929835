/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ExamenStatus {
  GESLAAGD = 'Geslaagd',
  AFGEWEZEN = 'Afgewezen',
  VOORTIJDIG_TERUGGETROKKEN = 'Voortijdig teruggetrokken',
  OVERIG = 'Overig',
  TIJDENS_EXAMEN_TERUGGETROKKEN = 'Tijdens examen teruggetrokken',
  VOORLAATSTE_JAAR = 'Voorlaatste jaar',
  GESPREID_EXAMEN = 'Gespreid examen'
}
