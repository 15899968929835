import { Component, OnInit } from '@angular/core';
import { MultiSelectCheckboxComponent, MultiSelectTextComponent, Option } from '@cumlaude/shared-components-inputs';
import { FilterService } from '../../services/filter.service';
import { DateRangeComponentParams, MultiSelectComponentParams, SingleSelectComponentParams } from '../../services/filter-config';
import { difference, union } from 'lodash-es';
import { generateCssClassForString, includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';
import { BaseSelectFilterComponent } from '../base-select-filter/base-select-filter.component';
import { generateCssClassForFilterString } from '../../services/css-generate-metadata';

@Component({
	selector: 'app-multi-select-filter',
	templateUrl: './multi-select-filter.component.html',
	styleUrls: ['./multi-select-filter.component.scss'],
	standalone: true,
	imports: [MultiSelectCheckboxComponent, MultiSelectTextComponent],
})
export class MultiSelectFilterComponent<T> extends BaseSelectFilterComponent<T, T[]> implements OnInit {
	private readonly SHOW_SELECT_ALL_AFTER = 10;

	currentValue?: T[] = [];

	minimum = 0;

	legenda = false;

	text = false;

	blockedHover?: string;
	first = true;

	constructor(protected filterService: FilterService) {
		super(filterService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.subscriptions.push(
			this.filterService.getFilterStateAsInput(this.filterName).subscribe((val) => {
				this.currentValue = val;

				if (this.first && this.inDropdown) {
					this.options = this.generateOptions();
					this.first = false;
				}
			})
		);
	}

	processComponentParams(
		componentParams: SingleSelectComponentParams<any> | MultiSelectComponentParams<any> | DateRangeComponentParams,
		valueAllowed: ((val: any) => boolean) | undefined
	) {
		const { fixedOptions, defaultOptions, minimum, blockedHover, legenda, text } = <MultiSelectComponentParams<T[]>>componentParams;

		this.minimum = minimum ?? (this.isOptional() ? 0 : 1);
		this.legenda = legenda ?? false;
		this.text = text ?? false;
		this.blockedHover = blockedHover;

		this.processFixedAndDefaultOptions(valueAllowed, fixedOptions, defaultOptions);
	}

	protected generateOptions(): Option<T>[] {
		let options = super.generateOptions();

		if (this.first && this.currentValue && this.inDropdown) {
			let selectedOptions = options.filter((option) => this.currentValue!.includes(option.value));
			options = options.filter((option) => !selectedOptions.includes(option));
			options.unshift(...selectedOptions);
		}

		return options;
	}

	isOptional(): boolean {
		return this.filterService.isOptional(this.filterName);
	}

	getSelected(options: Option<T>[]): Option<T>[] {
		if (this.currentValue === undefined) return [];
		return options.filter((option) => this.currentValue!.includes(option.value));
	}

	select(options: Option<T>[]) {
		const values = options.map((option) => option.value);
		const newValues = values.length === 0 ? undefined : this.filterService.sortValues(this.filterName, this.allValues, values);
		this.filterService.setFilterInput(this.filterName, newValues).then(() => {
			if (newValues === undefined) {
				if (this.inDropdown) this.filterService.deactivateIfNeeded([this.filterName]);
				else this.filterService.makeTentative(this.filterName);
			}
		});
	}

	/**
	 * zijn alle zichtbare actieve waarden geselecteerd?
	 */
	allSelected(): boolean {
		const selected = this.currentValue ?? [];
		return difference(this.getVisibleActive(), selected).length == 0;
	}

	selectAllOrNone() {
		if (this.allSelected()) {
			this.filterService.setFilterInput(this.filterName, undefined);
		} else {
			// selecteer alle zichtbare actieve waarden ERBIJ
			const selected = this.currentValue ?? [];
			const newValues = union(this.getVisibleActive(), selected);
			this.filterService.setFilterInput(this.filterName, newValues);
		}
	}

	isShowSelectAll() {
		return !this.inDropdown && (this.filterData?.activeValues ?? []).length >= this.SHOW_SELECT_ALL_AFTER;
	}

	private getVisibleActive() {
		return (this.filterData?.activeValues ?? []).filter((val) => this.matchesSearch(val, this.searchInput));
	}

	search($event: Event) {
		if ($event instanceof InputEvent) {
			this.searchInput = ($event.target as HTMLInputElement).value.trim();
			this.searchInputChange$.next(this.searchInput);
		}
	}

	protected mapValueToOption(val: T, inactive: boolean): Option<T> {
		const text = this.filterService.configs[this.filterName]!.valueString([val]);
		return {
			value: val,
			style: inactive ? 'inactive' : undefined,
			text,
			legendaClass: this.legenda ? generateCssClassForFilterString(this.filterName, text) : undefined,
		};
	}

	private matchesSearch(value: T, searchFilter?: string): boolean {
		if (searchFilter == undefined) return true;
		return includesIgnoreCaseAndDiacritics(this.filterService.configs[this.filterName]!.valueString([value]), searchFilter);
	}

	getClassName() {
		return generateCssClassForString(this.filterName);
	}

	applyFilter(options: Option<T>[], searchInput: undefined | string) {
		return options.filter((option) => this.matchesSearch(option.value, searchInput));
	}
}
