/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum PrestatieMetPrognose {
  DOORSTROOM = 'Doorstroom',
  GESLAAGD = 'Geslaagd',
  GESLAAGD_PROGNOSE = 'Geslaagd (prognose)',
  OPSTROOM = 'Opstroom',
  AFSTROOM = 'Afstroom',
  DOUBLURE = 'Doublure',
  AFGEWEZEN = 'Afgewezen',
  AFGEWEZEN_PROGNOSE = 'Afgewezen (prognose)',
  GEEN = 'Geen'
}
