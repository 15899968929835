import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class RestLoggingService {
	private _currentDashboard: string | null = null;

	get currentDashboard(): string | null {
		return this._currentDashboard;
	}

	set currentDashboard(value: string | null) {
		this._currentDashboard = value;
	}
}
