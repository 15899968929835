<app-top-level-menu></app-top-level-menu>
<div class="header-button" (click)="openReleaseNotes()" #releaseNotesButton><span class="svg-releasenotes"></span></div>
<div class="header-button" (click)="openFeedback()"><span class="svg-community"></span></div>
<div class="header-button" (click)="openHelp()">
	<span class="svg-help"></span>
</div>

@if (!isBeheer) {
	<div class="header-button" (click)="openBookmark()" #bookmarkButton><span class="svg-bookmark"></span></div>
}
<div class="header-button" (click)="submenu = !submenu">
	<span class="svg-settings"></span>
	@if (submenu) {
		<div class="menu" (clickOutside)="submenu = false" [clickOutsideEvents]="'click,touchend'" [delayClickOutsideInit]="true">
			<div class="menu-item menu-border" [class.menu-item-clickable]="multiAccount$ | async" (click)="switch()">
				<div class="user-text">
					<div class="username">{{ getUserDisplayName() }}</div>
					@if (instellingName$ | async; as instellingName) {
						<div>{{ instellingName }}</div>
					}
				</div>
				@if (multiAccount$ | async) {
					<div class="svg-switch"></div>
				}
			</div>
			@if (credentialsSub$ | async; as credentialsSub) {
				<div class="menu-item menu-item-clickable" (click)="wijzigWachtwoord()">Wachtwoord wijzigen</div>
			}
			@if (ikBenBeheerder$ | async) {
				@if (isBeheer) {
					<div class="menu-item menu-item-clickable" routerLink="dashboard">Terug naar CumLaude</div>
				} @else {
					<div class="menu-item menu-item-clickable" routerLink="beheer">Beheer</div>
				}
			}
			<div class="menu-item menu-item-clickable" (click)="uitloggen()">Uitloggen</div>
		</div>
	}
</div>
