import { Component, OnInit } from '@angular/core';
import { AuthService } from '@cumlaude/shared-authentication';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserAuthenticationService } from '../../services/user-authentication.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-new-session',
	templateUrl: './new-session.component.html',
	styleUrls: ['./new-session.component.scss'],
	standalone: true,
})
export class NewSessionComponent implements OnInit {
	constructor(
		private authService: AuthService,
		private oAuthService: OAuthService,
		private userAuthenticationService: UserAuthenticationService,
		private activatedRoute: ActivatedRoute
	) {}

	ngOnInit(): void {
		const msg = this.activatedRoute.snapshot.queryParamMap.get('toastr');
		this.authService.clearSessionStorage();
		this.userAuthenticationService.clearSessionStorage();
		this.oAuthService.initCodeFlow(msg ?? undefined);
	}
}
