<app-dashboard-container
	class="dashboard-uitzondering"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
>
	<app-filter-panel
		[defaultFilters]="initialFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions"
	></app-filter-panel>
	<section class="dashboard">
		@if (from) {
			<app-button buttonType="text" icon="icon-arrow-l" (clickit)="goBack()">Terug naar {{ from }}</app-button>
		}
		<app-dashboard-header></app-dashboard-header>
		@if (aantalJaren) {
			<div class="caption">
				{{ getSchooljarenCaption() }}
				@if (getSchooljarenTooltip()) {
					<span class="svg-info" [appTooltip]="getSchooljarenTooltip()!"></span>
				}
			</div>
			<div class="groepering">
				<app-schooljaar-indicator-over [hideExclusief]="hideExclusief"></app-schooljaar-indicator-over>
			</div>
		}
		<app-barchart-table
			[filters]="filterExpressions"
			[permanentFilters]="permanentFilterExpressions"
			[groups]="groups"
			[config]="this"
			[barchartWidth]="200"
		></app-barchart-table>
	</section>
</app-dashboard-container>
