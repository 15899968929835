import { Directive } from '@angular/core';
import { AccountScreen, AccountVestigingenRow, getAccountVestigingen } from '../account-screen';
import { RestService } from '@cumlaude/shared-services';
import { DataService } from '../../services/data.service';
import { AccountsService } from '../../services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { createColumnDef, createModel, TableModel } from '../../shared/components/table/table/table.model';
import { Observable } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { UserService } from '../../services/user.service';
import { RCumLaudeAccount, RCumLaudeModule, RVestiging } from '@cumlaude/service-contract';
import { map } from 'rxjs/operators';

function makeAccountVestigingenRow(account: RCumLaudeAccount, alleVestigingen: RVestiging[]): AccountVestigingenRow {
	const vestigingen = getAccountVestigingen(account, alleVestigingen);
	return { account, vestigingen };
}

@Directive()
export abstract class DocentMentorComponent extends AccountScreen<AccountVestigingenRow> {
	protected constructor(
		protected restService: RestService,
		protected dataService: DataService,
		protected userService: UserService,
		protected accountsService: AccountsService,
		protected dialog: Dialog,
		protected toastr: ToastrService
	) {
		super(restService, dataService, userService, accountsService, dialog, toastr);
	}

	getModule(): RCumLaudeModule {
		return RCumLaudeModule.DOCENT;
	}

	getRolDescription() {
		return `De rol ${this.getRolLabel()} wordt opgehaald uit uw bronsysteem. Het is niet mogelijk om deze lijst te bewerken in CumLaude.`;
	}

	makeAccountTableModel(accounts: RCumLaudeAccount[]): Observable<TableModel<AccountVestigingenRow>> {
		return this.alleVestigingen$.pipe(
			map((vestigingen) => {
				const model = createModel(
					accounts.map((account) => makeAccountVestigingenRow(account, vestigingen)),
					(row) => row.account.id!
				);
				model.columnDefs = [this.getNaamColumn(), createColumnDef('vestigingen', 'Vestigingen')];
				return model;
			})
		);
	}
}
