import { Component, OnDestroy, ViewChild } from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DeviceService } from '../services/device.service';
import { TopLevelMenuComponent } from '../top-level-menu/top-level-menu.component';

@Component({
	selector: 'app-hamburger-menu',
	templateUrl: './hamburger-menu.component.html',
	styleUrls: ['./hamburger-menu.component.scss'],
	standalone: true,
	imports: [TopLevelMenuComponent],
})
export class HamburgerMenuComponent implements OnDestroy {
	open = false;

	private subscriptions: Subscription[] = [];

	@ViewChild(TopLevelMenuComponent, { static: true })
	private menu!: TopLevelMenuComponent;

	constructor(router: Router, deviceService: DeviceService) {
		this.subscriptions.push(
			router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
				this.close();
			})
		);
		this.subscriptions.push(
			deviceService.onDeviceChange$.subscribe(() => {
				this.close();
			})
		);
	}

	close() {
		this.open = false;
		if (this.menu) this.menu.close();
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}
}
