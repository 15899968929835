import { Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { BaseDialogComponent } from '@cumlaude/shared-components-dialogs';
import { SortDirection } from '../../../shared/components/table/table/table.component';

@Component({
	selector: 'app-groepering-sorteren-dialog',
	templateUrl: './groepering-sorteren-dialog.component.html',
	styleUrls: ['./groepering-sorteren-dialog.component.scss'],
	standalone: true,
	imports: [BaseDialogComponent],
})
export class GroeperingSorterenDialogComponent {
	constructor(protected dialogRef: DialogRef<SortDirection>) {}
}
