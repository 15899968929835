import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { UserService } from '../../services/user.service';
import { LoadingService, LoadingType } from '../../services/loading.service';
import { Dialog } from '@angular/cdk/dialog';
import { PaginaDelenDialogComponent } from '../../dialogs/pagina-delen/pagina-delen-dialog/pagina-delen-dialog.component';
import { Overlay } from '@angular/cdk/overlay';
import { AsyncPipe } from '@angular/common';
import { keepSelectie, keepSelectieVariant, removeFromQp, removeFromQpVariant } from '@cumlaude/shared-components-menu';
import { vaardigheden, vaardigheid2route } from '../../services/vaardigheden';
import { Vaardigheid } from '@cumlaude/metadata';
import { CssSpinnerComponent } from '../css-spinner/css-spinner.component';

@Component({
	selector: 'app-tab-menu',
	templateUrl: './tab-menu.component.html',
	styleUrls: ['./tab-menu.component.scss'],
	standalone: true,
	imports: [RouterLinkActive, RouterLink, AsyncPipe, CssSpinnerComponent],
})
export class TabMenuComponent implements OnDestroy {
	private subscriptions: Subscription[] = [];

	private scrollCheck$ = new EventEmitter<void>();

	private clientWidth = 0;

	showExportSpinner$: Observable<boolean>;

	removeFromQp = removeFromQp;

	removeFromQpVariant = removeFromQpVariant;

	keepSelectie = keepSelectie;

	keepSelectieVariant = keepSelectieVariant;

	@Input()
	exportVisible = false;

	@Input()
	shareVisible = false;

	@Output()
	exportClicked = new EventEmitter<void>();

	@ViewChild('viewport')
	viewport?: ElementRef;

	showLeft = false;
	showRight = false;

	constructor(
		router: Router,
		protected userService: UserService,
		loadingService: LoadingService,
		public dialog: Dialog,
		private readonly overlay: Overlay
	) {
		this.showExportSpinner$ = loadingService.shouldShowLoadingIndicator(LoadingType.EXPORT);
		this.subscriptions.push(
			router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
				this.scrollCheck$.emit();
			})
		);
		// het debouncen dient 2 doelen:
		// 1. niet zo veel render loops tijdens het resizen
		// 2. bij een nieuwe route wachten totdat render loop uitgevoerd is en we de clientWidth weten
		this.subscriptions.push(this.scrollCheck$.pipe(debounceTime(50)).subscribe(() => this.calcScrollButtons()));
	}

	@HostListener('window:resize')
	onWindowResize() {
		this.scrollCheck$.emit();
	}

	calcScrollButtons() {
		const elt = this.viewport?.nativeElement;
		if (!elt) return;

		this.showLeft = elt.scrollLeft > 0;
		this.showRight = elt.scrollWidth > elt.scrollLeft + elt.clientWidth;
	}

	scrollLeft() {
		const elt = this.viewport?.nativeElement;
		if (!elt) return;

		elt.scrollLeft = Math.max(0, elt.scrollLeft - elt.clientWidth);
		this.calcScrollButtons();
	}

	scrollRight() {
		const elt = this.viewport?.nativeElement;
		if (!elt) return;

		elt.scrollLeft = Math.min(elt.scrollLeft + elt.clientWidth, elt.scrollWidth - elt.clientWidth);
		this.calcScrollButtons();
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	isAllowedForRol(url: string): boolean {
		return this.userService.isUrlAllowed(url);
	}

	sharePagina() {
		this.dialog.open<void>(PaginaDelenDialogComponent, {
			positionStrategy: this.overlay.position().global().top('7rem').right('4rem'),
		});
	}

	getBasisvaardigheidUrl(vaardigheid: Vaardigheid) {
		return `/basisvaardigheden/${vaardigheid2route(vaardigheid)}`;
	}

	getBasisvaardighedenBeheerUrl(vaardigheid: Vaardigheid) {
		return `/beheer/basisvaardigheden/${vaardigheid2route(vaardigheid)}`;
	}

	protected readonly vaardigheden = vaardigheden;
}
