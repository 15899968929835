/**
* Wordt gebruikt om een selectie van query parameters weg te halen bij wisselen van dashboards.
*/
export const removeFromQp = {
	sortOrder: null,
	selectie: null,
	schooljaar: null,
	subject: null,
	prognose: null,
	sf: null,
	sp: null,
	from: null,
	g: null,
	ds_nm_bbs_uitzondering_van: null,
	ds_nm_obs_uitzondering_van: null,
	ds_nm_op_uitzondering_van: null,
	ds_nm_plaatsing_uitzondering: null,
	ds_nm_prestatieanalyse_vso_uitzondering: null,
	ds_nm_einduitstroom_vso_uitzondering: null,
	ds_nm_tussentijdse_uitstroom_vso_uitzondering: null,
	ds_fun_uitstroom_iq_status: null,
};

/**
* Aanvulling van removeFromQp met variant weghalen voor dashboards die geen variant ondersteunen.
*/
export const removeFromQpVariant = {
	...removeFromQp,
	variant: null,
};

/**
* Wordt gebruikt op de details dashboards om een aantal selectie gerelateerde velden te behouden.
*/
export const keepSelectie = {
	sortOrder: null,
	prognose: null,
	g: null,
	ds_nm_bbs_uitzondering_van: null,
	ds_nm_obs_uitzondering_van: null,
	ds_nm_op_uitzondering_van: null,
	ds_nm_plaatsing_uitzondering: null,
	ds_nm_prestatieanalyse_vso_uitzondering: null,
	ds_nm_einduitstroom_vso_uitzondering: null,
	ds_nm_tussentijdse_uitstroom_vso_uitzondering: null,
	ds_fun_uitstroom_iq_status: null,
};

/**
* Aanvulling op keepSelectie met variant weghalen voor dashboards die geen variant ondersteunen.
*/
export const keepSelectieVariant = {
	...keepSelectie,
	variant: null,
};
