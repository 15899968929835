import { Component, DestroyRef, OnInit, signal, WritableSignal } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ConfirmDialogComponent, DialogHeaderComponent, BaseDialogComponent } from '@cumlaude/shared-components-dialogs';
import { ImmediateInputComponent } from '../../shared/components/immediate-input/immediate-input.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { marked } from 'marked';
import { RestService } from '@cumlaude/shared-services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { reverse } from 'lodash-es';

@Component({
	selector: 'app-releasenotes-dialog',
	templateUrl: './release-notes-dialog.component.html',
	styleUrls: ['./release-notes-dialog.component.scss'],
	standalone: true,
	imports: [BaseDialogComponent, DialogHeaderComponent, CdkScrollable, ImmediateInputComponent, ConfirmDialogComponent, AsyncPipe],
})
export class ReleaseNotesDialogComponent implements OnInit {
	constructor(
		private restService: RestService,
		private destroyRef: DestroyRef
	) {}

	notes: WritableSignal<string[]> = signal([]);

	detail: WritableSignal<string | undefined> = signal(undefined);

	ngOnInit() {
		this.restService
			.getReleaseNotes()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((notes_md) => this.notes.set(this.processResponse(notes_md)));
	}

	processResponse(notes_md: string[]): string[] {
		const parsed = notes_md.map((md) => <string>marked.parse(md));
		return reverse(parsed);
	}

	openNote(note: string) {
		this.detail.set(note);
	}

	closeNote() {
		this.detail.set(undefined);
	}
}
