export interface Option<T> {
	value: T;
	text: string;
	style?: string;
	legendaClass?: string;
}

export interface DropDownOption<T> extends Option<T> {
	icon?: string;
	iconColor?: string;
}

export class Option<T> {
	constructor(
		public value: T,
		public text: string = `${value}`,
		public style?: string,
		public legendaClass?: string
	) {}
}
