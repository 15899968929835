import { Component, computed, OnInit, Signal } from '@angular/core';
import { Sort } from '../../shared/components/table/table/table.component';
import { Observable } from 'rxjs';
import { maxDeelVanJaar, maxOver, maxOverMapped, sumOver } from '../../services/aggregation';
import { Path } from '../../services/data-tree';
import {
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	defaultDoorstroomAvailableGroups,
	ExportDataOptions,
	FilterExpression,
	xAggExcept,
} from '../../services/data.service';
import { att, att0, percOfTotal } from '../../services/measures';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { defaultDoorstroomActueelFilters, defaultDoorstroomHistorieFilters, FilterName } from '../../services/filter-config';
import { memoize } from 'lodash-es';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { FilterService } from '../../services/filter.service';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { Axis, createYAxis } from '../../services/axis';
import { DashboardVariant, Eenheid } from '../../services/weergave-opties';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { PsName } from '../../services/page-state.service';
import { BarInfo } from '../../services/stacked-bars';
import { WeergaveOptieComponent } from '../../shared/components/weergave-optie/weergave-optie.component';
import { formatPercent } from '@angular/common';

interface InstroomI extends Attributes {
	ds_nr_weging: number;
	'ds_fk_lb_van.lb_is_pl_voorlopig': string;
	xa: { [nr: number]: { ds_nr_weging: number } };
}

interface InstroomA extends Attributes {
	max: number;
	weging: number;
	ds_is_lb_voorlopig: number;
	maxPctJaar: number;
}

enum MeasureColumn {
	LEERLINGEN = 'Leerlingen',
	PERCENTAGE = 'Percentage',
}

const historieSchooljaarGroup: AttrPath = ['ds_nm_schooljaar_van'];

@Component({
	selector: 'app-instroom',
	templateUrl: './instroom.component.html',
	styleUrls: ['./instroom.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		WeergaveOptieComponent,
	],
})
export class InstroomComponent extends BarchartTableConfig<InstroomI, InstroomA> implements OnInit {
	defaultGroups: AttrPath[] = [['ds_fk_ll', 'll_nm_svh'], ['ds_co_brin_svh']];

	groups: AttrPath[] = this.defaultGroups;

	fixedSubGroups: AttrPath[] = [['ds_fk_lb_van', 'lb_is_pl_voorlopig']];

	availableGroups: AttrPath[] = [...defaultDoorstroomAvailableGroups, ['ds_fk_lb_vorig_sj', 'lb_nm_vestiging']];

	actueelFilters: FilterName[] = [
		...defaultDoorstroomActueelFilters, //
		'ds_fk_lb_van.lb_nm_instroomtype_in_schooljaar',
		'ds_fk_lb_van.lb_nm_instroommoment_in_schooljaar',
	];

	historieFilters: FilterName[] = [
		...defaultDoorstroomHistorieFilters, //
		'ds_fk_lb_van.lb_nm_instroomtype_in_schooljaar',
		'ds_fk_lb_van.lb_nm_instroommoment_in_schooljaar',
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = [
		new BasicFilterExpression(['ds_fun_is_plaatsing_peildatum_actueel'], 1),
		new BasicFilterExpression(['ds_is_plaatsing_opeenvolgend'], 1),
		new BasicFilterExpression(['ds_nm_instroom_in_schooljaar'], null, '<>'),
	];

	variant = this.qp.signal('variant');

	eenheid = this.qp.signal('eenheid');

	constructor(
		private dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(this.qp.observe_g().subscribe((groups) => (this.groups = groups ?? this.defaultGroups)));
	}

	factTable = FactTable.doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		const xa = this.variant() === DashboardVariant.ACTUEEL ? [] : [xAggExcept(options.g!, historieSchooljaarGroup)];
		return this.dataService.getDoorstroomData({ ...options, xa });
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected singleAggregators = {
		max: maxOver('ds_nr_weging'),
		weging: sumOver<'ds_nr_weging', InstroomI, number>('ds_nr_weging'),
		ds_is_lb_voorlopig: maxOverMapped<InstroomI>((v) => Number(v['ds_fk_lb_van.lb_is_pl_voorlopig'])),
		maxPctJaar: maxDeelVanJaar('ds_nr_weging'),
	};

	createLinkData(path: Path<unknown, number[]>, context: DashboardContext<InstroomI, InstroomA, InstroomComponent>): Partial<LinkData> {
		return {
			dashboard: '/details/leerling/doorstroom',
			dataProvider: 'doorstroom',
			...super.createLinkData(path, context),
		};
	}

	createMeasureColumns(): ColumnDef<DataRow<InstroomA>>[] {
		if (this.variant() === DashboardVariant.HISTORIE) return [];

		return [
			createMeasureColumn<InstroomI, InstroomA>(MeasureColumn.LEERLINGEN, att('weging'), { format: '1.0-2' }),
			createMeasureColumn(MeasureColumn.PERCENTAGE, percOfTotal('weging', 'weging'), { dataType: 'percentage' }),
		];
	}

	isHistorieBatchVariant(): boolean {
		return this.variant() === DashboardVariant.HISTORIE && this.groups.length > 0;
	}

	getBarchartQty(path: Path<InstroomA, number[]>) {
		if ((this.variant() === DashboardVariant.HISTORIE && this.eenheid()) == Eenheid.PERCENTAGE) return att0('maxPctJaar')(path);
		else return att0('weging')(path);
	}

	createYAxis(context: DashboardContext<InstroomI, InstroomA, InstroomComponent>): Axis {
		if (!context.dataRoot) return super.createYAxis(context);
		if (this.eenheid() === Eenheid.AANTAL) {
			return createYAxis([0, context.dataRoot.a.max]);
		} else {
			return createYAxis([0, context.dataRoot.a.maxPctJaar], 5, (val) => formatPercent(val, 'nl_NL'));
		}
	}

	createXAxis(context: DashboardContext<InstroomI, InstroomA, InstroomComponent>): Axis {
		return { min: 0, max: context.dataRoot!.a.max, ticks: [] };
	}

	getDefaultSort(): Sort {
		return { active: MeasureColumn.LEERLINGEN, direction: 'desc' };
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(InstroomComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(InstroomComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups([selectedGroups, fixedSubgroups]: [AttrPath[], AttrPath[]]): AttrPath[] {
		return [...selectedGroups.slice(-1), historieSchooljaarGroup, ...fixedSubgroups];
	}

	enrichTableModel(_context: DashboardContext<InstroomI, InstroomA, InstroomComponent>, tableModel: TableModel<DataRow<InstroomA>>) {
		tableModel.showFooters = this.variant() === DashboardVariant.ACTUEEL;
	}

	makeBar(attrs: InstroomI, path: Path<InstroomA, number[]>, context: DashboardContext<InstroomI, InstroomA, InstroomComponent>): BarInfo {
		const { 'ds_fk_lb_van.lb_is_pl_voorlopig': ds_is_lb_voorlopig, ds_nr_weging } = attrs;

		return {
			...super.makeBar(attrs, path, context),
			className: ds_is_lb_voorlopig == '1' ? 'dashboard-prognose' : 'dashboard-default',
			tooltip: [
				{ label: 'Prognose', value: ds_is_lb_voorlopig == '1' ? 'Ja' : 'Nee' },
				{ label: 'Leerlingen', value: `${ds_nr_weging}` },
			],
		};
	}

	onContextCreated(context: DashboardContext<InstroomI, InstroomA, InstroomComponent>): void {
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(context.dataRoot?.a.ds_is_lb_voorlopig)));
	}

	partitionMeasure: Signal<PartitionMeasure<InstroomA>> = computed(() => {
		if (this.eenheid() === Eenheid.AANTAL)
			return {
				type: 'number',
				getValue: att('weging'),
				format: '1.0-2',
			};
		else
			return {
				type: 'percentage',
				getValue: att('maxPctJaar'),
			};
	});

	protected readonly Eenheid = Eenheid;
	protected readonly DashboardVariant = DashboardVariant;
}
