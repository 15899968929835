/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum DoorstroomStatus {
  INFORMATIE_VOLLEDIG = 'Informatie volledig',
  EXAMENSTATUS_NIET_CORRECT_IN_SYSTEEM = 'Examenstatus niet correct in systeem',
  ADVIES_PLAATSING_VOLGEND_SCHOOLJAAR_NOG_NIET_BEKEND = '(Advies)plaatsing volgend schooljaar nog niet bekend',
  DOORSTROOM_NIET_AF_TE_LEIDEN_UIT_VERTREKSTATUS = 'Doorstroom niet af te leiden uit vertrekstatus',
  ONBEKENDE_DOORSTROOM = 'Onbekende doorstroom'
}
