<div class="barchart" [style.width]="data.barWidth | px" [class]="className">
	@if (data.axis.areas) {
		<div class="shades">
			@for (area of getCSSAreas(); track area) {
				<div class="shade" [class]="area.className" [style.width]="area.width | px"></div>
			}
		</div>
	}
	@if (data.axis.ticks) {
		<div class="marks">
			@for (tick of getCSSTicks(); track tick) {
				<div class="mark" [style.width]="tick.width | px" [class.line]="tick.showLine ?? true">
					<div class="label">{{ tick.label }}</div>
				</div>
			}
		</div>
	}
	@if (data.axis.min < 0) {
		<div class="filler" [style.width]="getFillerWidth() | px"></div>
	}
	<div [class.axis]="axis"></div>
	<div class="partition" [class.negative]="(data.qty ?? 0) < 0" [style.width]="qtyToPx(data.qty) | px">
		@for (rec of bars; track rec; let ix = $index) {
			<div class="anchor">
				<div
					class="underlay bar"
					[style.width]="getWidthInPixels(data.qty ?? 0, rec.pct) + 'px'"
					[class]="rec.className"
					data-princexml-ignore
					data-html2canvas-ignore
				></div>
				<div class="label" [style.width]="getWidthInPixels(data.qty ?? 0, rec.pct) + 'px'">
					<span>{{ rec.text }}</span>
				</div>
			</div>
			<div
				class="bar"
				[appTooltip]="rec.tooltip"
				[class]="rec.className"
				[style.width]="rec.pct + '%'"
				[routerLink]="urlService.getRouterLink(rec.linkData)"
				[queryParams]="urlService.getQueryParams(rec.linkData)"
				[class.clickable]="rec.linkData.dashboard"
			></div>
		}
	</div>
</div>
@if (data.text) {
	<div class="text" [style.left]="-data.barWidth + qtyToPx(data.qty) + 'px'">{{ data.text }}</div>
}
