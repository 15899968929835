<app-tab-menu (exportClicked)="startExport()" [exportVisible]="this.childDashboard?.factTable !== undefined" [shareVisible]="true"></app-tab-menu>
<div class="sidebar">
	@if (terugNaar) {
		<app-button buttonType="text" icon="icon-arrow-l" (clickit)="goBack()">Terug naar {{ terugNaar }}</app-button>
	}
	<div class="container">
		<div class="titlebar">
			<h3>{{ getTitle() }}</h3>
			<span>Geselecteerd: {{ getSelected() }} van de {{ getTotal() }}</span>
			@if (terugNaar) {
				<app-checkbox label="Toon alleen selectie" [value]="true" (valueChange)="selectieToggle$.next($event)"></app-checkbox>
			}
			@if (getType() === "leerling") {
				<a (click)="slaSelectieOp()">Selectie opslaan</a>
			}
		</div>
		<app-instant-search-box (fire)="searchInput$.next($event)"></app-instant-search-box>
		<app-lijst
			(nextPage)="fetch$.emit($event)"
			(selectedChange)="selectElement($event)"
			[elementen]="elementen"
			[getData]="getLijstElementData"
			[selected]="selectedLijstElement"
		></app-lijst>
	</div>
</div>
<app-detail-panel
	(changeElement)="changeElement$.emit($event)"
	[getData]="getDetailsElementData"
	[selected]="selectedDetailElement"
	[showNext]="elementen.length > 1 && getCurrentIndex() > 0"
	[showPrevious]="elementen.length > 1 && getCurrentIndex() < getTotal() - 1"
	[type]="getType()"
	data-exportable
></app-detail-panel>
<div (click)="closeSidebar()" [class.is-open]="sidebarOpened$ | async" class="overlay"></div>
<div [class.show-sidebar]="sidebarOpened$ | async" class="dashboard">
	<div class="spinner-overlay">
		@if (showSpinner$ | async) {
			<app-svg-spinner></app-svg-spinner>
		}
	</div>
	<router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
