import { Injectable } from '@angular/core';
import {
	BovenbouwsuccesUitzondering,
	DimMwMedewerker,
	DoorstroomExt,
	EinduitstroomVso,
	ExamencijfersUitzondering,
	ExamenStatus,
	Instroom,
	Niveau,
	OnderbouwsnelheidUitzondering,
	OnderwijspositieUitzondering,
	Overgangmoment,
	Prestatie,
	PrestatieMetPrognose,
	TussentijdseUitstroomVso,
	Uitstroom,
} from '@cumlaude/metadata';
import { AttrPath, DWHTable } from '../../services/data.service';
import { get } from 'lodash-es';
import {
	AbsentieKlasse,
	AbsentieKlasseHistorie,
	Afwezigheid,
	AfwijkingNegatiefPositief,
	APCG,
	Bekostigd,
	Bevorderd,
	Cijfer,
	CijferOV,
	CijferOVAdvies,
	CijferVerschil,
	Cohortrendementwaarde,
	CohortstatusTot,
	DefaultBooleanEnum,
	Diplomavak,
	ExamenKandidaat,
	ExamenOpleiding,
	ExamenOpstroom,
	Geoorloofd,
	InExamendossier,
	InVoortgangsdossier,
	IQPunt,
	Is1AprilPlaatsing,
	Is1FebrPlaatsing,
	Is1JanPlaatsing,
	Is1JuliPlaatsing,
	Is1OktPlaatsing,
	LaatstePlaatsingSchooljaar,
	Leerwerktraject,
	Lesregistratie,
	LWOO,
	MetZonderCE,
	Nieuwkomer,
	NiveauVerschil,
	Ondersteunend,
	OnderwijspositiePunten,
	OnderwijsresultatenActueel,
	OnderwijsresultatenHistorie,
	Onderwijzend,
	Overgang,
	PercentielNegatiefGemiddeldPositief,
	PlaatsingActueel,
	PlaatsingStatus,
	PrestatieanalyseHistorie,
	Prognose,
	ReferentieNiveau,
	Succesvol,
	TeltMeeVoorSlagen,
	Uitstroompunt,
	VakantieMidden,
	VakantieNoord,
	VakantieZuid,
	VAVO,
	Voldoende,
	Weekend,
} from '../../services/label-enums';

type EnumObject = { [p: string]: string };

type EnumMap<T> =
	| {
			[attr in keyof T]: EnumObject | EnumMap<NonNullable<T[attr]>>;
	  }
	| { cf_fks_mw: EnumMap<DimMwMedewerker> };

export type LegendaEnumNames =
	| 'x_met_overgang'
	| 'x_onderwijspositie_punten'
	| 'x_cijfer_ov'
	| 'x_cijfer_ov_advies'
	| 'x_cijfer_metzonder_ce'
	| 'x_afwijking_neg_pos'
	| 'x_aw_is_abs_geoorloofd_historie'
	| 'x_cohortrendement'
	| 'x_onderwijsresultaten_actueel'
	| 'x_onderwijsresultaten_historie'
	| 'x_percentiel_neg_gem_pos'
	| 'x_prestatieanalyse_historie';

type LegendaEnumMap<T> =
	| EnumMap<T>
	| Partial<{
			[attr in LegendaEnumNames]: EnumObject;
	  }>;

export function getAbsentieKlasse(isGeoorloofd: string | null): AbsentieKlasse {
	switch (isGeoorloofd) {
		case '0':
			return AbsentieKlasse.Ongeoorloofd;
		case '1':
			return AbsentieKlasse.Geoorloofd;
		default:
			throw new Error(`aw_is_abs_geoorloofd ${isGeoorloofd}, zou niet voor mogen komen.`);
	}
}

const iltEnumMap: EnumMap<DWHTable> = {
	ilt_is_lwoo: LWOO,
	ilt_is_leerwerktraject: Leerwerktraject,
	ilt_is_vavo: VAVO,

	ilt_nm_niveau: Niveau,
};

const loopbaanEnumMap: EnumMap<DWHTable> = {
	lb_is_1febr_plaatsing: Is1FebrPlaatsing,
	lb_is_1okt_plaatsing: Is1OktPlaatsing,
	lb_is_apcg: APCG,
	lb_is_bekostigd: Bekostigd,
	lb_is_bevorderd: Bevorderd,
	lb_is_examenkandidaat: ExamenKandidaat,
	lb_is_examenopleiding: ExamenOpleiding,
	lb_is_examenopstroom: ExamenOpstroom,
	lb_is_laatste_plaatsing_sj: LaatstePlaatsingSchooljaar,
	lb_is_pl_voorlopig: PlaatsingStatus,
	lb_is_nieuwkomer: Nieuwkomer,
};

const cijferEnumMap: EnumMap<DWHTable> = {
	cf_fk_ilt: iltEnumMap,
	cf_fk_lb: loopbaanEnumMap,

	cf_fun_is_plaatsing_peildatum_1april: Is1AprilPlaatsing,
	cf_fun_is_plaatsing_peildatum_1feb: Is1FebrPlaatsing,
	cf_fun_is_plaatsing_peildatum_1jan: Is1JanPlaatsing,
	cf_fun_is_plaatsing_peildatum_1juli: Is1JuliPlaatsing,
	cf_fun_is_plaatsing_peildatum_1okt: Is1OktPlaatsing,

	cf_is_diplomavak: Diplomavak,
	cf_is_examendossier: InExamendossier,
	cf_is_telt_mee_voor_slagen: TeltMeeVoorSlagen,
	cf_is_voldoende: Voldoende,
	cf_is_voortgangsdossier: InVoortgangsdossier,

	cf_nm_excijf_uitzondering: ExamencijfersUitzondering,
	cf_map_idu: PrestatieMetPrognose,

	cf_nr_cijfer_afgerond: Cijfer,
};

const doorstroomEnumMap: EnumMap<DWHTable> = {
	ds_fk_ilt_naar: iltEnumMap,
	ds_fk_ilt_van: iltEnumMap,
	ds_fk_lb_van: loopbaanEnumMap,

	ds_fun_doorstroom_ext: DoorstroomExt,
	ds_fun_is_plaatsing_actueel: PlaatsingActueel,
	ds_fun_is_plaatsing_peildatum_1april: Is1AprilPlaatsing,
	ds_fun_is_plaatsing_peildatum_1feb: Is1FebrPlaatsing,
	ds_fun_is_plaatsing_peildatum_1jan: Is1JanPlaatsing,
	ds_fun_is_plaatsing_peildatum_1juli: Is1JuliPlaatsing,
	ds_fun_is_plaatsing_peildatum_1okt: Is1OktPlaatsing,
	ds_fun_overgangmoment: Overgangmoment,

	ds_is_bevorderd: Bevorderd,
	ds_is_prognose: Prognose,
	ds_is_succesvol: Succesvol,

	ds_map_examenstatus: ExamenStatus,

	ds_nm_bbs_uitzondering_van: BovenbouwsuccesUitzondering,
	ds_nm_idu: Prestatie,
	ds_nm_instroom_in_schooljaar: Instroom,
	ds_nm_instroom_van: Instroom,
	ds_nm_obs_uitzondering_van: OnderbouwsnelheidUitzondering,
	ds_nm_op_uitzondering_van: OnderwijspositieUitzondering,
	ds_nm_uitstroom: Uitstroom,
	ds_nm_uitstroom_in_schooljaar: Uitstroom,
};

const periodeEnumMap: EnumMap<DWHTable> = {
	per_is_vakantie_midden: VakantieMidden,
	per_is_vakantie_noord: VakantieNoord,
	per_is_vakantie_zuid: VakantieZuid,
	per_is_weekend: Weekend,
};

const lesregistratieEnumMap: EnumMap<DWHTable> = {
	lr_d_datum: periodeEnumMap,

	lr_fk_ilt: iltEnumMap,
	lr_fk_lb: loopbaanEnumMap,

	lr_fun_geoorloofd: Geoorloofd,
	lr_fun_is_plaatsing_peildatum_1april: Is1AprilPlaatsing,
	lr_fun_is_plaatsing_peildatum_1feb: Is1FebrPlaatsing,
	lr_fun_is_plaatsing_peildatum_1jan: Is1JanPlaatsing,
	lr_fun_is_plaatsing_peildatum_1juli: Is1JuliPlaatsing,
	lr_fun_is_plaatsing_peildatum_1okt: Is1OktPlaatsing,

	lr_nm_lesregistratie: Lesregistratie,
};

const aanwezigheidEnumMap: EnumMap<DWHTable> = {
	aw_d_datum: periodeEnumMap,

	aw_fk_ilt: iltEnumMap,
	aw_fk_lb: loopbaanEnumMap,

	aw_fun_is_plaatsing_peildatum_1april: Is1AprilPlaatsing,
	aw_fun_is_plaatsing_peildatum_1feb: Is1FebrPlaatsing,
	aw_fun_is_plaatsing_peildatum_1jan: Is1JanPlaatsing,
	aw_fun_is_plaatsing_peildatum_1juli: Is1JuliPlaatsing,
	aw_fun_is_plaatsing_peildatum_1okt: Is1OktPlaatsing,

	aw_is_abs_geoorloofd: Geoorloofd,
	aw_is_absent: Afwezigheid,
};

const basisvaardighedenEnumMap: EnumMap<DWHTable> = {
	bv_fun_is_plaatsing_peildatum_1april: Is1AprilPlaatsing,
	bv_fun_is_plaatsing_peildatum_1feb: Is1FebrPlaatsing,
	bv_fun_is_plaatsing_peildatum_1jan: Is1JanPlaatsing,
	bv_fun_is_plaatsing_peildatum_1juli: Is1JuliPlaatsing,
	bv_fun_is_plaatsing_peildatum_1okt: Is1OktPlaatsing,
};

const vakkeuzeEnumMap: EnumMap<DWHTable> = {
	vkk_fun_is_plaatsing_peildatum_1april: Is1AprilPlaatsing,
	vkk_fun_is_plaatsing_peildatum_1feb: Is1FebrPlaatsing,
	vkk_fun_is_plaatsing_peildatum_1jan: Is1JanPlaatsing,
	vkk_fun_is_plaatsing_peildatum_1juli: Is1JuliPlaatsing,
	vkk_fun_is_plaatsing_peildatum_1okt: Is1OktPlaatsing,
};

const medewerkerEnumMap: EnumMap<DWHTable> = {};

const enumMap: EnumMap<DWHTable> = {
	...aanwezigheidEnumMap,
	...basisvaardighedenEnumMap,
	...cijferEnumMap,
	...doorstroomEnumMap,
	...lesregistratieEnumMap,
	...medewerkerEnumMap,
	...vakkeuzeEnumMap,
};

const iltLegendaEnumMap: LegendaEnumMap<DWHTable> = { ...iltEnumMap, ilt_is_lwoo: LWOO, ilt_is_vavo: VAVO };

const leerlingLegendaEnumMap: LegendaEnumMap<DWHTable> = {
	ll_is_cohortstatus_tot_volledig: CohortstatusTot,
	ll_nr_iq_punt: IQPunt,
	ll_nr_uitstroompunt: Uitstroompunt,
};

const medewerkerLegendaEnumMap: LegendaEnumMap<DWHTable> = {
	...medewerkerEnumMap,
	mw_is_ondersteunend: Ondersteunend,
	mw_is_onderwijzend: Onderwijzend,
};

const cijferLegendaEnumMap: LegendaEnumMap<DWHTable> = {
	...cijferEnumMap,

	cf_fk_ilt: iltLegendaEnumMap,
	cf_fks_mw: medewerkerLegendaEnumMap,
	cf_fun_cijferverschil_se_ce_afgerond: CijferVerschil,

	x_afwijking_neg_pos: AfwijkingNegatiefPositief,
	x_cijfer_metzonder_ce: MetZonderCE,
	x_cijfer_ov: CijferOV,
	x_cijfer_ov_advies: CijferOVAdvies,
};

const doorstroomLegendaEnumMap: LegendaEnumMap<DWHTable> = {
	...doorstroomEnumMap,

	ds_fk_ilt_naar: iltLegendaEnumMap,
	ds_fk_ilt_van: iltLegendaEnumMap,
	ds_fk_ll: leerlingLegendaEnumMap,

	ds_fun_verschil_basisschooladvies_van: NiveauVerschil,

	ds_is_apcg_van: APCG,
	ds_is_examenopstroom_van: ExamenOpstroom,
	ds_is_1okt_plaatsing_van: Is1OktPlaatsing,
	ds_is_1febr_plaatsing_van: Is1FebrPlaatsing,
	ds_is_examenkandidaat_van: ExamenKandidaat,
	ds_is_laatste_plaatsing_sj_van: LaatstePlaatsingSchooljaar,

	ds_nm_einduitstroom_vso: EinduitstroomVso,
	ds_nm_tussentijdse_uitstroom_vso: TussentijdseUitstroomVso,
};

const legendaEnumMap: LegendaEnumMap<DWHTable> = {
	...enumMap,
	...cijferLegendaEnumMap,
	...doorstroomLegendaEnumMap,

	aw_is_abs_geoorloofd: AbsentieKlasse,
	bv_fun_referentieniveau_bin: ReferentieNiveau,
	x_aw_is_abs_geoorloofd_historie: AbsentieKlasseHistorie,
	x_cohortrendement: Cohortrendementwaarde,
	x_met_overgang: Overgang,
	x_onderwijspositie_punten: OnderwijspositiePunten,
	x_onderwijsresultaten_actueel: OnderwijsresultatenActueel,
	x_onderwijsresultaten_historie: OnderwijsresultatenHistorie,
	x_percentiel_neg_gem_pos: PercentielNegatiefGemiddeldPositief,
	x_prestatieanalyse_historie: PrestatieanalyseHistorie,
};

export type Entry = {
	key: string | null;
	value: string;
};

@Injectable({
	providedIn: 'root',
})
export class EnumService {
	/**
	 * Geeft de lijst met mogelijke enum waardes terug.
	 *
	 * @param attrPath waarop gezocht moet worden.
	 */
	static enumValues(attrPath: AttrPath): string[] {
		const e = get(enumMap, attrPath);
		return e ? Object.values(e) : [];
	}

	/**
	 * Geeft de lijst met mogelijke enum waardes terug.
	 * Deze functie doet voor enums van string attributen hetzelfde als {@link enumValues}.
	 * Daarnaast voor boolean attributen geeft deze de enum waardes die beschrijven wat de boolean opties betekenen.
	 *
	 * @param attrPath waarop gezocht moet worden.
	 */
	static legendaEnumEntries(attrPath: AttrPath | [LegendaEnumNames]): Entry[] {
		const enumObj = get(legendaEnumMap, attrPath) ?? {};
		const entries = Object.entries<string>(enumObj);
		return entries.map(([key, value]) => ({
			key,
			value,
		}));
	}

	/**
	 * Geeft de enum waarde die hoort bij de meegegeven combinatie.
	 * Roept daarbij de {@link legendaEnumEntries} functie aan.
	 * Gaat daarbij vanuit dat de true waarde als eerste komt en de false waarde als tweede.
	 * Geeft een lege string terug als de waarde null is.
	 *
	 * @param attrPath waarop gezocht moet worden
	 * @param value is de waarde waarvan de enum waarde van opgezocht moet worden.
	 */
	static booleanLegendaEnumEntry(attrPath: AttrPath | [LegendaEnumNames], value: boolean): string {
		if (value == null) return '';

		const entries = this.legendaEnumEntries(attrPath);
		const index = value ? 0 : 1;
		return index < entries.length ? entries[index].value : Object.values(DefaultBooleanEnum)[index];
	}
}
