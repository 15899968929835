<app-base-dialog [sizeClass]="'size-23'">
	<div class="parent">
		<div class="group">
			<div class="toggle">
				<span class="label">Toon trendindicaties</span>
				<app-toggle-button class="right" (toggled)="qp.dispatch('toon-trend', $event)" [checked]="toonTrend()"></app-toggle-button>
			</div>
			<span class="description"> De trendindicaties tonen de ontwikkeling van jouw score ten opzichte van het voorgaande jaar. </span>
		</div>
		<div class="group">
			<div class="toggle">
				<span class="label">Gebruik beschikbare inspectiedata</span>
				<app-toggle-button class="right" (toggled)="qp.dispatch('inspectie-data', $event)" [checked]="useInspectieData()"></app-toggle-button>
			</div>
			<span class="description">
				Voor de jaren waarvan inspectiedata gepubliceerd is, zullen deze gebruikt worden. Voor de overige jaren worden de data uit je
				bronsysteem gebruikt. Door met je muis over een indicator te gaan, kun je zien voor welke schooljaren inspectiedata of data uit het
				bronsysteem wordt gebruikt.
			</span>
		</div>
	</div>
</app-base-dialog>
