import {
	Att,
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	FilterExpression,
} from '../../../services/data.service';
import { Observable } from 'rxjs';
import { MultiAggregator, SingleAggregator, sumOver } from '../../../services/aggregation';
import { Level, Path } from '../../../services/data-tree';
import { FilterName } from '../../../services/filter-config';
import { DashboardContext } from './dashboard-context';
import { BarInfo } from '../../../services/stacked-bars';
import { FilterService } from '../../../services/filter.service';
import { Dashboard } from './dashboard';
import { ToastrService } from 'ngx-toastr';
import { inject } from '@angular/core';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { difference, last } from 'lodash-es';
import { UserService } from '../../../services/user.service';

export interface Attributes {
	count_records: number;
}

export type Filter = {
	name: FilterName;
	value: string | null;
};

export type LinkData = {
	redirect: boolean;
	dashboard: string;
	dataProvider: string | string[];
	filter: FilterExpression | FilterExpression[];
	filters: Filter[];
	groups: AttrPath[];
};

export abstract class BaseDashboardConfig<I extends Attributes, A extends Attributes> extends Dashboard {
	/**
	 * Deze overriden in je subclass. Geeft meer type safety dan getSingleAggregators() overriden zou geven.
	 */
	protected singleAggregators: Partial<{ [ai in keyof A]: SingleAggregator<I, A[ai]> }> = {};

	/**
	 * Deze overriden in je subclass. Geeft meer type safety dan getMultiAggregators() overriden zou geven.
	 */
	protected multiAggregators: MultiAggregator<keyof A, I, A, A[keyof A]>[] = [];

	protected qp = inject(QueryParamStateService);

	protected userService = inject(UserService);

	protected constructor(
		protected filterService: FilterService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	abstract getData(options: DataOptions): Observable<DataResponse<number[]>>;

	/**
	 * Methode om optioneel extra afhandeling te doen met de dashboard data.
	 */
	onContextCreated(_context: DashboardContext<I, A, BaseDashboardConfig<I, A>>): void {
		//Implementatie in child
	}

	getSingleAggregators(): Partial<{ [ai in keyof A]: SingleAggregator<I, A[ai]> }> {
		const defaults = <Partial<{ [ai in keyof A]: SingleAggregator<I, A[ai]> }>>{ count_records: sumOver('count_records') };
		return { ...defaults, ...this.singleAggregators };
	}

	getMultiAggregators(): MultiAggregator<keyof A, I, A, A[keyof A]>[] {
		const defaults: MultiAggregator<keyof A, I, A, A[keyof A]>[] = [];
		return [...defaults, ...this.multiAggregators];
	}

	/**
	 * Organiseert de data voor een rij als "partition", dwz een verzameling stacks waarbij elke stack een verzameling bars is.
	 */
	partitionBarData(rowRoot: Level<A, number[]>, _context: DashboardContext<I, A, BaseDashboardConfig<I, A>>): Path<A, number[]>[][] {
		return [rowRoot.r];
	}

	/**
	 * Bepaalt de presentatie-attributen voor een enkele bar.
	 */
	makeBar(attrs: I, path: Path<A, number[]>, context: DashboardContext<I, A, BaseDashboardConfig<I, A>>): BarInfo {
		return {
			size: attrs.count_records,
			linkData: this.createLinkData(path, context),
			className: 'dashboard-default',
		};
	}

	createLinkData(path: Path<unknown, number[]>, context: DashboardContext<I, A, BaseDashboardConfig<I, A>>): Partial<LinkData> {
		return createLinkData<I, A>(path, context);
	}

	createZoomLinkData(
		atts: Att[],
		groups: string[],
		path: Level<A, number[]>[],
		linkData: Partial<LinkData>,
		options: { ignoreFirst?: number; singleGroup?: boolean } = {}
	) {
		const zoomAtts = atts.filter((att) => this.userService.isAttAllowed(att));
		const nextGroup = options.singleGroup ? zoomAtts[zoomAtts.indexOf(<Att>last(groups)) + 1] : difference(zoomAtts, groups)[0];
		if (nextGroup) {
			const filters = path
				.slice(1)
				.filter((_level, ix) => this.filterService.configs[<FilterName>groups[ix]] !== undefined)
				.map((level, ix) => ({ name: <FilterName>groups[ix], value: level.k }));
			const targetGroups = options.singleGroup ? [nextGroup] : [...groups.slice(options.ignoreFirst ?? 0), nextGroup];
			return {
				groups: targetGroups.map((group) => <AttrPath>group.split('.')),
				filters,
				redirect: false,
			};
		} else {
			return linkData;
		}
	}

	handleClick(filters: Filter[], groups: AttrPath[]) {
		const params = this.filterService.encodeFilters(filters, this.qp.encodeGroupQueryParam(groups));
		this.urlService.redirect([], params);
	}
}

export function getLevelFilters<I extends Attributes, A extends Attributes>(
	context: DashboardContext<I, A, BaseDashboardConfig<I, A>>,
	path: Level<unknown, number[]>[]
) {
	const levelNames = [...context.groupNames, ...context.subgroupNames];
	return path.slice(1).map((level, ix) => new BasicFilterExpression(levelNames[ix].split('.') as AttrPath, level.k));
}

export function createLinkData<I extends Attributes, A extends Attributes>(
	path: Path<unknown, number[]>,
	context: DashboardContext<I, A, BaseDashboardConfig<I, A>>
): Partial<LinkData> {
	const levelFilters = getLevelFilters(context, path);
	const filter = new CompoundFilterExpression([context.filter, ...levelFilters]);
	return { filter, redirect: true };
}
