import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

export interface Menu {
	conditie: (url: string) => boolean;
	menus: MenuItem[];
}

export interface MenuItem {
	qp?: Params;
	naam: string;
	url: string;
	subMenuName?: string;
	subMenus?: MenuItem[];
}

@Injectable({
	providedIn: 'root',
})
export abstract class AppAuthenticationService {
	abstract clearSessionStorage(): void;

	abstract getOAuthParams(): {};

	abstract isUrlAllowed(url: string): boolean;

	abstract getMenus(): Menu[];
}
