/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum Cijferkolomtype {
  LAATSTE_RAPPORTCIJFER = 'Laatste rapportcijfer',
  LAATSTE_GEMIDDELDE = 'Laatste gemiddelde',
  RAPPORTCIJFER = 'Rapportcijfer',
  GEMIDDELDE = 'Gemiddelde',
  SE_CIJFER = 'SE-cijfer',
  CE_CIJFER = 'CE-cijfer',
  EINDCIJFER = 'Eindcijfer',
  TOETS = 'Toets',
  ADVIES = 'Advies'
}
