import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogHeaderComponent, BaseDialogComponent } from '@cumlaude/shared-components-dialogs';
import { FavorietenListComponent } from '../../../favorieten-list/favorieten-list.component';

@Component({
	selector: 'app-favorieten-dialog',
	templateUrl: './favorieten-dialog.component.html',
	styleUrls: ['./favorieten-dialog.component.scss'],
	standalone: true,
	imports: [BaseDialogComponent, DialogHeaderComponent, FavorietenListComponent],
})
export class FavorietenDialogComponent {
	createMap = new Subject<void>();
	createFavoriet = new Subject<void>();
}
