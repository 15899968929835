<div (click)="showDropdown = !showDropdown" class="value-box text-content" [class.selection-open]="showDropdown" [class.has-selection]="!isEmpty()">
	@if (isEmpty()) {
		<div class="placeholder">Selecteren...</div>
	} @else {
		<div class="selected-value" [title]="value!.naam">{{ value!.naam }}</div>
	}
	@if (showDropdown || isEmpty()) {
		<i class="icon icon-arrow-open icon-medium chevron-icon"></i>
	}
	@if (!showDropdown && !isEmpty()) {
		<i class="icon icon-exit icon-medium chevron-icon" (click)="remove($event)"></i>
	}
</div>
@if (showDropdown) {
	<div
		(clickOutside)="closeDropDown($event)"
		[clickOutsideEvents]="'click,touchend'"
		[delayClickOutsideInit]="true"
		class="selection-box"
		#dropdown
	>
		@for (optie of mappen; track optie) {
			<div class="optie" [class.is-selected]="isSelected(optie)" (click)="select(optie)">
				<span class="svg-folder icon"></span>
				<span class="optie-text text-content">{{ optie.naam }}</span>
			</div>
		}
		@if (editing) {
			<div class="editing">
				<app-immediate-input [init]="template.naam" (done)="create($event)"></app-immediate-input>
			</div>
		}
		<div class="optie nieuwe-map" (click)="editing = true">
			<span class="svg-add-folder-grey icon"></span>
			<span class="optie-text text-content">Nieuwe map aanmaken</span>
		</div>
	</div>
}
