import { Component, OnInit } from '@angular/core';
import { AuthService } from '@cumlaude/shared-authentication';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserAuthenticationService } from '../../services/user-authentication.service';

@Component({
	selector: 'app-somtoday',
	templateUrl: './somtoday.component.html',
	styleUrls: ['./somtoday.component.scss'],
	standalone: true,
})
export class SomtodayComponent implements OnInit {
	constructor(
		private authService: AuthService,
		private oAuthService: OAuthService,
		private userAuthenticationService: UserAuthenticationService
	) {}

	ngOnInit(): void {
		this.authService.clearSessionStorage();
		this.userAuthenticationService.clearSessionStorage();
		this.oAuthService.initCodeFlow('', { federate: 'somtoday' });
	}
}
