import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { formatNumber, formatPercent, NgTemplateOutlet } from '@angular/common';
import { isUndefined } from 'lodash-es';
import { LinechartProps } from '../../../shared/dashboard/linechart-table/linechart/linechart.component';
import { DashboardVariant } from '../../../services/weergave-opties';
import { TooltipElement, TooltipDirective } from '@cumlaude/shared-components-overlays';
import { OnderwijsresultatenA } from '../onderwijsresultaten.component';

interface OnderwijsresultatenActueelInputRow {
	bron_schooljaren: string[][];
	or_nr_norm_landelijk: number;
	or_nr_norm_gecorrigeerd: number;
	or_nr_score: number;
	or_nr_score_vorig: number | undefined;
	or_nr_verschil_tov: number | undefined;
	or_nr_verschil2_tov: number | undefined;
	or_q_leerlingen: number;
	schooljaar1: number;
	schooljaar2: number;
}

export interface OnderwijsresultatenInputRow {
	indicator: string;
	key: string | null;
	or_nr_verschil: number | undefined;
	or_nr_verschil_vorig: number | undefined;
	actueelRow?: OnderwijsresultatenActueelInputRow;
	linechartProps?: LinechartProps<OnderwijsresultatenA>;
}

export interface OnderwijsresultatenRow {
	indicator: string;
	key: string | null;
	or_nr_verschil: number | undefined;
	or_nr_verschil_vorig: number | undefined;
	jaren_onvoldoende_class: string | undefined;
	positie_norm: string;
	verschil_class: string;
	actueelRow?: OnderwijsresultatenActueelRow;
	linechartProps?: LinechartProps<OnderwijsresultatenA>;
}

export interface OnderwijsresultatenActueelRow extends OnderwijsresultatenActueelInputRow {
	tooltip: TooltipElement[];
	graph_class: string | undefined;
}

@Component({
	selector: 'app-card-or-cell',
	templateUrl: './card-or-cell.component.html',
	styleUrls: ['./card-or-cell.component.scss'],
	standalone: true,
	imports: [TooltipDirective, NgTemplateOutlet],
})
export class CardOrCellComponent implements OnChanges {
	@Input()
	title!: string;

	@Input()
	variant!: DashboardVariant;

	@Input()
	peiljaar!: number;

	@Input()
	aantalJaren!: number;

	@Input()
	inputRows!: OnderwijsresultatenInputRow[];

	@Input()
	template!: TemplateRef<any>;

	rows!: OnderwijsresultatenRow[];

	jaren_onvoldoende_class: string | undefined;

	ngOnChanges(changes: SimpleChanges): void {
		if (!('inputRows' in changes)) return;

		this.rows = this.inputRows.map((row) => this.generateOnderwijsresultatenRow(row));

		if (this.rows.length == 1 && ['op', 'obs'].includes(this.rows[0].indicator))
			this.jaren_onvoldoende_class = this.rows[0].jaren_onvoldoende_class;
	}

	private generateOnderwijsresultatenRow(row: OnderwijsresultatenInputRow): OnderwijsresultatenRow {
		let verschilClass = 'score-boven-norm';
		let jarenOnvoldoendeClass = undefined;
		let positieNorm = 'boven';
		if (!isUndefined(row.or_nr_verschil) && row.or_nr_verschil < 0) {
			verschilClass = 'score-onder-norm';
			positieNorm = 'onder';
			jarenOnvoldoendeClass = 'indicator-1jaar';

			if (!isUndefined(row.or_nr_verschil_vorig) && row.or_nr_verschil_vorig < 0) {
				jarenOnvoldoendeClass = 'indicator-2jaren';
			}
		}

		let actueelRow = this.generateActueelRow(row);

		return {
			...row,
			actueelRow: actueelRow,
			jaren_onvoldoende_class: jarenOnvoldoendeClass,
			positie_norm: positieNorm,
			verschil_class: verschilClass,
		};
	}

	private generateActueelRow(row: OnderwijsresultatenInputRow): OnderwijsresultatenActueelRow | undefined {
		if (isUndefined(row.actueelRow)) return undefined;

		const tooltip = this.generateTooltip(row.indicator, row.or_nr_verschil!, row.actueelRow);
		let graphClass = undefined;
		if (!isUndefined(row.actueelRow.or_nr_verschil_tov)) {
			if (row.actueelRow.or_nr_verschil_tov < 0) {
				graphClass = 'svg-graph-dalend-warning';
				if (!isUndefined(row.actueelRow.or_nr_verschil2_tov) && row.actueelRow.or_nr_verschil2_tov < 0) {
					graphClass = 'svg-graph-dalend-error';
				}
			} else if (row.actueelRow.or_nr_verschil_tov > 0) {
				graphClass = 'svg-graph-stijgend';
			}
		}
		return {
			...row.actueelRow,
			graph_class: graphClass,
			tooltip,
		};
	}

	private generateTooltip(indicator: string, or_nr_verschil: number, row: OnderwijsresultatenActueelInputRow): TooltipElement[] {
		const tooltipElements: TooltipElement[] = [];

		let score;
		if (indicator === 'exc') score = formatNumber(row.or_nr_score, 'nl_NL', '1.2-2');
		else score = formatPercent(row.or_nr_score, 'nl_NL', '1.2-2');
		tooltipElements.push({ label: `Score ${row.schooljaar1}`, value: score });

		this.addNormenToTooltip(indicator, row, tooltipElements, or_nr_verschil);
		this.addVorigJaarData(row, indicator, tooltipElements);

		tooltipElements.push({ label: 'Aantal', value: `${formatNumber(row.or_q_leerlingen, 'nl_NL', '1.0-2')}` });

		row.bron_schooljaren.forEach((bronnen) => tooltipElements.push({ label: bronnen[0], value: bronnen[1] }));

		return tooltipElements;
	}

	private addVorigJaarData(row: OnderwijsresultatenActueelInputRow, indicator: string, tooltipElements: TooltipElement[]) {
		if (isUndefined(row.or_nr_score_vorig)) {
			tooltipElements.push({ label: `Score ${row.schooljaar2}`, value: '-' });
		} else {
			let scoreVorig;
			if (indicator === 'exc') scoreVorig = formatNumber(row.or_nr_score_vorig, 'nl_NL', '1.2-2');
			else scoreVorig = formatPercent(row.or_nr_score_vorig, 'nl_NL', '1.2-2');
			tooltipElements.push({ label: `Score ${row.schooljaar2}`, value: scoreVorig });
		}

		if (isUndefined(row.or_nr_verschil_tov)) {
			tooltipElements.push({ label: `Verschil ${row.schooljaar2}`, value: '-' });
		} else {
			tooltipElements.push({ label: `Verschil ${row.schooljaar2}`, value: formatNumber(row.or_nr_verschil_tov, 'nl_NL', '1.2-2') });
		}
	}

	private addNormenToTooltip(
		indicator: string,
		row: OnderwijsresultatenActueelInputRow,
		tooltipElements: TooltipElement[],
		or_nr_verschil: number
	) {
		if (indicator !== 'op') {
			let gecorrigeerdeNorm;
			if (indicator === 'exc') gecorrigeerdeNorm = formatNumber(row.or_nr_norm_gecorrigeerd, 'nl_NL', '1.2-2');
			else gecorrigeerdeNorm = formatPercent(row.or_nr_norm_gecorrigeerd, 'nl_NL', '1.2-2');
			tooltipElements.push({ label: 'Gecorrigeerde Norm', value: gecorrigeerdeNorm });
		}

		tooltipElements.push({ label: 'Verschil Norm', value: formatNumber(or_nr_verschil, 'nl_NL', '1.2-2') });

		let landelijkeNorm;
		if (indicator === 'exc') landelijkeNorm = formatNumber(row.or_nr_norm_landelijk, 'nl_NL', '1.2-2');
		else landelijkeNorm = formatPercent(row.or_nr_norm_landelijk, 'nl_NL', '1.2-2');
		tooltipElements.push({ label: 'Landelijke Norm', value: landelijkeNorm });
	}

	getExamencijfersTooltip() {
		return 'Voor dit peiljaar berekent de inspectie geen examencijfers in verband met coronajaren. Kijk in de handleiding voor meer informatie.';
	}
}
