<app-base-dialog sizeClass="size-25" [topBarTemplate]="topBar" [bottomBarTemplate]="bottomBar">
	<ng-template #topBar>
		<app-dialog-header title="Favoriet aanmaken"></app-dialog-header>
	</ng-template>
	<div class="content">
		<div class="row">
			<div class="row-title">
				<span>Naam favoriet </span>
				<span class="required-icon">*</span>
			</div>
			<input
				class="name-input"
				#inp
				required="true"
				type="text"
				(input)="updateName(inp.value)"
				[value]="naam"
				(keyup.enter)="close()"
				cdkFocusInitial
			/>
		</div>
		<div class="row">
			<div class="row-title">
				<span>Map</span>
			</div>
			<div class="dropdown-container">
				<app-mappen-dropdown
					[mappen]="getMappen()"
					[(value)]="selectedMap"
					[template]="data.template"
					(created)="nieuweMappen.push($event)"
				></app-mappen-dropdown>
			</div>
		</div>
	</div>
	<ng-template #bottomBar>
		<app-button buttonType="secondary" (clickit)="dialogRef.close()">Annuleren</app-button>
		<app-button [enabled]="isNotEmpty()" (clickit)="dialogRef.close(getResult())">Opslaan</app-button>
	</ng-template>
</app-base-dialog>
