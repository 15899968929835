@if (medewerker$ | async; as medewerker) {
	<div class="dashboard-cijfers">
		<app-filter-panel
			[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
			[allFilters]="getAllFilters()"
			(filterExpressionsChange)="filterExpressions = $event"
			endpoint="/cijfers"
			[permanentFilterExpressions]="getDashboardFilters(permanentFilterExpressions, medewerker.medewerker)"
			[overrideDefault]="getSchooljaarFilterOverride(medewerker.schooljaarInfo)"
		></app-filter-panel>
		<section class="dashboard">
			<app-dashboard-header [legenda]="['x_cijfer_ov']" [variant]="variant"></app-dashboard-header>
			@switch (variant) {
				@case ("Actueel") {
					<app-card-list
						[config]="this"
						[filters]="filterExpressions"
						[permanentFilters]="getDashboardFilters(permanentFilterExpressions, medewerker.medewerker)"
						[groups]="actueelGroups"
						[sortHeaders]="actueelSortHeaders"
						[template]="cardTemplate"
						data-exportable
					>
						<ng-template #cardTemplate let-row="row" let-context="context" let-group="group">
							<app-card-cell-header [title]="group['cf_nm_lesgroep']"></app-card-cell-header>
							<app-hbar-partition [data]="generateHbarPartition(row, context)" [axis]="false"></app-hbar-partition>
							<app-card-cell-footer [measures]="generateMeasures(row)"></app-card-cell-footer>
						</ng-template>
					</app-card-list>
				}
				@case ("Historie") {
					<app-card-list
						[config]="this"
						[filters]="filterExpressions"
						[permanentFilters]="getDashboardFilters(permanentFilterExpressions, medewerker.medewerker)"
						[groups]="historieGroups"
						[subgroups]="subGroups"
						[sortHeaders]="historieSortHeaders"
						[template]="cardTemplate"
						data-exportable
					>
						<ng-template #cardTemplate let-row="row" let-context="context" let-group="group">
							<app-card-cell-header [title]="group['cf_fk_lb.lb_nm_leerfase']"></app-card-cell-header>
							<app-vbar-series [yAxis]="yAxis" [partitions]="generateVbarPartitions(row, context)"></app-vbar-series>
						</ng-template>
					</app-card-list>
				}
			}
		</section>
	</div>
}
