import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageEnum } from '@cumlaude/metadata';
import { BugsnagService } from '@cumlaude/bugsnag';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
	constructor(
		protected toastr: ToastrService,
		protected bugsnag: BugsnagService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((err) => {
				if (err.status === HttpStatusCode.GatewayTimeout && err.error === ErrorMessageEnum.QUERY_TIMEOUT) {
					this.bugsnag.notify(err);
					this.toastr.error('Timeout bij ophalen data');
					return [];
				}

				return throwError(() => err);
			})
		);
	}
}
