@if (singleColumnTooltip) {
	<div class="tooltip-element tooltip-container single-column-tooltip" [class]="tooltipClass">
		@for (element of singleColumnTooltip; track element) {
			<span class="tooltip-element">
				{{ element }}
			</span>
		}
	</div>
}
@if (multiColumnTooltip) {
	<div class="tooltip-element tooltip-container multi-column-tooltip" [class]="tooltipClass">
		@for (element of multiColumnTooltip; track element) {
			<span class="tooltip-element label" [class.text-bold]="element.bold"> {{ element.label }}</span>
			<span class="tooltip-element" [class.text-bold]="element.bold">{{ element.value }}</span>
		}
	</div>
}
