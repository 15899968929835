import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { ExportColumnDef, ExportTable, getDataMeasures } from '../data-tree-table/data-tree-table';
import { DataResponse, DataService, ExportDataOptions, FilterExpression } from '../../../services/data.service';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { fromPairs, isFunction, isUndefined, last, omitBy, zip } from 'lodash-es';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { CardSortHeadersComponent, SortHeader } from './card-sort-headers/card-sort-headers.component';
import { Sort } from '../../components/table/table/table.component';
import { PartialPath, Path, shrub, sortByMeasure } from '../../../services/data-tree';
import { Router } from '@angular/router';
import { UrlService } from '../../../services/url.service';
import { ErrorMessage, ErrorMessageEnum } from '@cumlaude/metadata';
import { CardListConfig } from './card-list-config';
import { Attributes, LinkData } from '../base-dashboard/base-dashboard-config';
import { DashboardContext, isErrorContext, isHappyContext } from '../base-dashboard/dashboard-context';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { DashboardVariant } from '../../../services/weergave-opties';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';

export function getGroupKeys<G>(groupNames: string[], path: PartialPath<unknown, unknown>): G {
	const groupLevels = path.slice(1, groupNames.length + 1);
	return <G>fromPairs(
		zip(
			groupNames,
			groupLevels.map((lvl) => lvl.k)
		)
	);
}

export interface ExportCardListColumnDef<I extends Attributes, G, A extends Attributes, C extends CardListConfig<I, G, A>>
	extends ExportColumnDef<A> {
	getValue(path: Path<A, number[]>, context: DashboardContext<I, A, C>): any;
}

@Component({
	selector: 'app-card-list',
	templateUrl: './card-list.component.html',
	styleUrls: ['./card-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CardSortHeadersComponent, NgTemplateOutlet, AsyncPipe],
})
export class CardListComponent<I extends Attributes, G, A extends Attributes, C extends CardListConfig<I, G, A>> extends BaseDashboardComponent<
	I,
	A,
	C
> {
	@Input()
	sortHeaders: SortHeader[] = [];

	@Input()
	sortMeasure?: (row: Path<A, unknown>) => number;

	@Input()
	template!: TemplateRef<any>;

	/**
	 * Hiermee kan de DashboardVariant "vertraagd" naar de template doorgegeven worden, dwz pas als het viewmodel geüpdate is.
	 */
	@Input()
	variant?: DashboardVariant;

	@Input()
	elementPageBreak: boolean = false;

	private cardClick$ = new Subject<Path<A, number[]>>();

	vm$ = new ReplaySubject<{ context?: DashboardContext<I, A, CardListConfig<I, G, A>>; error?: ErrorMessage; variant?: DashboardVariant }>(1);

	public ErrorEnum: typeof ErrorMessageEnum = ErrorMessageEnum;

	constructor(
		protected router: Router,
		protected urlService: UrlService,
		protected dataService: DataService
	) {
		super(router, urlService);
		this.subscriptions.push(
			this.dashboardContext$
				.pipe(
					filter((value) => isHappyContext(value)),
					switchMap((context) => this.cardClick$.pipe(map((row) => this.config.createLinkData(row, <DashboardContext<I, A, C>>context))))
				)
				.subscribe((linkData) => this.handleClick(linkData))
		);

		this.subscriptions.push(
			this.dashboardContext$
				.pipe(
					switchMap((context) => {
						if (isErrorContext(context)) {
							return of({
								error: context,
							});
						}

						if (!isUndefined(this.sortMeasure)) {
							if (!isUndefined(context.dataRoot)) sortByMeasure(context.dataRoot, this.sortMeasure, 'desc');

							return of({
								context,
								variant: this.variant,
							});
						}

						if (this.sortHeaders.length == 0) {
							return of({
								context,
								variant: this.variant,
							});
						}

						return this.qp.observe('sortOrder', this.getDefaultSortOrder()).pipe(
							map((sortState) => {
								if (!isUndefined(context.dataRoot)) this.sortData(sortState, context);

								return {
									context,
									variant: this.variant,
								};
							})
						);
					})
				)
				.subscribe(this.vm$)
		);
	}

	protected createDashboardContext(resp: DataResponse<number[]>, f: FilterExpression): DashboardContext<I, A, C> {
		const context = super.createDashboardContext(resp, f);
		if (!context.dataRoot) return context;
		const dataRoot = shrub(context.dataRoot);
		return { ...context, dataRoot };
	}

	private getDefaultSortOrder(): Sort {
		if (this.sortHeaders.length == 0) return { active: '', direction: '' };

		const defaultSortHeader = this.sortHeaders[0];
		return {
			active: defaultSortHeader.sortTarget.join('.'),
			direction: defaultSortHeader.defaultDirection,
		};
	}

	private sortData(sortState: Sort, context: DashboardContext<I, A, CardListConfig<I, G, A>>) {
		const elements = [...context.groupNames, ...context.measureNames];
		const sortIndex = elements.indexOf(sortState.active) + 1;

		sortByMeasure(
			context.dataRoot!,
			(path: Path<A, number[]>) => {
				const firstRow = last(path)!.r[0];
				const keys = [...firstRow.map((value) => value.k), ...(<number[]>last(firstRow)!.v)];
				return keys[sortIndex];
			},
			<'asc' | 'desc'>sortState.direction
		);
	}

	onCardClick(row: Path<A, number[]>) {
		if (!this.config.clickable) return;
		this.cardClick$.next(row);
	}

	protected handleClick(linkData: Partial<LinkData>) {
		const { redirect } = linkData;
		if (redirect) {
			this.urlService.navigate(linkData);
		} else {
			const { groups, filters } = linkData;
			this.config.handleClick(filters!, groups!);
		}
	}

	getGroupKeys(row: Path<A, number[]>, context: DashboardContext<I, A, CardListConfig<I, G, A>>): G {
		return getGroupKeys(context.groupNames, row);
	}

	getMeasures(row: Path<A, number[]>, context: DashboardContext<I, A, CardListConfig<I, G, A>>): Partial<Attributes> {
		return getDataMeasures(context.measureNames, <number[]>last(row)!.v);
	}

	exportAsTable(options: ExportDataOptions): Observable<Blob> {
		return this.vm$.pipe(
			take(1),
			map(({ context }) => this.getExportTable(context!, options)),
			switchMap((exportTable) => this.dataService.getTableExport(exportTable))
		);
	}

	protected getExportTable(context: DashboardContext<I, A, CardListConfig<I, G, A>>, options: ExportDataOptions): ExportTable<A> {
		const exportTable = context.config.getExportTable(context, options);
		if (exportTable) return exportTable;

		const columns = context.config.getExportColumnDefs(context);
		return {
			data: [
				{
					columns,
					rows:
						context?.dataRoot?.r.map((path) => {
							return columns.map((col, ix) => {
								const format = isFunction(col.format) ? col.format({ _id: ix.toString(), _path: path }) : undefined;
								const value = col.getValue(path, context);
								const type = isFunction(col.type) ? col.type({ _id: ix.toString(), _path: path }) : undefined;
								if (format || type) {
									return omitBy({ value, format, type }, isUndefined);
								}
								return value;
							});
						}) ?? [],
				},
			],
			options,
		};
	}
}
